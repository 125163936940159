import React from 'react'
import { Link } from 'react-router-dom'
import { navBarDataDispatcher } from './NavBarData'


class TabButton extends React.Component {
    constructor(props) {
        super()


        this.buttonURL = props.buttonURL
        this.buttonTitle = props.buttonTitle
        this.buttonParam = props.buttonParam
        
        this.isDropdown = props.isDropdown
        this.dropdownLinks = props.dropdownLinks
        this.buttonID = "custom-button-" + this.buttonTitle
        this.showBorder = props.showBorder
        this.isDataNavBar = props.isDataNavBar
        this.paramKey = props.paramKey
        
        if (this.showBorder === null || this.showBorder === undefined || this.showBorder === false) {
            this.showBorder = false
        }
        
        this.className = "border-primary p-2 rounded-pill custom-rounded-button"
        if (this.showBorder) {
            this.className = "border border-primary p-2 rounded-pill custom-rounded-button"
        }


       
        if (this.isDataNavBar) {
            var urlString = window.location
            var currURL = new URL(urlString)
            
            if (currURL.searchParams.get(this.paramKey) === this.buttonParam) {
            // if (`?${window.location.href.split("?")[1]}` === this.buttonURL) {
                this.className = "border-primary p-2 rounded-pill custom-rounded-button custom-rounded-button-active"
            }
        }
        
        if (`${window.location.pathname}` === this.buttonURL.split("?")[0]) {
            this.className = "border-primary p-2 rounded-pill custom-rounded-button custom-rounded-button-active"
        }



        this.state = {
            dropdownBool: false,
            className: this.className
        }

    }

    componentDidMount() {
        
        // const id = "custom-button-" +this.buttonTitle

        // window.onclick = function(event) {
        //     console.log(event.target.id.toString(),id)
        //     if (event.target.id.toString() === id) {
        //         this.setState({dropdownBool:false,className:this.className})
        //     }
        // }
    }

    handleDropdownClick = () => {

        if (!this.state.dropdownBool) {
            this.setState({ dropdownBool: !this.state.dropdownBool, className: "border-primary p-2 rounded-pill custom-rounded-button custom-rounded-button-dropdown-selected" })
        } else {
            this.setState({ dropdownBool: !this.state.dropdownBool, className: this.className })
        }

    }

    render() {

        if (this.isDropdown) {
            return (
                <div className="custom-rounded-button-dropdown">
                    {/* <Link className={this.className} to={this.buttonURL}> */}
                    <a href="#!" id={this.buttonID} className={this.state.className} onClick={this.handleDropdownClick}>
                        {/* <div className="border-primary rounded-pill custom-rounded-button"> */}
                        {this.buttonTitle}
                    </a>
                    {/* </Link> */}
                    {/* <p>Hello</p> */}
                    <div className="custom-rounded-button-dropdown-content" hidden={!this.state.dropdownBool} style={this.state.dropdownStyle}>
                        <a className="p-2" href="#!">Hello</a>
                        <br />
                        <a className="p-2" href="#!">Hello</a>
                        <br />
                        <a className="p-2" href="#!">Hello</a>
                        <a className="p-2" href="#!">Hello</a>
                    </div>
                </div>
            )
        }

        return (
            <Link className={this.className} to={this.buttonURL} onClick={()=>{
                // console.log(window.location.href.split("?")[1])
                navBarDataDispatcher.dispatch({
                    actionType:"reload-navbar-data"
                })
            }}>
                {this.buttonTitle}
            </Link>
        )
    }

}


export default TabButton
