import main_config from './config'

// var MSURL = "http://127.0.0.1:8080/api/ecom/"
// var ADMINURL = "http://127.0.0.1:8082/api/ecom/"
var FRONTURL = ""
var ADMINURLFRONT = ""
// var MSURL = "http://192.168.0.111:8082/"
// var MSURL = "https://es.c3rl.com/a/0.1/"
// var MSURL = "http://localhost:8082/a/vinir/"




// var ADMINURL = "http://ecom-admin-54d15320d5cdca9a6d6e.c3rl.com/api/ecom/"
// var MSURL = "http://ecom-admin-54d15320d5cdca9a6d6e.c3rl.com/api/ecom/"

// var ADMINURL = "https://ecom-admin-12bffb236c34da8e8793.kasturiopulence.com/api/ecom/"
// var MSURL = "https://ecom-admin-12bffb236c34da8e8793.kasturiopulence.com/api/ecom/"

var ADMINURL = main_config.adminurl
var MSURL = main_config.msurl

export {
    FRONTURL,
    ADMINURL,
    ADMINURLFRONT
}

export default MSURL;