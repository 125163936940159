import React from 'react'
import NavBar from '../Components/NavBar';
import { NavBarData } from '../Components/NavBarData';
import { Window } from '../Components/Window';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ItemSearch } from '../Components/ItemSearch';
import { toastsDispatcher } from '../Components/Toasts';
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBox, faCheck, faCheckCircle, faCircleNotch, faDoorOpen, faExclamationCircle, faEye, faEyeSlash, faPaperPlane, faTimes, faTimesCircle, faUndo, faWrench } from '@fortawesome/free-solid-svg-icons';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearchTable } from '../Components/ItemSearchTable';
import jsPDF from 'jspdf';
import ReactQuill from 'react-quill';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { ADMINURL, ADMINURLFRONT } from '../Components/MSURLS';
import { ecommerce_helper_function_get_formatted_price, helper_function_info_dialog, helper_function_validat_email } from '../Components/helper_functions';
import { networkCallPost, networkCallPostFile } from '../Components/network';
import libphonenumber from 'google-libphonenumber';
import AuthenticatedImage from '../Components/AuthenticatedImage'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const phoneRegion = 'IN';


var ACCT = Cookies.get('ACCT')
var TP = Cookies.get('TP')

var adminAddUserDialogIndex = null
var adminEditUserDialogIndex = null

var viewAdminUsersDispatcher = null

var { DateTime } = require('luxon');

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}

function AdminRoleSingleRow(props) {

    return <div className='form-group' style={{
        webkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        cursor: 'pointer'
    }}>
        <label>{props.title}</label>
        <div className='d-flex flex-wrap'>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id={`${String(props.title).toLowerCase()}-read`} checked={props.state.r} onChange={(e) => {
                        if (props.onRChange) {
                            props.onRChange(e.target.checked)
                        }
                    }} style={{
                        cursor: 'pointer'
                    }} />
                    <label className="form-check-label" htmlFor={`${String(props.title).toLowerCase()}-read`} style={{
                        cursor: 'pointer'
                    }}>
                        Read
                    </label>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id={`${String(props.title).toLowerCase()}-write`} checked={props.state.w} onChange={(e) => {
                        if (props.onWChange) {
                            props.onWChange(e.target.checked)
                        }
                    }} style={{
                        cursor: 'pointer'
                    }} />
                    <label className="form-check-label" htmlFor={`${String(props.title).toLowerCase()}-write`} style={{
                        cursor: 'pointer'
                    }}>
                        Write
                    </label>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id={`${String(props.title).toLowerCase()}-delete`} checked={props.state.d} onChange={(e) => {
                        if (props.onDChange) {
                            props.onDChange(e.target.checked)
                        }
                    }} style={{
                        cursor: 'pointer'
                    }} />
                    <label className="form-check-label" htmlFor={`${String(props.title).toLowerCase()}-delete`} style={{
                        cursor: 'pointer'
                    }}>
                        Delete
                    </label>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id={`${String(props.title).toLowerCase()}-edit`} checked={props.state.e} onChange={(e) => {
                        if (props.onEChange) {
                            props.onEChange(e.target.checked)
                        }
                    }} style={{
                        cursor: 'pointer'
                    }} />
                    <label className="form-check-label" htmlFor={`${String(props.title).toLowerCase()}-edit`} style={{
                        cursor: 'pointer'
                    }}>
                        Edit
                    </label>
                </div>
            </div>
        </div>
    </div>
}

class AdminEditUser extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            firstname: this.data.firstname,
            lastname: this.data.lastname,
            email: this.data.email,
            phone: this.data.phone,
            password: "",
            retypepassword: "",
            profilepicture: this.data.pp,

            firstnameclassname: "form-control",
            lastnameclassname: "form-control",
            emailclassname: "form-control",
            phoneclassname: "form-control",
            passwordclassname: "form-control",
            retypepasswordclassname: "form-control",

            firstnameerror: "",
            lastnameerror: "",
            emailerror: "",
            phoneerror: "",
            passworderror: "",
            retypepassworderror: "",

            retypepasswordtype: "password",

            roles: this.data.roles

        }
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg" && input.target.files[ii]["type"] !== "image/png") {
                helper_function_info_dialog("Add Image", <span><span className='text-danger mr-1'><FontAwesomeIcon icon={faExclamationCircle} /></span>Only JPEG and PNG files are allowed.</span>, true, "Okay", null)
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            // toastsDispatcher.dispatch({
            //     actionType: 'add-toast',
            //     title: "Add Image",
            //     message: <span>Uploading image <b>{ff.name}</b>.</span>,
            //     date: new Date(),
            //     isInfo: true
            // })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image

                const image_data = dataURItoBlob(e.target.result)
                const image_name = ff.name


                networkCallPostFile(ADMINURL, "image", {}, {}, image_data, image_name, (data) => {


                    that.setState({ isLoading: false })

                    const response = data

                    if (!("status" in response) || !("payload" in response)) {


                        return
                    }

                    if (response["status"] !== "success") {


                        return
                    }
                    var tmpImage = {
                        image: response["payload"]["id"],
                        imagen: ff.name,
                        "alt": "",
                        "eqr": false,
                        "qp": "bl",
                        "qs": 100,
                        ip: false
                    }
                    that.setState({ profilepicture: tmpImage })


                }, (data) => {


                    that.setState({ isLoading: false })

                }, (data) => {
                    console.log(data)
                    that.setState({ isLoading: false })

                }, ACCT, null, "status")







            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    resetFormClasses = (callback) => {
        this.setState({
            firstnameclassname: "form-control",
            lastnameclassname: "form-control",
            emailclassname: "form-control",
            phoneclassname: "form-control",
            passwordclassname: "form-control",
            retypepasswordclassname: "form-control",
        }, () => {
            if (callback) {
                callback()
            }
        })
    }

    validateFields = () => {

        var isError = false

        if (this.state.firstname.trim().length < 1) {
            isError = true
            this.setState({
                firstnameclassname: "form-control is-invalid",
                firstnameerror: "Firstname is required"
            })
            document.getElementById("userfirstname").focus()
        }

        if (this.state.lastname.trim().length < 1) {
            isError = true
            this.setState({
                lastnameclassname: "form-control is-invalid",
                lastnameerror: "Lastname is required"
            })
            document.getElementById("userlastname").focus()
        }

        if (!helper_function_validat_email(this.state.email)) {
            isError = true
            this.setState({
                emailclassname: "form-control is-invalid",
                emailerror: "Invalid email"
            })
            document.getElementById("useremail").focus()
        }


        if (this.state.phone.length === 0) {
            this.setState({
                phoneclassname: "form-control is-invalid",
                phoneerror: "Please enter a phone number"
            })
            isError = true
            document.getElementById("userphone").focus()
        } else {
            try {
                const number = phoneUtil.parseAndKeepRawInput(this.state.phone, phoneRegion);
                if (!phoneUtil.isValidNumber(number, 'IN')) {
                    this.setState({
                        phoneclassname: "form-control is-invalid",
                        phoneerror: "Invalid phone number"
                    })
                    isError = true
                    document.getElementById("userphone").focus()
                }
            } catch (error) {
                this.setState({
                    phoneclassname: "form-control is-invalid",
                    phoneerror: "Invalid phone number"
                })
                isError = true
                document.getElementById("userphone").focus()
            }

        }

        if (this.state.retypepassword.length > 0) {
            if (this.state.password !== this.state.retypepassword) {
                this.setState({
                    retypepasswordclassname: "form-control is-invalid",
                    retypepassworderror: "Password does not match"
                })
                isError = true
                document.getElementById("userretypepassword").focus()
            }
        }


        if (isError) {
            return
        }

        this.updateUser()
    }

    updateUser() {
        this.setState({ isLoading: true, isSuccess: false, isError: false })

        networkCallPost(ADMINURL, "adminapi", {}, {}, {
            _id: this.data._id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            pt: this.state.password,
            pp: this.state.profilepicture,
            roles: this.state.roles

        },
            (response) => {
                this.setState({ isLoading: false, isError: false, isSuccess: true })
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Edit User",
                    message: <div>
                        <span className='text-success'><FontAwesomeIcon icon={faCheckCircle} /></span>&nbsp;User successfully edited.
                    </div>
                })
                fullDialogV2Dispatcher.dispatch({
                    actionType: "close-dialog",
                    dialogIndex: adminEditUserDialogIndex
                })


                viewAdminUsersDispatcher.dispatch({
                    actionType: "get-data"
                })
            },
            (response) => {
                this.setState({ isLoading: false, isError: true, isSuccess: false })
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Edit User",
                    message: <div>
                        <span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} /></span>&nbsp;{response.payload}
                    </div>
                })


            },
            (response) => {
                console.log(response)
                this.setState({ isLoading: false, isError: true, isSuccess: false })
            },
            ACCT, "upd")
    }

    clearFields() {
        this.setState({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            password: "",
            retypepassword: "",
            profilepicture: null,

            firstnameclassname: "form-control",
            lastnameclassname: "form-control",
            emailclassname: "form-control",
            phoneclassname: "form-control",
            passwordclassname: "form-control",
            retypepasswordclassname: "form-control",

            firstnameerror: "",
            lastnameerror: "",
            emailerror: "",
            phoneerror: "",
            passworderror: "",
            retypepassworderror: "",

            roles: {
                attendance: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                ledgers: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                users: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                employees: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                settings: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                images: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                authentication: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                jobs: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                login: {
                    r: false,
                    w: true,
                    d: false,
                    e: false,
                },
                logout: {
                    r: false,
                    w: true,
                    d: false,
                    e: false,
                },
                files: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                salary: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                locations: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                }
            }
        }, () => {
            document.getElementById("userfirstname").focus()
        })
    }

    render() {
        /* prepare profile picture */
        var profilepicture = null
        if (this.state.profilepicture !== null) {

            profilepicture = <div key={this.state.profilepicture.image} className="m-1">
                <AuthenticatedImage key={this.state.profilepicture.image} className="p-2" imageID={this.state.profilepicture.image} imageStyle={{
                    maxHeight: "150px",
                    cursor: 'pointer'
                    // borderRadius: '50%'
                }} onClick={() => {
                    fullDialogV2Dispatcher.dispatch({
                        actionType: "show-dialog",
                        title: null,
                        closeOnOutsideClick: true,
                        message: <AuthenticatedImage imageID={this.state.profilepicture.image} imageStyle={{
                            maxWidth: "100%",
                            width: "100%"
                        }}
                        />
                    })
                }} />
                <br />
                <div>
                    <div className='form-group'>
                        <span className="p-2 btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.setState({ profilepicture: null })
                        }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;remove</span>
                    </div>
                </div>
            </div>
        }

        return <div>
            <div className='form-group'>
                <label htmlFor='userfirstname'><b>Firstname</b></label>
                <input type={"text"} id="userfirstname" className={this.state.firstnameclassname} value={this.state.firstname} onChange={(e) => {
                    this.setState({ firstname: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.firstnameerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userlastname'><b>Lastname</b></label>
                <input type={"text"} id="userlastname" className={this.state.lastnameclassname} value={this.state.lastname} onChange={(e) => {
                    this.setState({ lastname: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.lastnameerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='useremail'><b>Email</b></label>
                <input type={"text"} id="useremail" className={this.state.emailclassname} value={this.state.email} onChange={(e) => {
                    this.setState({ email: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.emailerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userphone'><b>Phone</b></label>
                <input type={"text"} id="userphone" className={this.state.phoneclassname} value={this.state.phone} onChange={(e) => {
                    this.setState({ phone: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.phoneerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userpassword'><b>Password</b></label>
                <input type={"password"} id="userpassword" className={this.state.passwordclassname} value={this.state.password} onChange={(e) => {
                    this.setState({ password: e.target.value }, () => {
                        if (this.state.retypepassword.length > 0) {
                            if (this.state.password !== this.state.retypepassword) {
                                this.setState({
                                    retypepasswordclassname: "form-control is-invalid",
                                    retypepassworderror: "Password does not match"
                                })
                            } else if (this.state.password === this.state.retypepassword) {
                                this.setState({
                                    retypepasswordclassname: "form-control",
                                    retypepassworderror: ""
                                })
                            }
                        }
                    })
                }} />
                <div className="invalid-feedback">{this.state.passworderror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userretypepassword'><b>Retype Password</b></label>

                <div className='input-group'>
                    <input type={this.state.retypepasswordtype} id="userretypepassword" className={this.state.retypepasswordclassname} value={this.state.retypepassword} onChange={(e) => {

                        this.setState({ retypepassword: e.target.value }, () => {
                            if (this.state.retypepassword.length > 0) {
                                if (this.state.password !== this.state.retypepassword) {
                                    this.setState({
                                        retypepasswordclassname: "form-control is-invalid",
                                        retypepassworderror: "Password does not match"
                                    })
                                } else if (this.state.password === this.state.retypepassword) {
                                    this.setState({
                                        retypepasswordclassname: "form-control",
                                        retypepassworderror: ""
                                    })
                                }
                            }
                        })
                    }} />
                    <span hidden={this.state.retypepasswordtype === "text"} title="Show Password" className="input-group-text btn-link" id="retype-password-password" onClick={() => {
                        this.setState({ retypepasswordtype: "text" })
                    }}><FontAwesomeIcon icon={faEye} /></span>
                    <span hidden={this.state.retypepasswordtype !== "text"} title="Hide Password" className="input-group-text btn-link" id="retype-password-text" onClick={() => {
                        this.setState({ retypepasswordtype: "password" })
                    }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                    <div className="invalid-feedback">{this.state.retypepassworderror}</div>
                </div>
            </div>
            <div className='form-group'>
                <label><b>Profile Picture</b></label>
                <div className="card" >
                    <div className="card-body">
                        {/* <label htmlFor=""><b>Files</b></label> */}

                        <div className="d-flex flex-wrap align-items-center">
                            {profilepicture}
                        </div>

                        <br />
                        <input type="file" className="form-control-file" id="userAddProfilePictureUpload" onChange={(input) => {
                            this.handleImageUpload(input)
                        }} hidden multiple />
                        <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                            document.getElementById(`userAddProfilePictureUpload`).click();
                        }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>
                            <span hidden={this.state.profilepicture !== null}>Add Profile Picture</span>
                            <span hidden={this.state.profilepicture === null}>Change Profile Picture</span>
                        </button>

                    </div>
                </div>
            </div>
            <div className='form-group'>
                <label><b>Roles</b></label>

                <AdminRoleSingleRow state={this.state.roles.front} title="Front" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.header} title="Header" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.footer} title="Footer" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.collections} title="Collections" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.pages} title="Pages" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.descriptions} title="Descriptions" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.orders} title="Orders" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.marketing} title="Marketing" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.urlshortener} title="URLShortener" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.wallet} title="Wallet" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.shipping} title="Shipping" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.coupons} title="Coupons" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.subscribers} title="Subscribers" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.inventory} title="Inventory" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.reviews} title="Reviews" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.comments} title="Comments" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.notify} title="Notify" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.users} title="Users" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.storage} title="Storage" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.admin} title="Admin" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.addresses} title="Addresses" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.company} title="Company" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.files} title="Files" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.images} title="Images" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.manualusers} title="Manual Users" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.manualorders} title="Manual Orders" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.inventoryvariations} title="Inventory Variations" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.inventorybulk} title="Inventory Bulk" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.publicimages} title="Public Images" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.adminapi} title="Admin API" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.ecomusers} title="Ecommerce Users" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.w = c
                    this.setState({ roles: tmpRoles })
                }} />
            </div>
            <div className='form-group'>
                <button className='btn btn-primary m-1' disabled={this.state.isLoading} onClick={() => {
                    this.resetFormClasses(this.validateFields)
                }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Update User</button>
                <button className='btn btn-danger m-1' onClick={() => {
                    this.clearFields()
                }} disabled={this.state.isLoading}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Reset Fields</button>
            </div>

        </div>
    }
}

class AdminAddUser extends React.Component {
    constructor(props) {
        super()
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            password: "",
            retypepassword: "",
            profilepicture: null,

            firstnameclassname: "form-control",
            lastnameclassname: "form-control",
            emailclassname: "form-control",
            phoneclassname: "form-control",
            passwordclassname: "form-control",
            retypepasswordclassname: "form-control",

            firstnameerror: "",
            lastnameerror: "",
            emailerror: "",
            phoneerror: "",
            passworderror: "",
            retypepassworderror: "",

            retypepasswordtype: "password",

            roles: {
                front: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                header: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                footer: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                collections: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                pages: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                descriptions: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                orders: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                marketing: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                urlshortener: {
                    r: false,
                    w: true,
                    d: false,
                    e: false,
                },
                wallet: {
                    r: false,
                    w: true,
                    d: false,
                    e: false,
                },
                shipping: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                coupons: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                subscribers: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                inventory: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                reviews: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                comments: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                notify: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                users: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                storage: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                admin: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                addresses: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                company: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                files: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                images: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                manualusers: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                manualorders: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                inventoryvariations: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                inventorybulk: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                publicimages: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                adminapi: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                ecomusers: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
            }

        }
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg" && input.target.files[ii]["type"] !== "image/png") {
                helper_function_info_dialog("Add Image", <span><span className='text-danger mr-1'><FontAwesomeIcon icon={faExclamationCircle} /></span>Only JPEG and PNG files are allowed.</span>, true, "Okay", null)
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            // toastsDispatcher.dispatch({
            //     actionType: 'add-toast',
            //     title: "Add Image",
            //     message: <span>Uploading image <b>{ff.name}</b>.</span>,
            //     date: new Date(),
            //     isInfo: true
            // })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image

                const image_data = dataURItoBlob(e.target.result)
                const image_name = ff.name


                networkCallPostFile(ADMINURL, "image", {}, {}, image_data, image_name, (data) => {


                    that.setState({ isLoading: false })

                    const response = data

                    if (!("status" in response) || !("payload" in response)) {


                        return
                    }

                    if (response["status"] !== "success") {


                        return
                    }
                    var tmpImage = {
                        image: response["payload"]["id"],
                        imagen: ff.name,
                        "alt": "",
                        "eqr": false,
                        "qp": "bl",
                        "qs": 100,
                        ip: false
                    }
                    that.setState({ profilepicture: tmpImage })


                }, (data) => {


                    that.setState({ isLoading: false })

                }, (data) => {
                    console.log(data)
                    that.setState({ isLoading: false })

                }, ACCT, null, "status")







            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    resetFormClasses = (callback) => {
        this.setState({
            firstnameclassname: "form-control",
            lastnameclassname: "form-control",
            emailclassname: "form-control",
            phoneclassname: "form-control",
            passwordclassname: "form-control",
            retypepasswordclassname: "form-control",
        }, () => {
            if (callback) {
                callback()
            }
        })
    }

    validateFields = () => {

        var isError = false

        if (this.state.firstname.trim().length < 1) {
            isError = true
            this.setState({
                firstnameclassname: "form-control is-invalid",
                firstnameerror: "Firstname is required"
            })
            document.getElementById("userfirstname").focus()
        }

        if (this.state.lastname.trim().length < 1) {
            isError = true
            this.setState({
                lastnameclassname: "form-control is-invalid",
                lastnameerror: "Lastname is required"
            })
            document.getElementById("userlastname").focus()
        }

        if (!helper_function_validat_email(this.state.email)) {
            isError = true
            this.setState({
                emailclassname: "form-control is-invalid",
                emailerror: "Invalid email"
            })
            document.getElementById("useremail").focus()
        }


        if (this.state.phone.length === 0) {
            this.setState({
                phoneclassname: "form-control is-invalid",
                phoneerror: "Please enter a phone number"
            })
            isError = true
            document.getElementById("userphone").focus()
        } else {
            try {
                const number = phoneUtil.parseAndKeepRawInput(this.state.phone, phoneRegion);
                if (!phoneUtil.isValidNumber(number, 'IN')) {
                    this.setState({
                        phoneclassname: "form-control is-invalid",
                        phoneerror: "Invalid phone number"
                    })
                    isError = true
                    document.getElementById("userphone").focus()
                }
            } catch (error) {
                this.setState({
                    phoneclassname: "form-control is-invalid",
                    phoneerror: "Invalid phone number"
                })
                isError = true
                document.getElementById("userphone").focus()
            }

        }

        if (this.state.password.trim().length < 1) {
            isError = true
            this.setState({
                passwordclassname: "form-control is-invalid",
                passworderror: "Password is required"
            })
            document.getElementById("userpassword").focus()
        }


        if (this.state.retypepassword.length > 0) {
            if (this.state.password !== this.state.retypepassword) {
                this.setState({
                    retypepasswordclassname: "form-control is-invalid",
                    retypepassworderror: "Password does not match"
                })
                isError = true
                document.getElementById("userretypepassword").focus()
            }
        }


        if (isError) {
            return
        }

        this.addUser()
    }

    addUser() {
        this.setState({ isLoading: true, isSuccess: false, isError: false })

        networkCallPost(ADMINURL, "adminapi", {}, {}, {

            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            pt: this.state.password,
            pp: this.state.profilepicture,
            roles: this.state.roles

        },
            (response) => {
                this.setState({ isLoading: false, isError: false, isSuccess: true })
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Add User",
                    message: <div>
                        <span className='text-success'><FontAwesomeIcon icon={faCheckCircle} /></span>&nbsp;User successfully added.
                    </div>
                })
                fullDialogV2Dispatcher.dispatch({
                    actionType: "close-dialog",
                    dialogIndex: adminAddUserDialogIndex
                })


                viewAdminUsersDispatcher.dispatch({
                    actionType: "get-data"
                })
            },
            (response) => {
                this.setState({ isLoading: false, isError: true, isSuccess: false })
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Add User",
                    message: <div>
                        <span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} /></span>&nbsp;{response.payload}
                    </div>
                })


            },
            (response) => {
                console.log(response)
                this.setState({ isLoading: false, isError: true, isSuccess: false })
            },
            ACCT, "add")
    }

    clearFields() {
        this.setState({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            password: "",
            retypepassword: "",
            profilepicture: null,

            firstnameclassname: "form-control",
            lastnameclassname: "form-control",
            emailclassname: "form-control",
            phoneclassname: "form-control",
            passwordclassname: "form-control",
            retypepasswordclassname: "form-control",

            firstnameerror: "",
            lastnameerror: "",
            emailerror: "",
            phoneerror: "",
            passworderror: "",
            retypepassworderror: "",

            roles: {
                attendance: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                ledgers: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                users: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                employees: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                settings: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                images: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                authentication: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                jobs: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                login: {
                    r: false,
                    w: true,
                    d: false,
                    e: false,
                },
                logout: {
                    r: false,
                    w: true,
                    d: false,
                    e: false,
                },
                files: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                salary: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                },
                locations: {
                    r: false,
                    w: false,
                    d: false,
                    e: false,
                }
            }
        }, () => {
            document.getElementById("userfirstname").focus()
        })
    }

    render() {
        /* prepare profile picture */
        var profilepicture = null
        if (this.state.profilepicture !== null) {

            profilepicture = <div key={this.state.profilepicture.image} className="m-1">
                <AuthenticatedImage key={this.state.profilepicture.image} className="p-2" imageID={this.state.profilepicture.image} imageStyle={{
                    maxHeight: "150px",
                    cursor: 'pointer'
                    // borderRadius: '50%'
                }} onClick={() => {
                    fullDialogV2Dispatcher.dispatch({
                        actionType: "show-dialog",
                        title: null,
                        closeOnOutsideClick: true,
                        message: <AuthenticatedImage imageID={this.state.profilepicture.image} imageStyle={{
                            maxWidth: "100%",
                            width: "100%"
                        }}
                        />
                    })
                }} />
                <br />
                <div>
                    <div className='form-group'>
                        <span className="p-2 btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.setState({ profilepicture: null })
                        }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;remove</span>
                    </div>
                </div>
            </div>
        }

        return <div>
            <div className='form-group'>
                <label htmlFor='userfirstname'><b>Firstname</b></label>
                <input type={"text"} id="userfirstname" className={this.state.firstnameclassname} value={this.state.firstname} onChange={(e) => {
                    this.setState({ firstname: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.firstnameerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userlastname'><b>Lastname</b></label>
                <input type={"text"} id="userlastname" className={this.state.lastnameclassname} value={this.state.lastname} onChange={(e) => {
                    this.setState({ lastname: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.lastnameerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='useremail'><b>Email</b></label>
                <input type={"text"} id="useremail" className={this.state.emailclassname} value={this.state.email} onChange={(e) => {
                    this.setState({ email: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.emailerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userphone'><b>Phone</b></label>
                <input type={"text"} id="userphone" className={this.state.phoneclassname} value={this.state.phone} onChange={(e) => {
                    this.setState({ phone: e.target.value })
                }} />
                <div className="invalid-feedback">{this.state.phoneerror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userpassword'><b>Password</b></label>
                <input type={"password"} id="userpassword" className={this.state.passwordclassname} value={this.state.password} onChange={(e) => {
                    this.setState({ password: e.target.value }, () => {
                        if (this.state.retypepassword.length > 0) {
                            if (this.state.password !== this.state.retypepassword) {
                                this.setState({
                                    retypepasswordclassname: "form-control is-invalid",
                                    retypepassworderror: "Password does not match"
                                })
                            } else if (this.state.password === this.state.retypepassword) {
                                this.setState({
                                    retypepasswordclassname: "form-control",
                                    retypepassworderror: ""
                                })
                            }
                        }
                    })
                }} />
                <div className="invalid-feedback">{this.state.passworderror}</div>
            </div>
            <div className='form-group'>
                <label htmlFor='userretypepassword'><b>Retype Password</b></label>

                <div className='input-group'>
                    <input type={this.state.retypepasswordtype} id="userretypepassword" className={this.state.retypepasswordclassname} value={this.state.retypepassword} onChange={(e) => {

                        this.setState({ retypepassword: e.target.value }, () => {
                            if (this.state.retypepassword.length > 0) {
                                if (this.state.password !== this.state.retypepassword) {
                                    this.setState({
                                        retypepasswordclassname: "form-control is-invalid",
                                        retypepassworderror: "Password does not match"
                                    })
                                } else if (this.state.password === this.state.retypepassword) {
                                    this.setState({
                                        retypepasswordclassname: "form-control",
                                        retypepassworderror: ""
                                    })
                                }
                            }
                        })
                    }} />
                    <span hidden={this.state.retypepasswordtype === "text"} title="Show Password" className="input-group-text btn-link" id="retype-password-password" onClick={() => {
                        this.setState({ retypepasswordtype: "text" })
                    }}><FontAwesomeIcon icon={faEye} /></span>
                    <span hidden={this.state.retypepasswordtype !== "text"} title="Hide Password" className="input-group-text btn-link" id="retype-password-text" onClick={() => {
                        this.setState({ retypepasswordtype: "password" })
                    }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                    <div className="invalid-feedback">{this.state.retypepassworderror}</div>
                </div>
            </div>
            <div className='form-group'>
                <label><b>Profile Picture</b></label>
                <div className="card" >
                    <div className="card-body">
                        {/* <label htmlFor=""><b>Files</b></label> */}

                        <div className="d-flex flex-wrap align-items-center">
                            {profilepicture}
                        </div>

                        <br />
                        <input type="file" className="form-control-file" id="userAddProfilePictureUpload" onChange={(input) => {
                            this.handleImageUpload(input)
                        }} hidden multiple />
                        <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                            document.getElementById(`userAddProfilePictureUpload`).click();
                        }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>
                            <span hidden={this.state.profilepicture !== null}>Add Profile Picture</span>
                            <span hidden={this.state.profilepicture === null}>Change Profile Picture</span>
                        </button>

                    </div>
                </div>
            </div>
            <div className='form-group'>
                <label><b>Roles</b></label>
                <AdminRoleSingleRow state={this.state.roles.front} title="Front" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.front.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.header} title="Header" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.header.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.footer} title="Footer" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.footer.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.collections} title="Collections" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.collections.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.pages} title="Pages" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.pages.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.descriptions} title="Descriptions" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.descriptions.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.orders} title="Orders" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.orders.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.marketing} title="Marketing" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.marketing.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.urlshortener} title="URLShortener" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.urlshortener.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.wallet} title="Wallet" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.wallet.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.shipping} title="Shipping" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.shipping.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.coupons} title="Coupons" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.coupons.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.subscribers} title="Subscribers" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.subscribers.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.inventory} title="Inventory" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventory.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.reviews} title="Reviews" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.reviews.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.comments} title="Comments" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.comments.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.notify} title="Notify" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.notify.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.users} title="Users" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.users.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.storage} title="Storage" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.storage.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.admin} title="Admin" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.admin.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.addresses} title="Addresses" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.addresses.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.company} title="Company" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.company.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.files} title="Files" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.files.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.images} title="Images" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.images.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.manualusers} title="Manual Users" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualusers.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.manualorders} title="Manual Orders" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.manualorders.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.inventoryvariations} title="Inventory Variations" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventoryvariations.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.inventorybulk} title="Inventory Bulk" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.inventorybulk.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.publicimages} title="Public Images" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.publicimages.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.adminapi} title="Admin API" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.adminapi.w = c
                    this.setState({ roles: tmpRoles })
                }} />
                <AdminRoleSingleRow state={this.state.roles.ecomusers} title="Ecommerce Users" setState={this.setState} onEChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.e = c
                    this.setState({ roles: tmpRoles })
                }} onRChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.r = c
                    this.setState({ roles: tmpRoles })
                }} onDChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.d = c
                    this.setState({ roles: tmpRoles })
                }} onWChange={(c) => {
                    var tmpRoles = this.state.roles
                    tmpRoles.ecomusers.w = c
                    this.setState({ roles: tmpRoles })
                }} />

            </div>
            <div className='form-group'>
                <button className='btn btn-primary m-1' disabled={this.state.isLoading} onClick={() => {
                    this.resetFormClasses(this.validateFields)
                }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add User</button>
                <button className='btn btn-danger m-1' onClick={() => {
                    this.clearFields()
                }} disabled={this.state.isLoading}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Reset Fields</button>
            </div>

        </div>
    }
}

class AdminUsers extends React.Component {
    constructor(props) {
        super()

        var manualOrderDate = DateTime.local().toFormat("yyyy-LL-dd'T'HH:mm")

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }



    render() {


        return (
            <div className="p-2 w-100">

                <div className='form-group'>
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add User",
                            message: <AdminAddUser />,
                            dialogIndexCallback: (idx) => {
                                adminAddUserDialogIndex = idx
                            }

                        })
                    }}>
                        Add User
                    </button>
                </div>

                <div className='form-group'>
                    <ItemSearch nameTitleKey="firstname" getDataCaller={(d) => {
                        viewAdminUsersDispatcher = d
                    }} onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View User",
                            message: <AdminEditUser data={resultData} />,
                            dialogIndexCallback: (idx) => {
                                adminEditUserDialogIndex = idx
                            }

                        })
                    }} isResultLink={false} subNameTitleKey={'lastname'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="get" timeKey="" urlEndPoint="adminapi" subInfoKeys={[
                        {
                            "key": "phone",
                            "map": function (data) {
                                return data
                            }
                        },
                        {
                            "key": "email",
                            "map": function (data) {
                                if (data) {
                                    return <>
                                        <br />
                                        {data}
                                    </>
                                }
                                return null
                            }
                        },
                    ]} />
                </div>



            </div>
        )
    }
}

function Admin() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<AdminUsers orderID={id} history={history} />} title={"Users"} />,
    ]

}

export {
    Admin,
}