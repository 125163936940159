import React from 'react'


import axios from 'axios';
import Cookies from 'js-cookie';
import { ADMINURL } from '../Components/MSURLS';


var ACCT = Cookies.get('ACCT')


class Logout extends React.Component {
    constructor(props) {
        super()

        this.state = {
            message: "Logging you out..."
        }
    }
    
    handleLogoutResponse = (response) => {
        if (!("status" in response)) {
            this.setState({message:"Cannot log you out. Check your internet connection and try again."})
            return;
        }
        if (response["status"] === "fail" || response["status"] !== "success") {
            this.setState({message:"Cannot log you out. Some server side error has occurred please wait for some time and try again."})
            return;
        }
        if (response["status"] === "success" || response["status"] !== "Logged Out.") {
            Cookies.remove('T')
            Cookies.remove('F')
            Cookies.remove('L')
            Cookies.remove('ACCT')
            window.location.href = "/"
        }
        
    
    }

    handleLogout = () => {
        const that = this;
        axios.get(ADMINURL + "logout", {
            headers: { Authorization: ACCT },
            // params: params
        })
            .then(function (response) {
                that.handleLogoutResponse(response.data)
            })
            .catch(function (error) {
                that.handleLogoutResponse({})
            })
    }

    componentDidMount() {
        this.handleLogout()
    }

    render() {
        return (
            <div className="m-4">
                {this.state.message}
            </div>
        )
    }
}


export default Logout