import React from 'react'
import NavBar from '../Components/NavBar';
import { NavBarData } from '../Components/NavBarData';
import { Window } from '../Components/Window';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ItemSearch } from '../Components/ItemSearch';
import { toastsDispatcher } from '../Components/Toasts';
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBox, faCheck, faCircleNotch, faCopy, faDoorOpen, faExternalLinkAlt, faPaperPlane, faTimes, faUndo, faWrench } from '@fortawesome/free-solid-svg-icons';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearchTable } from '../Components/ItemSearchTable';
import jsPDF from 'jspdf';
import ReactQuill from 'react-quill';
import { ADMINURL } from '../Components/MSURLS';
import main_config from '../Components/config';
import { networkCallGet } from '../Components/network';

var ACCT = Cookies.get('ACCT')
var { DateTime } = require('luxon');

class GenerateAndViewURL extends React.Component {
    constructor(props) {
        super()
        this.urlKind = props.urlKind
        this.data = props.data
        this.companyWebsite = props.companyWebsite

        /* generate URL */
        this.generatedURL = ""
        if (this.urlKind === "item") {
            this.generatedURL = `${main_config.companyInfo.domain}/product?g=sng&scr=sku&sku=${this.data.sku}`
        }
        if (this.urlKind === "collection") {
            this.generatedURL = `${main_config.companyInfo.domain}/collections/collection/?cid=${this.data._id}/?&n=${this.data.name}`
        }

        this.state = {

        }
    }

    copyURL = () => {
        navigator.clipboard.writeText(this.generatedURL);
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Copy URL",
            message: `URL copied to your clipboard.`,
            date: new Date(),
            isSuccess: true
        })
    }

    selectURLText = (domID) => {

        const dom = document.getElementById(domID);
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(dom);
            range.select();
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(dom);
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            // console.warn("Could not select text in node: Unsupported browser.");
        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    URL: <div id="generatedURL" onClick={() => {
                        this.selectURLText("generatedURL")
                    }}><b>{this.generatedURL}</b></div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-sm" onClick={this.copyURL}>Copy URL</button>
                </div>
                <div className="form-group">
                    <a className="btn btn-primary btn-sm" href={this.generatedURL} target="_blank">Open Link&nbsp;&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div>
            </div>
        )
    }
}

class GenerateURL extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Generate URL
                </div>
                <div className="form-group">
                    Inventory Items
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View URL",
                            message: <GenerateAndViewURL data={resultData} urlKind="item" />
                        })

                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                </div>

                <div className="form-group">
                    Collections
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View URL",
                            message: <GenerateAndViewURL data={resultData} urlKind="collection" />
                        })

                    }} resultClickable={true} isResultLink={false} resultLink={'/collections/'} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                </div>
            </div>
        )
    }
}

class URLShortenerViewShortURL extends React.Component {
    constructor(props) {
        super()

        this.data = props.data
        this.shortURLID = props.shortURLID
        this.shortUrlDispatcher = props.shortUrlDispatcher
        this.companyWebsite = props.companyWebsite

        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <b>URL</b>
                    <br />
                    {this.data.u}
                </div>
                <div className="form-group">
                    <b>Shortened URL</b>
                    <br />
                    {main_config.companyInfo.domain}/m/{this.data.s}
                    <span title="Copy Short URL" className="ml-1 copy-button" id="myTooltip" style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigator.clipboard.writeText(`${main_config.companyInfo.domain}/m/${this.data.s}`);

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Copy Short URL",
                            message: `Shortened URL copied to your clipboard.`,
                            date: new Date(),
                            isSuccess: true
                        })

                    }}>
                        <FontAwesomeIcon icon={faCopy} />
                    </span>


                </div>
                <hr />
                <div className="form-group">
                    <button onClick={() => {
                        const that = this
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Delete Short URL",
                            message: <URLShortenerDeleteShortURL data={this.data} shortURLID={this.shortURLID} onSuccessCallback={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: "close-dialog"
                                })

                                that.shortUrlDispatcher.dispatch({
                                    actionType: "get-data"
                                })
                            }} />
                        })
                    }} className="btn btn-danger">Delete</button>
                </div>
            </div>
        )
    }
}

class URLShortenerDeleteShortURL extends React.Component {
    constructor(props) {
        super()

        this.shortURLID = props.shortURLID
        this.onSuccessCallback = props.onSuccessCallback
        this.data = props.data
        this.state = {

        }
    }

    handleDeleteShortURL = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to delete short url")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Delete Short URL",
                message: `Unable to delete short url. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Delete Short URL",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            // that.getOrderState()
            if (that.onSuccessCallback !== undefined && that.onSuccessCallback !== null) {
                that.onSuccessCallback(response.payload)
            }
        })

    }

    deleteShortURL = (state) => {

        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "urlshortener", {
            params: {
                g: "del",
                id: this.shortURLID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteShortURL(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleDeleteShortURL({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Do you want to delete this short URL?
                </div>
                <div className="form-group">
                    <button onClick={this.deleteShortURL} className="btn btn-danger m-1">
                        Delete
                    </button>
                    <button className="btn btn-primary m-1" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Short URL",
                            message: <URLShortenerViewShortURL data={this.data} shortURLID={this.shortURLID} />
                        })
                    }}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    }
}

class URLShortenerNewShortURL extends React.Component {

    constructor(props) {
        super()
        this.onSuccessCallback = props.onSuccessCallback
        this.companyWebsite = props.companyWebsite

        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            url: "",
            shortUrl: "",
            description: ""
        }
    }

    handleAddShortURL = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add short url")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Short URL",
                message: `Unable to add short url. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Short URL",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            // that.getOrderState()
            if (that.onSuccessCallback !== undefined && that.onSuccessCallback !== null) {
                that.onSuccessCallback(response.payload)
            }
        })

    }

    addShortURL = (state) => {

        /* prepare short url */

        var shortUrl = ""

        if (this.state.shortUrl.length > 0) {

            shortUrl = this.state.shortUrl
        }

        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "urlshortener", {
            s: shortUrl,
            u: this.state.url,
            d: this.state.description
        }, {
            params: {
                g: "add",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddShortURL(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleAddShortURL({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="newShortURLURL">
                        URL
                    </label>
                    <input type="text" id="newShortURLURL" className="form-control" value={this.state.url} onChange={(e) => {
                        this.setState({ url: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="newShortURLURL">
                        Short URL ({`${main_config.companyInfo.domain}/m/`} + )
                    </label>
                    <input type="text" id="newShortURLURL" className="form-control" value={this.state.shortUrl} onChange={(e) => {
                        this.setState({ shortUrl: e.target.value })
                    }} />
                    <div hidden={this.state.shortUrl.length === 0}>
                        {`${main_config.companyInfo.domain}//m/${this.state.shortUrl}`}
                    </div>
                    <small>For random short URL leave this field empty</small>
                </div>
                <div className="form-group">
                    <label htmlFor="newShortURLDescription">
                        Description
                    </label>
                    <textarea type="text" id="newShortURLDescription" className="form-control" value={this.state.description} onChange={(e) => {
                        this.setState({ description: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <button disabled={this.state.isLoading} className="btn btn-primary" onClick={this.addShortURL}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon spin icon={faCircleNotch} />&nbsp;</span>Add
                    </button>
                </div>
            </div>

        )
    }
}

class URLShortenerPage extends React.Component {
    constructor(props) {
        super()

        this.shortUrlDispatcher = undefined
        this.companyWebsite = props.companyWebsite

        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Shorten A URL
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "New Short URL",
                            message: <URLShortenerNewShortURL companyWebsite={this.companyWebsite}  onSuccessCallback={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: "close-dialog"
                                })

                                this.shortUrlDispatcher.dispatch({
                                    actionType: "get-data"
                                })
                            }} />
                        })
                    }}>New Short URL</button>
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="s" onClickCallback={(resultData) => {

                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Short URL",
                            message: <URLShortenerViewShortURL companyWebsite={this.companyWebsite} data={resultData} shortURLID={resultData._id} shortUrlDispatcher={this.shortUrlDispatcher} />
                        })
                    }} isResultLink={false} resultLink={'/urlshortener/'} subNameTitleKey={'u'} subHeadingKeys={[]} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="urlshortener" subInfoKeys={[
                        {
                            "key": "d",
                            "map": function (data) {
                                if (data.length === 0) {
                                    return null
                                }
                                return <span style={{
                                    whiteSpace: 'pre-wrap'
                                }}>{data}</span>
                            }
                        },
                    ]} smallMessages={["Click on an item to view its information"]}
                        getDataCaller={(d) => {
                            this.shortUrlDispatcher = d
                        }} />
                </div>
            </div>
        )
    }
}

class URLShortenerAndGeneratorPage extends React.Component {
    constructor(props) {
        super()
        
        this.state = {
            companyWebsite: "",

            isLoading: false,
            isError: false,
            isSuccess: false,

        }
    }

    getCompanyInfo = () => {
        this.setState({
            isLoading: true,
            isError: false,
            isSuccess: false,
        })
        networkCallGet(ADMINURL, "company", {}, {}, (data) => {
            // prepare company website string
            var company_website_string = data.payload
            if (company_website_string[company_website_string.length-1] === "/") {
                company_website_string = company_website_string.substr(0,company_website_string.length - 1)
            }

            this.setState({
                isLoading: false,
                isError: false,
                isSuccess: true,
                companyWebsite: company_website_string
            })
        }, (data) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
                companyWebsite: ""
            })
        }, (error) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
                companyWebsite: ""
            })
        }, ACCT, "web", "status")
    }

    componentDidMount() {
        this.getCompanyInfo()
    }

    render() {
        if (this.state.isLoading) {
            return <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        }
        if (this.state.isSuccess) {
            return (
                <div className="p-2">
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <URLShortenerPage companyWebsite={this.state.companyWebsite} />
                        </div>

                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <GenerateURL companyWebsite={this.state.companyWebsite} />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }
}

function URLShortener() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        // <Window content={<URLShortenerPage urlID={id} />} title={"URL Shortener"} />,
        // <Window content={<GenerateURL urlID={id} />} title={"Generate URL"} />,

        <Window content={<URLShortenerAndGeneratorPage />} title={"URL Shortener"} />,

        // <AboutPage />
    ]

}

export {
    URLShortener,
    URLShortenerPage,
    GenerateAndViewURL
}