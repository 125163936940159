import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

// import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';
import NavBar from '../Components/NavBar';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { toastsDispatcher } from '../Components/Toasts';
import { ItemSearch } from '../Components/ItemSearch';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircle, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import AuthenticatedImage from '../Components/AuthenticatedImage';
import { Link, useHistory, useParams } from "react-router-dom";
import AuthenticatedImage from '../Components/AuthenticatedImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { GenerateAndViewURL } from './UrlShortener';
import { ADMINURL, FRONTURL } from '../Components/MSURLS';


var ACCT = Cookies.get('ACCT')

var viewCollectionItemsDispatcher = null

class AddCollection extends React.Component {
    constructor(props) {
        super()

        this.addSuccessCallback = props.addSuccessCallback

        this.state = {
            name: "",
            description: "",


            isLoading: false,
            isSuccess: false,
            isError: false,


            response: null
        }
    }

    handleAddCollection = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add collection")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Collection",
                message: `Unable to add collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Collection",
            message: <span>Collection <b>{this.state.name}</b> successfully added.</span>,
            date: new Date(),
            isSuccess: true
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })

        if (this.addSuccessCallback !== undefined && this.addSuccessCallback !== null) {
            this.addSuccessCallback()
        }
    }

    addCollection = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "collections", {
            name: this.state.name,
            description: this.state.description
        }, {
            params: {
                g: "add",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddCollection(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleAddCollection({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="collectionName">Collection Name</label>
                    <input type="text" id="collectionName" className="form-control" placeholder="Name" value={this.state.name} onChange={(e) => {
                        this.setState({ name: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <label htmlFor="collectionDescription">Collection Description</label>
                    <textarea type="text" id="collectionDescription" className="form-control" placeholder="Description" value={this.state.description} onChange={(e) => {
                        this.setState({ description: e.target.value })
                    }} />
                </div>
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-secondary" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>Cancel</button>
                    <button className="btn btn-success ml-2" onClick={() => {
                        this.addCollection()
                    }}>Add</button>
                </div>
            </div>
        )
    }
}

class CreateCollections extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }



    render() {
        return (
            <div className="p-2">
                <div className="p-2">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add Collection",
                            message: <AddCollection addSuccessCallback={() => {

                            }} />
                        })
                    }}>Add Collection</button>
                </div>
                <div className="mt-2">
                    <h5 className="p-2">Collections</h5>
                    <ItemSearch nameTitleKey="name"
                        // onClickCallback={(resultData) => {
                        //     // const id = this.state.slider[iid]["_id"]
                        //     var tmpCurrentSliders = this.state.currentSlider
                        //     // console.log(tmpCurrentSliders)
                        //     tmpCurrentSliders.push(resultData)
                        //     // tmpCurrentSliders[resultData._id] = resultData
                        //     this.setState({ currentSlider: tmpCurrentSliders })

                        //     fullDialogDispatcher.dispatch({
                        //         actionType: "close-dialog"
                        //     })
                        // }} 
                        resultClickable={true} isResultLink={true} resultLink={'/collections/'} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                </div>
            </div>
        )
    }
}

class Collections extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<CreateCollections />} title={"Collections"} />,
            // <Window content={<ContactPage />} title={"Contact Page"} />,

            // <AboutPage />
        ]
    }
}

class ViewSingleInventoryCollectionData extends React.Component {
    constructor(props) {
        super()

        this.data = props.data
        this.collectionName = props.collectionName
        this.collectionID = props.collectionID

        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {

        }
    }

    handleRemoveItem = (response, itemName) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to remove item from collection")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Remove Item",
                message: `Unable to remove item from collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Remove Item",
            message: <span>Item <b>{itemName}</b> successfully removed.</span>,
            date: new Date(),
            isSuccess: true
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-all-dialogs"
        })

        viewCollectionItemsDispatcher.dispatch({
            actionType: "set-get-data",
            urlParams: {
                cid: this.collectionID
            },
        })
    }

    removeItem = (itemID, itemName) => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "collections", {


        }, {
            params: {
                g: "rmi",
                cid: this.collectionID,
                iid: itemID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleRemoveItem(response.data, itemName)
            })
            .catch(function (error) {
                console.log(error)
                that.handleRemoveItem({}, itemName)
            })
    }


    render() {
        return (

            <div className="p-2">
                <div className='form-group'>
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Name</th>
                                    <td>{this.data.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">SKU</th>
                                    <td>{this.data.sku}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Quantity</th>
                                    <td>{this.data.quantity}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Price</th>
                                    <td>
                                        <div>
                                            MRP: &#8377;&nbsp;{this.data.price.mrp}
                                            <br />
                                            SP: &#8377;&nbsp;{this.data.price.sp}
                                            <br />
                                            CP: &#8377;&nbsp;{this.data.price.cp}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Ecommerce Visibility</th>
                                    <td>{this.data.ecom ? <span className="text-success">Visible</span> : <span className="text-danger">NOT Visible</span>}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='form-group'>
                    <button className='btn btn-danger' onClick={() => {

                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Delete Item From Collection?",
                            message: <div>
                                <div className='form-group'>
                                    Are you sure you want to delete item <b>{this.data.name}</b> from the collection <b>{this.collectionName}</b>?
                                </div>
                                <div className='form-group'>
                                    <button className='btn btn-danger' onClick={() => {
                                        this.removeItem(this.data._id, this.data.name)
                                    }}>Delete</button>
                                </div>
                            </div>
                        })
                    }} >Delete From Collection</button>
                </div>
            </div>
        )
    }
}

class UpdateCollection extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.collectionID = props.collectionID
        this.onSuccessCallback = props.onSuccessCallback

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            name: this.data.name,
            description: this.data.description
        }
    }

    handleUpdateCollection = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update collection")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Collection",
                message: `Unable to update collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Collection",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        if (this.onSuccessCallback) {
            this.onSuccessCallback()
        }

    }

    updateCollection = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "collections", {
            name: this.state.name,
            description: this.state.description
        }, {
            params: {
                g: "upd",
                cid: this.collectionID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateCollection(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateCollection({})
            })
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className='p-2'>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        return (
            <div className='p-2'>
                <div className='form-group'>
                    <label htmlFor='updateCollectionName'></label>
                    <input type="text" id="updateCollectionName" className="form-control" placeholder="Name" value={this.state.name} onChange={(e) => {
                        this.setState({ name: e.target.value })
                    }} />
                </div>
                <div className='form-group'>

                    <label htmlFor='updateCollectionDescription'></label>
                    <textarea type="text" id="updateCollectionDescription" className="form-control" placeholder="Description" value={this.state.description} onChange={(e) => {
                        this.setState({ description: e.target.value })
                    }} />
                </div>
                <div className='form-group'>
                    <span onClick={this.updateCollection} className='btn btn-primary'>
                        Done
                    </span>
                </div>
            </div>
        )
    }
}


class SingleCollectionView extends React.Component {
    constructor(props) {
        super()
        this.history = props.history
        this.collectionID = props.collectionID
        this.collectionName = props.collectionName
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            items: [],
            totalItemCount: 0,
            collection: {},


        }
    }

    componentDidMount() {
        // this.fetchCollectionItems()
        this.fetchCollection()
    }


    handleFetchCollection = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get collection")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Collection",
                message: `Unable to get collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ collection: response.payload, isSuccess: true })
    }

    fetchCollection = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "collections", {
            params: {
                g: "get",
                cid: this.collectionID,
                pn: 1,
                sc: "t"
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchCollection(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchCollection({})
            })
    }

    handleFetchCollectionItems = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get items from collection")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Items",
                message: `Unable to get items from collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ items: response.payload.data, totalItemCount: response.payload.tc, isSuccess: true, })
    }

    fetchCollectionItems = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "collections", {
            params: {
                g: "gei",
                cid: this.collectionID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchCollectionItems(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchCollectionItems({})
            })
    }

    handleAddItem = (response, itemData) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add item to collection")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Item",
                message: `Unable to add item to collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Item",
            message: <span>Item <b>{itemData.name}</b> successfully added.</span>,
            date: new Date(),
            isSuccess: true
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })

        viewCollectionItemsDispatcher.dispatch({
            actionType: "set-get-data",
            urlParams: {
                cid: this.collectionID
            },
        })

        // this.fetchCollectionItems()

    }

    addItem = (itemData) => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "collections", {
            iid: itemData._id,
            cid: this.collectionID
        }, {
            params: {
                g: "adi",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddItem(response.data, itemData)
            })
            .catch(function (error) {
                console.log(error)
                that.handleAddItem({}, {})
            })
    }


    handleDeleteCollection = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to delete collection")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Delete Collection",
                message: `Unable to get collection. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Delete Collection",
            message: `Collection successfully deleted`,
            date: new Date(),
            isSuccess: true
        })


        this.history.push("/collections")

    }

    deleteCollection = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "collections", {
            params: {
                g: "rmv",
                cid: this.collectionID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteCollection(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleDeleteCollection({})
            })
    }


    render() {

        if (this.state.isLoading) {
            return (
                <div className="p-4">
                    <span>Loading...</span>
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="p-4">
                    <span className="text-danger">Some unexpected error has occurred. Please contact website administrator.</span>
                </div>
            )
        }

        return (
            <div className="p-2">
                <div className="p-2">
                    <Link to="/collections"><FontAwesomeIcon icon={faArrowLeft} /> Collections</Link>
                </div>
                <div className="p-2">
                    <h3>
                        {this.state.collection.name}
                    </h3>
                </div>

                <div className="p-2">
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View URL",
                            message: <GenerateAndViewURL data={this.state.collection} urlKind="collection" />
                        })
                    }}>View Collection URL</button>
                </div>
                <div className="p-2">
                    <span className="btn btn-primary mr-2" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Add Item',
                            message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                // const id = this.state.slider[iid]["_id"]
                                this.addItem(resultData)
                            }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                        })
                    }}>
                        Add Item
                    </span>

                    <span className="btn btn-primary mr-2" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Update Collection',
                            message: <UpdateCollection data={this.state.collection} collectionID={this.collectionID} onSuccessCallback={() => {
                                this.fetchCollection()
                                fullDialogDispatcher.dispatch({
                                    actionType:"close-dialog"
                                })
                            }} />
                        })
                    }}>
                        Update Collection
                    </span>

                    <span className="btn btn-danger mr-2" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Delete Collection',
                            message: <div>
                                <div className='form-group'>
                                    Are you sure you want to delete this collection?
                                </div>
                                <div className='form-group'>
                                    <span onClick={this.deleteCollection} className='btn btn-danger'>
                                        Delete
                                    </span>
                                </div>
                            </div>
                        })
                    }}>
                        Delete Collection
                    </span>
                </div>
                <div className="mt-2 p-2">
                    <h5>Items</h5>
                    {/* {items} */}
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Collection Item",
                            dialogWidth: 2,
                            message: <ViewSingleInventoryCollectionData collectionID={this.collectionID} collectionName={this.state.collection.name} data={resultData} />
                        })
                    }} isResultLink={false} resultClickable={true} searchAllowed={false} gType="gei" timeKey="t" urlEndPoint="collections" subInfoKeys={[
                        {
                            "key": "sku",
                            "map": function (data) {
                                return <b>{data}</b>
                            }
                        },
                        {
                            "key": "price",
                            "map": function (data) {
                                if (data !== undefined) {
                                    if (data.mrp !== undefined) {
                                        return [<br />, <span>MRP: &#8377;&nbsp;{data.mrp}</span>]
                                    } else {
                                        return [<br />, <span>SP: &#8377;&nbsp;{data.mrp}</span>]
                                    }
                                }
                            }
                        },
                        {
                            "key": "ecom",
                            "map": function (data) {
                                if (data === true) {
                                    return [<br />, <span className="text-success">Visible on Ecommerce</span>]
                                } else {
                                    return [<br />, <span className="text-danger">NOT Visible on Ecommerce</span>]
                                }

                            }
                        },
                        {
                            "key": "images",
                            "map": function (data) {
                                if (data !== undefined) {
                                    if (data.length === 0) {
                                        return null
                                    }
                                    /* prepare images */
                                    var images = []
                                    for (var i in data) {
                                        const idx = i
                                        images.push(
                                            <div style={{
                                                maxWidth: '100px'
                                            }}>
                                                <AuthenticatedImage key={data[idx].image} className="p-2" imageID={data[idx].image} imageStyle={{
                                                    width: "100%",
                                                    cursor: 'pointer'
                                                }} onClick={() => {
                                                    fullDialogDispatcher.dispatch({
                                                        actionType: "show-dialog",
                                                        title: null,
                                                        closeOnOutsideClick: true,
                                                        message: <AuthenticatedImage imageID={data[idx].image} imageStyle={{
                                                            maxWidth: "100%",
                                                            width: "100%"
                                                        }}
                                                        />
                                                    })
                                                }} />
                                            </div>
                                        )
                                    }
                                    return <div class="d-flex flex-wrap">
                                        {images}
                                    </div>
                                }
                            }
                        },

                    ]} urlParams={{
                        cid: this.collectionID
                    }} getDataCaller={(d) => {
                        viewCollectionItemsDispatcher = d
                    }} sortCriteria="t"
                    />
                </div>
            </div>
        )
    }
}

function SingleCollection() {
    let { id } = useParams();
    var history = useHistory();
    
    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<SingleCollectionView history={history} collectionID={id} />} title={"Collection"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    SingleCollection,
    Collections
}