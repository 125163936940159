import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';

import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearch } from '../Components/ItemSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AuthenticatedImage from '../Components/AuthenticatedImage';
import ReactQuill from 'react-quill';
import { DateTime } from 'luxon';
import { ADMINURL } from '../Components/MSURLS';

var ACCT = Cookies.get('ACCT')

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}

class HomePageCollections extends React.Component {
    constructor() {
        super()
        this.state = {
            images: {},
            images_extra: {},
            slider: {},
            homepageCollections: []
        }
    }

    componentDidMount() {
        this.fetchHomepageCollections()
    }


    fetchHomepageCollections = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "ghc",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch home page collections information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch home page collections information")
                    return
                }

                // prepare slider info
                // var preparedData = {}

                // for (var i in response.payload) {
                //     preparedData[response.payload[i]["_id"]] = response.payload[i]
                // }

                that.setState({ homepageCollections: response.payload })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchInventory = () => {

        if (this.state.search_term === "") {
            return
        }

        const that = this
        axios.get(ADMINURL + "inventory", {
            params: {
                g: "inv",
                st: this.state.search_term
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch inventory")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch inventory")
                    return
                }

                that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveHomepageCollections = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save home page collections information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save home page collections information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Home Page Collections",
            message: "Home page collections content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveHomepageCollections = () => {
        // if (Object.keys(this.state.currentSlider).length === 0) {
        //     return
        // } 


        var IDs = []
        // prepare data 

        for (var i in this.state.homepageCollections) {
            // IDs.push(this.state.homepageCollections[i]._id)
            IDs.push({
                id: this.state.homepageCollections[i]._id,
                im: ""
            })
        }

        const that = this
        axios.post(ADMINURL + "admin", {
            ids: IDs
        }, {
            params: {
                g: "shc",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveHomepageCollections(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveHomepageCollections({})
                // alert("Some error has occurred")
            })

    }

    render() {



        var homepageCollections = []
        for (var i in this.state.homepageCollections) {
            const idx = i

            homepageCollections.push(
                <div key={this.state.homepageCollections[i]._id} className="d-flex flex-column  border rounded p-2 mt-2">
                    <span>
                        <b>{this.state.homepageCollections[i]["name"]}</b>
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" title="Delete Item" onClick={() => {
                            // const id = e.target.getAttribute("id")
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Remove Item?",
                                message: <div className="d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={() => {
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }}>Cancel</button>
                                    <button className="btn btn-danger ml-2" onClick={() => {
                                        var tmpHomepageCollections = this.state.homepageCollections
                                        // delete tmpCurrentSliders[idx]
                                        tmpHomepageCollections.splice(idx, 1)
                                        this.setState({ homepageCollections: tmpHomepageCollections })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })

                                    }}>Remove</button>
                                </div>
                            })

                        }} style={{
                            cursor: 'pointer'
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === 0) {
                                return
                            }

                            var tmpHomepageCollections = this.state.homepageCollections
                            const tmpEle = tmpHomepageCollections[idx]
                            tmpHomepageCollections.splice(parseInt(idx), 1)
                            tmpHomepageCollections.splice(parseInt(idx) - 1, 0, tmpEle)
                            this.setState({ homepageCollections: tmpHomepageCollections })

                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === this.state.homepageCollections.length - 1) {
                                return
                            }

                            var tmpHomepageCollections = this.state.homepageCollections
                            const tmpEle = tmpHomepageCollections[idx]
                            tmpHomepageCollections.splice(parseInt(idx), 1)
                            tmpHomepageCollections.splice(parseInt(idx) + 1, 0, tmpEle)
                            this.setState({ homepageCollections: tmpHomepageCollections })

                        }} />
                    </span>
                    <span>
                        <p style={{
                            whiteSpace: 'pre-wrap'
                        }}>{this.state.homepageCollections[i]["description"]}</p>
                    </span>
                </div >
            )
        }

        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                    <div className="form-group mt-2">
                        <label><b>Home Page Collections</b></label>
                        {homepageCollections}
                    </div>

                    <div className="form-group">
                        <span className="btn btn-primary mr-2" onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: 'show-dialog',
                                title: 'Add Item',
                                message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    var tmpHomepageCollections = this.state.homepageCollections
                                    // check if item already exists or not

                                    for (var i in tmpHomepageCollections) {
                                        if (tmpHomepageCollections[i]._id === resultData._id) {
                                            return
                                        }
                                    }

                                    tmpHomepageCollections.push(resultData)
                                    // tmpCurrentSliders[resultData._id] = resultData
                                    this.setState({ homepageCollections: tmpHomepageCollections })

                                    fullDialogDispatcher.dispatch({
                                        actionType: "close-dialog"
                                    })
                                }} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                            })
                        }}>
                            Add Collection
                        </span>
                        <input type="button" className="btn btn-primary" value="Save" onClick={this.saveHomepageCollections} />
                    </div>
                </div>
                {/* {image_edit} */}


            </div>
        )
    }
}

class HomePageFeatured extends React.Component {
    constructor() {
        super()
        this.state = {
            images: {},
            images_extra: {},
            slider: {},
            homepageFeatured: []
        }
    }

    componentDidMount() {
        this.fetchHomepageFeatured()
    }

    ImageUploader = (e) => {

        var img_ = []
        for (var i in this.state.images) {
            console.log(i)
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} alt={`img${i}`} src={this.state.images[i]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        // $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        // $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <i class="fas fa-times" iid={i} onClick={(e) => {
                        var iid = e.target.getAttribute('iid');
                        var tmp_images = this.state.images;
                        tmp_images.splice(parseInt(iid), 1)
                        this.setState({ images: tmp_images })
                    }}> Delete</i>
                </div>
            )
        }


        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Add Images</h5>
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>

                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    fetchCurrentImages = () => {
        const that = this
        axios.get(ADMINURL + "image", {
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.setState({ retrivedInventory: response["data"]["payload"] })
            })
    }

    fetchHomepageFeatured = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "chp",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch home page featured information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch home page featured information")
                    return
                }

                // prepare slider info
                // var preparedData = {}

                // for (var i in response.payload) {
                //     preparedData[response.payload[i]["_id"]] = response.payload[i]
                // }

                that.setState({ homepageFeatured: response.payload })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchInventory = () => {

        if (this.state.search_term === "") {
            return
        }

        const that = this
        axios.get(ADMINURL + "inventory", {
            params: {
                g: "inv",
                st: this.state.search_term
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch inventory")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch inventory")
                    return
                }

                that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveHomepageFeatured = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save home page featured information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save home page featured information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Home Page Featured",
            message: "Home page featured content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveHomepageFeatured = () => {
        // if (Object.keys(this.state.currentSlider).length === 0) {
        //     return
        // } 


        var IDs = []
        // prepare data 

        for (var i in this.state.homepageFeatured) {
            // IDs.push(this.state.homepageFeatured[i]._id)
            IDs.push({
                id: this.state.homepageFeatured[i]._id,
                im: ""
            })
        }

        const that = this
        axios.post(ADMINURL + "admin", {
            ids: IDs
        }, {
            params: {
                g: "shp",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveHomepageFeatured(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveHomepageFeatured({})
                // alert("Some error has occurred")
            })

    }

    render() {



        var homepageFeatured = []
        for (var i in this.state.homepageFeatured) {
            const idx = i

            var images = []
            // prepare images
            for (var img in this.state.homepageFeatured[idx]["images"]) {
                const imgIdx = img
                images.push(
                    <AuthenticatedImage key={this.state.homepageFeatured[idx]["images"][imgIdx].image} className="p-2" imageID={this.state.homepageFeatured[idx]["images"][imgIdx].image} imageStyle={{
                        maxHeight: "150px",
                        cursor: 'pointer'
                        // borderRadius: '50%'
                    }} onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: null,
                            closeOnOutsideClick: true,
                            message: <AuthenticatedImage imageID={this.state.homepageFeatured[idx]["images"][imgIdx].image} imageStyle={{
                                maxWidth: "100%",
                                width: "100%"
                            }}
                            />
                        })
                    }} />
                )
            }
            homepageFeatured.push(
                <div key={this.state.homepageFeatured[i]._id} className="d-flex flex-column  border rounded p-2 mt-2">
                    <span>
                        <b>{this.state.homepageFeatured[i]["name"]}</b>
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" title="Delete Item" onClick={() => {
                            // const id = e.target.getAttribute("id")
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Remove Item?",
                                message: <div className="d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={() => {
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }}>Cancel</button>
                                    <button className="btn btn-danger ml-2" onClick={() => {
                                        var tmpHomepageFeatured = this.state.homepageFeatured
                                        // delete tmpCurrentSliders[idx]
                                        tmpHomepageFeatured.splice(idx, 1)
                                        this.setState({ homepageFeatured: tmpHomepageFeatured })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })

                                    }}>Remove</button>
                                </div>
                            })

                        }} style={{
                            cursor: 'pointer'
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === 0) {
                                return
                            }

                            var tmpHomepageFeatured = this.state.homepageFeatured
                            const tmpEle = tmpHomepageFeatured[idx]
                            tmpHomepageFeatured.splice(parseInt(idx), 1)
                            tmpHomepageFeatured.splice(parseInt(idx) - 1, 0, tmpEle)
                            this.setState({ homepageFeatured: tmpHomepageFeatured })

                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === this.state.homepageFeatured.length - 1) {
                                return
                            }

                            var tmpHomepageFeatured = this.state.homepageFeatured
                            const tmpEle = tmpHomepageFeatured[idx]
                            tmpHomepageFeatured.splice(parseInt(idx), 1)
                            tmpHomepageFeatured.splice(parseInt(idx) + 1, 0, tmpEle)
                            this.setState({ homepageFeatured: tmpHomepageFeatured })

                        }} />
                    </span>
                    <span>
                        {this.state.homepageFeatured[i]["sku"]}
                    </span>
                    <div className="d-flex flex-wrap">
                        {images}
                    </div>
                </div >
            )
        }

        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                    <div className="form-group mt-2">
                        <label><b>Home Page Featured</b></label>
                        {homepageFeatured}
                    </div>

                    <div className="form-group">
                        <span className="btn btn-primary mr-2" onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: 'show-dialog',
                                title: 'Add Item',
                                message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    var tmpHomepageFeatured = this.state.homepageFeatured
                                    // console.log(tmpCurrentSliders)
                                    tmpHomepageFeatured.push(resultData)
                                    // tmpCurrentSliders[resultData._id] = resultData
                                    this.setState({ homepageFeatured: tmpHomepageFeatured })

                                    fullDialogDispatcher.dispatch({
                                        actionType: "close-dialog"
                                    })
                                }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                            })
                        }}>
                            Add Item
                        </span>
                        <input type="button" className="btn btn-primary" value="Save" onClick={this.saveHomepageFeatured} />
                    </div>
                </div>
                {/* {image_edit} */}


            </div>
        )
    }
}

class AddHomePageSliderItem extends React.Component {
    constructor(props) {
        super()
        this.data = props.data

        this.onSuccessCallback = props.onSuccessCallback

        this.state = {
            t: this.data.t,
            st: this.data.st,
            d: this.data.d,
            lt: this.data.lt,
            l: this.data.l,
            i: this.data.i
        }
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'slider'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]
                        that.setState({ i: imgID })

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="sliderItemTitle">Title</label>
                    <input className="form-control" id="sliderItemTitle" value={this.state.t} onChange={(e) => {
                        this.setState({ t: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemSubTitle">Sub Title</label>
                    <input className="form-control" id="sliderItemSubTitle" value={this.state.st} onChange={(e) => {
                        this.setState({ st: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemDescription">Description</label>
                    <textarea rows="5" className="form-control" id="sliderItemDescription" value={this.state.d} onChange={(e) => {
                        this.setState({ d: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemLink">Link</label>
                    <input className="form-control" id="sliderItemLink" value={this.state.l} onChange={(e) => {
                        this.setState({ l: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemLinkTitle">Link Title</label>
                    <input className="form-control" id="sliderItemLinkTitle" value={this.state.lt} onChange={(e) => {
                        this.setState({ lt: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <AuthenticatedImage key={this.state.i} className="p-2" imageID={this.state.i} imageStyle={{
                        width: "100%",
                        cursor: 'pointer'
                    }} onClick={() => {
                    }} />

                    <input type="file" class="form-control-file" id={`sliderItemImageUploadButton`} onChange={(input) => {

                        this.handleImageUpload(input)
                    }} hidden />
                    <input className="form-control " type="button" value="Add/Change Image" onClick={(e) => {
                        document.getElementById(`sliderItemImageUploadButton`).click();
                    }} />
                </div>

                <div className="form-group">
                    <button className="btn btn-success m-1" onClick={() => {
                        this.onSuccessCallback({
                            t: this.state.t,
                            st: this.state.st,
                            d: this.state.d,
                            lt: this.state.lt,
                            l: this.state.l,
                            i: this.state.i
                        })
                    }}>Done</button>
                    <button className="btn btn-primary m-1" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>Close</button>
                    <button className="btn btn-danger m-1" onClick={() => {
                        this.setState({
                            t: "",
                            st: "",
                            d: "",
                            lt: "",
                            l: "",
                            i: ""
                        })
                    }}>Reset</button>

                    <button disabled={(this.type === "edit")} className="btn btn-danger m-1" onClick={() => {
                        this.onSuccessCallback({}, true)
                    }}>Delete</button>
                </div>
            </div>
        )
    }
}

class HomePageSlider extends React.Component {
    constructor() {
        super()
        this.getDataCaller = null

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: true,

            sliderItems: []
        }
    }

    fetchSlider = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "crs",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch slider information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch slider information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }

                that.setState({ sliderItems: response.payload.i, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(function (error) {
                that.setState({ isLoading: false, isError: true, isSuccess: false })
                alert("Some error has occurred")
            })
    }

    handleSaveSlider = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save slider information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save slider information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Slider",
            message: "Slider content successfully saved.",
            date: new Date(),
            isSuccess: true
        })

    }

    saveSlider = () => {
        const that = this
        axios.post(ADMINURL + "admin",
            {
                i: this.state.sliderItems
            }
            , {
                params: {
                    g: "scs",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleSaveSlider(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveSlider({})
                // alert("Some error has occurred")
            })
    }


    handleEdit = (idx, data) => {

    }

    componentDidMount() {
        this.fetchSlider()
    }

    render() {

        if (this.state.isLoading) {
            return null
        }

        if (this.state.isError) {
            return null
        }

        if (this.state.isSuccess) {
            return (
                <div className="m-2">
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                        <div className="form-group mt-2">
                            <label><b>Slider</b></label>

                        </div>

                        <div className="form-group">
                            <label>Slider Items</label>
                            <ItemSearch nameTitleKey="t" subNameTitleKey={'d'} onClickCallback={(resultData, idx, pn) => {

                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Edit Item',
                                    message: <AddHomePageSliderItem data={{
                                        t: resultData.t,
                                        st: resultData.st,
                                        d: resultData.d,
                                        lt: resultData.lt,
                                        l: resultData.l,
                                        i: resultData.i

                                    }} type="edit" onSuccessCallback={(data, d) => {
                                        var actionIDX = parseInt(idx) + (pn - 1) * 10
                                        var tmpSliderItems = this.state.sliderItems
                                        if (d === true) {
                                            // prepare index 
                                            tmpSliderItems.splice(actionIDX, 1)
                                        } else {
                                            tmpSliderItems[actionIDX] = data
                                        }

                                        this.setState({ sliderItems: tmpSliderItems }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog",
                                        })

                                    }} />
                                })
                            }} resultClickable={true} searchAllowed={true} data={this.state.sliderItems} gType="inv" noTime={true} urlEndPoint="" getDataCaller={(caller) => {
                                this.getDataCaller = caller
                            }} />
                        </div>



                        <div className="form-group">
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Product',
                                    message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                                        var tmpCurrentSliders = this.state.sliderItems

                                        // get image
                                        var image = ""
                                        if (resultData.images.length > 0) {
                                            image = `${resultData.images[0].image}`
                                        }

                                        tmpCurrentSliders.push({
                                            t: resultData.name,
                                            st: "",
                                            d: resultData.description,
                                            lt: "",
                                            l: `/product?g=sng&scr=sku&sku=${resultData.sku}`,
                                            i: image
                                        })

                                        this.setState({ sliderItems: tmpCurrentSliders }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                                })
                            }}>
                                Add Product
                        </span>
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Collection',
                                    message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                                        var tmpCurrentSliders = this.state.sliderItems

                                        tmpCurrentSliders.push({
                                            t: resultData.name,
                                            st: "",
                                            d: resultData.description,
                                            lt: "",
                                            l: `/collections/collection?cid=${resultData._id}&n=${resultData.name}`,
                                            i: ""
                                        })

                                        this.setState({ sliderItems: tmpCurrentSliders }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }} resultClickable={true} isResultLink={false} resultLink={''} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                                })
                            }}>
                                Add Collection
                        </span>
                            {/* <span> */}
                            <input disabled={this.state.isLoading} type="button" className="btn btn-primary" value="Save" onClick={this.saveSlider} />
                            {/* </span> */}
                        </div>

                    </div>
                </div>
            )
        }

        return null
    }

}

class HomePageSliderOld extends React.Component {
    constructor() {
        super()
        this.state = {
            images: {},
            images_extra: {},
            slider: {},
            currentSlider: [],

            isLoading: false,
            isError: false,
            isSuccess: false,

        }
    }

    componentDidMount() {
        this.fetchCurrentSlider()
    }

    ImageUploader = (e) => {

        var img_ = []
        for (var i in this.state.images) {
            console.log(i)
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} alt={`img${i}`} src={this.state.images[i]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        // $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        // $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <i class="fas fa-times" iid={i} onClick={(e) => {
                        var iid = e.target.getAttribute('iid');
                        var tmp_images = this.state.images;
                        tmp_images.splice(parseInt(iid), 1)
                        this.setState({ images: tmp_images })
                    }}> Delete</i>
                </div>
            )
        }


        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Add Images</h5>
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>

                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    fetchCurrentImages = () => {
        const that = this
        axios.get(ADMINURL + "image", {
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.setState({ retrivedInventory: response["data"]["payload"] })
            })
    }

    fetchCurrentSlider = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "crs",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch current slider information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch current slider information")
                    return
                }

                // prepare slider info
                // var preparedData = {}

                // for (var i in response.payload) {
                //     preparedData[response.payload[i]["_id"]] = response.payload[i]
                // }

                that.setState({ currentSlider: response.payload })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchInventory = () => {

        if (this.state.search_term === "") {
            return
        }

        const that = this
        axios.get(ADMINURL + "inventory", {
            params: {
                g: "inv",
                st: this.state.search_term
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch inventory")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch inventory")
                    return
                }

                that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveSlider = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save slider information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save slider information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Home Page Slider",
            message: "Slider content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveSlider = () => {
        // if (Object.keys(this.state.currentSlider).length === 0) {
        //     return
        // } 


        var IDs = []
        // prepare data 
        for (var i in this.state.currentSlider) {
            // IDs.push(this.state.currentSlider[i]._id)
            if (this.state.currentSlider[i].images === undefined || this.state.currentSlider[i].images.length === 0 || this.state.currentSlider[i].images[0].image === undefined || this.state.currentSlider[i].images[0].image === "") {
                return
            }
            IDs.push({
                id: this.state.currentSlider[i]._id,
                im: this.state.currentSlider[i].images[0].image
            })
        }

        const that = this
        axios.post(ADMINURL + "admin", {
            IDs: IDs
        }, {
            params: {
                g: "scs",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveSlider(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveSlider({})
                // alert("Some error has occurred")
            })

    }

    handleImageUpload = (input, idx) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'slider'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]

                        const tmpSlider = that.state.currentSlider
                        // alert(idx)
                        tmpSlider[idx].images = [
                            {
                                image: imgID,
                                alt: ""
                            }
                        ]
                        that.setState({ currentSlider: tmpSlider })
                        // const tmpStages = that.state.stages
                        // tmpStages[idx].images.push(
                        //     e.target.result
                        // )
                        // tmpStages[idx].images[imgID] = e.target.result
                        // that.setState({ image: e.target.result, imageID: imgID })

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {
        var currentSlider = []
        for (var i in this.state.currentSlider) {
            const idx = i

            var images = []
            // prepare images
            // for (var img in this.state.currentSlider[idx]["images"]) {
            //     const imgIdx = img
            var img = null
            if (this.state.currentSlider[idx].images.length > 0) {
                // alert(this.state.currentSlider[idx].im)
                img = <AuthenticatedImage key={this.state.currentSlider[idx].images[0].image} className="p-2" imageID={this.state.currentSlider[idx].images[0].image} imageStyle={{
                    // maxHeight: "150px",
                    cursor: 'pointer'
                    // borderRadius: '50%'
                }} onClick={() => {
                    fullDialogDispatcher.dispatch({
                        actionType: "show-dialog",
                        title: null,
                        closeOnOutsideClick: true,
                        message: <AuthenticatedImage imageID={this.state.currentSlider[idx].images[0].image} imageStyle={{
                            maxWidth: "100%",
                            width: "100%"
                        }}
                        />
                    })
                }} />
            }
            // alert(idx)
            images.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer", position: 'relative' }}>
                    {img}
                    <input type="file" class="form-control-file" id={`sliderImageUploadButton-${idx}`} onChange={(input) => {
                        this.handleImageUpload(input, idx)
                    }} hidden />
                    <input className="form-control " type="button" value="Add/Change Image" onClick={(e) => {
                        document.getElementById(`sliderImageUploadButton-${idx}`).click();
                    }} />

                </div>

            )
            // }
            currentSlider.push(
                <div key={this.state.currentSlider[i]._id} className="d-flex flex-column  border rounded p-2 mt-2">
                    <span>
                        <b>{this.state.currentSlider[i]["name"]}</b>
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" title="Delete Item" onClick={() => {
                            // const id = e.target.getAttribute("id")
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Remove Item?",
                                message: <div className="d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={() => {
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }}>Cancel</button>
                                    <button className="btn btn-danger ml-2" onClick={() => {
                                        var tmpCurrentSliders = this.state.currentSlider
                                        // delete tmpCurrentSliders[idx]
                                        tmpCurrentSliders.splice(idx, 1)
                                        this.setState({ currentSlider: tmpCurrentSliders })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })

                                    }}>Remove</button>
                                </div>
                            })

                        }} style={{
                            cursor: 'pointer'
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === 0) {
                                return
                            }

                            var tmpCurrentSliders = this.state.currentSlider
                            const tmpEle = tmpCurrentSliders[idx]
                            tmpCurrentSliders.splice(parseInt(idx), 1)
                            tmpCurrentSliders.splice(parseInt(idx) - 1, 0, tmpEle)
                            this.setState({ currentSlider: tmpCurrentSliders })

                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === this.state.currentSlider.length - 1) {
                                return
                            }

                            var tmpCurrentSliders = this.state.currentSlider
                            const tmpEle = tmpCurrentSliders[idx]
                            tmpCurrentSliders.splice(parseInt(idx), 1)
                            tmpCurrentSliders.splice(parseInt(idx) + 1, 0, tmpEle)
                            this.setState({ currentSlider: tmpCurrentSliders })

                        }} />
                    </span>
                    <span>
                        {this.state.currentSlider[i]["sku"]}
                    </span>
                    <div className="d-flex flex-wrap">
                        {images}
                    </div>
                </div >
            )
        }

        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                    <div className="form-group mt-2">
                        <label><b>Current Slider</b></label>
                        {currentSlider}
                    </div>

                    <div className="form-group">
                        <span className="btn btn-primary mr-2" onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: 'show-dialog',
                                title: 'Add Item',
                                message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    var tmpCurrentSliders = this.state.currentSlider
                                    // console.log(tmpCurrentSliders)
                                    tmpCurrentSliders.push(resultData)
                                    // tmpCurrentSliders[resultData._id] = resultData
                                    this.setState({ currentSlider: tmpCurrentSliders })

                                    fullDialogDispatcher.dispatch({
                                        actionType: "close-dialog"
                                    })
                                }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                            })
                        }}>
                            Add Item
                        </span>
                        <span className="btn btn-primary mr-2" onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: 'show-dialog',
                                title: 'Add Collection',
                                message: <ItemSearch nameTitleKey="name"
                                    // onClickCallback={(resultData) => {
                                    //     // const id = this.state.slider[iid]["_id"]
                                    //     var tmpCurrentSliders = this.state.currentSlider
                                    //     // console.log(tmpCurrentSliders)
                                    //     tmpCurrentSliders.push(resultData)
                                    //     // tmpCurrentSliders[resultData._id] = resultData
                                    //     this.setState({ currentSlider: tmpCurrentSliders })

                                    //     fullDialogDispatcher.dispatch({
                                    //         actionType: "close-dialog"
                                    //     })
                                    // }} 
                                    resultClickable={true} isResultLink={true} resultLink={'/collections/'} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                            })
                        }}>
                            Add Collection
                        </span>
                        {/* <span> */}
                        <input disabled={this.state.isLoading} type="button" className="btn btn-primary" value="Save" onClick={this.saveSlider} />
                        {/* </span> */}
                    </div>

                </div>
                {/* {image_edit} */}


            </div>
        )
    }
}

class TopBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            topbardata: "",
            topbardata2: "",
            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }

    componentDidMount() {
        this.fetchTopBar()
    }

    fetchTopBar = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gtb",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch Top Bar information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch Top Bar information")
                    return
                }

                that.setState({ topbardata: response.payload.data, topbardata2: response.payload.data2 })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveTopBar = (response) => {

        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save top bar information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save top bar information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Top Bar",
            message: "Top Bar content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveTopBar = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "admin", {
            data: this.state.topbardata,
            data2: this.state.topbardata2
        }, {
            params: {
                g: "tbr",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveTopBar(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveTopBar({})
                // alert("Some error has occurred")
            })

    }

    render() {
        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group mt-2">
                        <label><b>Set Top Bar data</b></label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="topbardata1">Blinking text</label>
                        <input id="topbardata" className="form-control" value={this.state.topbardata} onChange={(e) => {
                            this.setState({ topbardata: e.target.value })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="topbardata2">Normal text</label>
                        <input id="topbardata2" className="form-control" value={this.state.topbardata2} onChange={(e) => {
                            this.setState({ topbardata2: e.target.value })
                        }} />
                    </div>
                    <div className="form-group">
                        <input disabled={this.state.isLoading} type="button" className="btn btn-primary" value="Save" onClick={this.saveTopBar} />
                    </div>
                </div>
            </div>
        )
    }
}

class NotificationModal extends React.Component {
    constructor(props) {
        super()
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            dataTitle: "",
            dataPara: "",
            dataLink: "",
            dataLinkTitle: "",
            dataImage: "",
            dataEnable: true,
            dataBackgroundImage: "",
            dataIsSubscriptionDialog: false

        }
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'topbar'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]

                        that.setState({ dataImage: imgID })
                        // alert(imgID)

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    handleBackgroundImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'topbar'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]

                        that.setState({ dataBackgroundImage: imgID })
                        // alert(imgID)

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    componentDidMount() {
        this.fetchNotificationModal()
    }

    fetchNotificationModal = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gnm",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch Notification Modal information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch Notification Modal information")
                    return
                }

                that.setState({ dataTitle: response.payload.t, dataPara: response.payload.p, dataLink: response.payload.l, dataLinkTitle: response.payload.lt, dataImage: response.payload.i, dataEnable: response.payload.e, dataBackgroundImage: response.payload.b, dataIsSubscriptionDialog: response.payload.s })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveNotificationModal = (response) => {

        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save Notification Modal information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save Notification Modal information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Notification Modal",
            message: "Notification Modal content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveNotificationModal = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "admin", {
            t: this.state.dataTitle,
            p: this.state.dataPara,
            l: this.state.dataLink,
            lt: this.state.dataLinkTitle,
            i: this.state.dataImage,
            e: this.state.dataEnable,
            b: this.state.dataBackgroundImage,
            s: this.state.dataIsSubscriptionDialog
        }, {
            params: {
                g: "ntm",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveNotificationModal(response.data)
            })
            .catch(function (error) {
                that.handleSaveNotificationModal({})
            })

    }

    render() {
        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group mt-2">
                        <label><b>Set Notification Modal Data</b></label>
                    </div>
                    <div className="form-group" style={{
                        maxHeight: '400px',

                    }}>
                        <AuthenticatedImage key={this.state.dataImage} className="p-2" imageID={this.state.dataImage} imageStyle={{
                            maxHeight: "350px",
                            cursor: 'pointer'
                            // borderRadius: '50%'
                        }} onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: null,
                                closeOnOutsideClick: true,
                                message: <AuthenticatedImage imageID={this.state.dataImage} imageStyle={{
                                    maxWidth: "100%",
                                    width: "100%"
                                }}
                                />
                            })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="topbarImageUploadButton">Body Image</label>
                        <br />
                        <span style={{
                            cursor:'pointer'
                        }} onClick={()=>{
                            this.setState({dataImage:""})
                        }}>Remove</span>
                        <input type="file" class="form-control-file" id={`topbarImageUploadButton`} onChange={(input) => {

                            this.handleImageUpload(input)
                        }} hidden />
                        <input className="form-control " type="button" value="Add/Change Image" onClick={(e) => {
                            document.getElementById(`topbarImageUploadButton`).click();
                        }} />
                    </div>
                    <div className="form-group" style={{
                        maxHeight: '400px',

                    }}>
                        <AuthenticatedImage key={this.state.dataBackgroundImage} className="p-2" imageID={this.state.dataBackgroundImage} imageStyle={{
                            maxHeight: "350px",
                            cursor: 'pointer'
                            // borderRadius: '50%'
                        }} onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: null,
                                closeOnOutsideClick: true,
                                message: <AuthenticatedImage imageID={this.state.dataBackgroundImage} imageStyle={{
                                    maxWidth: "100%",
                                    width: "100%"
                                }}
                                />
                            })
                        }} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="topbarBackgroundImageUploadButton">Background Image</label>
                        <br />
                        <span style={{
                            cursor:'pointer'
                        }} onClick={()=>{
                            this.setState({dataBackgroundImage:""})
                        }}>Remove</span>
                        <input type="file" class="form-control-file" id={`topbarBackgroundImageUploadButton`} onChange={(input) => {

                            this.handleBackgroundImageUpload(input)
                        }} hidden />
                        <input className="form-control " type="button" value="Add/Change Image" onClick={(e) => {
                            document.getElementById(`topbarBackgroundImageUploadButton`).click();
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="notificationModalTitle">Title</label>
                        <input id="notificationModalTitle" className="form-control" value={this.state.dataTitle} onChange={(e) => {
                            this.setState({ dataTitle: e.target.value })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="notificationModalPara">Paragraph</label>
                        <ReactQuill id="notificationModalPara" theme="snow" value={this.state.dataPara} onChange={(data) => {
                            this.setState({ dataPara: data })
                        }} />
                        {/* <input id="topbardata" className="form-control" value={this.state.topbardata} onChange={(e) => {
                            this.setState({ topbardata: e.target.value })
                        }} /> */}
                    </div>
                    <div className="form-group">
                        <label htmlFor="notificationModalLinkButton">Link</label>
                        <input id="notificationModalLinkButton" className="form-control" value={this.state.dataLink} onChange={(e) => {
                            this.setState({ dataLink: e.target.value })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="notificationModalLinkTitle">Link Title</label>
                        <input id="notificationModalLinkTitle" className="form-control" value={this.state.dataLinkTitle} onChange={(e) => {
                            this.setState({ dataLinkTitle: e.target.value })
                        }} />
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="notificationModalIsSubscriptionDialog" aria-label="Enable Notification Modal" checked={this.state.dataIsSubscriptionDialog} onChange={(e) => {
                            this.setState({ dataIsSubscriptionDialog: e.target.checked })
                        }} />
                        <label className="form-check-label" htmlFor="notificationModalIsSubscriptionDialog">Is Subscription Dialog</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="notificationModalEnable" aria-label="Enable Notification Modal" checked={this.state.dataEnable} onChange={(e) => {
                            this.setState({ dataEnable: e.target.checked })
                        }} />
                        <label className="form-check-label" htmlFor="notificationModalEnable">Enable</label>
                    </div>
                    <div className="form-group">

                    </div>
                    {/* <div className="form-group">
                        <ReactQuill id="inventoryEditEcomDescription" theme="snow" value={this.state.data} onChange={(data) => {
                            this.setState({ data: data })
                        }} />
                    </div> */}
                    <div className="form-group">
                        <input disabled={this.state.isLoading} type="button" className="btn btn-primary" value="Save" onClick={this.saveNotificationModal} />
                    </div>
                </div>
            </div>
        )
    }
}

class TrendingCategory extends React.Component {
    constructor() {
        super()
        this.state = {
            images: {},
            images_extra: {},
            slider: {},
            trendingCategory: []
        }
    }

    componentDidMount() {
        this.fetchTrendingCategory()
    }

    ImageUploader = (e) => {

        var img_ = []
        for (var i in this.state.images) {
            console.log(i)
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} alt={`img${i}`} src={this.state.images[i]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        // $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        // $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <i class="fas fa-times" iid={i} onClick={(e) => {
                        var iid = e.target.getAttribute('iid');
                        var tmp_images = this.state.images;
                        tmp_images.splice(parseInt(iid), 1)
                        this.setState({ images: tmp_images })
                    }}> Delete</i>
                </div>
            )
        }


        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Add Images</h5>
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>

                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    fetchCurrentImages = () => {
        const that = this
        axios.get(ADMINURL + "image", {
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.setState({ retrivedInventory: response["data"]["payload"] })
            })
    }

    fetchTrendingCategory = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gtc",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch home page featured information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch home page featured information")
                    return
                }

                // prepare slider info
                // var preparedData = {}

                // for (var i in response.payload) {
                //     preparedData[response.payload[i]["_id"]] = response.payload[i]
                // }

                that.setState({ trendingCategory: response.payload })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchInventory = () => {

        if (this.state.search_term === "") {
            return
        }

        const that = this
        axios.get(ADMINURL + "inventory", {
            params: {
                g: "inv",
                st: this.state.search_term
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch inventory")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch inventory")
                    return
                }

                that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveTrendingCategory = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save trending category information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save trending category information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Trending Category",
            message: "Trending category content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveTrendingCategory = () => {
        // if (Object.keys(this.state.currentSlider).length === 0) {
        //     return
        // } 


        var IDs = []
        // prepare data 

        for (var i in this.state.trendingCategory) {
            // IDs.push(this.state.homepageFeatured[i]._id)
            IDs.push({
                id: this.state.trendingCategory[i]._id,
                im: ""
            })
        }

        const that = this
        axios.post(ADMINURL + "admin", {
            ids: IDs
        }, {
            params: {
                g: "stc",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveTrendingCategory(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveTrendingCategory({})
                // alert("Some error has occurred")
            })

    }

    render() {
        var trendingCategory = []
        for (var i in this.state.trendingCategory) {
            const idx = i

            var images = []
            // prepare images
            for (var img in this.state.trendingCategory[idx]["images"]) {
                const imgIdx = img
                images.push(
                    <AuthenticatedImage key={this.state.trendingCategory[idx]["images"][imgIdx].image} className="p-2" imageID={this.state.trendingCategory[idx]["images"][imgIdx].image} imageStyle={{
                        maxHeight: "150px",
                        cursor: 'pointer'
                        // borderRadius: '50%'
                    }} onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: null,
                            closeOnOutsideClick: true,
                            message: <AuthenticatedImage imageID={this.state.trendingCategory[idx]["images"][imgIdx].image} imageStyle={{
                                maxWidth: "100%",
                                width: "100%"
                            }}
                            />
                        })
                    }} />
                )
            }
            trendingCategory.push(
                <div key={this.state.trendingCategory[i]._id} className="d-flex flex-column  border rounded p-2 mt-2">
                    <span>
                        <b>{this.state.trendingCategory[i]["name"]}</b>
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" title="Delete Item" onClick={() => {
                            // const id = e.target.getAttribute("id")
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Remove Item?",
                                message: <div className="d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={() => {
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }}>Cancel</button>
                                    <button className="btn btn-danger ml-2" onClick={() => {
                                        var tmpTrendingCategory = this.state.trendingCategory
                                        // delete tmpCurrentSliders[idx]
                                        tmpTrendingCategory.splice(idx, 1)
                                        this.setState({ trendingCategory: tmpTrendingCategory })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })

                                    }}>Remove</button>
                                </div>
                            })

                        }} style={{
                            cursor: 'pointer'
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === 0) {
                                return
                            }

                            var tmpTrendingCategory = this.state.trendingCategory
                            const tmpEle = tmpTrendingCategory[idx]
                            tmpTrendingCategory.splice(parseInt(idx), 1)
                            tmpTrendingCategory.splice(parseInt(idx) - 1, 0, tmpEle)
                            this.setState({ trendingCategory: tmpTrendingCategory })

                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === this.state.trendingCategory.length - 1) {
                                return
                            }

                            var tmpTrendingCategory = this.state.trendingCategory
                            const tmpEle = tmpTrendingCategory[idx]
                            tmpTrendingCategory.splice(parseInt(idx), 1)
                            tmpTrendingCategory.splice(parseInt(idx) + 1, 0, tmpEle)
                            this.setState({ trendingCategory: tmpTrendingCategory })

                        }} />
                    </span>
                    <span>
                        {this.state.trendingCategory[i]["sku"]}
                    </span>
                    <div className="d-flex flex-wrap">
                        {images}
                    </div>
                </div >
            )
        }

        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                    <div className="form-group mt-2">
                        <label><b>Trending Category</b></label>
                        {trendingCategory}
                    </div>

                    <div className="form-group">
                        <span className="btn btn-primary mr-2" onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: 'show-dialog',
                                title: 'Add Item',
                                message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    var tmpTrendingCategory = this.state.trendingCategory
                                    // console.log(tmpCurrentSliders)
                                    tmpTrendingCategory.push(resultData)
                                    // tmpCurrentSliders[resultData._id] = resultData
                                    this.setState({ trendingCategory: tmpTrendingCategory })

                                    fullDialogDispatcher.dispatch({
                                        actionType: "close-dialog"
                                    })
                                }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                            })
                        }}>
                            Add Item
                        </span>
                        <input type="button" className="btn btn-primary" value="Save" onClick={this.saveTrendingCategory} />
                    </div>
                </div>
                {/* {image_edit} */}


            </div>
        )
    }

}

class AddCustomerReview extends React.Component {
    constructor(props) {
        super()

        this.data = props.data
        this.type = props.type
        this.onSuccessCallback = props.onSuccessCallback

        this.state = {
            t: this.data.t,
            m: this.data.m,
            n: this.data.n
        }
    }
    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="reviewName">Name</label>
                    <input className="form-control" id="reviewName" value={this.state.n} onChange={(e) => {
                        this.setState({ n: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="reviewTitle">Title</label>
                    <input className="form-control" id="reviewTitle" value={this.state.t} onChange={(e) => {
                        this.setState({ t: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="reviewMessage">Message</label>
                    <textarea rows="5" className="form-control" id="reviewMessage" value={this.state.m} onChange={(e) => {
                        this.setState({ m: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <button className="btn btn-success m-1" onClick={() => {
                        this.onSuccessCallback(this.state.t, this.state.m, this.state.n)
                    }}>Done</button>
                    <button className="btn btn-primary m-1" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>Close</button>
                    <button className="btn btn-danger m-1" onClick={() => {
                        this.setState({
                            t: "",
                            m: "",
                            n: ""
                        })
                    }}>Reset</button>

                    <button disabled={!(this.type === "edit")} className="btn btn-danger m-1" onClick={() => {
                        this.onSuccessCallback(this.state.t, this.state.m, this.state.n, true)
                    }}>Delete</button>
                </div>
            </div>
        )
    }
}

class CustomerReviews extends React.Component {
    constructor() {
        super()
        this.getDataCaller = null
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: true,

            t: "",
            r: []
        }
    }

    fetchCustomerReviews = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gcr",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch customer reviews information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch customer reviews information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }

                // prepare slider info
                // var preparedData = {}

                // for (var i in response.payload) {
                //     preparedData[response.payload[i]["_id"]] = response.payload[i]
                // }

                that.setState({ t: response.payload.t, r: response.payload.r, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(function (error) {
                that.setState({ isLoading: false, isError: true, isSuccess: false })
                alert("Some error has occurred")
            })
    }

    handleSaveCustomerReviews = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save customer reviews information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save customer reviews information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Customer Reviews",
            message: "Customer reviews content successfully saved.",
            date: new Date(),
            isSuccess: true
        })

    }

    saveCustomerReviews = () => {
        const that = this
        axios.post(ADMINURL + "admin",
            {
                t: this.state.t,
                r: this.state.r
            }
            , {
                params: {
                    g: "scr",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleSaveCustomerReviews(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveCustomerReviews({})
                // alert("Some error has occurred")
            })
    }


    handleEdit = (idx, data) => {

    }

    componentDidMount() {
        this.fetchCustomerReviews()
    }

    render() {

        if (this.state.isLoading) {
            return null
        }

        if (this.state.isError) {
            return null
        }

        if (this.state.isSuccess) {
            return (
                <div className="m-2">
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                        <div className="form-group mt-2">
                            <label><b>Customer Reviews</b></label>

                        </div>
                        <div className="form-group">
                            <label htmlFor="sectionTitleText">Section Title</label>
                            <input type="text" id="sectionTitleText" value={this.state.t} onChange={(e) => {
                                this.setState({ t: e.target.value })
                            }} className="form-control" />
                        </div>

                        <div className="form-group">
                            <label>Reviews</label>
                            <ItemSearch nameTitleKey="n" subNameTitleKey={'t'} onClickCallback={(resultData, idx, pn) => {

                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Edit Review',
                                    message: <AddCustomerReview data={{
                                        t: resultData.t,
                                        m: resultData.m,
                                        n: resultData.n
                                    }} type="edit" onSuccessCallback={(t, m, n, d) => {
                                        var actionIDX = parseInt(idx) + (pn - 1) * 10
                                        var tmpReviews = this.state.r
                                        if (d === true) {
                                            // prepare index 
                                            tmpReviews.splice(actionIDX, 1)
                                        } else {
                                            tmpReviews[actionIDX] = {
                                                t: t,
                                                m: m,
                                                n: n
                                            }
                                        }

                                        this.setState({ r: tmpReviews }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog",
                                        })

                                    }} />
                                })
                            }} resultClickable={true} searchAllowed={true} data={this.state.r} gType="inv" noTime={true} urlEndPoint="" getDataCaller={(caller) => {
                                this.getDataCaller = caller
                            }} />
                        </div>

                        <div className="form-group">
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Review',
                                    message: <AddCustomerReview data={{
                                        t: "",
                                        m: "",
                                        n: ""
                                    }} type="new" onSuccessCallback={(t, m, n) => {
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog",
                                        })
                                        var tmpReviews = this.state.r
                                        tmpReviews.push({
                                            t: t,
                                            m: m,
                                            n: n
                                        })
                                        this.setState({ r: tmpReviews }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })
                                    }} />
                                })
                            }}>
                                Add Review
                            </span>
                            <input type="button" className="btn btn-primary" value="Save" onClick={this.saveCustomerReviews} />
                        </div>
                    </div>
                </div>
            )
        }

        return null
    }
}

class AddBannerGridItem extends React.Component {
    constructor(props) {
        super()
        this.data = props.data

        this.onSuccessCallback = props.onSuccessCallback

        this.state = {
            t: this.data.t,
            st: this.data.st,
            lt: this.data.lt,
            l: this.data.l,
            i: this.data.i
        }
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'slider'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]
                        that.setState({ i: imgID })

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="sliderItemTitle">Title</label>
                    <input className="form-control" id="sliderItemTitle" value={this.state.t} onChange={(e) => {
                        this.setState({ t: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemSubTitle">Sub Title</label>
                    <input className="form-control" id="sliderItemSubTitle" value={this.state.st} onChange={(e) => {
                        this.setState({ st: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemLink">Link</label>
                    <input className="form-control" id="sliderItemLink" value={this.state.l} onChange={(e) => {
                        this.setState({ l: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemLinkTitle">Link Title</label>
                    <input className="form-control" id="sliderItemLinkTitle" value={this.state.lt} onChange={(e) => {
                        this.setState({ lt: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <AuthenticatedImage key={this.state.i} className="p-2" imageID={this.state.i} imageStyle={{
                        width: "100%",
                        cursor: 'pointer'
                    }} onClick={() => {
                    }} />

                    <input type="file" class="form-control-file" id={`sliderItemImageUploadButton`} onChange={(input) => {

                        this.handleImageUpload(input)
                    }} hidden />
                    <input className="form-control " type="button" value="Add/Change Image" onClick={(e) => {
                        document.getElementById(`sliderItemImageUploadButton`).click();
                    }} />
                </div>

                <div className="form-group">
                    <button className="btn btn-success m-1" onClick={() => {
                        this.onSuccessCallback({
                            t: this.state.t,
                            st: this.state.st,
                            lt: this.state.lt,
                            l: this.state.l,
                            i: this.state.i
                        })
                    }}>Done</button>
                    <button className="btn btn-primary m-1" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>Close</button>
                    <button className="btn btn-danger m-1" onClick={() => {
                        this.setState({
                            t: "",
                            st: "",
                            lt: "",
                            l: "",
                            i: ""
                        })
                    }}>Reset</button>

                    <button disabled={(this.type === "edit")} className="btn btn-danger m-1" onClick={() => {
                        this.onSuccessCallback({}, true)
                    }}>Delete</button>
                </div>
            </div>
        )
    }
}

class BannerGrid extends React.Component {
    constructor() {
        super()
        this.getDataCaller = null

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: true,

            bannerGridItems: []
        }
    }

    fetchBannerGrid = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gcb",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch banner grid information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch banner grid information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }

                that.setState({ bannerGridItems: response.payload.i, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(function (error) {
                that.setState({ isLoading: false, isError: true, isSuccess: false })
                alert("Some error has occurred")
            })
    }

    handleSaveBannerGrid = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save banner grid information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save banner grid information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Banner Grid",
            message: "Banner Grid content successfully saved.",
            date: new Date(),
            isSuccess: true
        })

    }

    saveBannerGrid = () => {
        const that = this
        axios.post(ADMINURL + "admin",
            {
                i: this.state.bannerGridItems
            }
            , {
                params: {
                    g: "scb",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleSaveBannerGrid(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveBannerGrid({})
                // alert("Some error has occurred")
            })
    }


    handleEdit = (idx, data) => {

    }

    componentDidMount() {
        this.fetchBannerGrid()
    }

    render() {

        if (this.state.isLoading) {
            return null
        }

        if (this.state.isError) {
            return null
        }

        if (this.state.isSuccess) {
            return (
                <div className="m-2">
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                        <div className="form-group mt-2">
                            <label><b>Banner Grid</b></label>

                        </div>

                        <div className="form-group">
                            <label>Banner Grid Items</label>
                            <ItemSearch nameTitleKey="t" subNameTitleKey={'d'} onClickCallback={(resultData, idx, pn) => {

                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Edit Item',
                                    message: <AddBannerGridItem data={{
                                        t: resultData.t,
                                        st: resultData.st,
                                        lt: resultData.lt,
                                        l: resultData.l,
                                        i: resultData.i

                                    }} type="edit" onSuccessCallback={(data, d) => {
                                        var actionIDX = parseInt(idx) + (pn - 1) * 10
                                        var tmpSliderItems = this.state.bannerGridItems
                                        if (d === true) {
                                            // prepare index 
                                            tmpSliderItems.splice(actionIDX, 1)
                                        } else {
                                            tmpSliderItems[actionIDX] = data
                                        }

                                        this.setState({ bannerGridItems: tmpSliderItems }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog",
                                        })

                                    }} />
                                })
                            }} resultClickable={true} searchAllowed={true} data={this.state.bannerGridItems} gType="inv" noTime={true} urlEndPoint="" getDataCaller={(caller) => {
                                this.getDataCaller = caller
                            }} />
                        </div>



                        <div className="form-group">
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Product',
                                    message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                                        var tmpCurrentSliders = this.state.bannerGridItems

                                        // get image
                                        var image = ""
                                        if (resultData.images.length > 0) {
                                            image = `${resultData.images[0].image}`
                                        }

                                        tmpCurrentSliders.push({
                                            t: resultData.name,
                                            st: "",
                                            lt: "",
                                            l: `/product?g=sng&scr=sku&sku=${resultData.sku}`,
                                            i: image
                                        })

                                        this.setState({ bannerGridItems: tmpCurrentSliders }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                                })
                            }}>
                                Add Product
                        </span>
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Collection',
                                    message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                                        var tmpCurrentSliders = this.state.bannerGridItems

                                        tmpCurrentSliders.push({
                                            t: resultData.name,
                                            st: "",
                                            lt: "",
                                            l: `/collections/collection/?cid=${resultData._id}&n=${resultData.name}`,
                                            i: ""
                                        })

                                        this.setState({ bannerGridItems: tmpCurrentSliders }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }} resultClickable={true} isResultLink={false} resultLink={''} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                                })
                            }}>
                                Add Collection
                        </span>
                            {/* <span> */}
                            <input disabled={this.state.isLoading} type="button" className="btn btn-primary" value="Save" onClick={this.saveBannerGrid} />
                            {/* </span> */}
                        </div>

                    </div>
                </div>
            )
        }

        return null
    }
}


class AddDealAreaItem extends React.Component {
    constructor(props) {
        super()
        this.data = props.data

        this.onSuccessCallback = props.onSuccessCallback

        this.state = {
            t: this.data.t,
            st: this.data.st,
            lt: this.data.lt,
            l: this.data.l,
            i: this.data.i,
            c: DateTime.fromISO(this.data.c),
            ce: this.data.ce
        }
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'slider'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]
                        that.setState({ i: imgID })

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="sliderItemTitle">Title</label>
                    <input className="form-control" id="sliderItemTitle" value={this.state.t} onChange={(e) => {
                        this.setState({ t: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemSubTitle">Sub Title</label>
                    <input className="form-control" id="sliderItemSubTitle" value={this.state.st} onChange={(e) => {
                        this.setState({ st: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemDescription">Description</label>
                    <textarea rows="5" className="form-control" id="sliderItemDescription" value={this.state.d} onChange={(e) => {
                        this.setState({ d: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemLink">Link</label>
                    <input className="form-control" id="sliderItemLink" value={this.state.l} onChange={(e) => {
                        this.setState({ l: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="sliderItemLinkTitle">Link Title</label>
                    <input className="form-control" id="sliderItemLinkTitle" value={this.state.lt} onChange={(e) => {
                        this.setState({ lt: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="dealAreaItemCountdown">Date (Countdown)</label>
                    <input className="form-control" type="datetime-local" id="dealAreaItemCountdown" value={this.state.c.toFormat("yyyy-LL-dd'T'HH:mm")} onChange={(e) => {
                        this.setState({ c: DateTime.fromFormat(e.target.value, "yyyy-LL-dd'T'HH:mm") })
                    }} />
                </div>

                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" checked={this.state.ce} id="dealAreaItemCountdownEnabled" onChange={(e) => {
                            this.setState({ ce: e.target.checked })
                        }} />
                        <label class="form-check-label" htmlFor="dealAreaItemCountdownEnabled">
                            Countdown Enabled
                        </label>
                    </div>

                </div>
                <div className="form-group">
                    <AuthenticatedImage key={this.state.i} className="p-2" imageID={this.state.i} imageStyle={{
                        width: "100%",
                        cursor: 'pointer'
                    }} onClick={() => {
                    }} />

                    <input type="file" class="form-control-file" id={`sliderItemImageUploadButton`} onChange={(input) => {

                        this.handleImageUpload(input)
                    }} hidden />
                    <input className="form-control " type="button" value="Add/Change Image" onClick={(e) => {
                        document.getElementById(`sliderItemImageUploadButton`).click();
                    }} />
                </div>

                <div className="form-group">
                    <button className="btn btn-success m-1" onClick={() => {
                        this.onSuccessCallback({
                            t: this.state.t,
                            st: this.state.st,
                            d: this.state.d,
                            lt: this.state.lt,
                            l: this.state.l,
                            i: this.state.i,
                            c: this.state.c,
                            ce: this.state.ce
                        })
                    }}>Done</button>
                    <button className="btn btn-primary m-1" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>Close</button>
                    <button className="btn btn-danger m-1" onClick={() => {
                        this.setState({
                            t: "",
                            st: "",
                            d: "",
                            lt: "",
                            l: "",
                            i: "",
                            c: DateTime.now(),
                            ce: false
                        })
                    }}>Reset</button>

                    <button disabled={(this.type === "edit")} className="btn btn-danger m-1" onClick={() => {
                        this.onSuccessCallback({}, true)
                    }}>Delete</button>
                </div>
            </div>
        )
    }
}

class DealArea extends React.Component {
    constructor() {
        super()
        this.getDataCaller = null

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: true,

            dealAreaItems: []
        }
    }

    fetchDealArea = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gcd",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch deal area information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch deal area information")
                    that.setState({ isLoading: false, isError: true, isSuccess: false })
                    return
                }

                that.setState({ dealAreaItems: response.payload.i, isLoading: false, isSuccess: true, isError: false })
            })
            .catch(function (error) {
                that.setState({ isLoading: false, isError: true, isSuccess: false })
                alert("Some error has occurred")
            })
    }

    handleSaveDealArea = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save deal area information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save deal area information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Deal Area",
            message: "Deal area content successfully saved.",
            date: new Date(),
            isSuccess: true
        })

    }

    saveDealArea = () => {
        const that = this
        axios.post(ADMINURL + "admin",
            {
                i: this.state.dealAreaItems
            }
            , {
                params: {
                    g: "scd",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleSaveDealArea(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveDealArea({})
                // alert("Some error has occurred")
            })
    }


    handleEdit = (idx, data) => {

    }

    componentDidMount() {
        this.fetchDealArea()
    }

    render() {

        if (this.state.isLoading) {
            return null
        }

        if (this.state.isError) {
            return null
        }

        if (this.state.isSuccess) {
            return (
                <div className="m-2">
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                        <div className="form-group mt-2">
                            <label><b>Deal Area</b></label>

                        </div>

                        <div className="form-group">
                            <label>Deal Area Items</label>
                            <ItemSearch nameTitleKey="t" subNameTitleKey={'d'} onClickCallback={(resultData, idx, pn) => {

                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Edit Item',
                                    message: <AddDealAreaItem data={{
                                        t: resultData.t,
                                        st: resultData.st,
                                        lt: resultData.lt,
                                        l: resultData.l,
                                        i: resultData.i,
                                        c: resultData.c,
                                        ce: resultData.ce

                                    }} type="edit" onSuccessCallback={(data, d) => {
                                        var actionIDX = parseInt(idx) + (pn - 1) * 10
                                        var tmpSliderItems = this.state.dealAreaItems
                                        if (d === true) {
                                            // prepare index 
                                            tmpSliderItems.splice(actionIDX, 1)
                                        } else {
                                            tmpSliderItems[actionIDX] = data
                                        }

                                        this.setState({ dealAreaItems: tmpSliderItems }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog",
                                        })

                                    }} />
                                })
                            }} resultClickable={true} searchAllowed={true} data={this.state.dealAreaItems} gType="inv" noTime={true} urlEndPoint="" getDataCaller={(caller) => {
                                this.getDataCaller = caller
                            }} />
                        </div>



                        <div className="form-group">
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Product',
                                    message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                                        var tmpCurrentSliders = this.state.dealAreaItems

                                        // get image
                                        var image = ""
                                        if (resultData.images.length > 0) {
                                            image = `${resultData.images[0].image}`
                                        }

                                        tmpCurrentSliders.push({
                                            t: resultData.name,
                                            st: "",
                                            lt: "",
                                            l: `/product?g=sng&scr=sku&sku=${resultData.sku}`,
                                            i: image,
                                            c: DateTime.now(),
                                            ce: false
                                        })

                                        this.setState({ dealAreaItems: tmpCurrentSliders }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                                })
                            }}>
                                Add Product
                        </span>
                            <span className="btn btn-primary mr-2" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: 'show-dialog',
                                    title: 'Add Collection',
                                    message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                                        var tmpCurrentSliders = this.state.dealAreaItems

                                        tmpCurrentSliders.push({
                                            t: resultData.name,
                                            st: "",
                                            lt: "",
                                            l: `/collections/collection/?cid=${resultData._id}&n=${resultData.name}`,
                                            i: "",
                                            c: DateTime.now(),
                                            ce: false
                                        })

                                        this.setState({ dealAreaItems: tmpCurrentSliders }, () => {
                                            this.getDataCaller.dispatch({
                                                actionType: "get-data"
                                            })
                                        })

                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }} resultClickable={true} isResultLink={false} resultLink={''} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                                })
                            }}>
                                Add Collection
                        </span>
                            {/* <span> */}
                            <input disabled={this.state.isLoading} type="button" className="btn btn-primary" value="Save" onClick={this.saveDealArea} />
                            {/* </span> */}
                        </div>

                    </div>
                </div>
            )
        }

        return null
    }
}

class Front extends React.Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<HomePageSlider />} title={"Home Page Slider"} />,
            <Window content={<HomePageFeatured />} title={"Home Page Featured"} />,
            <Window content={<HomePageCollections />} title={"Home Page Collections"} />,
            <Window content={<TopBar />} title={"Top Bar (Black)"} />,
            <Window content={<NotificationModal />} title={"Notification Modal"} />,
            <Window content={<TrendingCategory />} title={"Trending Category"} />,
            <Window content={<CustomerReviews />} title={"Customer Reviews"} />,
            <Window content={<BannerGrid />} title={"Banner Grid"} />,
            <Window content={<DealArea />} title={"Deal Area"} />,

            // <AboutPage />
        ]
    }

}



export {
    Front,
    dataURItoBlob
}