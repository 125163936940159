import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import NavBar from '../Components/NavBar';
import { Window } from '../Components/Window';
import { useHistory, useParams } from 'react-router';
import { ItemSearch } from '../Components/ItemSearch';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { toastsDispatcher } from '../Components/Toasts';
import AuthenticatedImage from '../Components/AuthenticatedImage';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ADMINURL } from '../Components/MSURLS';

var ACCT = Cookies.get('ACCT')
var { DateTime } = require('luxon');

var viewAllCommentsDispatcher = null
var addCommentDialogIndex = null

class CommentOnSelector extends React.Component {
    constructor(props) {
        super()
        this.onClickCallback = props.onClickCallback
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Generate URL
                </div>
                <div className="form-group">
                    Inventory Items
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                        this.onClickCallback({
                            kind: "item",
                            data: resultData
                        })

                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                </div>

                <div className="form-group">
                    Collections
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                        this.onClickCallback({
                            kind: "collection",
                            data: resultData
                        })

                    }} resultClickable={true} isResultLink={false} resultLink={'/collections/'} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                </div>
            </div>
        )
    }
}

class CommentBySelector extends React.Component {
    constructor(props) {
        super()
        this.onClickCallback = props.onClickCallback
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">
                <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => { this.onClickCallback(resultData) }} isResultLink={false} subNameTitleKey={'l'} resultClickable={true} searchAllowed={true} gType="ecm" timeKey="" urlEndPoint="users" subInfoKeys={[]} />
            </div>
        )
    }
}
class AddComment extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        this.data = props.data

        this.state = {
            comment: "",
            commentOn: null,
            commentBy: null,
            isLoading: false,
            isError: false,
            isSuccess: false,

            commentByDialogIndex: null,
            commentOnDialogIndex: null
        }
    }

    handleAddComment = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add comment")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Comment",
                message: `Failed to add comment. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Comment",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-dialog",
            dialogIndex: addCommentDialogIndex
        })

        viewAllCommentsDispatcher.dispatch({
            actionType: "get-data"
        })


    }
    addComment = () => {

        if (this.state.commentBy === null) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Comment",
                message: `Failed to add comment. Comment By cannot be null.`,
                date: new Date(),
                isError: true
            })
            return
        }
        if (this.state.commentOn === null) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Comment",
                message: `Failed to add comment. Comment On cannot be null.`,
                date: new Date(),
                isError: true
            })
            return
        }
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "comments", {
            b: this.state.comment,
            c: this.state.commentOn.data._id,
            cb: this.state.commentBy._id
        }, {
            params: {
                g: "add",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddComment(response.data)
            })
            .catch(function (error) {
                that.handleAddComment({})
            })
    }

    render() {

        /* prepare comment on */
        var commentOn = null
        if (this.state.commentOn !== null) {
            commentOn = <div>
                <div className="from-group">
                    <b>{this.state.commentOn.kind}</b>
                </div>
                <div className="from-group">
                    {this.state.commentOn.data.name}
                </div>
            </div>
        }

        /* prepare comment by */
        var commentBy = null
        if (this.state.commentBy !== null) {
            commentBy = <div>
                <div className="from-group">
                    {this.state.commentBy.f}&nbsp;{this.state.commentBy.l}
                </div>
                <div className="from-group">
                    {this.state.commentBy.ph}
                </div>
                <div className="from-group">
                    {this.state.commentBy.e}
                </div>
            </div>
        }


        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="commentComment">Comment</label>
                    <textarea id="commentComment" className="form-control" value={this.state.comment} onChange={(e) => {
                        this.setState({ comment: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="commentCommentBy">Comment By</label>
                    <br />
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Comment By",
                            dialogIndexCallback: (d) => {
                                this.setState({ commentByDialogIndex: d })
                            },
                            message: <CommentBySelector onClickCallback={(result) => {
                                this.setState({
                                    commentBy: result
                                })
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "close-dialog",
                                    dialogIndex: this.state.commentByDialogIndex
                                })
                            }} />
                        })
                    }}>Select</button>
                    {commentBy}
                </div>
                <div className="form-group">
                    <label htmlFor="commentCommentOn">Comment On</label>
                    <br />
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Comment On",
                            dialogIndexCallback: (d) => {
                                this.setState({ commentOnDialogIndex: d })
                            },
                            message: <CommentOnSelector onClickCallback={(result) => {
                                this.setState({
                                    commentOn: result
                                })
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "close-dialog",
                                    dialogIndex: this.state.commentOnDialogIndex
                                })
                            }} />
                        })
                    }}>Select</button>
                    {commentOn}
                </div>
                <div className="form-group">
                    <button onClick={this.addComment} disabled={this.state.isLoading} className="btn btn-primary"><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add</button>
                </div>
            </div>
        )
    }
}

class ViewSingleComment extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        this.commentID = props.commentID
        this.history = props.history

        this.data = props.data

        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            what: "n",
            data: null
        }
    }

    handleFetchSingleComment = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get comment")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Comment",
                message: `Unable to get comment. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ data: response.payload, isSuccess: true })
    }

    fetchSingleComment = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "comments", {
            params: {
                g: "sgl",
                id: this.commentID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchSingleComment(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchSingleComment({})
            })
    }

    handleUpdateSingleComment = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to change comment state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Comment",
                message: `Unable to change comment state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Comment",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false, }, () => {
            this.fetchSingleComment()
        })
    }

    updateSingleComment = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "comments", {
            b: this.state.data.c.b
        }, {
            params: {
                g: "upd",
                id: this.commentID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateSingleComment(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateSingleComment({})
            })
    }

    handleApproveSingleComment = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to change comment state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Comment",
                message: `Unable to change comment state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Comment",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false, }, () => {
            this.fetchSingleComment()
        })
    }

    approveDisapproveSingleComment = (approve) => {

        var approveOrDisapproveGtype = "apr"

        if (approve === false) {
            approveOrDisapproveGtype = "dpr"
        }

        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "comments", {
            params: {
                g: approveOrDisapproveGtype,
                id: this.commentID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleApproveSingleComment(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleApproveSingleComment({})
            })
    }

    handleDeleteSingleComment = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to change comment state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Comment",
                message: `Unable to change comment state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Comment",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false, }, () => {
            this.history.push("/comments");
        })
    }

    deleteSingleComment = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "comments", {
            // b: this.state.data.c.b
        }, {
            params: {
                g: "del",
                id: this.commentID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteSingleComment(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleDeleteSingleComment({})
            })
    }

    componentDidMount() {
        this.fetchSingleComment()
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div>
                    loading...
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="text-danger">
                    Some error has occurrred. Please try again.
                </div>
            )
        }
        if (this.state.isSuccess) {

            var commentOn = null


            if (this.state.data.cl._id !== "000000000000000000000000") {
                commentOn = <div className="form-group">
                    <b>{this.state.data.cl.name}</b>
                    <br />
                    <span style={{
                        whiteSpace: 'pre-wrap'
                    }}>
                        {this.state.data.cl.description}
                    </span>
                </div>
            }
            if (this.state.data.i._id !== "000000000000000000000000") {
                /* prepare comment on images */

                var images = []
                for (var i in this.state.data.i.images) {
                    const idx = i
                    images.push(
                        <div style={{
                            maxWidth: '100px'
                        }}>
                            <AuthenticatedImage key={this.state.data.i.images[idx].image} className="p-2" imageID={this.state.data.i.images[idx].image} imageStyle={{
                                width: "100%",
                                cursor: 'pointer'
                            }} onClick={() => {
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: null,
                                    closeOnOutsideClick: true,
                                    message: <AuthenticatedImage imageID={this.state.data.i.images[idx].image} imageStyle={{
                                        maxWidth: "100%",
                                        width: "100%"
                                    }}
                                    />
                                })
                            }} />
                        </div>
                    )
                }

                commentOn = <div className="form-group">
                    <b>{this.state.data.i.name}</b>
                    <br />
                    SKU: {this.state.data.i.sku}
                    <br />
                    <div class="d-flex flex-wrap">
                        {images}
                    </div>
                </div>
            }




            return (
                <div className="p-4">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.push('/comments');
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Comments</span>
                        {/* <Link to="/orders"><FontAwesomeIcon icon={faArrowLeft} /> Orders</Link> */}
                    </div>

                    <div className="form-group" hidden={!this.state.data.c.e}>
                        <span><i><u>Edited</u></i></span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="editCommentBody">Comment Body</label>
                        <textarea rows="8" className="form-control" id="editCommentBody" value={this.state.data.c.b} onChange={(e) => {
                            const tmpData = this.state.data
                            tmpData.c.b = e.target.value
                            this.setState({ data: tmpData })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Comment Creation Time</label>
                        <br />
                        <b>{DateTime.fromISO(this.state.data.c.t).toLocaleString(DateTime.DATETIME_MED)}</b>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Comment Updation Time</label>
                        <br />
                        {DateTime.fromISO(this.state.data.c.up).toLocaleString(DateTime.DATETIME_MED)}
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Comment By</label>
                        <div className="form-group" style={{
                            fontWeight: 'bold'
                        }}>
                            {this.state.data.u.f}&nbsp;{this.state.data.u.l}
                            <br />
                            {this.state.data.u.ph}
                            <br />
                            {this.state.data.u.e}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Comment On</label>
                        {commentOn}
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary m-1" disabled={this.state.isLoading} hidden={this.state.data.c.d} onClick={this.updateSingleComment}>Update</button>
                        <button className="btn btn-primary m-1" disabled={this.state.isLoading} hidden={this.state.data.c.a || this.state.data.c.d} onClick={() => { this.approveDisapproveSingleComment(true) }}>Approve Comment</button>
                        <button className="btn btn-primary m-1" disabled={this.state.isLoading} hidden={!this.state.data.c.a || this.state.data.c.d} onClick={() => { this.approveDisapproveSingleComment(false) }}>Disapprove Comment</button>
                        <button className="btn btn-danger m-1" hidden={this.state.data.c.d} disabled={this.state.isLoading} onClick={() => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Delete Comment",
                                message: <div className="p-2">
                                    <div className="form-group" hidden={!this.state.data.c.a} style={{
                                        fontWeight: "bold"
                                    }}>
                                        You are deleting an approved comment. Are you sure you still want to delete it?
                                    </div>
                                    <button className="btn btn-primary m-1" onClick={() => {
                                        fullDialogV2Dispatcher.dispatch({
                                            actionType: "close-all-dialogs"
                                        })
                                    }}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-danger m-1" onClick={() => {
                                        this.deleteSingleComment()
                                    }}>
                                        Delete
                                    </button>
                                </div>
                            })
                        }}>Delete Comment</button>
                    </div>
                </div>
            )
        }

        return null
    }
}

class ViewAllComments extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        this.state = {
            what: "n"
        }
    }

    render() {
        return (
            <div className="p-2">

                <div className="form-group">
                    Filter Comments:
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioCommentsAll" id="radioCommentsAll" checked={this.state.what === "all"} onChange={() => {
                            this.setState({ what: "all" }, () => {
                                viewAllCommentsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioCommentsAll">
                            All
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioCommentsApproved" id="radioCommentsApproved" checked={this.state.what === "a"} onChange={() => {
                            this.setState({ what: "a" }, () => {
                                viewAllCommentsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioCommentsApproved">
                            Approved
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioCommentsNotApproved" id="radioCommentsNotApproved" checked={this.state.what === "n"} onChange={() => {
                            this.setState({ what: "n" }, () => {
                                viewAllCommentsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioCommentsNotApproved">
                            Not Approved
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioCommentsDeleted" id="radioCommentsDeleted" checked={this.state.what === "d"} onChange={() => {
                            this.setState({ what: "d" }, () => {
                                viewAllCommentsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioCommentsDeleted">
                            Deleted
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="b" isResultLink={true} resultLink={'/comments/'} onClickCallback={(resultData) => {

                    }} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="comments" urlParams={{
                        w: this.state.what
                    }} getDataCaller={(d) => {
                        viewAllCommentsDispatcher = d
                    }} subInfoKeys={[
                        {
                            "key": "commentBy",
                            "map": function (data) {
                                return <div>
                                    {data[0].f}
                                    <br />
                                    {data[0].l}
                                    <br />
                                    {data[0].ph}
                                    <br />
                                    {data[0].e}
                                </div>
                            }
                        },
                    ]} />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add Comment",
                            dialogWidth: 3,
                            dialogIndexCallback: (idx) => {
                                addCommentDialogIndex = idx
                            },
                            message: <AddComment />
                        })
                    }}>Add Comment</button>
                </div>
            </div>
        )
    }
}

class CommentsPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")


        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="">
                    <div className="col-6 col-sm-12 col-md-6 col-lg-6">
                        <ViewAllComments />
                    </div>
                </div>
            </div>
        )
    }
}

function Comments() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<CommentsPage urlID={id} />} title={"View Comments"} />,
        // <Window content={<AddWalletEntry urlID={id} />} title={"Add Wallet Entry"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

function SingleComment() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewSingleComment commentID={id} history={history} />} title={"Comment"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    CommentsPage,
    Comments,
    SingleComment
}