import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';

import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ADMINURL } from '../Components/MSURLS';

var _ = require('lodash');


var ACCT = Cookies.get('ACCT')

Object.defineProperty(Array.prototype, 'move', {
    value: function (old_index, new_index) {
        while (old_index < 0) {
            old_index += this.length;
        }
        while (new_index < 0) {
            new_index = 0
            // new_index += this.length;
        }
        if (new_index >= this.length) {
            let k = new_index - this.length;
            while ((k--) + 1) {
                // this.push(undefined);
            }
        }
        this.splice(new_index, 0, this.splice(old_index, 1)[0]);
        return this;
    }
});

class SubMenuItemColumnMenuItem extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
        this.columnID = props.columnID
        this.columnMenuItemID = props.columnMenuItemID
        this.onSubMenuItemColumnMenuItemDelete = props.onSubMenuItemColumnMenuItemDelete
        this.onSubMenuItemColumnMenuItemMoveDown = props.onSubMenuItemColumnMenuItemMoveDown
        this.onSubMenuItemColumnMenuItemMoveUp = props.onSubMenuItemColumnMenuItemMoveUp
    }

    render() {
        const id = this.id
        const columnID = this.columnID
        const columnMenuItemID = this.columnMenuItemID

        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }
        if (this.state.menuItems[id].columns[columnID] === null || this.state.menuItems[id].columns[columnID] === undefined) {
            return null
        }
        if (this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID] === null || this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID] === undefined) {
            return null
        }
        return (
            <div className="form-group rounded p-2  " style={{
                border: "solid 1px blue"
            }}>
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <span>
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onSubMenuItemColumnMenuItemMoveUp) {
                                this.onSubMenuItemColumnMenuItemMoveUp(id, columnID, columnMenuItemID)
                            }
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onSubMenuItemColumnMenuItemMoveDown) {
                                this.onSubMenuItemColumnMenuItemMoveDown(id, columnID, columnMenuItemID)
                            }

                        }} />
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onSubMenuItemColumnMenuItemDelete) {
                                this.onSubMenuItemColumnMenuItemDelete(id, columnID, columnMenuItemID)
                            }
                        }} />
                    </span>

                </label>
                <input type="text" value={this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].columns[columnID].menuitems[columnMenuItemID].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
                <label>Link</label>
                <input type="text" value={this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID].link} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].columns[columnID].menuitems[columnMenuItemID].link = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
            </div>
        )
    }
}

class SubMenutItemColumn extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
        this.columnID = props.columnID
        this.onDelete = props.onDelete
        this.onSubMenutItemColumnDelete = props.onSubMenutItemColumnDelete
        this.onSubMenuItemColumnMenuItemDelete = props.onSubMenuItemColumnMenuItemDelete
        this.onSubMenuItemColumnMenuItemMoveDown = props.onSubMenuItemColumnMenuItemMoveDown
        this.onSubMenuItemColumnMenuItemMoveUp = props.onSubMenuItemColumnMenuItemMoveUp
        this.onSubMenutItemColumnMoveUp = props.onSubMenutItemColumnMoveUp
        this.onSubMenutItemColumnMoveDown = props.onSubMenutItemColumnMoveDown
    }


    render() {
        const columnID = this.columnID
        const id = this.id
        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }
        if (this.state.menuItems[id].columns[columnID] === null || this.state.menuItems[id].columns[columnID] === undefined) {
            return null
        }

        var menuItems = []
        for (var i in this.state.menuItems[id].columns[columnID].menuitems) {
            const idx = i
            menuItems.push(
                <SubMenuItemColumnMenuItem id={id} columnID={columnID} columnMenuItemID={idx} state={this.state} onSubMenuItemColumnMenuItemDelete={this.onSubMenuItemColumnMenuItemDelete} onSubMenuItemColumnMenuItemMoveUp={this.onSubMenuItemColumnMenuItemMoveUp} onSubMenuItemColumnMenuItemMoveDown={this.onSubMenuItemColumnMenuItemMoveDown} />
            )
        }

        return (
            <div className="form-group rounded p-2   " style={{
                border: "solid 1px red"
            }}>
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <span>
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onSubMenutItemColumnMoveUp) {
                                this.onSubMenutItemColumnMoveUp(id, columnID)
                            }
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onSubMenutItemColumnMoveDown) {
                                this.onSubMenutItemColumnMoveDown(id, columnID)
                            }
                        }} />
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" onClick={(e) => {
                            if (this.onSubMenutItemColumnDelete) {
                                this.onSubMenutItemColumnDelete(id, columnID)
                            }
                        }} style={{
                            cursor: 'pointer'
                        }} />
                    </span>

                </label>
                <input type="text" value={this.state.menuItems[id].columns[columnID].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].columns[columnID].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />

                <div className="form-group">
                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns[columnID].menuitems.push(
                            {
                                "title": "",
                                "link": "",
                                "type": "menuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Menu Item</span>
                    {menuItems}
                </div>

            </div>
        )
    }
}

class SubMenuItem extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
        this.onDelete = props.onDelete
        this.onSubMenutItemColumnDelete = props.onSubMenutItemColumnDelete
        this.onSubMenuItemColumnMenuItemDelete = props.onSubMenuItemColumnMenuItemDelete

        this.onSubMenuItemColumnMenuItemMoveDown = props.onSubMenuItemColumnMenuItemMoveDown
        this.onSubMenuItemColumnMenuItemMoveUp = props.onSubMenuItemColumnMenuItemMoveUp
        this.onSubMenutItemColumnMoveUp = props.onSubMenutItemColumnMoveUp
        this.onSubMenutItemColumnMoveDown = props.onSubMenutItemColumnMoveDown
        this.onMoveDown = props.onMoveDown
        this.onMoveUp = props.onMoveUp
    }

    render() {
        const id = this.id
        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }

        var currentColumns = []

        for (var i in this.state.menuItems[id].columns) {
            const iid = i
            currentColumns.push(
                <SubMenutItemColumn id={id} columnID={iid} state={this.state} onSubMenutItemColumnDelete={this.onSubMenutItemColumnDelete} onSubMenuItemColumnMenuItemDelete={this.onSubMenuItemColumnMenuItemDelete} onSubMenuItemColumnMenuItemMoveDown={this.onSubMenuItemColumnMenuItemMoveDown} onSubMenuItemColumnMenuItemMoveUp={this.onSubMenuItemColumnMenuItemMoveUp} onSubMenutItemColumnMoveUp={this.onSubMenutItemColumnMoveUp} onSubMenutItemColumnMoveDown={this.onSubMenutItemColumnMoveDown} />
            )
        }

        return (
            <div className="form-group rounded p-2   " style={{
                border: "solid 1px black"
            }} >
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <span>
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onMoveUp) {
                                this.onMoveUp(id)
                            }
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onMoveDown) {
                                this.onMoveDown(id)
                            }
                        }} />
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" onClick={(e) => {
                            if (this.onDelete) {
                                this.onDelete(id)
                            }
                        }} style={{
                            cursor: 'pointer'
                        }} />
                    </span>

                </label>
                <input type="text" value={this.state.menuItems[id].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />

                <label>Columns</label>

                <div className="form-group">
                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns.push(
                            {
                                "title": "",
                                "menuitems": [],
                                "type": "columnmenuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add New Columns</span>
                </div>

                {currentColumns}


            </div>
        )
    }
}

class ImageSubMenuItem extends React.Component {
    constructor(props) {
        super()
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

class SingleColumnSubMenuItem extends React.Component {
    constructor(props) {
        super()
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

class MenuItem extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
        this.onDelete = props.onDelete
        this.onMoveDown = props.onMoveDown
        this.onMoveUp = props.onMoveUp
    }

    render() {
        const id = this.id
        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }
        return (
            <div className="form-group rounded p-2" style={{
                border: "solid 1px black"
            }}>
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <span>
                        <FontAwesomeIcon icon={faArrowCircleUp} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onMoveUp) {
                                this.onMoveUp(id)
                            }
                        }} />
                        <FontAwesomeIcon icon={faArrowCircleDown} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={(e) => {
                            if (this.onMoveDown) {
                                this.onMoveDown(id)
                            }
                        }} />
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" onClick={(e) => {
                            if (this.onDelete) {
                                this.onDelete(id)
                            }
                        }} style={{
                            cursor: 'pointer'
                        }} />
                    </span>


                </label>
                <input type="text" value={this.state.menuItems[id].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
                <label>Link</label>
                <input type="text" value={this.state.menuItems[id].link} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].link = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
            </div>
        )
    }
}

class HeaderNavBar extends React.Component {
    constructor() {
        super()
        this.state = {
            menuItems: []
        }
    }

    componentDidMount() {
        this.fetchNavBar()
    }

    handleSaveNavBar = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save navbar information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save navbar information")
            return
        }
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "NavBar",
            message: "NavBar content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveNavBar = () => {

        const that = this
        axios.post(ADMINURL + "admin", {
            menuItems: this.state.menuItems
        }, {
            params: {
                g: "snb",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveNavBar(response.data)
                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                console.log(error)
                that.handleSaveNavBar({})
                // alert("Some error has occurred")
            })
    }

    fetchNavBar = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "nvb",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch navbar information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch navbar information")
                    return
                }


                that.setState({ menuItems: response.payload.menuItems })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    render() {
        var currentMenuItems = []

        for (var i in this.state.menuItems) {
            const id = i
            if (this.state.menuItems[id].type === "menuitem") {
                currentMenuItems.push(
                    // <MenuItem key={`MenuItem` + id} id={id} state={this.state} />
                    <MenuItem id={id} state={this.state} onDelete={(id) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.splice(parseInt(id), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }} onMoveDown={(id) => {
                        const tmpMenuItems = this.state.menuItems
                        this.setState({ menuItems: tmpMenuItems.move(id, id + 1) })
                    }} onMoveUp={(id) => {
                        const tmpMenuItems = this.state.menuItems
                        this.setState({ menuItems: tmpMenuItems.move(id, id - 1) })
                    }} />
                )
            }

            if (this.state.menuItems[id].type === "submenuitem") {
                currentMenuItems.push(
                    // <SubMenuItem key={`SubMenuItem` + id} id={id} state={this.state} />
                    <SubMenuItem id={id} state={this.state} onDelete={(id) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.splice(parseInt(id), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }} onSubMenutItemColumnDelete={(id, columnID) => {
                        var tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns.splice(parseInt(columnID), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }} onSubMenuItemColumnMenuItemDelete={(id, columnID, columnMenuItemID) => {
                        var tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns[columnID].menuitems.splice(parseInt(columnMenuItemID), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }} onSubMenuItemColumnMenuItemMoveDown={(id, columnID, columnMenuItemID) => {
                        var tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns[columnID].menuitems = tmpMenuItems[id].columns[columnID].menuitems.move(columnMenuItemID, columnMenuItemID + 1)
                        this.setState({ menuItems: tmpMenuItems })

                    }} onSubMenuItemColumnMenuItemMoveUp={(id, columnID, columnMenuItemID) => {
                        var tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns[columnID].menuitems = tmpMenuItems[id].columns[columnID].menuitems.move(columnMenuItemID, columnMenuItemID - 1)
                        this.setState({ menuItems: tmpMenuItems })

                    }} onSubMenutItemColumnMoveUp={(id, columnID) => {
                        var tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns = tmpMenuItems[id].columns.move(parseInt(columnID), parseInt(columnID)-1)
                        this.setState({ menuItems: tmpMenuItems })
                    }} onSubMenutItemColumnMoveDown={(id, columnID) => {
                        var tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns = tmpMenuItems[id].columns.move(parseInt(columnID), parseInt(columnID)+1)
                        this.setState({ menuItems: tmpMenuItems })
                    }} onMoveDown={(id) => {
                        const tmpMenuItems = this.state.menuItems
                        this.setState({ menuItems: tmpMenuItems.move(id, id + 1) })
                    }} onMoveUp={(id) => {
                        const tmpMenuItems = this.state.menuItems
                        this.setState({ menuItems: tmpMenuItems.move(id, id - 1) })
                    }} />
                )
            }
        }

        return (
            <div className="m-2">
                <div className="form-group">
                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.push(
                            {
                                "title": "",
                                "link": "",
                                "type": "menuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Menu Item</span>

                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.push(
                            {
                                "title": "",
                                "columns": [],
                                "type": "submenuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Sub Menu Item</span>

                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.push(
                            {
                                "title": "",
                                "items": [],
                                "type": "singlecolumnsubmenuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Single Column Sub Menu Item</span>


                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.push(
                            {
                                "title": "",
                                "items": [],
                                "type": "bannersubmenuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Banner Sub Menu Item</span>

                </div>

                <div className="form-group">
                    <label>Current Menu Items</label>
                    {currentMenuItems}
                </div>

                <div className="form-group">
                    <input type="button" className="btn btn-primary" value="Save" onClick={this.saveNavBar} />
                </div>
            </div>
        )
    }
}

class Header extends React.Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<HeaderNavBar />} title={"NavBar"} />,
            // <Window content={<ContactPage />} title={"Contact Page"} />,

            // <AboutPage />
        ]
    }

}


export {
    Header
}