import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useParams } from 'react-router'
import { fullDialogDispatcher } from '../Components/FullDialog'
import { ItemSearch } from '../Components/ItemSearch'
import NavBar from '../Components/NavBar'
import { toastsDispatcher } from '../Components/Toasts'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';
import { ADMINURL } from '../Components/MSURLS'

var { DateTime } = require('luxon');
var ACCT = Cookies.get('ACCT')
const XLSX = require('xlsx');

class ViewSingleSubscriber extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th scope="row">Email</th>
                                <td>{this.data.e}</td>
                            </tr>
                            <tr>
                                <th scope="row">Email Sub Status</th>
                                <td>{this.data.se ? <span>Yes</span> : <span className="text-danger">No</span>}</td>
                            </tr>
                            <tr>
                                <th scope="row">Phone</th>
                                <td>{this.data.p}</td>
                            </tr>
                            <tr>
                                <th scope="row">Phone Sub Status</th>
                                <td>{this.data.sp ? <span>Yes</span> : <span className="text-danger">No</span>}</td>
                            </tr>
                            <tr>
                                <th scope="row">Created</th>
                                <td>{DateTime.fromISO(this.data.t).toLocaleString(DateTime.DATETIME_MED)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Updated</th>
                                <td>{DateTime.fromISO(this.data.up).toLocaleString(DateTime.DATETIME_MED)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

class SubscribersPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }

    processExcel = (data) => {
        this.setState({ isLoading: true })

        const sheetData = []
        // // prepare data
        for (var i in data) {
            var emailSubStatus = "Yes"
            if (!data[i].se) {
                emailSubStatus = "No"
            }

            var phoneSubStatus = "Yes"
            if (!data[i].sp) {
                phoneSubStatus = "No"
            }

            sheetData.push(
                {
                    "Email": data[i].e,
                    "Email Sub Status": emailSubStatus,
                    "Phone": data[i].p,
                    "Phone Sub Status": phoneSubStatus,
                    "Created": DateTime.fromISO(data[i].t).toLocaleString(DateTime.DATETIME_MED),
                    "Updated": DateTime.fromISO(data[i].up).toLocaleString(DateTime.DATETIME_MED),
                }
            )
        }

        var wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(sheetData)
        wb.SheetNames.push("Subscribers List")
        wb.Sheets["Subscribers List"] = sheet

        const d = new Date()
        const filename = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}-subscribers-list.xlsx`

        XLSX.writeFile(wb, filename)


        this.setState({ isLoading: false })
    }

    handleDownloadSubscriberData = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to download subscribers data")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Download Subscribers Data",
                message: `Failed to download subscribers data. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }

        this.processExcel(response.payload)


    }

    downloadSubscriberData = () => {
        this.setState({ isLoading: true })
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "subscriptions", {
            params: {
                g: "dwn",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDownloadSubscriberData(response.data)
            })
            .catch(function (error) {
                that.handleDownloadSubscriberData({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <button disabled={this.state.isLoading} onClick={this.downloadSubscriberData} className="btn btn-primary"><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Download All Subscriber Data</button>
                </div>
                <div className="form-group">
                    View All Subscribers
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="e" onClickCallback={(resultData) => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Subscriber",
                            message: <ViewSingleSubscriber data={resultData} />
                        })
                    }} isResultLink={false} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="subscriptions" subInfoKeys={[
                        {
                            "key": "m",
                            "map": function (data) {
                                if (data === true) {
                                    return "Manual"
                                }
                                return null
                            }
                        },
                        {
                            "key": "r",
                            "map": function (data) {
                                if (data === true) {
                                    return "Refund"
                                }
                                return null
                            }
                        },
                        {
                            "key": "e",
                            "map": function (data) {
                                if (data === true) {
                                    return "E-commerce"
                                }
                                return null
                            }
                        },
                    ]} urlParams={{}}
                    />
                </div>
            </div>
        )
    }
}

function Subscribers() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<SubscribersPage urlID={id} />} title={"Subscribers"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    Subscribers
}