import React from 'react'
import TabButton from '../Components/TabButton'
import { FullDialog } from '../Components/FullDialog'
import { Toasts } from '../Components/Toasts'
import logo from './logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { FullDialogV2 } from './FullDialogV2'
import Cookies from 'js-cookie'
import AuthenticatedImage from '../Components/AuthenticatedImage'



class NavBar extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <>
                <div className='pl-2 pt-2'>
                    <span className="pt-4 pb-4">
                        <Link to="/" >
                            <img src={logo} alt="ProOne logo" className="logo-bg "></img>
                        </Link>
                    </span>

                </div>
                <div className='pl-2 pt-2'>
                    <span>User: {Cookies.get("F")}{Cookies.get("L") ? <>&nbsp;{Cookies.get("L")}</> : null}</span>
                    <AuthenticatedImage key={Cookies.get("PP")} className="p-2" imageID={Cookies.get("PP")} imageStyle={{
                        maxHeight: "50px",
                        cursor: 'pointer',
                        borderRadius: '50%'
                        // borderRadius: '50%'
                    }} />
                </div></>,
            <div className="" style={{
                width: '100%',
                WebkitOverflowScrolling: 'touch',
                overflowX: 'auto',
                // overflowY:'unset'
            }}>
                {/* <table className="d-flex justify-content-center"> */}
                <table className="">
                    <tbody>

                        <tr>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/" buttonTitle="Home" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/front" buttonTitle="Front" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/header" buttonTitle="Header" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/footer" buttonTitle="Footer" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/collections" buttonTitle="Collections" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/pages" buttonTitle="Pages" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/descriptions" buttonTitle="Descriptions" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/orders?show=ecom&status=all" buttonTitle="Orders" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/marketing" buttonTitle="Marketing" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/urlshortener" buttonTitle="URL Shortener" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/wallet" buttonTitle="Wallet" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/shipping" buttonTitle="Shipping" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/coupons" buttonTitle="Coupons" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/subscribers" buttonTitle="Subscribers" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/inventory" buttonTitle="Inventory" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/reviews" buttonTitle="Reviews" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/comments" buttonTitle="Comments" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/notify" buttonTitle="Notify" />
                            </td>
                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/users" buttonTitle="Users" />
                            </td>

                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/storage" buttonTitle="Storage" />
                            </td>

                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/subscriptions" buttonTitle="Subscriptions" />
                            </td>



                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/admin" buttonTitle="Admin" />
                            </td>


                            <td className="pt-4 pb-4">
                                <TabButton buttonURL="/logout" buttonTitle={<FontAwesomeIcon icon={faPowerOff} title="Log Out" onClick={(e) => { }} />} />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div >,
            <hr className="m-0 ml-4 mr-4" />,

            <FullDialog />,
            <FullDialogV2 />,
            <Toasts />
        ]
    }
}


export default NavBar