import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import NavBar from '../Components/NavBar';
import { Window } from '../Components/Window';
import { useHistory, useParams } from 'react-router';
import { ItemSearch } from '../Components/ItemSearch';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { toastsDispatcher } from '../Components/Toasts';
import AuthenticatedImage from '../Components/AuthenticatedImage';
import { fullDialogDispatcher } from '../Components/FullDialog';
import ReactStars from "react-rating-stars-component";
import { dataURItoBlob } from './Front';
import { ADMINURL } from '../Components/MSURLS';

var ACCT = Cookies.get('ACCT')
var { DateTime } = require('luxon');

var viewAllReviewsDispatcher = null
var addReviewDialogIndex = null

class ReviewOnSelector extends React.Component {
    constructor(props) {
        super()
        this.onClickCallback = props.onClickCallback
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Generate URL
                </div>
                <div className="form-group">
                    Inventory Items
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                        this.onClickCallback({
                            kind: "item",
                            data: resultData
                        })

                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                </div>

                <div className="form-group">
                    Collections
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                        this.onClickCallback({
                            kind: "collection",
                            data: resultData
                        })

                    }} resultClickable={true} isResultLink={false} resultLink={'/collections/'} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
                </div>
            </div>
        )
    }
}

class ReviewBySelector extends React.Component {
    constructor(props) {
        super()
        this.onClickCallback = props.onClickCallback
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">
                <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => { this.onClickCallback(resultData) }} isResultLink={false} subNameTitleKey={'l'} resultClickable={true} searchAllowed={true} gType="ecm" timeKey="" urlEndPoint="users" subInfoKeys={[]} />
            </div>
        )
    }
}

class AddReview extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        this.data = props.data

        this.state = {
            review: "",
            rating: 0,
            title: "",
            images: [],
            reviewOn: null,
            reviewBy: null,
            isLoading: false,
            isError: false,
            isSuccess: false,

            reviewByDialogIndex: null,
            reviewOnDialogIndex: null
        }
    }

    handleAddReview = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add review")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Review",
                message: `Failed to add review. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Review",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-dialog",
            dialogIndex: addReviewDialogIndex
        })

        viewAllReviewsDispatcher.dispatch({
            actionType: "get-data"
        })


    }

    addReview = () => {

        if (this.state.reviewBy === null) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Review",
                message: `Failed to add review. Review By cannot be null.`,
                date: new Date(),
                isError: true
            })
            return
        }
        if (this.state.reviewOn === null) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Review",
                message: `Failed to add review. Review On cannot be null.`,
                date: new Date(),
                isError: true
            })
            return
        }
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "reviews", {
            r: this.state.rating,
            ti: this.state.title,
            b: this.state.review,
            ro: this.state.reviewOn.data._id,
            rb: this.state.reviewBy._id,
            i: this.state.images
        }, {
            params: {
                g: "add",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddReview(response.data)
            })
            .catch(function (error) {
                that.handleAddReview({})
            })
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'slider'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })
                        const imgID = response["payload"]["id"]
                        const tmpImages = that.state.images
                        tmpImages.push({
                            image: imgID,
                            alt: ""
                        })
                        that.setState({ images: tmpImages })
                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {

        /* prepare review on */
        var reviewOn = null
        if (this.state.reviewOn !== null) {
            reviewOn = <div>
                <div className="from-group">
                    <b>{this.state.reviewOn.kind}</b>
                </div>
                <div className="from-group">
                    {this.state.reviewOn.data.name}
                </div>
            </div>
        }

        /* prepare review by */
        var reviewBy = null
        if (this.state.reviewBy !== null) {
            reviewBy = <div>
                <div className="from-group">
                    {this.state.reviewBy.f}&nbsp;{this.state.reviewBy.l}
                </div>
                <div className="from-group">
                    {this.state.reviewBy.ph}
                </div>
                <div className="from-group">
                    {this.state.reviewBy.e}
                </div>
            </div>
        }

        /* prepare images */
        var images = []

        for (var i in this.state.images) {
            const idx = i
            images.push(
                <div key={this.state.images[idx].image}>
                    <AuthenticatedImage key={this.state.images[idx].image} className="p-2" imageID={this.state.images[idx].image} imageStyle={{
                        maxHeight: "150px",
                        cursor: 'pointer'
                        // borderRadius: '50%'
                    }} onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: null,
                            closeOnOutsideClick: true,
                            message: <AuthenticatedImage imageID={this.state.images[idx].image} imageStyle={{
                                maxWidth: "100%",
                                width: "100%"
                            }}
                            />
                        })
                    }} />
                    <br />
                    <span style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        var tmpImages = this.state.images
                        tmpImages.splice(idx, 1)
                        this.setState({ images: tmpImages })
                    }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                </div>
            )
        }

        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="reviewRating">Rating</label>
                    <ReactStars
                        count={5}
                        onChange={(val) => {
                            this.setState({ rating: val })
                        }}
                        size={24}
                    // activeColor="#ffd700"
                    />
                    {/* <span>{this.state.rating} Stars</span> */}
                </div>
                <div className="form-group">
                    <label htmlFor="reviewTitle">Title</label>
                    <input id="reviewTitle" className="form-control" value={this.state.title} onChange={(e) => {
                        this.setState({ title: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="reviewReview">Review</label>
                    <textarea rows="7" id="reviewReview" className="form-control" value={this.state.review} onChange={(e) => {
                        this.setState({ review: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="reviewImages">Images</label>
                    <br />
                    <div className="d-flex flex-wrap">
                        {images}
                    </div>
                    <br />
                    <input type="file" class="form-control-file" id="addReviewImageUpload" onChange={(input) => {
                        this.handleImageUpload(input)
                    }} hidden multiple />
                    <button className="btn btn-primary btn-sm" type="button" value="Add Image" onClick={(e) => {
                        document.getElementById(`addReviewImageUpload`).click();
                    }}>Add Image </button>
                </div>
                <div className="form-group">
                    <label htmlFor="reviewReviewBy">Review By</label>
                    <br />
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Review By",
                            dialogIndexCallback: (d) => {
                                this.setState({ reviewByDialogIndex: d })
                            },
                            message: <ReviewBySelector onClickCallback={(result) => {
                                this.setState({
                                    reviewBy: result
                                })
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "close-dialog",
                                    dialogIndex: this.state.reviewByDialogIndex
                                })
                            }} />
                        })
                    }}>Select</button>
                    {reviewBy}
                </div>
                <div className="form-group">
                    <label htmlFor="reviewReviewOn">Review On</label>
                    <br />
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Review On",
                            dialogIndexCallback: (d) => {
                                this.setState({ reviewOnDialogIndex: d })
                            },
                            message: <ReviewOnSelector onClickCallback={(result) => {
                                this.setState({
                                    reviewOn: result
                                })
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "close-dialog",
                                    dialogIndex: this.state.reviewOnDialogIndex
                                })
                            }} />
                        })
                    }}>Select</button>
                    {reviewOn}
                </div>
                <div className="form-group">
                    <button onClick={this.addReview} disabled={this.state.isLoading} className="btn btn-primary"><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add</button>
                </div>
            </div>
        )
    }
}

class ViewSingleReview extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        this.reviewID = props.reviewID
        this.history = props.history

        this.data = props.data

        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            what: "n",
            data: null
        }
    }

    handleFetchSingleReview = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get review")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Review",
                message: `Unable to get review. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ data: response.payload, isSuccess: true })
    }

    fetchSingleReview = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "reviews", {
            params: {
                g: "sgl",
                id: this.reviewID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchSingleReview(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchSingleReview({})
            })
    }

    handleUpdateSingleReview = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to change review state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Review",
                message: `Unable to change review state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Review",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false, }, () => {
            this.fetchSingleReview()
        })
    }

    updateSingleReview = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "reviews", {
            ti: this.state.data.r.ti,
            b: this.state.data.r.b,
            r: this.state.data.r.r,
            i: this.state.data.r.i,
        }, {
            params: {
                g: "upd",
                id: this.reviewID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateSingleReview(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateSingleReview({})
            })
    }

    handleApproveSingleReview = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to change review state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Review",
                message: `Unable to change review state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Review",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false, }, () => {
            this.fetchSingleReview()
        })
    }

    approveDisapproveSingleReview = (approve) => {

        var approveOrDisapproveGtype = "apr"

        if (approve === false) {
            approveOrDisapproveGtype = "dpr"
        }

        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "reviews", {
            params: {
                g: approveOrDisapproveGtype,
                id: this.reviewID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleApproveSingleReview(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleApproveSingleReview({})
            })
    }

    handleDeleteSingleReview = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to change review state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Review",
                message: `Unable to change review state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Review",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false, }, () => {
            this.history.push("/reviews");
        })
    }

    deleteSingleReview = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "reviews", {
            // b: this.state.data.c.b
        }, {
            params: {
                g: "del",
                id: this.reviewID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteSingleReview(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleDeleteSingleReview({})
            })
    }

    componentDidMount() {
        this.fetchSingleReview()
    }

    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'slider'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })
                        const imgID = response["payload"]["id"]

                        const tmpData = that.state.data
                        tmpData.r.i.push({
                            image: imgID,
                            alt: ""
                        })
                        that.setState({ data: tmpData })
                    })
                    .catch(function (response) {

                        console.log(response)

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div>
                    loading...
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="text-danger">
                    Some error has occurrred. Please try again.
                </div>
            )
        }
        if (this.state.isSuccess) {

            var reviewOn = null


            if (this.state.data.cl._id !== "000000000000000000000000") {
                reviewOn = <div className="form-group">
                    <b>{this.state.data.cl.name}</b>
                    <br />
                    <span style={{
                        whiteSpace: 'pre-wrap'
                    }}>
                        {this.state.data.cl.description}
                    </span>
                </div>
            }
            if (this.state.data.i._id !== "000000000000000000000000") {
                /* prepare review on images */

                var reviewOnImages = []
                for (var i in this.state.data.i.images) {
                    const idx = i
                    reviewOnImages.push(
                        <div style={{
                            maxWidth: '100px'
                        }}>
                            <AuthenticatedImage key={this.state.data.i.images[idx].image} className="p-2" imageID={this.state.data.i.images[idx].image} imageStyle={{
                                width: "100%",
                                cursor: 'pointer'
                            }} onClick={() => {
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: null,
                                    closeOnOutsideClick: true,
                                    message: <AuthenticatedImage imageID={this.state.data.i.images[idx].image} imageStyle={{
                                        maxWidth: "100%",
                                        width: "100%"
                                    }}
                                    />
                                })
                            }} />
                        </div>
                    )
                }

                reviewOn = <div className="form-group">
                    <b>{this.state.data.i.name}</b>
                    <br />
                    SKU: {this.state.data.i.sku}
                    <br />
                    <div class="d-flex flex-wrap">
                        {reviewOnImages}
                    </div>
                </div>
            }

            /* prepare images */
            var images = []

            for (var i in this.state.data.r.i) {
                const idx = i
                images.push(
                    <div key={this.state.data.r.i[idx].image}>
                        <AuthenticatedImage key={this.state.data.r.i[idx].image} className="p-2" imageID={this.state.data.r.i[idx].image} imageStyle={{
                            maxHeight: "150px",
                            cursor: 'pointer'
                            // borderRadius: '50%'
                        }} onClick={() => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: null,
                                closeOnOutsideClick: true,
                                message: <AuthenticatedImage imageID={this.state.data.r.i[idx].image} imageStyle={{
                                    maxWidth: "100%",
                                    width: "100%"
                                }}
                                />
                            })
                        }} />
                        <br />
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            const tmpData = this.state.data
                            tmpData.r.i.splice(idx, 1)
                            this.setState({ data: tmpData })

                        }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                    </div>
                )
            }


            return (
                <div className="p-4">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.push('/reviews');
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Reviews</span>
                        {/* <Link to="/orders"><FontAwesomeIcon icon={faArrowLeft} /> Orders</Link> */}
                    </div>

                    <div className="form-group" hidden={!this.state.data.r.e}>
                        <span><i><u>Edited</u></i></span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="editReviewRating">Rating</label>
                        <ReactStars
                            count={5}
                            onChange={(val) => {
                                const tmpData = this.state.data
                                tmpData.r.r = val
                                this.setState({ data: tmpData })
                            }}
                            size={24}
                            value={this.state.data.r.r}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="editReviewTitle">Review Title</label>
                        <input rows="8" className="form-control" id="editReviewTitle" value={this.state.data.r.ti} onChange={(e) => {
                            const tmpData = this.state.data
                            tmpData.r.ti = e.target.value
                            this.setState({ data: tmpData })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="editReviewBody">Review Body</label>
                        <textarea rows="8" className="form-control" id="editReviewBody" value={this.state.data.r.b} onChange={(e) => {
                            const tmpData = this.state.data
                            tmpData.r.b = e.target.value
                            this.setState({ data: tmpData })
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="reviewImages">Images</label>
                        <br />
                        <div className="d-flex flex-wrap">
                            {images}
                        </div>
                        <br />
                        <input type="file" class="form-control-file" id="editReviewImageUpload" onChange={(input) => {
                            this.handleImageUpload(input)
                        }} hidden multiple />
                        <button disabled={this.state.data.r.d === true} className="btn btn-primary btn-sm" type="button" value="Add Image" onClick={(e) => {
                            document.getElementById(`editReviewImageUpload`).click();
                        }}>Add Image </button>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Review Creation Time</label>
                        <br />
                        <b>{DateTime.fromISO(this.state.data.r.t).toLocaleString(DateTime.DATETIME_MED)}</b>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Review Updation Time</label>
                        <br />
                        {DateTime.fromISO(this.state.data.r.up).toLocaleString(DateTime.DATETIME_MED)}
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Review By</label>
                        <div className="form-group" style={{
                            fontWeight: 'bold'
                        }}>
                            {this.state.data.u.f}&nbsp;{this.state.data.u.l}
                            <br />
                            {this.state.data.u.ph}
                            <br />
                            {this.state.data.u.e}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Review On</label>
                        {reviewOn}
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary m-1" disabled={this.state.isLoading} hidden={this.state.data.r.d} onClick={this.updateSingleReview}>Update</button>
                        <button className="btn btn-primary m-1" disabled={this.state.isLoading} hidden={this.state.data.r.a || this.state.data.r.d} onClick={() => { this.approveDisapproveSingleReview(true) }}>Approve Review</button>
                        <button className="btn btn-primary m-1" disabled={this.state.isLoading} hidden={!this.state.data.r.a || this.state.data.r.d} onClick={() => { this.approveDisapproveSingleReview(false) }}>Disapprove Review</button>
                        <button className="btn btn-danger m-1" hidden={this.state.data.r.d} disabled={this.state.isLoading} onClick={() => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Delete Review",
                                message: <div className="p-2">
                                    <div className="form-group" hidden={!this.state.data.r.a} style={{
                                        fontWeight: "bold"
                                    }}>
                                        You are deleting an approved review. Are you sure you still want to delete it?
                                    </div>
                                    <button className="btn btn-primary m-1" onClick={() => {
                                        fullDialogV2Dispatcher.dispatch({
                                            actionType: "close-all-dialogs"
                                        })
                                    }}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-danger m-1" onClick={() => {
                                        this.deleteSingleReview()
                                    }}>
                                        Delete
                                    </button>
                                </div>
                            })
                        }}>Delete Review</button>
                    </div>
                </div>
            )
        }

        return null
    }
}

class ViewAllReviews extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        this.state = {
            what: "n"
        }
    }

    render() {
        return (
            <div className="p-2">

                <div className="form-group">
                    Filter Reviews:
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsAll" id="radioReviewsAll" checked={this.state.what === "all"} onChange={() => {
                            this.setState({ what: "all" }, () => {
                                viewAllReviewsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsAll">
                            All
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsApproved" id="radioReviewsApproved" checked={this.state.what === "a"} onChange={() => {
                            this.setState({ what: "a" }, () => {
                                viewAllReviewsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsApproved">
                            Approved
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsNotApproved" id="radioReviewsNotApproved" checked={this.state.what === "n"} onChange={() => {
                            this.setState({ what: "n" }, () => {
                                viewAllReviewsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsNotApproved">
                            Not Approved
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsDeleted" id="radioReviewsDeleted" checked={this.state.what === "d"} onChange={() => {
                            this.setState({ what: "d" }, () => {
                                viewAllReviewsDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsDeleted">
                            Deleted
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="b" isResultLink={true} resultLink={'/reviews/'} onClickCallback={(resultData) => {

                    }} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="reviews" urlParams={{
                        w: this.state.what
                    }} getDataCaller={(d) => {
                        viewAllReviewsDispatcher = d
                    }} subInfoKeys={[
                        {
                            "key": "r",
                            "map": function (data) {
                                return <div>
                                    <ReactStars
                                        count={5}
                                        size={24}
                                        value={data}
                                        edit={false}
                                    />
                                </div>
                            }
                        },
                        {
                            "key": "reviewBy",
                            "map": function (data) {
                                return <div>
                                    {data[0].f}
                                    <br />
                                    {data[0].l}
                                    <br />
                                    {data[0].ph}
                                    <br />
                                    {data[0].e}
                                </div>
                            }
                        },
                    ]} />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add Review",
                            dialogWidth: 3,
                            dialogIndexCallback: (idx) => {
                                addReviewDialogIndex = idx
                            },
                            message: <AddReview />
                        })
                    }}>Add Review</button>
                </div>
            </div>
        )
    }
}

class ReviewsPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")


        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="">
                    <div className="col-6 col-sm-12 col-md-6 col-lg-6">
                        <ViewAllReviews />
                    </div>
                </div>
            </div>
        )
    }
}

function Reviews() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ReviewsPage urlID={id} />} title={"View Reviews"} />,
        // <Window content={<AddWalletEntry urlID={id} />} title={"Add Wallet Entry"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

function SingleReview() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewSingleReview reviewID={id} history={history} />} title={"Review"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    ReviewsPage,
    Reviews,
    SingleReview
}