import React from 'react'
import { useParams } from 'react-router'
import { fullDialogDispatcher } from '../Components/FullDialog'
import { ItemSearch } from '../Components/ItemSearch'
import NavBar from '../Components/NavBar'
import { toastsDispatcher } from '../Components/Toasts'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { ADMINURL } from '../Components/MSURLS'
import { ecommerce_helper_function_get_formatted_price } from '../Components/helper_functions'

var ACCT = Cookies.get('ACCT')
var { DateTime } = require('luxon');

var walletHistoryGetDataDispatcher = null
var fetchCurrentWalletValue = null


class CurrentWalletValue extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID

        fetchCurrentWalletValue = this.fetchCurrentWalletValue

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            currentValue: 0,
        }
    }

    componentDidMount() {
        this.fetchCurrentWalletValue()
    }

    handleFetchCurrentWalletValue = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add wallet entry")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            this.setState({ isSuccess: false, isError: true, })
            return
        }

        this.setState({ isSuccess: true, isError: false, currentValue: response.payload.v })
    }

    fetchCurrentWalletValue = () => {


        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "wallet", {
            params: {
                g: "get",
                uid: this.userID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchCurrentWalletValue(response.data)
            })
            .catch(function (error) {
                that.handleFetchCurrentWalletValue({})
            })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <div className="form-group">
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                </div>
            )
        }
        if (this.state.isError) {
            return (
                <div>
                    <div className="form-group text-danger">
                        Failed to get current wallet value.
                    </div>
                </div>
            )
        }
        if (this.state.isSuccess) {
            return (
                <div>
                    <div className="form-group">
                        Current Wallet Value
                    </div>
                    <div className="form-group">
                        <h4>
                            {ecommerce_helper_function_get_formatted_price(this.state.currentValue)}
                        </h4>
                    </div>
                </div>
            )
        }
        return null
    }
}

class AddWalletEntry extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            amount: 0,

            walletEntryType: "walletManualEntry"
        }
    }


    handleWalletEntry = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add wallet entry")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Wallet Entry",
                message: `Failed to add wallet entry. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        that.setState({ amount: 0 })
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Wallet Entry",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        walletHistoryGetDataDispatcher.dispatch({
            actionType: "get-data"
        })
        fetchCurrentWalletValue()


    }

    addWalletEntry = () => {

        /* prepare post data */
        var m = false
        var r = false
        var e = false

        if (this.state.walletEntryType === "walletManualEntry") {
            m = true
        }
        if (this.state.walletEntryType === "walletRefundEntry") {
            r = true
        }
        if (this.state.walletEntryType === "walletEcomEntry") {
            e = true
        }

        var postData = {
            a: parseFloat(this.state.amount),
            m: m,
            r: r,
            e: e,
            uid: this.userID
        }


        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "wallet",
            postData
            , {
                params: {
                    g: "man",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleWalletEntry(response.data)
            })
            .catch(function (error) {
                that.handleWalletEntry({})
            })
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    Add Wallet Entry
                </div>
                <div className="form-group">
                    <label htmlFor="walletEntryAmount">Amount</label>
                    <input type="number" id="walletEntryAmount" value={this.state.amount} className="form-control" onChange={(e) => {
                        this.setState({ amount: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Entry Type</label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="walletManualEntry" id="walletManualEntry" checked={this.state.walletEntryType === "walletManualEntry"} onChange={(e) => {
                            this.setState({
                                walletEntryType: e.target.name
                            })
                        }} />
                        <label class="form-check-label" for="walletManualEntry">
                            Manual
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="walletRefundEntry" id="walletRefundEntry" checked={this.state.walletEntryType === "walletRefundEntry"} onChange={(e) => {
                            this.setState({
                                walletEntryType: e.target.name
                            })
                        }} />
                        <label class="form-check-label" for="walletRefundEntry">
                            Refund
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="walletEcomEntry" id="walletEcomEntry" checked={this.state.walletEntryType === "walletEcomEntry"} onChange={(e) => {
                            this.setState({
                                walletEntryType: e.target.name
                            })
                        }} />
                        <label class="form-check-label" for="walletEcomEntry">
                            Ecommerce
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <button disabled={this.state.isLoading} onClick={this.addWalletEntry} className="btn btn-primary">
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCheckCircle} spin />&nbsp;</span>Add
                    </button>
                </div>
            </div>
        )
    }
}

class ViewWalletHistory extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.state = {

        }
    }

    render() {
        return (
            <div className="">
                <div className="form-group">
                    Wallet History
                </div>
                <div className="form-group">
                    <ItemSearch onClickCallback={(resultData) => { }} isResultLink={false} resultClickable={false} searchAllowed={false} gType="hst" timeKey="t" urlEndPoint="wallet" subInfoKeys={[
                        {
                            "key": "a",
                            "map": function (data) {
                                return <>
                                    <h4>
                                        {ecommerce_helper_function_get_formatted_price(data)}
                                    </h4>
                                </>

                            }

                        },
                        {
                            "key": "m",
                            "map": function (data) {
                                if (data === true) {
                                    return "Manual"
                                }
                                return null
                            }
                        },
                        {
                            "key": "r",
                            "map": function (data) {
                                if (data === true) {
                                    return "Refund"
                                }
                                return null
                            }
                        },
                        {
                            "key": "e",
                            "map": function (data) {
                                if (data === true) {
                                    return "E-commerce"
                                }
                                return null
                            }
                        },
                    ]} urlParams={{
                        uid: this.userID
                    }
                    } getDataCaller={(dispatcher) => {
                        walletHistoryGetDataDispatcher = dispatcher
                    }} />
                </div>
            </div>
        )
    }
}

class WalletPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")


        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {
                    fullDialogDispatcher.dispatch({
                        actionType: "show-dialog",
                        title: "Wallet Entry",
                        dialogWidth: 5,
                        message: <div>
                            <div className="row m-0">
                                <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                                    <CurrentWalletValue userID={resultData._id} />
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                                    <AddWalletEntry userID={resultData._id} />
                                </div>

                                <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                                    <ViewWalletHistory userID={resultData._id} />
                                </div>
                            </div>
                        </div>
                    })
                }} isResultLink={false} subNameTitleKey={'l'} resultClickable={true} searchAllowed={true} gType="ecm" timeKey="" urlEndPoint="users" subInfoKeys={[
                    {
                        "key": "ph",
                        "map": function (data) {
                            return <>{data}</>
                        }
                    },
                    {
                        "key": "e",
                        "map": function (data) {
                            return <><br />{data}</>
                        }
                    }
                ]} />
            </div>
        )
    }
}

function Wallet() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<WalletPage urlID={id} />} title={"View Wallet Entries"} />,
        // <Window content={<AddWalletEntry urlID={id} />} title={"Add Wallet Entry"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    Wallet
}