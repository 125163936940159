import React from 'react'
import NavBar from '../Components/NavBar';
import { NavBarData } from '../Components/NavBarData';
import { Window } from '../Components/Window';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ItemSearch } from '../Components/ItemSearch';
import { toastsDispatcher } from '../Components/Toasts';
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBox, faCheck, faCircleNotch, faDoorOpen, faPaperPlane, faTimes, faUndo, faWrench } from '@fortawesome/free-solid-svg-icons';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearchTable } from '../Components/ItemSearchTable';
import jsPDF from 'jspdf';
import ReactQuill from 'react-quill';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { ADMINURL, ADMINURLFRONT } from '../Components/MSURLS';
import { ecommerce_helper_function_get_formatted_price } from '../Components/helper_functions';

var ACCT = Cookies.get('ACCT')
var TP = Cookies.get('TP')

var { DateTime } = require('luxon');

class StorageData extends React.Component {
    constructor(props) {
        super()




        this.state = {

        }
    }

    componentDidUpdate() {

    }

    render() {



        return (
            <div className="p-4">
               
               
            </div>
        )
    }

}

class StorageNamespaces extends React.Component {
    constructor(props) {
        super()




        this.state = {

        }
    }

    componentDidUpdate() {

    }

    render() {



        return (
            <div className="p-4">
               
               
            </div>
        )
    }

}


class Storage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {
            show: show,
            status: status,
            showContent: null
        }
    }

    componentDidUpdate() {

        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        if (show !== this.state.show || status !== this.state.status) {
            this.setState({ show: show, status: status })
        }
        
    }

    render() {

        
        

        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<StorageNamespaces />} title={"Namespaces"} />,
            // <Window content={<ViewOrders show={this.state.show}/>} title={"Orders"} />,
        ]
    }

}


export {
    Storage
}
