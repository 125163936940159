import React from 'react'
import logo from '../Components/logo.svg'
// import MSURL from '../Components/MSURL';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { ADMINURL } from '../Components/MSURLS';

// import { useHistory } from 'react-router-dom'

var ACCT = Cookies.get('ACCT')


// function GoToHome() {
//     const history = useHistory();
//     history.push('/')

//     return null
// }

class Login extends React.Component {

    constructor(props) {
        super()
        this.state = {

            loginSectionStyle: {
                minHeight: '70vh'
            },

            isLoading: false,

            usernameClassName: "form-control",
            passwordClassName: "form-control",

            username: "",
            password: "",

            goToHomeBool: false,

            loginMessage: null

        }
    }

    componentDidMount() {
        this.handleEcho()
    }

    resetLoginSection = () => {
        this.setState({
            loginSectionStyle: {
                minHeight: '70vh' 
            },
            isLoading: false
        })
    }

    handleLoginResponse = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            this.setState({ loginMessage: <span className="text-danger">Failed to login. Some server side error has occurred.</span> })
            this.resetLoginSection()
            return
        }

        if (response["status"] !== "success") {
            this.setState({ loginMessage: <span className="text-danger">Failed to login. Please check your username and password.</span> })
            this.resetLoginSection()
            return
        }
        this.setState({ loginMessage: <span className="text-success">Login success.</span> })

        Cookies.set('ACCT', response["payload"]["t"])
        Cookies.set('F', response["payload"]["f"])
        Cookies.set('L', response["payload"]["l"])
        Cookies.set('TP', response["payload"]["tp"])
        Cookies.set('PP', response["payload"]["pp"])

        this.setState({ goToHomeBool: true })
    }

    handleLogin = () => {
        var hasError = false

        if (this.state.username.length === 0) {
            this.setState({ usernameClassName: "form-control is-invalid" })
            hasError = hasError | true
        } else {
            this.setState({ usernameClassName: "form-control" })
            hasError = hasError | false
        }

        if (this.state.password.length === 0) {
            this.setState({ passwordClassName: "form-control is-invalid" })
            hasError = true
        } else {
            this.setState({ passwordClassName: "form-control" })
            hasError = hasError | false
        }

        if (hasError) {
            return
        }

        this.setState({
            loginSectionStyle: {
                opacity: 0.5,
                pointerEvents: 'none',
                minHeight: '70vh' 
            },
            isLoading: true
        })
        const that = this;
        axios.post(ADMINURL + "login", {
            user: this.state.username,
            pass: this.state.password
        })
            .then(function (response) {
                that.handleLoginResponse(response.data)
            })
            .catch(function (error) {
                that.handleLoginResponse({})
            })

    }

    handleEchoResponse = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            return
        }

        if (response["status"] !== "success") {
            return
        }

        if (response["status"] === "success") {
            window.location.replace("/")
        }

    }

    handleEcho = () => {
        const that = this;
        axios.get(ADMINURL + "echo", {
            headers: { Authorization: ACCT },
            // params: params
        })
            .then(function (response) {
                that.handleEchoResponse(response.data)
            })
            .catch(function (error) {
                that.handleEchoResponse({})
            })
    }


    render() {

        if (this.state.goToHomeBool) {
            // return (
            //     <GoToHome />
            // )
            window.location.href = "/"
        }

        return (
            <div className="d-flex justify-content-center align-items-center p-5 " style={this.state.loginSectionStyle} >
                <div className="col-md-6 col-xl-3 col-lg-4 col-sm-12">
                    <div className="form-group">
                        <img src={logo} alt="ProOne logo" className="logo-bg "></img>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Username</label>
                        <input type="text" className={this.state.usernameClassName} value={this.state.username} id="loginUsername" aria-describedby="emailHelp" onChange={(e) => {
                            // this.setState({ username: escape(e.target.value) })
                            this.setState({ username: e.target.value })
                            // this.setState({ username: e.target.value.replace(/[^\w]/gi, "") });
                        }} />
                        <div class="invalid-feedback">
                            Please enter your username.
                        </div>
                        {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className={this.state.passwordClassName} value={this.state.password} id="loginPassword" onChange={(e) => {
                            this.setState({ password: e.target.value })
                        }} />
                        <div class="invalid-feedback">
                            Please enter your password.
                        </div>
                    </div>
                    <div className="form-group">
                        <a href="troubleshoot">Forgot password?</a>
                    </div>
                    <div className="form-group">
                        <p>
                            {this.state.loginMessage}
                        </p>
                    </div>
                    {/* <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        <label class="form-check-label" for="exampleCheck1">Check me out</label>
                    </div> */}
                    <button type="button" onClick={this.handleLogin} className="btn btn-primary"><FontAwesomeIcon className="" icon={faCircleNotch} spin hidden={!this.state.isLoading} />&nbsp;Log In</button>
                </div>
            </div>
        )
    }
}


export default Login