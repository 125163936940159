import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';

import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { ADMINURL } from '../Components/MSURLS';


var ACCT = Cookies.get('ACCT')



class FooterContent extends React.Component {
    constructor() {
        super()
        this.state = {
            social: {}
        }
    }

    componentDidMount() {
        this.fetchFooter()
    }

    handleSaveFooter = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save footer information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save footer information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Footer",
            message: "Footer content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveFooter = () => {
        const that = this
        axios.post(ADMINURL + "admin", this.state, {
            params: {
                g: "sft",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveFooter(response.data)
                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                console.log(error)
                that.handleSaveFooter({})
            })
    }

    fetchFooter = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "fot",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch footer information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch footer information")
                    return
                }


                that.setState({ copyrightMessage: response.payload.copyrightMessage, social: response.payload.social })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    render() {
        return (
            <div className="m-2">
                <div className="form-group">
                    <label>
                        Copyright Message
                    </label>
                    <input type="text" value={this.state.copyrightMessage} className="form-control" onChange={(e) => {
                        this.setState({ copyrightMessage: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>
                        Social
                    </label>
                    <div className="form-group border rounded p-2">
                        <label>Facebook</label>
                        <input type="text" value={this.state.social.facebook} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.facebook = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>Instagram</label>
                        <input type="text" value={this.state.social.instagram} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.instagram = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>Twitter</label>
                        <input type="text" value={this.state.social.twitter} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.twitter = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>WhatsApp</label>
                        <input type="text" value={this.state.social.whatsapp} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.whatsapp = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>YouTube</label>
                        <input type="text" value={this.state.social.youtube} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.youtube = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                    </div>
                </div>
                <div className="form-group">
                    <input type="button" value="Save" className="btn btn-primary" onClick={this.saveFooter} />
                </div>
            </div>
        )
    }
}



class Footer extends React.Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<FooterContent />} title={"Footer"} />,
            // <Window content={<ContactPage />} title={"Contact Page"} />,

            // <AboutPage />
        ]
    }

}


export {
    Footer
}