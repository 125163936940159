import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useParams } from 'react-router'
import { fullDialogDispatcher } from '../Components/FullDialog'
import { ItemSearch } from '../Components/ItemSearch'
import NavBar from '../Components/NavBar'
import { toastsDispatcher } from '../Components/Toasts'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';

import { fullDialogV2Dispatcher } from '../Components/FullDialogV2'
import { ADMINURL } from '../Components/MSURLS'
var { DateTime } = require('luxon');
var ACCT = Cookies.get('ACCT')
const XLSX = require('xlsx');

var viewAllNotifyEntriesDispatcher = null
var deleteNotifyEntryDialogIndex = null


class NotifyAllEntries extends React.Component {
    constructor(props) {
        super()

        this.data = props.data

        this.state = {
            email: true,
            sms: false,
            app: false,

            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }

    handleNotifyAll = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Notify",
                message: `Failed to notify all. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Notify",
                message: `Failed to notify all. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Notify",
            message: `All Notified Successfully.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: false, isError: true })
        return

    }

    notifyAll = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "notify", {
            ea: true,
            ar: true,
            e: this.state.email,
            s: this.state.sms,
            a: this.state.app,
        }, {
            params: {
                g: "exa",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleNotifyAll(response.data)
            })
            .catch(function (error) {
                that.handleNotifyAll({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Notify through:
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" checked={this.state.email} onChange={(e) => {
                            this.setState({ email: e.target.checked })
                        }} id="notifyEmail" />
                        <label class="form-check-label" for="notifyEmail">
                            Email 
                        </label>
                    </div>
                    <div class="form-check">
                        <input  class="form-check-input" type="checkbox" value="" id="notifySMS" checked={this.state.sms} onChange={(e) => {
                            this.setState({ sms: e.target.checked })
                        }} />
                        <label class="form-check-label" for="notifySMS">
                            SMS
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="notifyApp" checked={this.state.app} onChange={(e) => {
                            this.setState({ app: e.target.checked })
                        }} />
                        <label class="form-check-label" for="notifyApp">
                            App Notification
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <span className="btn-link" style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        this.setState({
                            email: true,
                            sms: true,
                            app: true
                        })
                    }}>
                        Select All
                    </span>
                </div>
                <div className="form-group">
                    <button  disabled={this.state.isLoading} className="btn btn-primary" onClick={this.notifyAll}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Notify
                    </button>
                </div>
            </div>
        )
    }
}


class DeleteNotifyEntry extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false
        }
    }

    handleDeleteNotifyEntry = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Notify",
                message: `Failed to delete notify entry. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Notify",
                message: `Failed to delete notify entry. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Notify",
            message: `Notify entry successfully deleted.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: false, isError: true })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-all-dialogs"
        })


        viewAllNotifyEntriesDispatcher.dispatch({
            actionType: "get-data"
        })

        return
    }

    deleteNotifyEntry = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "notify", {
            params: {
                g: "del",
                id: this.data._id
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteNotifyEntry(response.data)
            })
            .catch(function (error) {
                that.handleDeleteNotifyEntry({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Are you sure you want to delete this entry?
                </div>
                <div className="form-group">
                    <button className="btn btn-primary m-1" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "close-dialog",
                            dialogIndex: deleteNotifyEntryDialogIndex
                        })
                    }}>
                        Cancel
                    </button>
                    <button disabled={this.state.isLoading} className="btn btn-danger m-1" onClick={this.deleteNotifyEntry}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Delete
                    </button>
                </div>
            </div>
        )
    }
}

class NotifySingleEntry extends React.Component {
    constructor(props) {
        super()

        this.data = props.data

        this.state = {
            email: true,
            sms: false,
            app: false,

            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }

    handleNotify = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Notify",
                message: `Failed to notify. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Notify",
                message: `Failed to notify. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Notify",
            message: `Notified Successfully.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: false, isError: true })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-all-dialogs"
        })


        viewAllNotifyEntriesDispatcher.dispatch({
            actionType: "get-data"
        })
        
        return

    }

    notify = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "notify", {
            e: this.state.email,
            s: this.state.sms,
            a: this.state.app,
            id: this.data._id
        }, {
            params: {
                g: "exe",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleNotify(response.data)
            })
            .catch(function (error) {
                that.handleNotify({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Notify through:
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input disabled={this.data.d} class="form-check-input" type="checkbox" checked={this.state.email} onChange={(e) => {
                            this.setState({ email: e.target.checked })
                        }} id="notifyEmail" />
                        <label class="form-check-label" for="notifyEmail">
                            Email <span hidden={!this.data.en}>(Last Notified On: {DateTime.fromISO(this.data.te).toLocaleString(DateTime.DATETIME_MED)})</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input disabled={this.data.d} class="form-check-input" type="checkbox" value="" id="notifySMS" checked={this.state.sms} onChange={(e) => {
                            this.setState({ sms: e.target.checked })
                        }} />
                        <label class="form-check-label" for="notifySMS">
                            SMS
                        </label>
                    </div>
                    <div class="form-check">
                        <input disabled={this.data.d} class="form-check-input" type="checkbox" value="" id="notifyApp" checked={this.state.app} onChange={(e) => {
                            this.setState({ app: e.target.checked })
                        }} />
                        <label class="form-check-label" for="notifyApp">
                            App Notification
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <span hidden={this.data.d} className="btn-link" style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        this.setState({
                            email: true,
                            sms: true,
                            app: true
                        })
                    }}>
                        Select All
                    </span>
                </div>
                <div className="form-group">
                    <button hidden={this.data.d} disabled={this.state.isLoading} className="btn btn-primary" onClick={this.notify}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Notify
                    </button>
                </div>
                <hr hidden={this.data.d} />
                <div className="form-group">
                    <button hidden={this.data.d} className="btn btn-danger" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Delete Entry",
                            message: <DeleteNotifyEntry data={this.data} />,
                            dialogWidth: 0.5,
                            dialogIndexCallback: (idx) => {
                                deleteNotifyEntryDialogIndex = idx
                            },
                        })
                    }}>Delete</button>
                </div>
            </div>
        )
    }
}


class NotifyPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            what: "r"

        }
    }


    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <button disabled={this.state.isLoading} onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Notify All" ,
                            message: <NotifyAllEntries />
                        })
                    }} className="btn btn-primary"><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Notify All</button>
                </div>
                <div className="form-group">
                    View All Notify Entries
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsReady" id="radioReviewsReady" checked={this.state.what === "r"} onChange={() => {
                            this.setState({ what: "r" }, () => {
                                viewAllNotifyEntriesDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsReady">
                            Ready
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsAll" id="radioReviewsAll" checked={this.state.what === "all"} onChange={() => {
                            this.setState({ what: "all" }, () => {
                                viewAllNotifyEntriesDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsAll">
                            All
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsNotified" id="radioReviewsNotified" checked={this.state.what === "a"} onChange={() => {
                            this.setState({ what: "a" }, () => {
                                viewAllNotifyEntriesDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsNotified">
                            Notified
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsNotNotified" id="radioReviewsNotNotified" checked={this.state.what === "n"} onChange={() => {
                            this.setState({ what: "n" }, () => {
                                viewAllNotifyEntriesDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsNotNotified">
                            Not Notified
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioReviewsDeleted" id="radioReviewsDeleted" checked={this.state.what === "d"} onChange={() => {
                            this.setState({ what: "d" }, () => {
                                viewAllNotifyEntriesDispatcher.dispatch({
                                    actionType: "set-get-data",
                                    urlParams: {
                                        w: this.state.what
                                    }
                                })
                            })
                        }} />
                        <label class="form-check-label" for="radioReviewsDeleted">
                            Deleted
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="user[0].f" isResultLink={false} resultLink={'/notify/'} sortCriteria="t" onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Notify",
                            message: <NotifySingleEntry data={resultData} />
                        })
                    }} resultClickable={true} searchAllowed={false} gType="get" timeKey="t" urlEndPoint="notify" urlParams={{
                        w: this.state.what
                    }} getDataCaller={(d) => {
                        viewAllNotifyEntriesDispatcher = d
                    }} subInfoKeys={[
                        {
                            "key": "user",
                            "map": function (data) {
                                return <div>
                                    {data[0].l}
                                    <br />
                                    {data[0].ph}
                                    <br />
                                    {data[0].e}
                                </div>
                            }
                        },
                        {
                            "key": "what",
                            "map": function (data) {
                                return <div>
                                    {data[0].name}
                                    <br />
                                    <b>{data[0].sku}</b>
                                </div>
                            }
                        },
                        {
                            "key": "en",
                            "map": function (data) {
                                if (data === true) {
                                    return <div className="text-success">Notified through email</div>
                                }
                            }
                        },
                        {
                            "key": "sn",
                            "map": function (data) {
                                if (data === true) {
                                    return <div className="text-success">Notified through SMS</div>
                                }
                            }
                        },
                        {
                            "key": "an",
                            "map": function (data) {
                                if (data === true) {
                                    return <div className="text-success">Notified through app notification</div>
                                }
                            }
                        },
                    ]} />
                </div>
            </div>
        )
    }
}

function Notify() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<NotifyPage urlID={id} />} title={"Notify"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    Notify
}