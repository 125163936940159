import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'


class CommonErrorDialog extends React.Component {
    constructor(props) {
      super()
    }

    render() {
        return (
            <div className='mt-2'>
                <h5>
                <span className='text-danger mr-1'><FontAwesomeIcon icon={faExclamationCircle} /></span>Some undesired error has occurred. Please refresh or try again later.
                </h5>
            </div>
        )
    }

}


  

  class ImageJPEGErrorDialog extends React.Component {
    constructor(props) {
      super()
    }

    render() {
        return (
            <div className='mt-2'>
                <h5>
                <span className='text-danger mr-1'><FontAwesomeIcon icon={faExclamationCircle} /></span>Only JPEG files are allowed.
                </h5>
            </div>
        )
    }

}

export {
    CommonErrorDialog,
    ImageJPEGErrorDialog
}