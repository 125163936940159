import React from 'react'
import NavBar from '../Components/NavBar';
import { NavBarData } from '../Components/NavBarData';
import { Window } from '../Components/Window';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ItemSearch } from '../Components/ItemSearch';
import { toastsDispatcher } from '../Components/Toasts';
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBox, faCheck, faCheckCircle, faCircleNotch, faDoorOpen, faExclamationCircle, faEye, faEyeSlash, faPaperPlane, faTimes, faTimesCircle, faUndo, faWrench } from '@fortawesome/free-solid-svg-icons';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearchTable } from '../Components/ItemSearchTable';
import jsPDF from 'jspdf';
import ReactQuill from 'react-quill';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { ADMINURL, ADMINURLFRONT } from '../Components/MSURLS';
import { ecommerce_helper_function_get_formatted_price, helper_function_info_dialog, helper_function_select_inventory_item, helper_function_validat_email } from '../Components/helper_functions';
import { networkCallGet, networkCallPost, networkCallPostFile } from '../Components/network';
import libphonenumber from 'google-libphonenumber';
import AuthenticatedImage from '../Components/AuthenticatedImage'
import { icon } from '@fortawesome/fontawesome-svg-core';
import { DateTime } from 'luxon';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const phoneRegion = 'IN';


var ACCT = Cookies.get('ACCT')
var TP = Cookies.get('TP')

var addServiceDialogIndex = null
var viewServiceDialogIndex = null
var generalAllDialogIndex = null

var viewAllServicesDispatcher = null


class GenerateAllInvoices extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,
        }
    }

    componentDidMount() {


    }

    generateInvoices = () => {
        this.setState({
            isLoading: true,
            isSuccess: false,
            isError: false,
        })
        networkCallGet(ADMINURL, "subscriptions", {
            sid: this.serviceID
        }, {}, (data) => {

            helper_function_info_dialog("Generate Invoices", <div>
                <div className='form-group'>
                    Generated invoices with following responses.
                </div>
                <div className='form-group'>
                    Successes:
                </div>
                <div className='form-group' style={{
                    maxHeight: '200px'
                }}>
                    <ol>
                        {
                            data.payload.s.map((val, idx) => {
                                return <li>{val}</li>
                            })
                        }
                    </ol>
                </div>

                <div className='form-group'>
                    Failures:
                </div>
                <div className='form-group' style={{
                    maxHeight: '200px'
                }}>
                    <ol>
                        {
                            data.payload.f.map((val, idx) => {
                                return <li>{val}: {data.payload.fs[idx]}</li>
                            })
                        }
                    </ol>
                </div>
            </div>)
            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false,
            })

        }, (data) => {
            helper_function_info_dialog("Generate Invoices", <div><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} /></span>&nbsp;Failed to generate invoices.</div>)
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
            })

        }, (error) => {

            helper_function_info_dialog("Generate Invoices", <div><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} /></span>&nbsp;Unable to generate invoices.</div>)

            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
            })
        }, ACCT, "gai", "status")
    }

    render() {
        return <div>
            <div className='form-group'>
                <button className='btn btn-primary' onClick={() => {
                    this.generateInvoices()
                }}>Generate Invoices</button>
            </div>
        </div>
    }
}

class ViewSingleService extends React.Component {
    constructor(props) {
        super()
        this.serviceID = props.serviceID
        this.state = {
            serviceData: {},

            isLoading: false,
            isSuccess: false,
            isError: false,
        }
    }

    componentDidMount() {
        this.getServiceData()
    }

    getServiceData = () => {
        this.setState({
            isLoading: true,
            isSuccess: false,
            isError: false,
        })
        networkCallGet(ADMINURL, "subscriptions", {
            sid: this.serviceID
        }, {}, (data) => {

            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false,
                serviceData: data.payload
            })

        }, (data) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                serviceData: {}
            })

        }, (error) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                serviceData: {}
            })
        }, ACCT, "gss", "status")
    }

    render() {

        if (this.state.isLoading) {
            return <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        }

        if (this.state.isError) {
            return <div className='text-danger'>
                Failed to get service data.
            </div>
        }

        if (this.state.isSuccess) {
            return <div>
                <div className='form-group'>
                    Name:&nbsp;<span>{this.state.serviceData.n}</span>
                </div>
                <div className='form-group'>
                    Description:&nbsp;<span style={{
                        whiteSpace: 'pre-wrap'
                    }}>{this.state.serviceData.d}</span>
                </div>
                <div className='form-group'>
                    ID:&nbsp;<span>{this.state.serviceData._id}</span>
                </div>
                <div className='form-group'>
                    Price Calculation Granularity:&nbsp;<span>{this.state.serviceData.pcg}</span>
                </div>
                {/* <div className='form-group'>
                    Billing Cycle:&nbsp;<span>{this.state.serviceData.bc}</span>
                </div> */}
                <div className='form-group'>
                    Created At:&nbsp;<span>{DateTime.fromISO(this.state.serviceData.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
                </div>
                <div className='form-group'>
                    Item Data:
                    <div class="table-responsive">
                        <table className='table'>

                            <tr>
                                <td>
                                    Name:
                                </td>
                                <td>
                                    {this.state.serviceData.ic["name"]}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Price:
                                </td>
                                <td>
                                    {ecommerce_helper_function_get_formatted_price(this.state.serviceData.ic["price"]["sp"])}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tax:
                                </td>
                                <td>
                                    {this.state.serviceData.ic["tax"]["p"] / 100}%&nbsp;({this.state.serviceData.ic["tax"]["t"]})
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className='form-group'>
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Users",
                            message: <ViewUsersForService serviceID={this.serviceID} />
                        })
                    }}>View Users</button>
                </div>
            </div>
        }
        return null
    }
}

class AddServices extends React.Component {
    constructor(props) {
        super()

        this.state = {
            name: "",
            description: "",
            itemID: "",
            billCycle: "Month",
            priceCalculationGranularity: "Hour",
            itemData: null,
            freeUsageCount: 0,
            freeCount: 0,
            isLoading: false,
            isSuccess: false,
            isError: false,
        }
    }

    addService = () => {


        if (this.state.name.trim().length === 0) {
            helper_function_info_dialog("Add Service", <span><span className='text-warning'><FontAwesomeIcon icon={faExclamationCircle} />&nbsp;</span>Please enter a service name.</span>, true, "Okay", () => { })
            return
        }

        if (this.state.description.trim().length === 0) {
            helper_function_info_dialog("Add Service", <span><span className='text-warning'><FontAwesomeIcon icon={faExclamationCircle} />&nbsp;</span>Please enter a service description.</span>, true, "Okay", () => { })
            return
        }


        if (this.state.itemID.trim().length === 0) {
            helper_function_info_dialog("Add Service", <span><span className='text-warning'><FontAwesomeIcon icon={faExclamationCircle} />&nbsp;</span>Please select an item.</span>, true, "Okay", () => { })
            return
        }


        this.setState({
            isLoading: true,
            isSuccess: false,
            isError: false,
        })

        networkCallPost(ADMINURL, "subscriptions", {}, {}, {

            "n": this.state.name.trim(),
            "pcg": this.state.priceCalculationGranularity.trim(),
            "d": this.state.description.trim(),
            "iid": this.state.itemID.trim(),
            "fc": parseInt(this.state.freeCount),
            "fuc": parseInt(this.state.freeUsageCount),
            // "bc": this.state.billCycle.trim()

        }, (data) => {

            helper_function_info_dialog("Add Service", <span><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>Service added.</span>, true, "Okay", () => { })

            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false,
            })

            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: addServiceDialogIndex
            })

            viewAllServicesDispatcher.dispatch({
                actionType: "get-data"
            })

            return

        }, (data) => {

            helper_function_info_dialog("Add Service", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Unable to add service. {data.payload}</span>, true, "Okay", () => { })


            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
            })

            return

        }, (error) => {

            helper_function_info_dialog("Add Service", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to add service. Please try again.</span>, true, "Okay", () => { })

            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
            })

            return
        }, ACCT, "csv", "status")

    }

    render() {
        return (
            <div className="">
                <div className="form-group">
                    <label htmlFor='serviceName'>
                        Service Name
                    </label>
                    <input id="serviceName" className='form-control' onChange={(e) => {
                        this.setState({
                            name: e.target.value
                        })
                    }} value={this.state.name} />
                </div>
                <div className="form-group">
                    <label htmlFor='serviceDescription'>
                        Service Description
                    </label>
                    <textarea id="serviceDescription" className='form-control' onChange={(e) => {
                        this.setState({
                            description: e.target.value
                        })
                    }} value={this.state.description} />
                </div>
                <div className="form-group">
                    <label htmlFor='serviceItem'>
                        Item
                    </label>
                    <br />

                    {this.state.itemData ? <div>
                        Name: <b>{this.state.itemData.name}</b>
                        <br />
                        Price: <b>{ecommerce_helper_function_get_formatted_price(this.state.itemData.price.sp)}</b>
                    </div> : null}
                    <span className='btn-link' onClick={() => {
                        helper_function_select_inventory_item((data) => {
                            this.setState({
                                itemData: data,
                                itemID: data._id
                            })
                        })
                    }}>Select Item</span>
                </div>
                {/* <div className="form-group">
                    <label htmlFor='serviceBillCycle'>
                        Billing Cycle
                    </label>
                    <select id="serviceBillCycle" className='form-control' onChange={(e) => {
                        this.setState({
                            billCycle: e.target.value
                        })
                    }} value={this.state.billCycle} >
                        <option value={`Day`}>Day</option>
                        <option value={`Week`}>Week</option>
                        <option value={`Month`}>Month</option>
                        <option value={`Year`}>Year</option>
                    </select>
                </div> */}
                <div className="form-group">
                    <label htmlFor='servicePriceCalculationGranularity'>
                        Price Calculation Granularity
                    </label>
                    <select id="servicePriceCalculationGranularity" className='form-control' onChange={(e) => {
                        this.setState({
                            priceCalculationGranularity: e.target.value
                        })
                    }} value={this.state.priceCalculationGranularity} >
                        <option value={`Second`}>Second</option>
                        <option value={`Minute`}>Minute</option>
                        <option value={`Hour`}>Hour</option>
                        <option value={`Day`}>Day</option>
                        <option value={`Week`}>Week</option>
                        <option value={`Month`}>Month</option>
                        <option value={`Year`}>Year</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor='serviceFreeCount'>
                        Free Count
                    </label>
                    <input className='form-control' type='number' onChange={(e) => {
                        this.setState({ freeCount: e.target.value })
                    }} value={this.state.freeCount} />
                </div>

                <div className="form-group">
                    <label htmlFor='serviceFreeUsageCount'>
                        Free Usage Count
                    </label>
                    <input className='form-control' type='number' onChange={(e) => {
                        this.setState({ freeUsageCount: e.target.value })
                    }} value={this.state.freeUsageCount} />
                </div>

                <div className="form-group">
                    <button disabled={this.state.isLoading} className='btn btn-primary' onClick={this.addService}>Add Service</button>
                </div>
            </div>
        )
    }
}

class ViewUsersForService extends React.Component {
    constructor(props) {
        super()
        this.serviceID = props.serviceID
        this.state = {


        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <ItemSearch nameTitleKey="f" getDataCaller={(d) => {
                        viewAllServicesDispatcher = d
                    }} onClickCallback={(resultData) => {


                    }} isResultLink={false} subNameTitleKey={'l'} subHeadingKeys={["e", "ph"]} resultClickable={true} searchAllowed={false} gType="gus" timeKey="t" urlEndPoint="subscriptions" subInfoKeys={[


                    ]} smallMessages={[""]} urlParams={{
                        sc: "n",
                        sid: this.serviceID
                    }} />
                </div>
            </div>
        )
    }
}


class ViewServices extends React.Component {
    constructor(props) {
        super()

        this.state = {


        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group p-2">
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add Service",
                            message: <AddServices />,
                            dialogIndexCallback: (idx) => {
                                addServiceDialogIndex = idx
                            }
                        })
                    }}>
                        Add Service
                    </button>
                    &nbsp;
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Generate Invoivces",
                            message: <GenerateAllInvoices />,
                            dialogIndexCallback: (idx) => {
                                generalAllDialogIndex = idx
                            }
                        })
                    }}>
                        Generate Invoices
                    </button>
                </div>

                <div className="form-group">
                    <ItemSearch nameTitleKey="n" getDataCaller={(d) => {
                        viewAllServicesDispatcher = d
                    }} onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Service",
                            message: <ViewSingleService serviceID={resultData._id} />,
                            dialogIndexCallback: (idx) => {
                                viewServiceDialogIndex = idx
                            }
                        })

                    }} isResultLink={false} resultLink={'/orders/'} subNameTitleKey={'d'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="gas" timeKey="t" urlEndPoint="subscriptions" subInfoKeys={[
                        // {
                        //     "key": "pcg",
                        //     "map": function (data) {
                        //         return <span>Granularity: <b>{data}</b></span>
                        //     }
                        // },
                        {
                            "key": "ic",
                            "map": function (data) {
                                return <>
                                    {/* <br /> */}
                                    <span>Item: <b>{data.name}</b></span>
                                </>
                            }
                        },
                        // {
                        //     "key": "bc",
                        //     "map": function (data) {
                        //         return <>
                        //             <br />
                        //             <span>Bill Cycle: <b>{data}</b></span>
                        //         </>
                        //     }
                        // },


                    ]} smallMessages={[""]} urlParams={{
                        sc: "n"
                    }} />
                </div>
            </div>
        )
    }
}


class ViewSingleServiceForUser extends React.Component {
    constructor(props) {
        super()
        this.serviceID = props.serviceID
        this.userID = props.userID
        this.state = {
            serviceData: {},
            subscriptionData: {},
            usageData: {},

            isLoading: false,
            isSuccess: false,
            isError: false,
        }
    }

    componentDidMount() {
        this.getServiceData()
        this.getSubscriptionData()
        this.getServiceUsage()
    }

    getServiceUsage = () => {
        this.setState({
            isLoading: true,
            isSuccess: false,
            isError: false,
        })
        networkCallGet(ADMINURL, "subscriptions", {
            sid: this.serviceID,
            uid: this.userID
        }, {}, (data) => {

            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false,
                usageData: data.payload
            })

        }, (data) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                usageData: {}
            })

        }, (error) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                usageData: {}
            })
        }, ACCT, "usu", "status")
    }

    getServiceData = () => {
        this.setState({
            isLoading: true,
            isSuccess: false,
            isError: false,
        })
        networkCallGet(ADMINURL, "subscriptions", {
            sid: this.serviceID
        }, {}, (data) => {

            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false,
                serviceData: data.payload
            })

        }, (data) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                serviceData: {}
            })

        }, (error) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                serviceData: {}
            })
        }, ACCT, "gss", "status")
    }

    getSubscriptionData = () => {
        this.setState({
            isLoading: true,
            isSuccess: false,
            isError: false,
        })
        networkCallGet(ADMINURL, "subscriptions", {
            sid: this.serviceID,
            uid: this.userID
        }, {}, (data) => {

            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false,
                subscriptionData: data.payload
            })

        }, (data) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                subscriptionData: {}
            })

        }, (error) => {
            this.setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                subscriptionData: {}
            })
        }, ACCT, "sta", "status")
    }

    render() {

        if (this.state.isLoading) {
            return <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        }

        if (this.state.isError) {
            return <div className='text-danger'>
                Failed to get service data.
            </div>
        }

        if (this.state.isSuccess) {
            return <div>
                <div className='form-group'>
                    <b>Subscription Status</b>
                </div>
                <div className='form-group'>
                    Active:&nbsp;{this.state.subscriptionData.ac ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span>}
                </div>
                <div className='form-group'>
                    Subscribed:&nbsp;{this.state.subscriptionData.sb ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span>}
                </div>
                <div className='form-group'>
                    Start Time:&nbsp;<span>{DateTime.fromISO(this.state.subscriptionData.st).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
                </div>
                <hr />
                <div className='form-group'>
                    <b>Service Usage</b>
                </div>
                <div className="form-group">
                    Usage: <b>{this.state.usageData.tu}&nbsp;{this.state.usageData.gr}</b>
                </div>
                <div className="form-group">
                    Granularity: <b>{this.state.usageData.gr}</b>
                </div>
                <div className="form-group">
                    Price: <b>{ecommerce_helper_function_get_formatted_price(this.state.usageData.tp)}</b>
                </div>
                <div className="form-group">
                    Start Time: <b>{DateTime.fromISO(this.state.usageData.st).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</b>
                </div>
                <div className="form-group">
                    End Time: <b>{DateTime.fromISO(this.state.usageData.et).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</b>
                </div>
                <hr />
                <div className='form-group'>
                    <b>Service Info</b>
                </div>
                <div className='form-group'>
                    Name:&nbsp;<span>{this.state.serviceData.n}</span>
                </div>
                <div className='form-group'>
                    Description:&nbsp;<span style={{
                        whiteSpace: 'pre-wrap'
                    }}>{this.state.serviceData.d}</span>
                </div>
                <div className='form-group'>
                    Price Calculation Granularity:&nbsp;<span>{this.state.serviceData.pcg}</span>
                </div>
                <div className='form-group'>
                    Billing Cycle:&nbsp;<span>{this.state.serviceData.bc}</span>
                </div>
                <div className='form-group'>
                    Created At:&nbsp;<span>{DateTime.fromISO(this.state.serviceData.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
                </div>
                <div className='form-group'>
                    Item Data:
                    <div class="table-responsive">
                        <table className='table'>

                            <tr>
                                <td>
                                    Name:
                                </td>
                                <td>
                                    {this.state.serviceData.ic["name"]}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Price:
                                </td>
                                <td>
                                    {ecommerce_helper_function_get_formatted_price(this.state.serviceData.ic["price"]["sp"])}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tax:
                                </td>
                                <td>
                                    {this.state.serviceData.ic["tax"]["p"] / 100}%&nbsp;({this.state.serviceData.ic["tax"]["t"]})
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        }
        return null
    }
}

class ViewServicesForUser extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.state = {


        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="">
                <div className="form-group">
                    <ItemSearch nameTitleKey="sn" getDataCaller={(d) => {
                        viewAllServicesDispatcher = d
                    }} onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Service",
                            message: <ViewSingleServiceForUser serviceID={resultData._id} userID={this.userID} />,
                            dialogIndexCallback: (idx) => {
                                viewServiceDialogIndex = idx
                            }
                        })

                    }} isResultLink={false} resultLink={''} subNameTitleKey={'d'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="gsu" timeKey="st" urlEndPoint="subscriptions" subInfoKeys={[
                        {
                            "key": "pcg",
                            "map": function (data) {
                                return <span>Granularity: <b>{data}</b></span>
                            }
                        },
                        {
                            "key": "in",
                            "map": function (data) {
                                return <>
                                    <br />
                                    <span>Item: <b>{data}</b></span>
                                </>
                            }
                        },
                        {
                            "key": "bc",
                            "map": function (data) {
                                return <>
                                    <br />
                                    <span>Bill Cycle: <b>{data}</b></span>
                                </>
                            }
                        },



                    ]} smallMessages={[""]} urlParams={{
                        sc: "n",
                        uid: this.userID
                    }} />
                </div>
                <div className="form-group">
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Invoices",

                        })
                    }} >View Invoices</button>
                    &nbsp;
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Invoices",

                        })
                    }}>View Usage</button>
                </div>
            </div>
        )
    }
}

class ViewUsers extends React.Component {
    constructor(props) {
        super()

        this.state = {
            userType: "Ecommerce Platform"
        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <div class="form-check">
                        <input
                            id="selectCustomerEcommercePlatform"
                            class="form-check-input"
                            type="radio"
                            value="Ecommerce Platform"
                            checked={this.state.userType === "Ecommerce Platform"}
                            onChange={(e) => {
                                this.setState({ userType: "Ecommerce Platform" })
                            }}
                        />
                        <label class="form-check-label" for="selectCustomerEcommercePlatform">
                            Ecommerce Platform
                        </label>
                    </div>

                    <div class="form-check">
                        <input
                            disabled={TP !== "owner"}
                            id="selectCustomerManualUsers"
                            class="form-check-input"
                            type="radio"
                            value="Manual Users"
                            checked={this.state.userType === "Manual Users"}
                            onChange={(e) => {
                                this.setState({ userType: "Manual Users" })
                            }}
                        />
                        <label class="form-check-label" for="selectCustomerManualUsers">
                            Manual Users
                        </label>
                    </div>
                </div>
                <div hidden={!(this.state.userType === "Ecommerce Platform")}>
                    <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {

                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Services For User",
                            message: <ViewServicesForUser userID={resultData._id} />
                        })


                    }} isResultLink={false} subNameTitleKey={'l'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="geu" timeKey="" urlEndPoint="ecommerceusers" subInfoKeys={[
                        {
                            "key": "ph",
                            "map": function (data) {
                                return data
                            }
                        },
                        {
                            "key": "e",
                            "map": function (data) {
                                if (data) {
                                    return <>
                                        <br />
                                        {data}
                                    </>
                                }
                                return null
                            }
                        },
                    ]} />
                </div>
                <div hidden={!(this.state.userType === "Manual Users")}>
                    <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {



                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "View Services For User",
                            message: <ViewServicesForUser userID={resultData._id} />
                        })



                    }} isResultLink={false} subNameTitleKey={'l'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="manualusers" subInfoKeys={[
                        {
                            "key": "ph",
                            "map": function (data) {
                                return data
                            }
                        },
                        {
                            "key": "e",
                            "map": function (data) {
                                if (data) {
                                    return <>
                                        <br />
                                        {data}
                                    </>
                                }
                                return null
                            }
                        },
                    ]} />
                </div>
            </div>
        )
    }
}

function Subscriptions() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewServices />} title={"Create and View Services"} />,
        <Window content={<ViewUsers />} title={"View Subscriptions For Users"} />,
    ]

}

export {
    Subscriptions,
}