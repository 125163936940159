import React from 'react'
import TabButton from '../Components/TabButton'

const Dispatcher = require('flux').Dispatcher;

var navBarDataDispatcher = new Dispatcher()

function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

class NavBarData extends React.Component {

    constructor(props) {
        super()
        this.data = props.data
        this.paramKey = props.paramKey
        
        this.state = {
            data: this.data,
            entries: []
        }


    }

    componentDidMount() {
        navBarDataDispatcher.register((payload) => {
            if (payload.actionType === "reload-navbar-data") {
                this.setState({ data: this.data })
            }
        })

        this.prepare_data()
    }

    prepare_data = () => {

        var tmpEntries = this.state.entries
        for (var i in this.state.data) {
            tmpEntries.push(
                <td className="pt-4 pb-4">
                    <TabButton paramKey={this.paramKey} showBorder={true} isDataNavBar={true} buttonParam={this.data[i].paramKey} buttonURL={this.data[i].url} key={randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')} buttonTitle={this.data[i].title} />
                </td>
            )
        }

        this.setState({entries:tmpEntries})

    }

    render() {

        return (
            <div className="" style={{
                width: '100%',
                WebkitOverflowScrolling: 'touch',
                overflowX: 'auto',
                // overflowY:'unset'
            }}>
                {/* <table className="d-flex justify-content-center"> */}
                <table className="">
                    <tbody>
                        <tr>
                            {this.state.entries}
                        </tr>

                    </tbody>
                </table>
            </div >
        )
    }
}

export {
    NavBarData,
    navBarDataDispatcher
}


