import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faImage } from '@fortawesome/free-solid-svg-icons';
import { ADMINURL } from './MSURLS';

var ACCT = Cookies.get('ACCT')


class AuthenticatedImage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: true,
            isError: false,
            imageData: null
        }

        this.imageClassname = props.className

        this.imageID = props.imageID

        this.imageStyle = props.imageStyle

        this.onClickHandler = props.onClick

        this.onClickDownloadable = props.onClickDownloadable
        this.imageName = props.imageName

    }
    componentDidMount() {
        if (this.imageID === undefined || this.imageID === null) {
            return
        }
        this.fetchImage()
    }

    fetchImage = () => {
        const that = this;

        var params = { "id": this.imageID }
        axios.get(ADMINURL + "image", {
            responseType: "blob",
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                var reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function () {

                    var imageDataUrl = reader.result;
                    that.setState({ isLoading: false, imageData: imageDataUrl })
                }
            })
            .catch(function (response) {
                that.setState({ isLoading: false, isError: true, imageData: undefined })
            })
    }

    render() {
        if (this.state.isLoading || this.state.imageData === null) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }
        if (this.state.isError) {
            return (
                <span className="text-danger p-2" style={{

                }}>
                    <FontAwesomeIcon icon={faImage} />
                </span>
            )
        }
        if (this.imageID === undefined || this.imageID === null) {
            return (
                null
            )
        }

        if (this.onClickDownloadable !== undefined && this.onClickDownloadable !== null && this.onClickDownloadable) {
            
            return (
                <a href={this.state.imageData} download={this.imageName}>
                    <img key={this.props.key} src={this.state.imageData} className={this.imageClassname} alt="Authenticated" style={this.imageStyle} />
                </a>
            )
        }
        return (
            <img src={this.state.imageData} key={this.props.key} className={this.imageClassname} alt="Authenticated" style={this.imageStyle} onClick={(e)=>{
                e.stopPropagation()
                if (this.onClickHandler) {
                    this.onClickHandler()   
                }
            }} />
        )
    }
}


export class UnAuthenticatedImage extends React.Component {
    constructor(props) {
        super()
        this.imageClassname = props.className

        this.imageStyle = props.imageStyle

        this.onClickHandler = props.onClick

        this.onClickDownloadable = props.onClickDownloadable
        this.imageName = props.imageName
        this.imageData = props.imageData

        this.state = {
            isLoading: false,
            isError: false,
            imageData: this.imageData
        }


    }

    componentDidMount() {
      
        
    }


    

    render() {
        if (this.state.isLoading || this.state.imageData === null) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }
        if (this.state.isError) {
            return (
                <span className="text-danger p-2" style={{

                }}>
                    <FontAwesomeIcon icon={faImage} />
                </span>
            )
        }
    
        

        if (this.onClickDownloadable !== undefined && this.onClickDownloadable !== null && this.onClickDownloadable) {

        
            return (
                <a href={this.state.imageData} download={this.imageName}>
                    <img key={this.props.key} src={this.state.imageData} className={this.imageClassname} alt="Authenticated" style={this.imageStyle} />
                </a>
            )
        }
        
        return (
            
            <img src={this.state.imageData} key={this.props.key} className={this.imageClassname} alt="Authenticated" style={this.imageStyle} onClick={(e)=>{
                if (this.onClickHandler) {
                    this.onClickHandler()   
                }
                e.stopPropagation()
            }} />
        )
    }
}

export default AuthenticatedImage