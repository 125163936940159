import axios from 'axios';

var statusString = "status"
var payloadString = "payload"
var successString = "success"
var failString = "fail"

/**
 * @param {Document} params
 * @param {Function} onSuccessCallback
 * @param {Function} onErrorCallback
 * @param {String} Authorization
 * @param {String} url
 * @param {String} endpoint
 */

function networkCallGet(url, endpoint, params, headers, onSuccessCallback, onFailCallback, onErrorCallback, Authorization, gType, _statusString) {

    var finalHeaders = headers
    if (Authorization) {
        finalHeaders["Authorization"] = Authorization
    }

    if (_statusString) {
        statusString = _statusString
    }
    
    var finalParams = params
    if (gType) {
        finalParams["g"] = gType
    }

    axios.get(url + endpoint, {
        params: finalParams,
        headers: finalHeaders,

    })
        .then(function (response) {
            if (!(statusString in response.data) || !(payloadString in response.data)) {
                onErrorCallback(response["data"])
                return
            }
            if (response.data[statusString] !== successString) {
                onFailCallback(response["data"])
                return
            }

            if (response["data"][statusString] === successString) {
                onSuccessCallback(response["data"])
            }
        })
        .catch(function (error) {
            console.log(error)
            onErrorCallback(error)
        })
}

/**
 * @param {Document} params
 * @param {Function} onSuccessCallback
 * @param {Function} onErrorCallback
 * @param {String} Authorization
 * @param {Document} postData
 * @param {String} url
 * @param {String} endpoint
 */

function networkCallPost(url, endpoint, params, headers, postData, onSuccessCallback, onFailCallback, onErrorCallback, Authorization, gType,_statusString) {

    var finalHeaders = headers
    if (Authorization) {
        finalHeaders["Authorization"] = Authorization
    }

    var finalParams = params
    if (gType) {
        finalParams["g"] = gType
    }

    if (_statusString) {
        statusString = _statusString
    }

    axios.post(url + endpoint, postData, {
        params: finalParams,
        headers: finalHeaders,

    })
        .then(function (response) {
            if (!(statusString in response.data) || !(payloadString in response.data)) {
                onFailCallback(response["data"])
                return
            }
            if (response.data[statusString] !== successString) {
                onFailCallback(response["data"])
                return
            }

            if (response["data"][statusString] === successString) {
                onSuccessCallback(response["data"])
            }
        })
        .catch(function (error) {
            onErrorCallback(error)
        })
}


function networkCallGetBlob(url, endpoint, params, headers, onSuccessCallback, onFailCallback, onErrorCallback, Authorization, gType, _statusString) {

    var finalHeaders = headers
    if (Authorization) {
        finalHeaders["Authorization"] = Authorization
    }

    if (_statusString) {
        statusString = _statusString
    }
    
    var finalParams = params
    if (gType) {
        finalParams["g"] = gType
    }

    axios.get(url + endpoint, {
        params: finalParams,
        headers: finalHeaders,
        responseType: 'blob'
    })
        .then(function (response) {

            if (!("content-type" in response.headers) || !("content-disposition" in response.headers)) {
                onErrorCallback(response["data"])
                return
            }

            if (response.headers["content-length"] === 0 || response.headers["content-length"] === '0') {
                onFailCallback(response["data"])
                return
            }

            if (response) {
                onSuccessCallback(response)
            }
        })
        .catch(function (error) {
            console.log(error)
            onErrorCallback(error)
        })
}

function networkCallPostFile(url, endpoint, params, headers, fileData,fileName, onSuccessCallback, onFailCallback, onErrorCallback, Authorization, gType, _statusString) {
    var bodyFormData = new FormData();

    bodyFormData.append('image', fileData, fileName);

    var finalHeaders = headers
    if (Authorization) {
        finalHeaders["Authorization"] = Authorization
    }

    var finalParams = params
    if (gType) {
        finalParams["g"] = gType
    }

    if (_statusString) {
        statusString = _statusString
    }

    axios.post(url + endpoint, bodyFormData, {
        params: finalParams,
        headers: finalHeaders,

    })
        .then(function (response) {
            if (!(statusString in response.data) || !(payloadString in response.data)) {
                onFailCallback(response["data"])
                return
            }
            if (response.data[statusString] !== successString) {
                onFailCallback(response["data"])
                return
            }

            if (response["data"][statusString] === successString) {
                onSuccessCallback(response["data"])
            }
        })
        .catch(function (error) {
            onErrorCallback(error)
        })
}

export {
    networkCallGet,
    networkCallPost,
    networkCallGetBlob,
    networkCallPostFile
}