import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faInfoCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

var moment = require('moment'); // require

const Dispatcher = require('flux').Dispatcher;

var toastsDispatcher = new Dispatcher()

// auto hide toasts after 5 seconds

class Toasts extends React.Component {
    constructor(props) {
        super()

        this.toastIdx = 0

        this.state = {
            toasts: {}
        }
    }

    componentDidMount() {
        const that = this
        toastsDispatcher.register(function (payload) {
            if (payload.actionType === "add-toast") {
                var tmpToasts = that.state.toasts
                tmpToasts[that.toastIdx] = {
                    title: payload.title,
                    message: payload.message,
                    date: payload.date,
                    isError: payload.isError,
                    isInfo: payload.isInfo,
                    isSuccess: payload.isSuccess
                }
                that.toastIdx += 1
                that.setState({
                    toasts: tmpToasts
                })
            }
        })
    }

    render() {

        var toasts = []
        // var i = this.state.toasts.length

        // for (i - 1; i >=0 ; i--) {
        for (var i in this.state.toasts) {
            const idx = i
            const that = this

            // var myTimeout = setTimeout(function () {
            setTimeout(function () {
                const tmpToasts = that.state.toasts
                delete tmpToasts[idx]
                // tmpToasts.splice(parseInt(idx), 1)
                that.setState({ toasts: tmpToasts })
            }, 10000);

            var messageIcon = null
            if (this.state.toasts[idx].isError) {
                messageIcon = <FontAwesomeIcon className="text-danger" icon={faTimesCircle} title="Error" />
            }
            if (this.state.toasts[idx].isInfo) {
                messageIcon = <FontAwesomeIcon className="text-info" icon={faInfoCircle} title="Error" />
            }
            if (this.state.toasts[idx].isSuccess) {
                messageIcon = <FontAwesomeIcon className="text-success" icon={faCheckCircle} title="Error" />
            }



            toasts.push(
                <div key={idx} className="toast m-2 " role="alert" aria-live="assertive" aria-atomic="true" data-autohide="true" style={{
                    opacity: 1,
                    zIndex: 10000
                }}>
                    <div className="toast-header">
                        <strong className="mr-auto"> {this.state.toasts[idx].title}</strong>
                        {/* <small>{moment(this.state.toasts[idx].date).fromNow()}</small> */}
                        <small>{moment(this.state.toasts[idx].date).format('h:mm:ss a')}</small>

                        <button type="button" className="ml-2 mb-1 close text-primary" data-dismiss="toast" aria-label="Close" onClick={() => {
                            const tmpToasts = this.state.toasts
                            delete tmpToasts[idx]
                            //    tmpToasts.splice(parseInt(idx), 1)
                            this.setState({ toasts: tmpToasts })
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body toast-animation" style={{ wordWrap: 'break-word' }}>
                        {messageIcon}&nbsp;{this.state.toasts[idx].message}
                    </div>
                </div>
            )
        }

        return (
            <div style={{
                position: 'fixed',
                top: 0,
                right: 0,
                overflowY: 'auto',
                zIndex: 10000
                // botto`m:0
            }}
                className="col-md-4 col-lg-4 col-xl-2 col-sm-4"
            >

                {toasts.reverse()}

            </div>


        )
    }
}

export {
    Toasts,
    toastsDispatcher
}