import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';

import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { ADMINURL } from '../Components/MSURLS';
import { ItemSearch } from '../Components/ItemSearch';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { ecommerce_helper_function_get_formatted_price } from '../Components/helper_functions'
import { fullDialogDispatcher } from '../Components/FullDialog';

var ACCT = Cookies.get('ACCT')


var getShippingEnriesDispatcher = null

var viewSingleShippingEntryDialogIndex = null

class ViewSingleShippingEntry extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {
        }
    }

    handleDeleteShippingEntry = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to delete shipping entry")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to delete shipping entry")
            return
        }

        getShippingEnriesDispatcher.dispatch({
            actionType: "get-data"
        })
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Delete Shipping Entry",
            message: "Shipping entry successfully deleted.",
            date: new Date(),
            isSuccess: true
        })
        fullDialogV2Dispatcher.dispatch({
            actionType:"close-dialog",
            dialogIndex:viewSingleShippingEntryDialogIndex
        })
    }

    deleteShippingEntry = () => {
        const that = this
        axios.post(ADMINURL + "shipping", this.state, {
            params: {
                g: "dse",
                sid: this.data._id
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteShippingEntry(response.data)
                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                console.log(error)
                that.handleDeleteShippingEntry({})
            })
    }



    render() {
        return (
            <div>
                <div className='form-group'>
                    <button className='btn btn-danger' onClick={() => {
                        this.deleteShippingEntry()
                    }}>
                        Delete
                    </button>
                </div>
            </div>
        )
    }
}

class ShippingPage extends React.Component {
    constructor() {
        super()
        this.state = {
            city: "",
            state: "",
            country: "",
            pincode: "",
            phone: "",
            email: "",
            user: "",
            title: "",
            description: "",
            shippingAmount: 0,
            aboveOrderValue: 0,
            aboveWeightValue: 0,
            aboveVolumeValue: 0,
        }
    }

    componentDidMount() {


    }

    handleAddShippingEntry = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add shipping entry")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to add shipping entry")
            return
        }

        getShippingEnriesDispatcher.dispatch({
            actionType: "get-data"
        })
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Shipping Entry",
            message: "Shipping entry successfully added.",
            date: new Date(),
            isSuccess: true
        })
    }

    addShippingEntry = () => {
        const that = this
        axios.post(ADMINURL + "shipping", this.state, {
            params: {
                g: "ade",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddShippingEntry(response.data)
                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                console.log(error)
                that.handleAddShippingEntry({})
            })
    }




    render() {
        return (
            <div className="p-4">

                <div className="d-flex flex-wrap">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Title</label>
                            <input type="text" value={this.state.title} className="form-control" onChange={(e) => {
                                this.setState({ title: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea type="text" value={this.state.description} className="form-control" onChange={(e) => {
                                this.setState({ description: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input type="text" value={this.state.city} className="form-control" onChange={(e) => {
                                this.setState({ city: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>State</label>
                            <input type="text" value={this.state.state} className="form-control" onChange={(e) => {
                                this.setState({ state: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Country</label>
                            <input type="text" value={this.state.country} className="form-control" onChange={(e) => {
                                this.setState({ country: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Pincode</label>
                            <input type="text" value={this.state.pincode} className="form-control" onChange={(e) => {
                                this.setState({ pincode: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="text" value={this.state.phone} className="form-control" onChange={(e) => {
                                this.setState({ phone: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Shipping Amount</label>
                            <input type="text" value={this.state.shippingAmount} className="form-control" onChange={(e) => {
                                this.setState({ shippingAmount: parseInt(e.target.value) })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Above Order Value</label>
                            <input type="text" value={this.state.aboveOrderValue} className="form-control" onChange={(e) => {
                                this.setState({ aboveOrderValue: parseInt(e.target.value) })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Above Weight Value</label>
                            <input type="text" value={this.state.aboveWeightValue} className="form-control" onChange={(e) => {
                                this.setState({ aboveWeightValue: parseInt(e.target.value) })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Above Volume Value</label>
                            <input type="text" value={this.state.aboveVolumeValue} className="form-control" onChange={(e) => {
                                this.setState({ aboveVolumeValue: parseInt(e.target.value) })
                            }} />
                        </div>

                        <div className="form-group">
                            <input type="button" value="Add" className="btn btn-primary" onClick={this.addShippingEntry} />
                        </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <ItemSearch nameTitleKey="title"
                            onClickCallback={(resultData) => {
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "View Shipping Entry",
                                    message: <ViewSingleShippingEntry data={resultData} />,
                                    dialogIndexCallback: (d) => {
                                        viewSingleShippingEntryDialogIndex = d
                                    }
                                })

                            }} isResultLink={false} resultClickable={true} searchAllowed={false} gType="ase" timeKey="t" urlEndPoint="shipping" subInfoKeys={[
                                {
                                    "key": "description",
                                    "map": function (data) {
                                        return <span style={{
                                            whiteSpace: 'pre-wrap'
                                        }}>{data}</span>
                                    }
                                },
                                {
                                    "key": "shippingAmount",
                                    "map": function (data) {
                                        return <span style={{
                                            whiteSpace: 'pre-wrap'
                                        }}><br />{ecommerce_helper_function_get_formatted_price(data)}</span>
                                    }
                                },

                            ]} urlParams={{
                                uid: this.userID
                            }} sortCriteria="t"
                            // postData={{
                            //     f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
                            //     t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
                            //     s: this.state.searchTerm,
                            //     e: this.state.ecom,
                            //     a: this.state.all
                            // }}
                            getDataCaller={(dispatcher) => {
                                getShippingEnriesDispatcher = dispatcher
                            }}
                        />

                    </div>


                </div>
            </div>
        )
    }
}



class Shipping extends React.Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<ShippingPage />} title={"Shipping"} />,
            // <Window content={<ContactPage />} title={"Contact Page"} />,

            // <AboutPage />
        ]
    }

}


export {
    Shipping
}