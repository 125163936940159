import React from 'react'

import axios from 'axios';
import Cookies from 'js-cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';

import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ADMINURL } from '../Components/MSURLS';

var ACCT = Cookies.get('ACCT')

function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

class AboutPage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            aboutPageContent: ""
        }
    }

    componentDidMount = () => {
        this.fetchAboutPage()
    }

    handleSaveAboutPage = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save about page information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save about page information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "About Page",
            message: "About page content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveAboutPage = () => {

        const that = this
        axios.post(ADMINURL + "admin", {
            content: this.state.aboutPageContent,
            shortPara: this.state.aboutPageShortParagragh,
            title: this.state.aboutPageTitle
        }, {
            params: {
                g: "sab",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveAboutPage(response.data)
                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveAboutPage({})
            })
    }

    fetchAboutPage = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "abt",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch about page information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch about page information")
                    return
                }


                that.setState({ aboutPageContent: response["payload"]["content"], aboutPageShortParagragh: response["payload"]["shortPara"], aboutPageTitle: response["payload"]["title"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    render() {
        return (
            <div className="m-2">
                <div className="form-group">
                    <label>About Page Title</label>
                    <input type="text" className="form-control" value={this.state.aboutPageTitle} onChange={(e) => {
                        this.setState({ aboutPageTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>About Page Short Paragragh</label>
                    <textarea rows="3" className="form-control" value={this.state.aboutPageShortParagragh} onChange={(e) => {
                        this.setState({ aboutPageShortParagragh: e.target.value })
                    }}></textarea>
                </div>
                <div className="form-group">
                    <label>About Page Content</label>
                    <ReactQuill id="inventoryEditEcomDescription" theme="snow" value={this.state.aboutPageContent} onChange={(data) => {
                        this.setState({ aboutPageContent: data })
                    }} />
                    {/* <textarea rows="5" className="form-control" value={this.state.aboutPageContent} onChange={(e) => {
                        this.setState({ aboutPageContent: e.target.value })
                    }}></textarea> */}
                </div>
                <div className="form-group">
                    <input type="button" value="Save" className="btn btn-primary" onClick={this.saveAboutPage} />
                </div>
            </div>
        )
    }
}

class ContactPage extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    componentDidMount() {
        this.fetchContactPage()
    }


    fetchContactPage = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "cnt",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch contact page information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch contact page information")
                    return
                }


                that.setState({ contactPageTitle: response["payload"]["title"], contactPageUnderTitlePara: response["payload"]["underTitlePara"], contactPageRightSectionTitle: response["payload"]["rightSectionTitle"], contactPageRightSectionUnderTitlePara: response["payload"]["rightSectionUnderTitlePara"], contactPageRightSectionAddress: response["payload"]["rightSectionAddress"], contactPageRightSectionPhone: response["payload"]["rightSectionPhone"], contactPageRightSectionEmail: response["payload"]["rightSectionEmail"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    handleSaveContactPage = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to contact page information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save contact page information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Contact Page",
            message: "Contact page content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveContactPage = () => {
        const that = this
        axios.post(ADMINURL + "admin", {
            title: this.state.contactPageTitle,
            underTitlePara: this.state.contactPageUnderTitlePara,
            rightSectionTitle: this.state.contactPageRightSectionTitle,
            rightSectionUnderTitlePara: this.state.contactPageRightSectionUnderTitlePara,
            rightSectionAddress: this.state.contactPageRightSectionAddress,
            rightSectionPhone: this.state.contactPageRightSectionPhone,
            rightSectionEmail: this.state.contactPageRightSectionEmail
        }, {
            params: {
                g: "scn",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveContactPage(response.data)

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveContactPage({})
                // alert("Some error has occurred")
            })
    }

    render() {
        return (
            <div className="m-2">
                <div className="form-group">
                    <label>Contact Page Title</label>
                    <input type="text" className="form-control" value={this.state.contactPageTitle} onChange={(e) => {
                        this.setState({ contactPageTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Page Under Title Paragrapgh</label>
                    <input type="text" className="form-control" value={this.state.contactPageUnderTitlePara} onChange={(e) => {
                        this.setState({ contactPageUnderTitlePara: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Page Right Section Title</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionTitle} onChange={(e) => {
                        this.setState({ contactPageRightSectionTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Right Section Under Title Paragrapgh</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionUnderTitlePara} onChange={(e) => {
                        this.setState({ contactPageRightSectionUnderTitlePara: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Right Section Address</label>
                    <textarea type="text" rows="4" className="form-control" value={this.state.contactPageRightSectionAddress} onChange={(e) => {
                        this.setState({ contactPageRightSectionAddress: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <label>Contact Right Section Phone</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionPhone} onChange={(e) => {
                        this.setState({ contactPageRightSectionPhone: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <label>Contact Right Section Email</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionEmail} onChange={(e) => {
                        this.setState({ contactPageRightSectionEmail: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <input type="button" className="btn btn-primary" value="Save" onClick={this.saveContactPage} />
                </div>

            </div>
        )
    }
}

class FaqPage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            faqs: []
        }
    }

    componentDidMount = () => {
        this.fetchFaqPage()
    }

    handleSaveFaqPage = (response) => {

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save faq page information")
            return
        }
        if (response["status"] !== "success") {
            alert("Unable to save faq page information")
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "FAQ Page",
            message: "FAQ page content successfully saved.",
            date: new Date(),
            isSuccess: true
        })
    }

    saveFaqPage = () => {

        const that = this
        axios.post(ADMINURL + "admin", {
            i: this.state.faqs
        }, {
            params: {
                g: "sfq",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveFaqPage(response.data)
                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                that.handleSaveFaqPage({})
            })
    }

    fetchFaqPage = () => {
        const that = this
        axios.get(ADMINURL + "admin", {
            params: {
                g: "gfq",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch faq page information")
                    return
                }
                if (response["status"] !== "success") {
                    alert("Unable to fetch faq page information")
                    return
                }


                that.setState({ faqs: response["payload"].i })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    render() {

        /* prepare faq items */
        var items = []

        for (var i in this.state.faqs) {
            const idx = i
            items.push(
                <div className="form-group" key={`faqkey${idx}`}>
                    <span className="d-flex flex-row">
                        <h5>Item {parseInt(idx) + 1}</h5>
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            var tmpFaqs = this.state.faqs
                            tmpFaqs.splice(parseInt(idx), 1)
                            this.setState({ faqs: tmpFaqs })
                        }}><FontAwesomeIcon icon={faTimesCircle} /></span>
                        &nbsp;
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === 0) {
                                return
                            }
                            var tmpFaqs = this.state.faqs
                            arraymove(tmpFaqs, idx, idx - 1);
                            this.setState({ faqs: tmpFaqs })
                        }}><FontAwesomeIcon icon={faArrowCircleUp} /></span>
                        &nbsp;
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (parseInt(idx) === this.state.faqs.length - 1) {
                                return
                            }
                            var tmpFaqs = this.state.faqs
                            arraymove(tmpFaqs, idx, idx + 1);
                            this.setState({ faqs: tmpFaqs })
                        }}><FontAwesomeIcon icon={faArrowCircleDown} /></span>
                    </span>
                    <label htmlFor={`faqQ${idx}`}>Question</label>
                    <input type="text" id={`faqQ${idx}`} value={this.state.faqs[idx].q} onChange={(e) => {
                        var tmpFaqs = this.state.faqs
                        tmpFaqs[idx].q = e.target.value
                        this.setState({ faqs: tmpFaqs })
                    }} className="form-control" />
                    <label htmlFor={`faqA1${idx}`}>Answer 1</label>
                    <input type="text" id={`faqA1${idx}`} value={this.state.faqs[idx].a1} onChange={(e) => {
                        var tmpFaqs = this.state.faqs
                        tmpFaqs[idx].a1 = e.target.value
                        this.setState({ faqs: tmpFaqs })
                    }} className="form-control" />
                    <label htmlFor={`faqA2${idx}`}>Answer 2</label>
                    <input type="text" id={`faqA2${idx}`} value={this.state.faqs[idx].a2} onChange={(e) => {
                        var tmpFaqs = this.state.faqs
                        tmpFaqs[idx].a2 = e.target.value
                        this.setState({ faqs: tmpFaqs })
                    }} className="form-control" />
                </div>
            )
        }

        return (
            <div className="m-2">
                <div className="form-group">
                    <span className="btn btn-link" style={{
                        cursor: "pointer"
                    }} onClick={() => {
                        var tmpFaqs = this.state.faqs
                        tmpFaqs.push({ q: "", a1: "", a2: "" })
                        this.setState({ faqs: tmpFaqs })
                    }}>Add Item</span>
                </div>
                <div className="form-group">
                    {items}
                </div>
                <div className="form-group">
                    <input type="button" value="Save" className="btn btn-primary" onClick={this.saveFaqPage} />
                </div>
            </div>
        )
    }

}

class Pages extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<AboutPage />} title={"About Page"} />,
            <Window content={<ContactPage />} title={"Contact Page"} />,
            <Window content={<FaqPage />} title={"FAQ Page"} />,

            // <AboutPage />
        ]
    }
}


export {
    Pages
}