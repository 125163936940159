import React from 'react'
import { faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';

import Pagination from "react-js-pagination";

import Cookies from 'js-cookie';

import { ADMINURL } from './MSURLS';
const Dispatcher = require('flux').Dispatcher;

var _ = require('lodash');


const { DateTime, } = require("luxon");

var T = Cookies.get('T')



class ItemSearchTable extends React.Component {
    constructor(props) {
        super()

        this.urlEndPoint = props.urlEndPoint
        this.onClickCallback = props.onClickCallback
        this.gType = props.gType
        this.searchLimit = props.searchLimit
        this.urlParams = props.urlParams
        this.apiEndpoint = props.apiEndpoint
        this.getDataCaller = props.getDataCaller

        this.data = props.data

        this.timeKey = props.timeKey
        this.updatedKey = props.updatedKey
        this.nameTitleKey = props.nameTitleKey
        this.subNameTitleKey = props.subNameTitleKey
        this.searchAllowed = props.searchAllowed
        this.resultClickable = props.resultClickable
        this.noTime = props.noTime
        this.sortCriteria = props.sortCriteria
        this.errorResponse = props.errorResponse

        this.smallMessages = props.smallMessages

        this.subHeadingKeys = props.subHeadingKeys

        this.subInfoKeys = props.subInfoKeys

        this.resultView = props.resultView

        this.isResultLink = props.isResultLink
        this.resultLink = props.resultLink

        this.resultExtraClassname = ""
        this.resultStyle = {}

        this.isData = false

        this.searchTypeTimer = null

        if (this.apiEndpoint === undefined || this.apiEndpoint === null) {
            this.apiEndpoint = ADMINURL
        }



        if (this.timeKey === undefined || this.timeKey === null) {
            this.timeKey = "t"
        }
        if (this.updatedKey === undefined || this.updatedKey === null) {
            this.updatedKey = "u" // u is user????
        }

        if (this.searchAllowed === undefined || this.searchAllowed === null) {
            this.searchAllowed = true // u is user????
        }

        if (this.isResultLink === undefined || this.isResultLink === null) {
            this.isResultLink = false
        }

        if (this.resultLink === undefined || this.resultLink === null) {
            this.resultLink = ""
        }

        if (this.noTime === undefined || this.noTime === null) {
            this.noTime = false
        } else {
            this.noTime = props.noTime
        }

        if (this.sortCriteria === undefined || this.sortCriteria === null) {
            this.sortCriteria = "t"
        }

        if (this.resultClickable !== undefined || this.resultClickable !== null) {
            if (this.resultClickable === true) {
                this.resultExtraClassname = " list-group-item-action"
                this.resultStyle = {
                    cursor: 'pointer'
                }
            }
        }


        if (this.searchLimit !== undefined || this.searchLimit !== null) {
            if (parseInt(this.searchLimit)) {
                this.searchLimit = parseInt(this.searchLimit)
            } else {
                this.searchLimit = 10
            }
        }

        if (this.smallMessages !== undefined || this.smallMessages !== null) {
            var tmpSmallMessage = []
            for (var i in this.smallMessages) {
                tmpSmallMessage.push(<small>{this.smallMessages[i]}</small>)
            }
            tmpSmallMessage.push(<br />)
            this.smallMessages = tmpSmallMessage
        } else {
            this.smallMessages = null
        }


        /**
         * init get data dispatcher
         */
        var getDataDispatcher = new Dispatcher()
        const that = this
        getDataDispatcher.register(function (payload) {
            if (payload.actionType === "get-data") {
                that.getData()
            }
        })

        if (this.getDataCaller !== undefined && this.getDataCaller !== null) {
            this.getDataCaller(getDataDispatcher)
        }

        if (this.errorResponse === undefined || this.errorResponse === null) {
            this.errorResponse = <span className="text-danger">
                Unable to fetch search results.
            </span>
        }


        /**
         * get columns and its key
         * 
         * 
         * {
         *  "title"
         *  "key"
         *  "map"
         * }
         * 
         */

        this.columns = props.columns

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,
            isLoaded: false,

            containerStyle: {},

            searchTerm: "",

            pageNumber: 1,

            totalDocCount: 0,
            data: [],
            fullData: []
        }

    }

    componentDidMount() {
        if (this.data !== undefined && this.data !== null) {
            this.isData = true
            // disable search
            this.searchAllowed = false

            // assumed page number is 1
            const tmpData = this.data.slice(0, this.searchLimit)
            // this.setState({ data: this.data,totalDocCount:this.data.length })
            var dataLength = this.data.length
            // if (dataLength > this.searchLimit) {
            //     dataLength = this.searchLimit
            // }
            this.setState({
                data: tmpData,
                fullData: this.data,
                totalDocCount: dataLength,
                isLoading: false,
                isSuccess: true,
                isError: false,
                isLoaded: true,
            })

        } else {
            this.getData()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== undefined && this.props.data !== null && this.props.data.length != this.state.totalDocCount) {
            this.data = this.props.data
            this.isData = true
            // disable search
            this.searchAllowed = false

            // assumed page number is 1
            // const tmpData = this.props.data.slice(0, this.searchLimit)
            const tmpData = this.props.data.slice((this.state.pageNumber - 1) * this.searchLimit, this.state.pageNumber * this.searchLimit)
            // this.setState({ data: this.data,totalDocCount:this.data.length })
            var dataLength = this.props.data.length
            // if (dataLength > this.searchLimit) {
            //     dataLength = this.searchLimit
            // }
            this.setState({
                data: tmpData,
                fullData: this.props.data,
                totalDocCount: dataLength,
                isLoading: false,
                isSuccess: true,
                isError: false,
                isLoaded: true,
            })

        }
    }

    handleGetData = (response) => {
        if (!("status" in response) || !("payload" in response)) {
            this.setState({ isLoading: false, isError: true, isSuccess: false })
            return
        }

        if (response["status"] !== "success") {
            this.setState({ isLoading: false, isError: true, isSuccess: false })
            return
        }

        this.setState({ isLoading: false, isLoaded: true, isSuccess: true, isError: false, data: response.payload.data, totalDocCount: response.payload.tc, containerStyle: {} })
    }

    getData = () => {

        if (this.isData === true) {
            
            var tmpData = []
            tmpData = this.data.slice((this.state.pageNumber - 1) * this.searchLimit, this.state.pageNumber * this.searchLimit)

            if (tmpData.length === 0) {
                if (this.state.pageNumber >= 2) {
                    tmpData = this.data.slice((this.state.pageNumber - 2) * this.searchLimit, this.state.pageNumber * this.searchLimit)
                    this.setState({ pageNumber: this.state.pageNumber - 1, data: tmpData, totalDocCount: this.data.length }, () => {

                    })
                }
            } else {
                this.setState({ data: tmpData, totalDocCount: this.data.length }, () => {

                })
            }


            return;
        }

        this.setState({
            isLoading: true, containerStyle: {
                opacity: 0.5,
                pointerEvents: 'none'
            }
        })
        const that = this

        axios.get(this.apiEndpoint + this.urlEndPoint, {
            params: {
                g: this.gType,
                pn: this.state.pageNumber,
                sc: this.sortCriteria,
                st: this.state.searchTerm,
                sl: this.searchLimit,
                ...this.urlParams
            },
            headers: { Authorization: T },

        })
            .then(function (response_) {
                const response = response_.data
                that.handleGetData(response)
            })
            .catch(function (error) {
                console.log(error)
                that.handleGetData({})
            })
    }



    render() {

        if (this.state.isError) {
            return (
                <div>
                    {this.errorResponse}
                </div>
            )
        }
        if (this.state.isLoading && this.state.isLoaded === false) {
            return (
                <div>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }


        if (this.state.isLoaded) {
            /**
                     * prepare data
                     */

            /**
             * get headers
             */

            var headers = []

            for (var i in this.columns) {
                headers.push(
                    <th scope="col">{this.columns[i].title}</th>
                )
            }

            /**
             * prepare data rows
             */

            var dataRows = []
            for (var i in this.state.data) {
                var tmpRow = []
                /**
                 * get data according to the coloumn value
                 */
                for (var j in this.columns) {
                    tmpRow.push(
                        <td>{this.columns[j].map(this.state.data[i],i)}</td>
                    )
                }

                dataRows.push(
                    <tr>
                        {tmpRow}
                    </tr>
                )
            }

            return (
                <div style={this.state.containerStyle}>
                    <div className="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                        <div className="d-inline-flex  justify-content-center align-items-center w-100" style={{
                            userSelect: 'none',
                            WebkitTouchCallout: 'none',
                            WebkitUserSelect: 'none',
                            KhtmlUserSelect: 'none',
                            MozUserSelect: 'none',
                            msUserSelect: 'none',
                        }}>
                            <Pagination
                                innerClass="pagination"
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.pageNumber}
                                itemsCountPerPage={this.searchLimit}
                                totalItemsCount={this.state.totalDocCount}
                                pageRangeDisplayed={5}
                                onChange={(pageNumber) => {
                                    this.setState({ pageNumber: parseInt(pageNumber) }, this.getData)
                                }}
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        {this.smallMessages}
                        <small>Total Records: {this.state.totalDocCount}. Showing: {((this.state.pageNumber - 1) * this.searchLimit) + 1} - {((this.state.pageNumber - 1) * this.searchLimit) + dataRows.length}.</small>&nbsp;
                    </div>
                    <div class="table-responsive">
                        <table class="table">

                            <thead>
                                <tr>
                                    {headers}
                                </tr>
                            </thead>
                            <tbody>
                                {dataRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

        return null

    }
}


export {
    ItemSearchTable
}