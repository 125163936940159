import { faArrowLeft, faCheckCircle, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useParams, useHistory } from 'react-router'
import { fullDialogDispatcher } from '../Components/FullDialog'
import { ItemSearch } from '../Components/ItemSearch'
import NavBar from '../Components/NavBar'
import { toastsDispatcher } from '../Components/Toasts'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';

import { fullDialogV2Dispatcher } from '../Components/FullDialogV2'
import { helper_function_info_dialog } from '../Components/helper_functions'
import { networkCallGet, networkCallPost } from '../Components/network'
import { ItemSearchTable } from '../Components/ItemSearchTable'
import { ADMINURL, ADMINURLFRONT } from '../Components/MSURLS'
var { DateTime } = require('luxon');
var ACCT = Cookies.get('ACCT')
const XLSX = require('xlsx');

var viewUserOrdersDispatcher = null

var addManualUserDialogIndex = null
var addManualUserAddressDialogIndex = null
var updateManualUserAddressDialogIndex = null

class ViewSingleUserBlockSuspendHistory extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.history = props.history
        this.onSuccessCallback = props.onSuccessCallback
        this.userID = props.userID


        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,


        }
    }

    render() {

        return (
            <div className="p-2">

                <div className='form-group'>
                    <ItemSearch nameTitleKey="u.u" onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        // this.addItem(resultData)
                    }} isResultLink={false} subHeadingKeys={["f"]} resultClickable={false} subNameTitleKey={'l'} searchAllowed={false} gType="gsh" timeKey="t" urlParams={{
                        id: this.userID
                    }} urlEndPoint="users" subInfoKeys={[
                        {
                            "key": "w",
                            "map": function (data) {

                                var action = ""
                                if (data === "b") {
                                    action = "User Blocked"
                                } else if (data === "s") {
                                    action = "User Suspend"
                                } else if (data === "o") {
                                    action = "Order Blocked"
                                } else if (data === "c") {
                                    action = "COD Blocked"
                                } else if (data === "w") {
                                    action = "Wishlist Blocked"
                                } else if (data === "cb") {
                                    action = "Cart Blocked"
                                } else if (data === "op") {
                                    action = "Online Payment Blocked"
                                } else {
                                    action = "Invalid Action"
                                }
                                return <><span>Action: <b>{action}</b></span></>
                            }
                        },
                        {
                            "key": "d",
                            "map": function (data) {
                                if (data === "" || data.length === 0 || data === null || data === undefined) {
                                    return null
                                }
                                return <><br />Description: <span style={{
                                    whiteSpace: 'pre-wrap'
                                }}>
                                    {data}
                                </span></>

                            }
                        },
                        {
                            "key": "o",
                            "map": function (data) {
                                if (data === true) {
                                    return <><br /><span>State was: <span className='text-danger'>Yes</span></span></>
                                }
                                return <><br /><span>State was: <span className='text-success'>No</span></span></>
                            }
                        },
                        {
                            "key": "n",
                            "map": function (data) {
                                if (data === true) {
                                    return <><br /><span>State is: <span className='text-danger'>Yes</span></span></>
                                }
                                return <><br /><span>State is: <span className='text-success'>No</span></span></>
                            }
                        },
                        {
                            "key": "u",
                            "map": function (data) {
                                return <><br /><span>Who Firstname: {data.f}</span></>
                            }
                        },
                        {
                            "key": "u",
                            "map": function (data) {
                                return <><br /><span>Who Lastname: {data.l}</span></>
                            }
                        }
                    ]} smallMessages={["Showing recent history first. 10 per page."]} />
                </div>

            </div>
        )
    }
}


class ViewSingleUserBlockSuspendList extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.history = props.history
        this.onSuccessCallback = props.onSuccessCallback
        this.userID = props.userID


        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            bs: this.data.bs,

            userBlockedDescription: "",
            userSuspendedDescription: "",
            orderBlockedDescription: "",
            codBlockedDescription: "",
            wishListBlockedDescription: "",
            cartBlockedDescription: "",
            onlinePayBlockedDescription: ""
        }
    }

    handleUpdateSingleUserBlockSuspend = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update user block/suspend data")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Block/Suspend User",
                message: `Unable to update user block/suspend data. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Block/Suspend User",
            message: `Successfully updated user block/suspend data.`,
            date: new Date(),
            isSuccess: true
        })


        this.setState({ isSuccess: true })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-all-dialogs"
        })

        if (this.onSuccessCallback) {
            this.onSuccessCallback()
        }
    }

    updateSingleUserBlockSuspend = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "users", {
            ...this.state.bs,
            bd: this.state.userBlockedDescription,
            sd: this.state.userSuspendedDescription,
            od: this.state.orderBlockedDescription,
            cd: this.state.codBlockedDescription,
            wd: this.state.wishListBlockedDescription,
            cbd: this.state.cartBlockedDescription,
            opd: this.state.onlinePayBlockedDescription,
        }, {
            params: {
                g: "usb",
                id: this.userID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateSingleUserBlockSuspend(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateSingleUserBlockSuspend({})
            })
    }

    render() {

        return (
            <div className="p-2">
                <div className="form-group">
                    <b>User Blocked</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userBlocked" checked={this.state.bs.b} onChange={(e) => {
                            const bs = this.state.bs
                            bs.b = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userBlocked">
                            Block User
                        </label>
                        <br />
                        <small>User wont be able to login</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userBlockedDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userBlockedDescription" value={this.state.userBlockedDescription} onChange={(e) => {
                        this.setState({ userBlockedDescription: e.target.value })
                    }} />
                </div>
                <hr />

                <div className="form-group">
                    <b>User Suspend</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userSuspend" checked={this.state.bs.s} onChange={(e) => {
                            const bs = this.state.bs
                            bs.s = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userSuspend">
                            Suspend User
                        </label>
                        <br />
                        <small>User wont be able to login</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userSuspendDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userSuspendDescription" value={this.state.userSuspendedDescription} onChange={(e) => {
                        this.setState({ userSuspendedDescription: e.target.value })
                    }} />
                </div>
                <hr />

                <div className="form-group">
                    <b>User Order Block</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userOrderBlock" checked={this.state.bs.o} onChange={(e) => {
                            const bs = this.state.bs
                            bs.o = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userOrderBlock">
                            Block User Order
                        </label>
                        <br />
                        <small>User wont be able to place orders/checkout</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userOrderBlockDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userOrderBlockDescription" value={this.state.orderBlockedDescription} onChange={(e) => {
                        this.setState({ orderBlockedDescription: e.target.value })
                    }} />
                </div>
                <hr />


                <div className="form-group">
                    <b>User COD Block</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userOrderCODBlock" checked={this.state.bs.c} onChange={(e) => {
                            const bs = this.state.bs
                            bs.c = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userOrderCODBlock">
                            Block User COD Orders
                        </label>
                        <br />
                        <small>User wont be able to place COD orders</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userOrderCODBlockDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userOrderCODBlockDescription" value={this.state.codBlockedDescription} onChange={(e) => {
                        this.setState({ codBlockedDescription: e.target.value })
                    }} />
                </div>
                <hr />


                <div className="form-group">
                    <b>User Wishlist Block</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userWishlistBlock" checked={this.state.bs.w} onChange={(e) => {
                            const bs = this.state.bs
                            bs.w = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userWishlistBlock">
                            Block User Wishlist
                        </label>
                        <br />
                        <small>User wont be able to add items to wishlist</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userWishlistBlockDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userWishlistBlockDescription" value={this.state.wishListBlockedDescription} onChange={(e) => {
                        this.setState({ wishListBlockedDescription: e.target.value })
                    }} />
                </div>
                <hr />


                <div className="form-group">
                    <b>User Cart Block</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userCartBlock" checked={this.state.bs.cb} onChange={(e) => {
                            const bs = this.state.bs
                            bs.cb = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userCartBlock">
                            Block User Cart
                        </label>
                        <br />
                        <small>User wont be able to add items to cart</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userCartBlockDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userCartBlockDescription" value={this.state.cartBlockedDescription} onChange={(e) => {
                        this.setState({ cartBlockedDescription: e.target.value })
                    }} />
                </div>
                <hr />


                <div className="form-group">
                    <b>User Online Payment Block</b>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="userOnlinePayBlock" checked={this.state.bs.op} onChange={(e) => {
                            const bs = this.state.bs
                            bs.op = e.target.checked
                            this.setState({ bs: bs })
                        }} />
                        <label class="form-check-label" for="userOnlinePayBlock">
                            Block User Online Payment
                        </label>
                        <br />
                        <small>User wont be able to place order with online payment</small>
                    </div>
                </div>
                <div className="form-group">
                    <label class="form-check-label" for="userOnlinePayBlockDescription">
                        Description
                    </label>
                    <input type='text' className='form-control' id="userOnlinePayBlockDescription" value={this.state.onlinePayBlockedDescription} onChange={(e) => {
                        this.setState({ onlinePayBlockedDescription: e.target.value })
                    }} />
                </div>
                <hr />


                <div className="form-group">
                    <button className="btn btn-primary" onClick={this.updateSingleUserBlockSuspend}>Done</button>
                </div>


            </div>
        )
    }
}

class ViewSingleUserAddresses extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.history = props.history
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }

    render() {

        /* prepare tables */
        var addressesTables = []

        const addresses = this.data.addresses

        for (i in addresses) {
            const idx = parseInt(i)
            var addressTRs = []
            const currAddress = addresses[i]
            for (var i in currAddress) {
                addressTRs.push(
                    <tr>
                        <td>{i.capitalize()}</td>
                        <td>{currAddress[i]}</td>
                    </tr>
                )
            }

            addressesTables.push(
                <div className="form-group">
                    <b>Address {idx + 1}</b>
                </div>
            )

            if (idx === this.data.defaultAddressIndex) {
                addressesTables.push(
                    <div className="form-group">
                        <b>Default Address</b>
                    </div>
                )
            }

            addressesTables.push(
                <div className="table-responsive">
                    <table className="table">
                        {addressTRs}
                    </table>
                </div>
            )
        }

        return (
            <div className="p-2">
                {addressesTables}
            </div>
        )
    }
}

class ViewSingleUserAuthData extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.history = props.history
        this.userID = props.userID
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            authData: {}
        }
    }

    handleFetchSingleUserAuthData = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get user auth data")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get User",
                message: `Unable to get user auth data. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ authData: response.payload, isSuccess: true })
    }

    fetchSingleUserAuthData = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "users", {
            params: {
                g: "gua",
                id: this.userID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchSingleUserAuthData(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchSingleUserAuthData({})
            })
    }

    componentDidMount() {
        this.fetchSingleUserAuthData()
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="p-4 text-danger">
                    <div className="form-group">
                        Failed to fetch user auth data
                    </div>
                </div>
            )
        }

        if (this.state.isSuccess) {
            return (
                <div className="p-2">
                    <div className="form-group">
                        <b>Last Login</b>
                    </div>
                    <div className="form-group">
                        {DateTime.fromISO(this.state.authData.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                    </div>
                </div>
            )
        }

        return null

    }
}

class ViewSingleUser extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.history = props.history
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            orderStatus: "all",

            user: {},

        }
    }

    handleFetchSingleUser = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get user")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get User",
                message: `Unable to get user. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ user: response.payload, isSuccess: true })
    }

    fetchSingleUser = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "users", {
            params: {
                g: "gse",
                id: this.userID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchSingleUser(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchSingleUser({})
            })
    }

    componentDidMount() {
        this.fetchSingleUser()
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="p-4 text-danger">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.goBack();
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Back</span>
                    </div>
                    <div className="form-group">
                        Failed to fetch user data
                    </div>
                </div>
            )
        }

        if (this.state.isSuccess) {

            /* prepare default address */
            var defaultAddressTRs = []
            // if (this.state.user.addresses.length > 0) {

            //     const defaultAddress = this.state.user.addresses[this.state.user.defaultAddressIndex]

            //     for (var i in defaultAddress) {
            //         defaultAddressTRs.push(
            //             <tr>
            //                 <td>{i.capitalize()}</td>
            //                 <td>{defaultAddress[i]}</td>
            //             </tr>
            //         )
            //     }
            // }

            return (
                <div className="p-4">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.goBack();
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Back</span>
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Details</b>
                            </div>
                            <div className="form-group">
                                <b>Firstname</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.f}
                            </div>
                            <div className="form-group">
                                <b>Lastname</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.l}
                            </div>
                            <div className="form-group">
                                <b>Email</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.e}
                            </div>
                            <div className="form-group">
                                <b>Phone</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.ph}
                            </div>
                            <div className="form-group">
                                <b>GST</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.g}
                            </div>
                            <div className="form-group">
                                <b>User Created At</b>
                            </div>
                            <div className="form-group">
                                {DateTime.fromISO(this.state.user.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                            </div>

                            <div className="form-group">
                                <b>Default Address</b>
                            </div>
                            <div className="form-group">
                                <div className="table-responsive">
                                    <table className="table">
                                        {defaultAddressTRs}
                                    </table>
                                </div>
                            </div>
                            <div className="form-group">
                                <span style={{
                                    cursor: 'pointer'
                                }} className="btn-link" onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "View User Addresses",
                                        message: <ViewSingleUserAddresses data={this.state.user} history={this.history} />
                                    })
                                }}>View all addresses</span>
                            </div>
                        </div>

                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Orders</b>
                            </div>
                            <div className="form-group">
                                Order Status
                            </div>
                            <div className="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersAll" id="radioUserOrdersAll" checked={this.state.orderStatus === "all"} onChange={() => {
                                        this.setState({ orderStatus: "all" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "ecom",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersAll">
                                        All
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersProcessing" id="radioUserOrdersProcessing" checked={this.state.orderStatus === "Processing"} onChange={() => {
                                        this.setState({ orderStatus: "Processing" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "ecom",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersProcessing">
                                        Processing
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersCancelled" id="radioUserOrdersCancelled" checked={this.state.orderStatus === "Cancelled"} onChange={() => {
                                        this.setState({ orderStatus: "Cancelled" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "ecom",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersCancelled">
                                        Cancelled
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersShipped" id="radioUserOrdersShipped" checked={this.state.orderStatus === "Shipped"} onChange={() => {
                                        this.setState({ orderStatus: "Shipped" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "ecom",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersShipped">
                                        Shipped
                                    </label>
                                </div>

                            </div>

                            <div className="form-group">
                                <ItemSearch searchLimit={5} nameTitleKey="oid" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    // this.addItem(resultData)
                                }} isResultLink={true} resultLink={'/orders/'} subNameTitleKey={'ba.phone'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="guo" timeKey="t" urlEndPoint="users" subInfoKeys={[
                                    {
                                        "key": "c.cart",
                                        "map": function (data) {
                                            return `${Object.keys(data).length} Items(s)`
                                        }
                                    },
                                    {
                                        "key": "c",
                                        "map": function (data) {
                                            if (data.paymentType === "online") {
                                                return <><br /><b>&#8377;{`${parseFloat(data.allItemPrice)}`}</b></>
                                            }
                                            if (data.paymentType === "cod") {
                                                return <><br /><b>COD</b></>
                                            }
                                            return null
                                        }
                                    }
                                ]} smallMessages={["Showing oldest orders first. 10 per page."]} urlParams={{
                                    os: this.state.orderStatus,
                                    id: this.userID,
                                    ch: "ecom",
                                }} getDataCaller={(d) => {
                                    viewUserOrdersDispatcher = d
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Wishlist</b>
                            </div>
                            <div className="form-group">
                                <ItemSearchTable searchLimit={5} urlParams={{
                                    id: this.userID
                                }} gType="guw" timeKey="t" urlEndPoint="users" columns={[
                                    {
                                        title: "SKU",
                                        key: "itemData",
                                        map: (x) => {
                                            /* dialog to view product */
                                            return <a target="_blank" href={`${ADMINURLFRONT}product?g=sng&scr=sku&sku=${x.itemData[0].sku}`}>{x.itemData[0].sku}</a>
                                        }
                                    },
                                    {
                                        title: "Name",
                                        key: "itemData",
                                        map: (x) => {
                                            return x.itemData[0].name
                                        }
                                    },
                                    {
                                        title: "Selling Price",
                                        key: "itemData",
                                        map: (x) => {
                                            return <span>&#8377;{x.itemData[0].price.sp}</span>
                                        }
                                    },
                                    {
                                        title: "Quantity",
                                        key: "itemData",
                                        map: (x) => {
                                            return x.itemData[0].quantity
                                        }
                                    },
                                    {
                                        title: "Added On",
                                        key: "t",
                                        map: (x) => {
                                            return DateTime.fromISO(x.time).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                                        }
                                    },
                                ]} />
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Current Cart</b>
                            </div>
                            <div className="form-group">
                                <ItemSearchTable searchLimit={5} urlParams={{
                                    id: this.userID
                                }} gType="guc" timeKey="t" urlEndPoint="users" columns={[
                                    {
                                        title: "SKU",
                                        key: "sku",
                                        map: (x) => {
                                            /* dialog to view product */
                                            return <a target="_blank" href={`${ADMINURLFRONT}product?g=sng&scr=sku&sku=${x.sku}`}>{x.sku}</a>
                                        }
                                    },
                                    {
                                        title: "Name",
                                        key: "name",
                                        map: (x) => {
                                            return x.name
                                        }
                                    },
                                    {
                                        title: "Selling Price",
                                        key: "itemData",
                                        map: (x) => {
                                            return <span>&#8377;{x.price.sp}</span>
                                        }
                                    },
                                    {
                                        title: "Cart Quantity",
                                        key: "cartQuantity",
                                        map: (x) => {
                                            return x.cartQuantity
                                        }
                                    },
                                    // {
                                    //     title: "Added On",
                                    //     key: "t",
                                    //     map: (x) => {
                                    //         return DateTime.fromISO(x.time).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                                    //     }
                                    // },
                                ]} />
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Auth Details</b>
                            </div>
                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "User Auth Data",
                                        message: <ViewSingleUserAuthData history={this.history} userID={this.userID} />
                                    })
                                }}>Click to view user auth data</span>
                            </div>
                            <div className="form-group">
                                <b>Block/Suspend User</b>
                            </div>
                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Block/Suspend User",
                                        message: <ViewSingleUserBlockSuspendList history={this.history} data={this.state.user} userID={this.userID} onSuccessCallback={() => {
                                            this.fetchSingleUser()
                                        }} />
                                    })
                                }}>Click to block/suspend user</span>
                            </div>

                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "User Block/Suspend History",
                                        message: <ViewSingleUserBlockSuspendHistory history={this.history} data={this.state.user} userID={this.userID} />
                                    })
                                }}>Click to view user block/suspend history</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return null

    }
}

class UsersPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            userType: "Ecommerce Users"

        }
    }


    render() {
        return (
            <div className="p-2">

                <div className="form-group">
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add User",
                            message: <UserAdd />,
                            dialogWidth: 3,
                            dialogIndexCallback: (idx) => {
                                addManualUserDialogIndex = idx
                            }
                        })
                    }}>Add Manual User</button>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input
                            id="selectEcommerceUsers"
                            class="form-check-input"
                            type="radio"
                            value="Ecommerce Platform"
                            checked={this.state.userType === "Ecommerce Users"}
                            onChange={(e) => {
                                this.setState({ userType: "Ecommerce Users" })
                            }}
                        />
                        <label class="form-check-label" for="selectEcommerceUsers">
                            Ecommerce Users

                        </label>
                    </div>

                    <div class="form-check">
                        <input

                            id="selectManualUsers"
                            class="form-check-input"
                            type="radio"
                            value="Manual Users"
                            checked={this.state.userType === "Manual Users"}
                            onChange={(e) => {
                                this.setState({ userType: "Manual Users" })
                            }}
                        />
                        <label class="form-check-label" for="selectManualUsers">
                            Manual Users
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    {/* {
                        this.state.userType === "Ecommerce Users" ?
                            <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {

                            }} isResultLink={true} resultLink={'/users/'} subNameTitleKey={'l'} resultClickable={true} searchAllowed={true} gType="ecm" timeKey="" urlEndPoint="users" subInfoKeys={[
                                {
                                    "key": "ph",
                                    "map": function (data) {
                                        return <>{data}</>
                                    }
                                },
                                {
                                    "key": "e",
                                    "map": function (data) {
                                        return <><br />{data}</>
                                    }
                                }
                            ]} />
                            : null
                    } */}

                    <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {

                    }} isResultLink={true} resultLink={this.state.userType === "Manual Users" ? "/manualusers/" : "/users/"} subNameTitleKey={'l'} resultClickable={true} searchAllowed={true} gType={this.state.userType === "Manual Users" ? "get" : "ecm"} timeKey="" urlEndPoint={this.state.userType === "Manual Users" ? "manualusers" : "users"} subInfoKeys={[
                        {
                            "key": "ph",
                            "map": function (data) {
                                return <>{data}</>
                            }
                        },
                        {
                            "key": "e",
                            "map": function (data) {
                                return <><br />{data}</>
                            }
                        }
                    ]} />

                </div>
            </div>
        )
    }
}

function SingleUser() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewSingleUser userID={id} history={history} />} title={"User"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}


/* add user */

class ManualUserViewAddresses extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.history = props.history
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            orderStatus: "all",

            user: {},

        }
    }
    render() {
        return <div className="">


            <div className="form-group" style={{
                // padding: '0px !important'
            }}>
                <ItemSearch nameTitleKey="firstname" urlParams={{
                    uid: this.userID
                }} onClickCallback={(resultData) => {

                    fullDialogV2Dispatcher.dispatch({
                        actionType: "show-dialog",
                        title: "Update Address",
                        message: <ManualUserEditAddress userID={this.userID} data={resultData} />,
                        dialogIndexCallback: (idx) => {
                            updateManualUserAddressDialogIndex = idx
                        }
                    })

                }} isResultLink={false} subNameTitleKey={'lastname'} resultClickable={true} searchAllowed={true} gType="gta" timeKey="t" urlEndPoint="address" subInfoKeys={[
                    {
                        "key": "address1",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    }, {
                        "key": "address2",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    },
                    {
                        "key": "city",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    },
                    {
                        "key": "state",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    },
                    {
                        "key": "country",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    },
                    {
                        "key": "pincode",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    },
                    {
                        "key": "phone",
                        "map": function (data) {
                            return <>
                                <b>{data}</b>
                                <br />
                            </>
                        }
                    }]} />
            </div>


        </div>
    }
}

class ManualUserAddAddress extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.history = props.history
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,


            firstname: "",
            lastname: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            phone: "",

        }
    }

    addAddress = () => {
        this.setState({
            isLoading: true,
            isError: false,
            isSuccess: false,
        })

        networkCallPost(ADMINURL, "address", {
            uid: this.userID
        }, {}, {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            company: this.state.company,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            pincode: this.state.pincode,
            phone: this.state.phone,
        }, (data) => {

            this.setState({
                isLoading: false,
                isError: false,
                isSuccess: true,
            })

            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: addManualUserAddressDialogIndex
            })

            helper_function_info_dialog("Add Manual User", <span><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>address added</span>, true, "Okay", null)
        }, (data) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
            })

            helper_function_info_dialog("Add Manual User", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>failed to add address: {data.payload}</span>, true, "Okay", null)
        }, (error) => {

            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
            })

            helper_function_info_dialog("Add Manual User", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>unable to add address.</span>, true, "Okay", null)
        }, ACCT, "add", "status")
    }

    render() {
        return <div className=''>
            <div className='form-group'>
                <label htmlFor="manualUserFirstname">Firstname</label>
                <input className="form-control" id="manualUserFirstname" value={this.state.firstname} onChange={(e) => {
                    this.setState({ firstname: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserLastname">Lastname</label>
                <input className="form-control" id="manualUserLastname" value={this.state.lastname} onChange={(e) => {
                    this.setState({ lastname: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <label htmlFor="manualUserCompany">Company</label>
                <input className="form-control" id="manualUserCompany" value={this.state.company} onChange={(e) => {
                    this.setState({ company: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <label htmlFor="manualUserAddress1">Address 1</label>
                <input className="form-control" id="manualUserAddress1" value={this.state.address1} onChange={(e) => {
                    this.setState({ address1: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserAddress2">Address 2</label>
                <input className="form-control" id="manualUserAddress2" value={this.state.address2} onChange={(e) => {
                    this.setState({ address2: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserCity">City</label>
                <input className="form-control" id="manualUserCity" value={this.state.city} onChange={(e) => {
                    this.setState({ city: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserState">State</label>
                <input className="form-control" id="manualUserState" value={this.state.state} onChange={(e) => {
                    this.setState({ state: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserCountry">Country</label>
                <input className="form-control" id="manualUserCountry" value={this.state.country} onChange={(e) => {
                    this.setState({ country: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserPincode">Pincode</label>
                <input className="form-control" id="manualUserPincode" value={this.state.pincode} onChange={(e) => {
                    this.setState({ pincode: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <label htmlFor="manualUserPhone">Phone</label>
                <input className="form-control" id="manualUserPhone" value={this.state.phone} onChange={(e) => {
                    this.setState({ phone: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <button onClick={this.addAddress} disabled={this.state.isLoading} className='btn btn-primary'><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add</button>
            </div>
        </div>
    }
}

class ManualUserEditAddress extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.history = props.history
        this.userID = props.userID
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,


            firstname: this.data.firstname,
            lastname: this.data.lastname,
            company: this.data.company,
            address1: this.data.address1,
            address2: this.data.address2,
            city: this.data.city,
            state: this.data.state,
            country: this.data.country,
            pincode: this.data.pincode,
            phone: this.data.phone,

        }
    }

    updateAddress = () => {

        this.setState({
            isLoading: true,
            isError: false,
            isSuccess: false,
        })

        networkCallPost(ADMINURL, "address", {
            uid: this.userID
        }, {}, {
            _id: this.data._id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            company: this.state.company,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            pincode: this.state.pincode,
            phone: this.state.phone,
        }, (data) => {
            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: updateManualUserAddressDialogIndex
            })
            this.setState({
                isLoading: false,
                isError: false,
                isSuccess: true,
            })


            helper_function_info_dialog("Add Manual User", <span><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>address updated</span>, true, "Okay", null)
        }, (data) => {
            helper_function_info_dialog("Add Manual User", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>failed to update address: {data.payload}</span>, true, "Okay", null)

            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
            })

        }, (error) => {
            helper_function_info_dialog("Add Manual User", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>unable to update address.</span>, true, "Okay", null)

            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
            })


        }, ACCT, "upd", "status")
    }

    render() {
        return <div className=''>
            <div className='form-group'>
                <label htmlFor="manualUserFirstname">Firstname</label>
                <input className="form-control" id="manualUserFirstname" value={this.state.firstname} onChange={(e) => {
                    this.setState({ firstname: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserLastname">Lastname</label>
                <input className="form-control" id="manualUserLastname" value={this.state.lastname} onChange={(e) => {
                    this.setState({ lastname: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <label htmlFor="manualUserCompany">Company</label>
                <input className="form-control" id="manualUserCompany" value={this.state.company} onChange={(e) => {
                    this.setState({ company: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <label htmlFor="manualUserAddress1">Address 1</label>
                <input className="form-control" id="manualUserAddress1" value={this.state.address1} onChange={(e) => {
                    this.setState({ address1: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserAddress2">Address 2</label>
                <input className="form-control" id="manualUserAddress2" value={this.state.address2} onChange={(e) => {
                    this.setState({ address2: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserCity">City</label>
                <input className="form-control" id="manualUserCity" value={this.state.city} onChange={(e) => {
                    this.setState({ city: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserState">State</label>
                <input className="form-control" id="manualUserState" value={this.state.state} onChange={(e) => {
                    this.setState({ state: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserCountry">Country</label>
                <input className="form-control" id="manualUserCountry" value={this.state.country} onChange={(e) => {
                    this.setState({ country: e.target.value })
                }} />
            </div>
            <div className='form-group'>
                <label htmlFor="manualUserPincode">Pincode</label>
                <input className="form-control" id="manualUserPincode" value={this.state.pincode} onChange={(e) => {
                    this.setState({ pincode: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <label htmlFor="manualUserPhone">Phone</label>
                <input className="form-control" id="manualUserPhone" value={this.state.phone} onChange={(e) => {
                    this.setState({ phone: e.target.value })
                }} />
            </div>

            <div className='form-group'>
                <button onClick={this.updateAddress} disabled={this.state.isLoading} className='btn btn-primary'><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Update</button>
            </div>
        </div>
    }
}

class ViewSingleManualUser extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.history = props.history
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            orderStatus: "all",

            user: {},

            defaultAddress: null,

        }
    }

    handleFetchSingleUser = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get user")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get User",
                message: `Unable to get user. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ user: response.payload, isSuccess: true })
    }

    fetchSingleUser = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "manualusers", {
            params: {
                g: "gsg",
                id: this.userID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchSingleUser(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchSingleUser({})
            })
    }

    fetchDefaultAddress = () => {
        this.setState({
            isLoading: true,
            isError: false,
            isSuccess: false,
            defaultAddress: null
        })
        networkCallGet(ADMINURL, "address", {
            uid: this.userID
        }, {}, (data) => {
            this.setState({
                isLoading: false,
                isError: false,
                isSuccess: true,
                defaultAddress: data.payload
            })
        }, (data) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
                defaultAddress: null
            })
        }, (error) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
                defaultAddress: null
            })
        }, ACCT, "gdd", "status")
    }

    componentDidMount() {
        this.fetchSingleUser()
        this.fetchDefaultAddress()
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="p-4 text-danger">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.goBack();
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Back</span>
                    </div>
                    <div className="form-group">
                        Failed to fetch user data
                    </div>
                </div>
            )
        }

        if (this.state.isSuccess) {

            /* prepare default address */
            var defaultAddressTRs = []
            if (this.state.defaultAddress !== null) {

                const defaultAddress = this.state.defaultAddress


                defaultAddressTRs.push(
                    <>
                        <tr>
                            <td>Firstname</td>
                            <td>{defaultAddress.firstname}</td>
                        </tr>
                        <tr>
                            <td>Lastname</td>
                            <td>{defaultAddress.lastname}</td>
                        </tr>
                        <tr>
                            <td>Address 1</td>
                            <td>{defaultAddress.address1}</td>
                        </tr>
                        <tr>
                            <td>Address 2</td>
                            <td>{defaultAddress.address2}</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>{defaultAddress.city}</td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>{defaultAddress.state}</td>
                        </tr>
                        <tr>
                            <td>Country</td>
                            <td>{defaultAddress.country}</td>
                        </tr>
                        <tr>
                            <td>Pincode</td>
                            <td>{defaultAddress.pincode}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{defaultAddress.phone}</td>
                        </tr>

                    </>
                )

            }

            const userID = this.userID

            return (
                <div className="p-4">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.goBack();
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Back</span>
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Details</b>
                            </div>
                            <div className="form-group">
                                <b>Firstname</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.f}
                            </div>
                            <div className="form-group">
                                <b>Lastname</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.l}
                            </div>
                            <div className="form-group">
                                <b>Email</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.e}
                            </div>
                            <div className="form-group">
                                <b>Phone</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.ph}
                            </div>
                            <div className="form-group">
                                <b>GST</b>
                            </div>
                            <div className="form-group">
                                {this.state.user.g}
                            </div>
                            <div className="form-group">
                                <b>User Created At</b>
                            </div>
                            <div className="form-group">
                                {DateTime.fromISO(this.state.user.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                            </div>

                            <div className="form-group">
                                <b>Default Address</b>
                            </div>
                            <div className="form-group">
                                <div className="table-responsive">
                                    <table className="table">
                                        {defaultAddressTRs}
                                    </table>
                                </div>
                            </div>
                            <div className="form-group">
                                <span style={{
                                    cursor: 'pointer'
                                }} className="btn-link" onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "View User Addresses",
                                        message: <ManualUserViewAddresses userID={this.state.user._id} data={this.state.user} history={this.history} />
                                    })
                                }}>View all addresses</span>
                                <br />
                                <span style={{
                                    cursor: 'pointer'
                                }} className="btn-link" onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Add Address",
                                        message: <ManualUserAddAddress userID={this.userID} data={this.state.user} history={this.history} />,
                                        dialogIndexCallback: (idx) => {
                                            addManualUserAddressDialogIndex = idx
                                        }
                                    })
                                }}>Add Addresses</span>

                            </div>
                        </div>

                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Orders</b>
                            </div>
                            <div className="form-group">
                                Order Status
                            </div>
                            <div className="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersAll" id="radioUserOrdersAll" checked={this.state.orderStatus === "all"} onChange={() => {
                                        this.setState({ orderStatus: "all" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "manual",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersAll">
                                        All
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersProcessing" id="radioUserOrdersProcessing" checked={this.state.orderStatus === "Processing"} onChange={() => {
                                        this.setState({ orderStatus: "Processing" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "manual",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersProcessing">
                                        Processing
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersCancelled" id="radioUserOrdersCancelled" checked={this.state.orderStatus === "Cancelled"} onChange={() => {
                                        this.setState({ orderStatus: "Cancelled" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "manual",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersCancelled">
                                        Cancelled
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="radioUserOrdersShipped" id="radioUserOrdersShipped" checked={this.state.orderStatus === "Shipped"} onChange={() => {
                                        this.setState({ orderStatus: "Shipped" }, () => {
                                            viewUserOrdersDispatcher.dispatch({
                                                actionType: "set-get-data",
                                                urlParams: {
                                                    os: this.state.orderStatus,
                                                    id: this.userID,
                                                    ch: "manual",
                                                }
                                            })
                                        })
                                    }} />
                                    <label class="form-check-label" for="radioUserOrdersShipped">
                                        Shipped
                                    </label>
                                </div>

                            </div>

                            <div className="form-group">

                                <ItemSearch searchLimit={5} nameTitleKey="oid" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    // this.addItem(resultData)
                                }} isResultLink={true} resultLink={'/orders/'} subNameTitleKey={'ba.phone'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="guo" timeKey="t" urlEndPoint="manualusers" subInfoKeys={[
                                    {
                                        "key": "c.cart",
                                        "map": function (data) {
                                            return `${Object.keys(data).length} Items(s)`
                                        }
                                    },
                                    {
                                        "key": "c",
                                        "map": function (data) {
                                            if (data.paymentType === "online") {
                                                return <><br /><b>&#8377;{`${parseFloat(data.allItemPrice)}`}</b></>
                                            }
                                            if (data.paymentType === "cod") {
                                                return <><br /><b>COD</b></>
                                            }
                                            return null
                                        }
                                    }, {
                                        "key": "sa",
                                        "map": function (data) {

                                            if (userID === data.uid) {
                                                return <><br /><span><b>Shipped To</b></span></>
                                            }
                                            return null
                                        }
                                    },
                                    {
                                        "key": "ba",
                                        "map": function (data) {

                                            if (userID === data.uid) {
                                                return <><br /><span><b>Billed To</b></span></>
                                            }
                                            return null
                                        }
                                    },

                                    {
                                        "key": "ia",
                                        "map": function (data) {

                                            if (userID === data.uid) {
                                                return <><br /><span><b>Issuer</b></span></>
                                            }
                                            return null
                                        }
                                    }
                                ]} smallMessages={["Showing oldest orders first. 10 per page."]} urlParams={{
                                    os: this.state.orderStatus,
                                    id: this.userID,
                                    ch: "manual",
                                }} getDataCaller={(d) => {
                                    viewUserOrdersDispatcher = d
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                <b>User Auth Details</b>
                            </div>
                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "User Auth Data",
                                        message: <ViewSingleUserAuthData history={this.history} userID={this.userID} />
                                    })
                                }}>Click to view user auth data</span>
                            </div>
                            <div className="form-group">
                                <b>Block/Suspend User</b>
                            </div>
                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Block/Suspend User",
                                        message: <ViewSingleUserBlockSuspendList history={this.history} data={this.state.user} userID={this.userID} onSuccessCallback={() => {
                                            this.fetchSingleUser()
                                        }} />
                                    })
                                }}>Click to block/suspend user</span>
                            </div>

                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "User Block/Suspend History",
                                        message: <ViewSingleUserBlockSuspendHistory history={this.history} data={this.state.user} userID={this.userID} />
                                    })
                                }}>Click to view user block/suspend history</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return null

    }
}
class UserAdd extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location
        this.userID = props.userID
        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,


            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            gst: ""

        }
    }

    addManualUser = () => {
        networkCallPost(ADMINURL, "manualusers", {
            uid: this.userID
        }, {}, {
            f: this.state.firstname,
            l: this.state.lastname,
            e: this.state.email,
            g: this.state.gst,
            ph: this.state.phone
        }, (data) => {

            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: addManualUserDialogIndex
            })

            helper_function_info_dialog("Add Manual User", <span><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>manual user added</span>, true, "Okay", null)
        }, (data) => {
            helper_function_info_dialog("Add Manual User", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>failed to add manual user: {data.payload}</span>, true, "Okay", null)
        }, (error) => {
            helper_function_info_dialog("Add Manual User", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>unable to add manual user.</span>, true, "Okay", null)



        }, ACCT, "add", "status")
    }

    render() {
        return (
            <div className=''>
                <div className='form-group'>
                    <label htmlFor="manualUserFirstname">Firstname</label>
                    <input className="form-control" id="manualUserFirstname" value={this.state.firstname} onChange={(e) => {
                        this.setState({ firstname: e.target.value })
                    }} />
                </div>
                <div className='form-group'>
                    <label htmlFor="manualUserLastname">Lastname</label>
                    <input className="form-control" id="manualUserLastname" value={this.state.lastname} onChange={(e) => {
                        this.setState({ lastname: e.target.value })
                    }} />
                </div>
                <div className='form-group'>
                    <label htmlFor="manualUserEmail">Email</label>
                    <input className="form-control" id="manualUserEmail" value={this.state.email} onChange={(e) => {
                        this.setState({ email: e.target.value })
                    }} />
                </div>
                <div className='form-group'>
                    <label htmlFor="manualUserPhone">Phone</label>
                    <input className="form-control" id="manualUserPhone" value={this.state.phone} onChange={(e) => {
                        this.setState({ phone: e.target.value })
                    }} />
                </div>
                <div className='form-group'>
                    <label htmlFor="manualUserGST">GST</label>
                    <input className="form-control" id="manualUserGST" value={this.state.gst} onChange={(e) => {
                        this.setState({ gst: e.target.value })
                    }} />
                </div>
                <div className='form-group'>
                    <button onClick={this.addManualUser} disabled={this.state.isLoading} className='btn btn-primary'><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add</button>
                </div>
            </div>
        )
    }
}

function SingleManualUser() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewSingleManualUser userID={id} history={history} />} title={"Manual User"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

function Users() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<UsersPage urlID={id} />} title={"Users"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    Users,
    SingleUser,
    SingleManualUser,
}