import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import 'react-quill/dist/quill.snow.css';
import { Window } from '../Components/Window';

import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearch } from '../Components/ItemSearch';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { faArrowCircleDown, faArrowCircleUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import AuthenticatedImage from '../Components/AuthenticatedImage';
import ReactQuill from 'react-quill';
import { ADMINURL } from '../Components/MSURLS';

const { DateTime, } = require("luxon");

var ACCT = Cookies.get('ACCT')


class ApproveDescriptionDialog extends React.Component {
    constructor(props) {
        super()
        this.descriptionID = props.descriptionID
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false
        }
    }
    handleApproveDescription = (response) => {
        // this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to approve description")
            this.setState({ isLoading: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Approve Description",
                message: `Unable to approve description. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            this.setState({ isLoading: false, isError: true })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Approve Description",
            message: `Description successfully approved and removed from queue.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isLoading: false, isError: false, isSuccess: true })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
        // this.setState({ isLoading: false, isError: false, isSuccess: true, description: response.payload })
    }

    approveDescription = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "descriptions", {
            _id: this.descriptionID
        }, {
            params: {
                g: "apr"
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleApproveDescription(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleApproveDescription({})
            })
    }

    render() {
        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-secondary" onClick={() => {
                    fullDialogDispatcher.dispatch({
                        actionType: "close-dialog"
                    })
                }}>Cancel</button>
                <button disabled={this.state.isLoading} className="btn btn-primary ml-2" onClick={() => {
                    // this.setState({ items: {} })
                    this.approveDescription()

                }}>
                    <span hidden={!this.state.isLoading} className="mr-1">
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </span>
                    Approve
                </button>
            </div>
        )
    }
}

class ApproveDescription extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="p-2">
                    <Link to="/descriptions"><FontAwesomeIcon icon={faArrowLeft} /> Descriptions</Link>
                </div>
                <div className="p-2">
                    <span>
                        Showing latest updated first.
                    </span>
                </div>
                <div className="p-2">
                    <ItemSearch nameTitleKey="in" subNameTitleKey="is" onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        // this.addItem(resultData)
                        fullDialogDispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Approve Description?',
                            message: <ApproveDescriptionDialog descriptionID={resultData._id} />
                        })
                    }} resultClickable={true} isResultLink={false} sortCriteria="up" searchAllowed={true} gType="get" urlEndPoint="descriptions" />
                </div>
            </div>
        )
    }
}

function ApproveDescriptionWindowed() {
    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ApproveDescription />} title={"Approve Descriptions"} />,
    ]
}

class EditDescription extends React.Component {
    constructor(props) {
        super()
        this.descriptionID = props.descriptionID

        this.state = {
            description: {},

            isSuccess: false,
            isError: false,
            isLoading: true,


            isSaveLoading: false
        }
    }

    componentDidMount() {
        this.fetchDescription()
    }

    handleFetchDescription = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get to description")
            this.setState({ isLoading: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Description",
                message: `Unable to get to description. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            this.setState({ isLoading: false, isError: true })
            return
        }


        this.setState({ isLoading: false, isError: false, isSuccess: true, description: response.payload })
        // toastsDispatcher.dispatch({
        //     actionType: 'add-toast',
        //     title: "Get Description",
        //     message: <span>Description successfully updated.</span>,
        //     date: new Date(),
        //     isSuccess: true
        // })

    }

    fetchDescription = () => {

        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "descriptions", {
            params: {
                g: "get",
                did: this.descriptionID,
                pn: 1,
                sc: "t"
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchDescription(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchDescription({})
            })
    }

    handleSaveDescription = (response) => {
        // this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save to description")
            this.setState({ isSaveLoading: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Save Description",
                message: `Unable to save to description. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            this.setState({ isSaveLoading: false, isError: true })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Save Description",
            message: `Description successfully saved.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSaveLoading: false, isError: false, isSuccess: true })

        // this.setState({ isLoading: false, isError: false, isSuccess: true, description: response.payload })
    }

    saveDescription = () => {
        const that = this
        this.setState({ isSaveLoading: true })
        axios.post(ADMINURL + "descriptions", {
            desc: this.state.description.desc,
            _id: this.descriptionID
        }, {
            params: {
                g: "sav"
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveDescription(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleSaveDescription({})
            })
    }


    render() {

        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    Loading...
                </div>
            )
        }

        if (this.state.isError) {
            return (
                <div className="p-2">
                    <span className="text-danger">Some unexpected error has occurred. Please contact website administrator.</span>
                </div>
            )
        }
        if (this.state.isSuccess) {

            // generate images 
            var images = []

            return (
                <div className="p-2">
                    <div className="p-2">
                        <Link to="/descriptions"><FontAwesomeIcon icon={faArrowLeft} /> Descriptions</Link>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="p-2">
                                <h5>{this.state.description.in}</h5>
                                <small>{this.state.description.is}</small>
                            </div>
                            <div className="pl-2">
                                <span className="mr-1">
                                    <small>Created:</small>
                                </span>
                                <span>
                                    <small title={DateTime.fromISO(this.state.description.t).toRelative().toString()}>{DateTime.fromISO(this.state.description.t).toLocaleString(DateTime.DATETIME_MED)}</small>
                                </span>
                            </div>
                            <div className="pl-2">
                                <span className="mr-1">
                                    <small>Updated:</small>
                                </span>
                                <span>
                                    <small title={DateTime.fromISO(this.state.description.up).toRelative().toString()}>{DateTime.fromISO(this.state.description.up).toLocaleString(DateTime.DATETIME_MED)}</small>
                                </span>
                            </div>
                            <div className="p-2">
                                <ReactQuill theme="snow" value={this.state.description.desc} onChange={(data) => {
                                    const tmpDescription = this.state.description
                                    tmpDescription.desc = data
                                    this.setState({ description: tmpDescription })
                                }} />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="">
                                <h4>Images</h4>
                            </div>
                        </div>
                    </div>

                    <div className="p-2 ">
                        <button className="btn btn-primary ml-2" disabled={this.state.isSaveLoading} onClick={() => {
                            this.saveDescription()
                            // this.setState({ isItemSelected: false, isUserSelected: false })
                        }}>
                            <span hidden={!this.state.isSaveLoading}>
                                <FontAwesomeIcon icon={faCircleNotch} spin className="mr-1" />
                            </span>
                            Save
                        </button>
                    </div>
                </div>
            )
        }

        return (
            null
        )
    }
}

function EditDescriptionWindowed() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<EditDescription descriptionID={id} />} title={"Edit Descriptions"} />,
    ]
}

class AssignDescription extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isSuccess: false,
            isError: false,
            isLoading: false,

            isItemSelected: false,
            isUserSelected: false,

            items: {},
            users: {},
            user: {},
            singleUserID: null
        }
    }

    handleAddToQueue = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add to description queue")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Description",
                message: `Unable to add to description queue. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Description",
            message: <span>Successfully added to description queue.</span>,
            date: new Date(),
            isSuccess: true
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })

        this.setState({
            isItemSelected: false,
            isUserSelected: false,

            items: {},
            users: {},
            user: {},
            singleUserID: null
        })

    }

    addToQueue = () => {

        // prepare data
        var itemIDs = []
        for (var i in this.state.items) {
            itemIDs.push(i)
        }

        //

        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "descriptions", {
            iids: itemIDs,
            tid: this.state.singleUserID
        }, {
            params: {
                g: "adq",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddToQueue(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleAddToQueue({})
            })
    }

    render() {
        if (!this.state.isItemSelected) {
            const totalItems = Object.keys(this.state.items).length
            // prepare items
            var items = []
            for (var i in this.state.items) {
                const idx = i
                items.push(
                    <span className="badge bg-rust text-light rounded-pill p-2 m-1 d-flex justify-content-between" key={i}>
                        <span>
                            {this.state.items[idx].name}
                        </span>
                        <span>
                            <FontAwesomeIcon className="ml-2" icon={faTimesCircle} title={`Remove`} style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                const tmpItems = this.state.items
                                delete tmpItems[idx]
                                this.setState({ items: tmpItems })
                            }} />
                        </span>
                    </span>
                )
            }
            ///

            return (
                <div className="p-2">
                    <div className="p-2">
                        <Link to="/descriptions"><FontAwesomeIcon icon={faArrowLeft} /> Descriptions</Link>
                    </div>
                    <div className="p-2 d-flex flex-wrap">
                        {items}
                    </div>
                    <div className="p-2">
                        <h5>Select Item</h5>
                    </div>
                    <div className="pt-0">

                        <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                            const tmpItems = this.state.items
                            tmpItems[resultData._id] = resultData
                            this.setState({ items: tmpItems })

                        }} resultClickable={true} searchLimit={4} isResultLink={false} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                    </div>
                    <div className="p-2 d-flex justify-content-end">
                        <button className="btn btn-danger ml-2" onClick={() => {
                            fullDialogDispatcher.dispatch({
                                actionType: 'show-dialog',
                                title: "Clear Selected Items?",
                                message: <div className="d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={() => {
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })
                                    }}>Cancel</button>
                                    <button className="btn btn-danger ml-2" onClick={() => {
                                        this.setState({ items: {} })
                                        fullDialogDispatcher.dispatch({
                                            actionType: "close-dialog"
                                        })

                                    }}>Clear</button>
                                </div>
                            })
                        }}>
                            Clear
                        </button>
                        <button className="btn btn-primary ml-2" onClick={() => {
                            if (parseInt(totalItems) === 0) {
                                return
                            }
                            this.setState({ isItemSelected: true })
                        }}>
                            Done
                        </button>

                    </div>
                </div>
            )
        }

        if (this.state.isItemSelected && !this.state.isUserSelected) {

            var selectedUser = null
            if (this.state.singleUserID !== null) {
                selectedUser = <div className="border rounded p-2 w-100">
                    <span>{this.state.user.firstname}&nbsp;{this.state.user.lastname}</span>
                    <br />
                    <small>{this.state.user.account_type}</small>
                </div>
            }

            return (
                <div className="p-2">
                    {/* <div className="p-2">
                        <Link to="/descriptions"><FontAwesomeIcon icon={faArrowLeft} /> Descriptions</Link>
                    </div> */}
                    <div className="p-2 d-flex flex-wrap">
                        {selectedUser}
                    </div>
                    <div className="p-2">
                        <h5>Select User</h5>
                    </div>
                    <div className="pt-0">

                        <ItemSearch nameTitleKey="firstname" onClickCallback={(resultData) => {
                            this.setState({
                                user: resultData,
                                singleUserID: resultData._id
                            })

                        }} resultClickable={true} subNameTitleKey={`account_type`} searchLimit={4} noTime={true} isResultLink={false} searchAllowed={true} gType="get" timeKey="_created" urlEndPoint="users" />
                    </div>
                    <div className="p-2 d-flex justify-content-end">
                        <button className="btn btn-primary ml-2" onClick={() => {
                            this.setState({ isItemSelected: false, isUserSelected: false })
                        }}>
                            Back
                        </button>
                        <button className="btn btn-primary ml-2" onClick={() => {
                            if (this.state.singleUserID === null) {
                                return
                            }
                            // this.setState({ isUserSelected: true })
                            this.addToQueue()
                        }}>
                            Done
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <div className="p-2">
                Unexpected error. Contact website administrator.
            </div>
        )
    }
}

class ViewDescriptions extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">

                <div className="p-2">
                    <span className="btn btn-primary mr-2" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Select Item',
                            message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                // const id = this.state.slider[iid]["_id"]
                                this.addItem(resultData)
                            }} resultClickable={true} isResultLink={true} resultLink={'/descriptions/'} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                        })
                    }}>
                        Select Item
                    </span>
                    <Link to="/descriptions/assign" className="btn btn-primary mr-2">
                        Assign
                    </Link>
                    <Link to="/descriptions/approve" className="btn btn-primary mr-2">
                        Approve
                    </Link>
                </div>
                <div className="p-2">
                    <span>Description edit queue</span>
                    <ItemSearch nameTitleKey="in" subNameTitleKey="is" onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        this.addItem(resultData)
                    }} resultClickable={true} isResultLink={true} resultLink={'/descriptions/'} searchAllowed={true} gType="get" urlEndPoint="descriptions" />
                </div>
            </div>
        )
    }
}

class AssignDescriptionWindowed extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<AssignDescription />} title={"Assign Descriptions"} />,
        ]
    }
}

class Descriptions extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return [
            <div>
                <NavBar />
            </div>,
            <Window content={<ViewDescriptions />} title={"Descriptions"} />,
            // <Window content={<ContactPage />} title={"Contact Page"} />,

            // <AboutPage />
        ]
    }
}


export {
    Descriptions,
    AssignDescriptionWindowed,
    EditDescriptionWindowed,
    ApproveDescriptionWindowed
}