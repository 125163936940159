import React from 'react'
import NavBar from '../Components/NavBar';
import { NavBarData } from '../Components/NavBarData';
import { Window } from '../Components/Window';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ItemSearch } from '../Components/ItemSearch';
import { toastsDispatcher } from '../Components/Toasts';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactDOMServer from 'react-dom/server'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBox, faCheck, faCircleNotch, faDoorOpen, faPaperPlane, faTimes, faTrash, faUndo, faWrench } from '@fortawesome/free-solid-svg-icons';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearchTable } from '../Components/ItemSearchTable';
import jsPDF from 'jspdf';
import ReactQuill from 'react-quill';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { ADMINURL, ADMINURLFRONT } from '../Components/MSURLS';
import { networkCallGet, networkCallGetBlob, networkCallPost } from '../Components/network';
import { ecommerce_helper_function_get_cart_total, ecommerce_helper_function_get_coupon_discount_value, ecommerce_helper_function_get_formatted_price, ecommerce_helper_function_get_wallet_amount, helper_function_info_dialog } from '../Components/helper_functions';

var ACCT = Cookies.get('ACCT')
var TP = Cookies.get('TP')

var selecteUserAddressDialogIndex = null

var { DateTime } = require('luxon');

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});

const topBarData = [


    {
        "url": "?show=ecom&status=all",
        "title": "Ecommerce",
        "paramKey": "ecom"
    },
    {
        "url": "?show=manual&status=all",
        "title": "Manual",
        "paramKey": "manual"
    },
    {
        "url": "?show=subscription&status=all",
        "title": "Subscription",
        "paramKey": "subscription"
    }
]



class ViewOrders extends React.Component {
    constructor(props) {
        super()

        this.show = props.show

        this.state = {

        }
    }

    render() {
        return (
            <div>
                {this.show}
            </div>
        )
    }
}

class UserAddresses extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

        }
    }
    render() {



        return (
            <div className="">


                <div className="form-group" style={{
                    // padding: '0px !important'
                }}>
                    <ItemSearch nameTitleKey="address1" urlParams={{
                        uid: this.userID
                    }} onClickCallback={(resultData) => {

                        if (this.props.onClickCallback) {
                            this.props.onClickCallback(resultData)
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "close-dialog",
                                dialogIndex: selecteUserAddressDialogIndex
                            })
                        }

                    }} isResultLink={false} subNameTitleKey={'address2'} resultClickable={true} searchAllowed={true} gType="gea" timeKey="t" urlEndPoint="ecommerceusers" subInfoKeys={[
                        {
                            "key": "city",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "state",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "country",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "pincode",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "phone",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        }]} />
                </div>


            </div>
        )
    }
}

class ManualUserAddresses extends React.Component {
    constructor(props) {
        super()
        this.userID = props.userID
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

        }
    }
    render() {



        return (
            <div className="">


                <div className="form-group" style={{
                    // padding: '0px !important'
                }}>
                    <ItemSearch nameTitleKey="address1" urlParams={{
                        uid: this.userID
                    }} onClickCallback={(resultData) => {

                        if (this.props.onClickCallback) {
                            this.props.onClickCallback(resultData)
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "close-dialog",
                                dialogIndex: selecteUserAddressDialogIndex
                            })
                        }

                    }} isResultLink={false} subNameTitleKey={'address2'} resultClickable={true} searchAllowed={true} gType="gta" timeKey="t" urlEndPoint="address" subInfoKeys={[
                        {
                            "key": "city",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "state",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "country",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "pincode",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        },
                        {
                            "key": "phone",
                            "map": function (data) {
                                return <>
                                    <b>{data}</b>
                                    <br />
                                </>
                            }
                        }]} />
                </div>


            </div>
        )
    }
}

class AddNewManualOrder extends React.Component {
    constructor(props) {
        super()

        var manualOrderDate = DateTime.local().toFormat("yyyy-LL-dd'T'HH:mm")

        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            c: {},
            allItemPrice: 0.0,
            billingAddressIndex: 0,
            shippingAddressIndex: 0,
            rid: "",
            paymentType: "",
            billingAddress: {},
            shippingAddress: {},

            manualOrderDate: manualOrderDate,

            selectedCustomer: null,

            issuedBy: null,

            customerType: "Ecommerce Platform",
            senderType: "Company",


            couponData: {},
            couponString: "",
            couponApplied: false,
            couponValue: 0,

            walletUseAmount: 0,
            useWallet: false,

            shippingAmount: 0,

        }
    }

    handlePostManualOrder = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Manual Order",
                message: `Failed to add manual order. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Manual Order",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        // this.setState({ isSuccess: true, isError: false }, () => {
        //     // that.getOrderState()
        //     that.onCancelCallback()
        // })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
    }

    removeCoupon = () => {
        this.setState({
            couponData: {},
            couponString: "",
            couponApplied: false,
            couponValue: 0,
        })
    }
    addCouponToOrder = () => {

        if (this.state.couponString.trim().length === 0) {
            helper_function_info_dialog("Apply Coupon", "Please enter your coupon and then try.", true, "Okay", () => { })
            return
        }

        if (!this.state.selectedCustomer) {
            helper_function_info_dialog("Apply Coupon", "Please select a customer first.", true, "Okay", () => { })
            return
        }

        this.setState({
            couponApplied: false,
            isLoading: true,
            isError: false,
            isSuccess: false,
        })

        ACCT = Cookies.get('ACCT')
        networkCallPost(ADMINURL, "manualorder", {}, {}, {
            c: this.state.couponString,
            w: "c",
            a: ecommerce_helper_function_get_cart_total(this.state.c),
            uid: this.state.selectedCustomer._id
        }, (data) => {
            this.setState({
                couponData: data.payload,
                couponApplied: true,
                isLoading: false,
                isError: false,
                isSuccess: true,
            }, () => {
                const couponValue = ecommerce_helper_function_get_coupon_discount_value(this.state.couponData, ecommerce_helper_function_get_cart_total(this.state.c), this.state.shippingAmount)
                this.reCalculateAmounts()


                helper_function_info_dialog("Coupon Applied", `Enjoy discount of ${ReactDOMServer.renderToStaticMarkup(ecommerce_helper_function_get_formatted_price(couponValue))}!`, true, "Okay", () => { })

            })
        }, (response) => {
            this.setState({
                couponString: "",
                couponData: {},
                couponApplied: false,
                couponValue: 0,

                isLoading: false,
                isError: true,
                isSuccess: false,

            }, () => {
                this.reCalculateAmounts()
            })


            helper_function_info_dialog("Apply Coupon", "Coupon not applicable.", true, "Okay", () => { })



        }, (response) => {
            this.setState({
                couponString: "",
                couponData: {},
                couponApplied: false,
                couponValue: 0,

                isLoading: false,
                isError: true,
                isSuccess: false,
            }, () => {
                this.reCalculateAmounts()
            })

            helper_function_info_dialog("Apply Coupon", "Unable to add coupon. Please check your internet connection and try again.", true, "Okay", () => { })

        }, ACCT, "aco", "status")
    }

    reCalculateAmounts = () => {



        // coupon

        const couponValue = ecommerce_helper_function_get_coupon_discount_value(this.state.couponData, ecommerce_helper_function_get_cart_total(this.state.c), this.state.shippingAmount)

        // wallet
        var walletUseAmount = 0
        if (this.state.useWallet) {
            walletUseAmount = ecommerce_helper_function_get_wallet_amount(this.state.walletData.v, this.state.c, this.state.shippingAmount, couponValue)
        }
        this.getShippingCharges()
        this.setState({
            couponValue: couponValue,
            walletUseAmount: walletUseAmount
        })

    }

    getShippingCharges = () => {

        if (!this.state.shippingAddress) {
            // helper_function_info_dialog("Apply Coupon", "Please enter your coupon and then try.", true, "Okay", () => { })
            return
        }

        if (!this.state.selectedCustomer) {
            return
        }

        ACCT = Cookies.get('ACCT')
        networkCallPost(ADMINURL, "manualorder", {}, {}, {
            v: ecommerce_helper_function_get_cart_total(this.state.c),
            w: 0,
            vl: 0,
            a: this.state.shippingAddress._id,
            u: this.state.selectedCustomer._id
        }, (data) => {
            this.setState({
                isLoading: false,
                isError: false,
                isSuccess: true,
                shippingAmount: data.payload
            })

        }, (response) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
                shippingAmount: -1
            })
        }, (response) => {
            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false,
                shippingAmount: -1
            })
        }, ACCT, "gsc", "status")
    }

    postManualOrder = () => {

        /*
            check for errors
        */

        if (this.state.selectedCustomer === null) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Manual Order",
                message: `Please select a customer.`,
                date: new Date(),
                isError: true
            })
            return
        }


        if (this.state.issuedBy === null && this.state.senderType !== "Company") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Manual Order",
                message: `Please select issued by.`,
                date: new Date(),
                isError: true
            })
            return
        }

        if (Object.keys(this.state.c).length === 0) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Manual Order",
                message: `Cart cannot be empty.`,
                date: new Date(),
                isError: true
            })
            return
        }

        // disable dialog close
        fullDialogDispatcher.dispatch({
            actionType: "close-disable"
        })

        var issuedByID = null
        if (this.state.issuedBy) {
            issuedByID = this.state.issuedBy._id
        }
        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "manualorder", {
            cart: { "items": this.state.c },
            paymentType: "online",
            shippingAddress: this.state.shippingAddress,
            billingAddress: this.state.billingAddress,
            ia: this.state.issuedBy,
            allItemPrice: ecommerce_helper_function_get_cart_total(this.state.c) + this.state.shippingAmount - this.state.walletUseAmount - this.state.couponValue,
            ig: false,
            cid: this.state.selectedCustomer._id,
            mt: DateTime.fromFormat(this.state.manualOrderDate, "yyyy-LL-dd'T'HH:mm").toString(),
            cc: true,
            isSendorCompany: this.state.senderType === "Company",
            ibi: issuedByID,

            cp: this.state.couponData._id,
            w: false,
            gn: "",
            ci: ""
        }, {
            params: {
                g: "add",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handlePostManualOrder(response.data)
            })
            .catch(function (error) {
                that.handlePostManualOrder({})
            })
    }

    render() {

        /*
            perpare cart items and also calculate total cart value
        */

        // var cartItems = []

        var cart_list = []

        // var allItemPrice = 0

        for (var i in this.state.c) {
            cart_list.push(this.state.c[i])
            // var itemTotalPrice = parseFloat(parseInt(this.state.c[i].cartQuantity) * parseFloat(this.state.c[i].price.sp))
            // allItemPrice += itemTotalPrice
        }
        /**
         * prepare selected customer
        */

        var selectedCustomer = null
        if (this.state.selectedCustomer !== null) {
            selectedCustomer = <div className="card pb-3">
                <div className="p-3">
                    <b>{this.state.selectedCustomer.f}&nbsp;{this.state.selectedCustomer.l}</b>
                    <br />
                    {this.state.selectedCustomer.ph}
                    <br />
                    {this.state.selectedCustomer.e}
                </div>
                <div className="row p-0 m-0">
                    <div className="col-6 col-xl-6 col-md-6 col-sm-12 col-lg-6">
                        <b>
                            Shipping Address
                        </b>
                        <br />
                        {this.state.shippingAddress.address1}
                        <br />
                        {this.state.shippingAddress.address2}
                        <br />
                        {this.state.shippingAddress.city}
                        <br />
                        {this.state.shippingAddress.state}
                        <br />
                        {this.state.shippingAddress.country}
                        <br />
                        {this.state.shippingAddress.pincode}
                    </div>
                    <div className="col-6 col-xl-6 col-md-6 col-sm-12 col-lg-6">
                        <b>
                            Billing Address
                        </b>
                        <br />
                        {this.state.billingAddress.address1}
                        <br />
                        {this.state.billingAddress.address2}
                        <br />
                        {this.state.billingAddress.city}
                        <br />
                        {this.state.billingAddress.state}
                        <br />
                        {this.state.billingAddress.country}
                        <br />
                        {this.state.billingAddress.pincode}
                    </div>
                </div>
            </div>
        }



        var issuedBy = null
        if (this.state.issuedBy !== null) {
            issuedBy = <div className="card pb-3">
                <div className="p-3">
                    <b>{this.state.issuedBy.firstname}&nbsp;{this.state.issuedBy.lastname}</b>
                    <br />
                    {this.state.issuedBy.phone}
                    <br />
                    {this.state.issuedBy.email}
                </div>
                <div className="row p-0 m-0">
                    <div className="col-6 col-xl-6 col-md-6 col-sm-12 col-lg-6">
                        <b>
                            Address
                        </b>
                        <br />
                        {this.state.issuedBy.address1}
                        <br />
                        {this.state.issuedBy.address2}
                        <br />
                        {this.state.issuedBy.city}
                        <br />
                        {this.state.issuedBy.state}
                        <br />
                        {this.state.issuedBy.country}
                        <br />
                        {this.state.issuedBy.pincode}
                    </div>

                </div>
            </div>
        }

        return (
            <div className="p-2 w-100">


                <div className="row m-0" style={{
                    // padding: '0px !important'
                }}>
                    <div className="col-md-5 col-xl-5 col-sm-11 col-lg-5">
                        <div className="form-group" style={{
                            fontWeight: 'bolder'
                        }}>
                            <label>Manual Order Date</label>
                            <input type="datetime-local" className="form-control" value={this.state.manualOrderDate} onChange={(e) => {
                                this.setState({ manualOrderDate: e.target.value })
                            }} />
                        </div>
                        <span style={{
                            fontWeight: 'bolder'
                        }}>
                            Select Item to add to cart
                        </span>
                        <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {

                            /* check if item already exist or not */

                            if (!resultData) {
                                return
                            }

                            if (resultData._id in this.state.c) {
                                toastsDispatcher.dispatch({
                                    actionType: 'add-toast',
                                    title: "Add Item",
                                    message: <span>Item <b>{resultData.name}</b> already exist in cart.</span>,
                                    date: new Date(),
                                    isSuccess: true
                                })
                                return
                            }

                            /* check if item has enough quantity */

                            if (resultData.quantity === 0) {
                                toastsDispatcher.dispatch({
                                    actionType: 'add-toast',
                                    title: "Add Item",
                                    message: <span>Cannot add item <b>{resultData.name}</b> to cart. Not enough stock.</span>,
                                    date: new Date(),
                                    isError: true
                                })
                                return
                            }

                            // const id = this.state.slider[iid]["_id"]
                            const that = this
                            var tmpCart = this.state.c
                            tmpCart[resultData._id] = {
                                cartQuantity: 1,
                                name: resultData.name,
                                price: resultData.price,
                                quantity: resultData.quantity,
                                sku: resultData.sku,
                                tax: resultData.tax,
                                _id: resultData._id,
                                item: resultData
                            }
                            this.setState({ c: tmpCart }, () => {
                                // console.log(that.state.c)
                                that.reCalculateAmounts()
                            })

                        }} resultClickable={true} searchAllowed={true} searchLimit={5} gType="inv" timeKey="_created" urlEndPoint="inventory" />
                    </div>
                    <div className="col-md-5 col-xl-5 col-sm-11 col-lg-5">
                        <span style={{
                            fontWeight: 'bolder'
                        }}>
                            Cart
                        </span>
                        <div>

                            <ItemSearchTable searchLimit={5} data={cart_list} columns={[
                                {
                                    title: "Name",
                                    key: "name",
                                    map: (x) => {
                                        const item_id = x._id
                                        const idx = item_id
                                        if (!(idx in this.state.c)) {
                                            return null
                                        }

                                        return <span>{x.name}</span>
                                    }
                                },

                                {
                                    title: "SKU",
                                    key: "sku",
                                    map: (x) => {
                                        const item_id = x._id
                                        const idx = item_id
                                        if (!(idx in this.state.c)) {
                                            return null
                                        }

                                        return <span>{x.sku}</span>
                                    }
                                },

                                {
                                    title: "Quantity",
                                    key: "q",
                                    map: (x) => {
                                        const item_id = x._id
                                        const idx = item_id

                                        if (!(idx in this.state.c)) {
                                            return null
                                        }


                                        return <input data-idx={idx} className="form-control" type="number" value={this.state.c[idx].cartQuantity} onChange={(e) => {
                                            if (parseInt(e.target.value) === NaN) {
                                                var tmpCart = this.state.c
                                                tmpCart[idx].cartQuantity = 1
                                                this.setState({ c: tmpCart })
                                                return
                                            }

                                            if (parseInt(e.target.value) <= 0) {
                                                return
                                            }

                                            if (parseInt(e.target.value) > this.state.c[idx].quantity) {
                                                toastsDispatcher.dispatch({
                                                    actionType: 'add-toast',
                                                    title: "Add Item",
                                                    message: <span>Cannot add more item <b>{this.state.c[idx].name}</b> to cart. Not enough stock. Only <b>{this.state.c[idx].quantity}</b> available.</span>,
                                                    date: new Date(),
                                                    isError: true
                                                })
                                                return
                                            }

                                            var tmpCart = this.state.c
                                            tmpCart[idx].cartQuantity = parseInt(e.target.value)
                                            this.setState({ c: tmpCart })
                                        }} />
                                    }
                                },

                                {
                                    title: "Total",
                                    key: "q",
                                    map: (x) => {

                                        const item_id = x._id
                                        const idx = item_id

                                        if (!(idx in this.state.c)) {
                                            return null
                                        }

                                        return <span>
                                            {ecommerce_helper_function_get_formatted_price(parseFloat(parseInt(this.state.c[idx].cartQuantity) * parseFloat(this.state.c[idx].price.sp)))}
                                        </span>
                                    }
                                },

                                {
                                    title: "Actions",
                                    key: "t",
                                    map: (x) => {
                                        const item_id = x._id
                                        const idx = item_id

                                        if (!(idx in this.state.c)) {
                                            return null
                                        }

                                        return <div>
                                            <span title='Delete Item' style={{
                                                cursor: 'pointer'
                                            }} className='' onClick={() => {
                                                var tmpCart = this.state.c
                                                delete tmpCart[idx]
                                                this.setState({ c: tmpCart }, () => {
                                                    this.removeCoupon()
                                                })
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </span>
                                        </div>
                                    }
                                }
                            ]} />


                        </div>
                        <hr />
                        <div className='form-group'>
                            <b>Cart Value</b>
                        </div>
                        <div className='form-group'>
                            {ecommerce_helper_function_get_formatted_price(ecommerce_helper_function_get_cart_total(this.state.c))}
                        </div>
                        <hr />
                        <div className='form-group'>
                            <b>Coupon</b>
                        </div>
                        <div className='form-group'>
                            <input className='form-control' value={this.state.couponString} onChange={(e) => {
                                this.setState({ couponString: e.target.value })
                            }} />
                        </div>
                        {
                            this.state.couponApplied ? <div className='form-group'>
                                -&nbsp;{ecommerce_helper_function_get_formatted_price(this.state.couponValue)}
                            </div> : <></>
                        }
                        <div className='form-group'>
                            <button hidden={this.state.couponApplied} className='btn btn-primary' onClick={this.addCouponToOrder}>
                                Apply
                            </button>

                            <button disabled={this.state.isLoading} hidden={!this.state.couponApplied} className='btn btn-danger' onClick={() => {
                                this.setState({
                                    couponData: {},
                                    couponString: "",
                                    couponApplied: false,
                                    couponValue: 0,
                                })
                            }}>
                                Remove coupon
                            </button>

                        </div>
                        <hr />
                        <div className='form-group'>
                            <b>Shipping</b>
                        </div>
                        <div className='form-group'>
                            {ecommerce_helper_function_get_formatted_price(this.state.shippingAmount)}
                        </div>
                        <hr />
                        <div style={{
                            fontWeight: 'bolder'
                        }}>
                            Total: {ecommerce_helper_function_get_formatted_price(ecommerce_helper_function_get_cart_total(this.state.c) + this.state.shippingAmount - this.state.walletUseAmount - this.state.couponValue)}
                        </div>
                    </div>

                </div>
                <div className="row m-0" style={{
                    // padding: '0px !important'
                }}>
                    <div className="col-md-5 col-xl-5 col-sm-11 col-lg-5">
                        <span style={{
                            fontWeight: 'bolder'
                        }}>
                            Select Customer
                        </span>
                        <div className="form-group">
                            {selectedCustomer}
                        </div>

                        <div className="form-group">
                            <div class="form-check">
                                <input
                                    id="selectCustomerEcommercePlatform"
                                    class="form-check-input"
                                    type="radio"
                                    value="Ecommerce Platform"
                                    checked={this.state.customerType === "Ecommerce Platform"}
                                    onChange={(e) => {
                                        this.setState({ customerType: "Ecommerce Platform" })
                                    }}
                                />
                                <label class="form-check-label" for="selectCustomerEcommercePlatform">
                                    Ecommerce Platform
                                </label>
                            </div>

                            <div class="form-check">
                                <input
                                    disabled={TP !== "owner"}
                                    id="selectCustomerManualUsers"
                                    class="form-check-input"
                                    type="radio"
                                    value="Manual Users"
                                    checked={this.state.customerType === "Manual Users"}
                                    onChange={(e) => {
                                        this.setState({ customerType: "Manual Users" })
                                    }}
                                />
                                <label class="form-check-label" for="selectCustomerManualUsers">
                                    Manual Users
                                </label>
                            </div>
                        </div>
                        <div hidden={!(this.state.customerType === "Ecommerce Platform")}>
                            <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {

                                const selectedCustomer = resultData
                                /* prepare shipping and billing address */
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Select Address",
                                    message: <ManualUserAddresses userID={resultData._id} onClickCallback={(resultData) => {
                                        this.setState({
                                            shippingAddress: resultData,
                                            billingAddress: resultData,
                                            selectedCustomer: selectedCustomer
                                        })
                                    }} />,
                                    dialogIndexCallback: (idx) => {
                                        selecteUserAddressDialogIndex = idx
                                    }
                                })

                            }} isResultLink={false} subNameTitleKey={'l'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="geu" timeKey="" urlEndPoint="ecommerceusers" subInfoKeys={[
                                {
                                    "key": "ph",
                                    "map": function (data) {
                                        return data
                                    }
                                },
                                {
                                    "key": "e",
                                    "map": function (data) {
                                        if (data) {
                                            return <>
                                                <br />
                                                {data}
                                            </>
                                        }
                                        return null
                                    }
                                },
                            ]} />
                        </div>
                        <div hidden={!(this.state.customerType === "Manual Users")}>
                            <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {
                                const selectedCustomer = resultData
                                /* prepare shipping and billing address */
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Select Address",
                                    message: <ManualUserAddresses userID={resultData._id} onClickCallback={(resultData) => {
                                        this.setState({
                                            shippingAddress: resultData,
                                            billingAddress: resultData,
                                            selectedCustomer: selectedCustomer
                                        })
                                    }} />,
                                    dialogIndexCallback: (idx) => {
                                        selecteUserAddressDialogIndex = idx
                                    }
                                })


                            }} isResultLink={false} subNameTitleKey={'l'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="manualusers" subInfoKeys={[
                                {
                                    "key": "ph",
                                    "map": function (data) {
                                        return data
                                    }
                                },
                                {
                                    "key": "e",
                                    "map": function (data) {
                                        if (data) {
                                            return <>
                                                <br />
                                                {data}
                                            </>
                                        }
                                        return null
                                    }
                                },
                            ]} />
                        </div>
                    </div>
                    <div className="col-md-5 col-xl-5 col-sm-11 col-lg-5">


                    </div>
                </div>

                <div className="row m-0" style={{
                    // padding: '0px !important'
                }}>
                    <div className="col-md-5 col-xl-5 col-sm-11 col-lg-5">
                        <span style={{
                            fontWeight: 'bolder'
                        }}>
                            Select Issuer
                        </span>
                        <div className="form-group">
                            {issuedBy}
                        </div>

                        <div className="form-group">
                            <div class="form-check">
                                <input
                                    id="selectSenderCompany"
                                    class="form-check-input"
                                    type="radio"
                                    value="Company"
                                    checked={this.state.senderType === "Company"}
                                    onChange={(e) => {
                                        this.setState({ issuedBy: null, senderType: "Company" })
                                    }}
                                />
                                <label class="form-check-label" for="selectSenderCompany">
                                    Company
                                </label>
                            </div>


                            <div class="form-check">
                                <input
                                    id="selectSenderEcommercePlatform"
                                    class="form-check-input"
                                    type="radio"
                                    value="Ecommerce Platform"
                                    checked={this.state.senderType === "Ecommerce Platform"}
                                    onChange={(e) => {
                                        this.setState({ senderType: "Ecommerce Platform" })
                                    }}
                                />
                                <label class="form-check-label" for="selectSenderEcommercePlatform">
                                    Ecommerce Platform
                                </label>
                            </div>

                            <div class="form-check">
                                <input
                                    disabled={TP !== "owner"}
                                    id="selectSenderManualUsers"
                                    class="form-check-input"
                                    type="radio"
                                    value="Manual Users"
                                    checked={this.state.senderType === "Manual Users"}
                                    onChange={(e) => {
                                        this.setState({ senderType: "Manual Users" })
                                    }}
                                />
                                <label class="form-check-label" for="selectSenderManualUsers">
                                    Manual Users
                                </label>
                            </div>
                        </div>
                        <div hidden={!(this.state.senderType === "Ecommerce Platform")}>
                            <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {

                                /* prepare shipping and billing address */

                                this.setState({

                                    // billingAddress: {
                                    //     firstname: resultData.firstname,
                                    //     lastname: resultData.lastname,
                                    //     company: "",
                                    //     address1: resultData.billingAddressAddress1,
                                    //     address2: resultData.billingAddressAddress2,
                                    //     city: resultData.billingAddressCity,
                                    //     state: resultData.billingAddressState,
                                    //     country: resultData.billingAddressCountry,
                                    //     pincode: resultData.billingAddressPincode,
                                    //     phone: resultData.phone
                                    // },
                                    // shippingAddress: {
                                    //     firstname: resultData.firstname,
                                    //     lastname: resultData.lastname,
                                    //     company: "",
                                    //     address1: resultData.shippingAddressAddress1,
                                    //     address2: resultData.shippingAddressAddress2,
                                    //     city: resultData.shippingAddressCity,
                                    //     state: resultData.shippingAddressState,
                                    //     country: resultData.shippingAddressCountry,
                                    //     pincode: resultData.shippingAddressPincode,
                                    //     phone: resultData.phone
                                    // },

                                    issuedBy: resultData
                                })

                            }} isResultLink={false} subNameTitleKey={'l'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="geu" timeKey="" urlEndPoint="ecommerceusers" subInfoKeys={[
                                {
                                    "key": "ph",
                                    "map": function (data) {
                                        return data
                                    }
                                },
                                {
                                    "key": "e",
                                    "map": function (data) {
                                        if (data) {
                                            return <>
                                                <br />
                                                {data}
                                            </>
                                        }
                                        return null
                                    }
                                },
                            ]} />
                        </div>
                        <div hidden={!(this.state.senderType === "Manual Users")}>
                            <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {

                                /* prepare shipping and billing address */
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Select Address",
                                    message: <UserAddresses userID={resultData._id} onClickCallback={(resultData) => {
                                        this.setState({

                                            // billingAddress: {
                                            //     firstname: resultData.firstname,
                                            //     lastname: resultData.lastname,
                                            //     company: "",
                                            //     address1: resultData.address1,
                                            //     address2: resultData.address2,
                                            //     city: resultData.city,
                                            //     state: resultData.state,
                                            //     country: resultData.country,
                                            //     pincode: resultData.pincode,
                                            //     phone: resultData.phone
                                            // },
                                            // shippingAddress: {
                                            //     firstname: resultData.firstname,
                                            //     lastname: resultData.lastname,
                                            //     company: "",
                                            //     address1: resultData.address1,
                                            //     address2: resultData.address2,
                                            //     city: resultData.city,
                                            //     state: resultData.state,
                                            //     country: resultData.country,
                                            //     pincode: resultData.pincode,
                                            //     phone: resultData.phone
                                            // },

                                            issuedBy: resultData
                                        })
                                    }} />,
                                    dialogIndexCallback: (idx) => {
                                        selecteUserAddressDialogIndex = idx
                                    }
                                })


                            }} isResultLink={false} subNameTitleKey={'l'} searchLimit={5} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="manualusers" subInfoKeys={[
                                {
                                    "key": "ph",
                                    "map": function (data) {
                                        return data
                                    }
                                },
                                {
                                    "key": "e",
                                    "map": function (data) {
                                        if (data) {
                                            return <>
                                                <br />
                                                {data}
                                            </>
                                        }
                                        return null
                                    }
                                },
                            ]} />
                        </div>
                    </div>
                    <div className="col-md-5 col-xl-5 col-sm-11 col-lg-5">


                    </div>
                </div>


                <hr />
                <div className="form-group">
                    <button className="btn btn-primary" disabled={this.state.isLoading} onClick={() => {
                        // this.setState({ allItemPrice: allItemPrice }, () => {
                        this.postManualOrder()
                        // })
                    }}>
                        <span hidden={!this.state.isLoading}>
                            <FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;
                        </span>
                        Create Order
                    </button>
                </div>

            </div>
        )
    }
}

class ManualOrders extends React.Component {
    constructor(props) {
        super()

        var urlString = window.location

        var currURL = new URL(urlString)
        // var show = currURL.searchParams.get("show")
        this.orderFilter = currURL.searchParams.get("status")

        this.state = {


        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <PickupRegistrationButton />
                </div>
                <div className="form-group">
                    View orders from
                </div>

                <div className="form-group">
                    <NavBarData paramKey="show" data={topBarData} />
                </div>
                <div className="form-group m-3">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add New Manual Order",
                            message: <AddNewManualOrder />,
                            dialogWidth: 5
                        })
                    }}>
                        New Order
                    </button>
                </div>
                <div className="form-group">
                    <NavBarData paramKey="status" data={[
                        {
                            "url": "?show=manual&status=all",
                            "title": "All",
                            "paramKey": "all"
                        },
                        {
                            "url": "?show=manual&status=Processing",
                            "title": "Processing",
                            "paramKey": "Processing"
                        },
                        {
                            "url": "?show=manual&status=Cancelled",
                            "title": "Cancelled",
                            "paramKey": "Cancelled"
                        },
                        {
                            "url": "?show=manual&status=Shipped",
                            "title": "Shipped",
                            "paramKey": "Shipped"
                        }
                    ]} />
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="oid" onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        // this.addItem(resultData)
                    }} isResultLink={true} resultLink={'/orders/'} subNameTitleKey={'ba.phone'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="orders" subInfoKeys={[
                        {
                            "key": "c.cart",
                            "map": function (data) {
                                return `${Object.keys(data.items).length} Items(s)`
                            }
                        },
                        {
                            "key": "c",
                            "map": function (data) {
                                return <><br />{<span>{ecommerce_helper_function_get_formatted_price(data.allItemPrice)}</span>}</>
                            }
                        },
                        {
                            "key": "mt",
                            "map": function (data) {
                                return <><br /><span>Order Date: {DateTime.fromISO(data).toLocaleString(DateTime.DATETIME_MED)}</span></>
                            }
                        }

                    ]} smallMessages={["Showing oldest orders first. 10 per page."]} urlParams={{
                        of: this.orderFilter,
                        ch: "manual"
                    }} />
                </div>
            </div>
        )
    }
}


class SubscriptionOrders extends React.Component {
    constructor(props) {
        super()

        var urlString = window.location

        var currURL = new URL(urlString)
        // var show = currURL.searchParams.get("show")
        this.orderFilter = currURL.searchParams.get("status")

        this.state = {


        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <PickupRegistrationButton />
                </div>
                <div className="form-group">
                    View orders from
                </div>

                <div className="form-group">
                    <NavBarData paramKey="show" data={topBarData} />
                </div>
                
                <div className="form-group">
                    <NavBarData paramKey="status" data={[
                        {
                            "url": "?show=subscription&status=all",
                            "title": "All",
                            "paramKey": "all"
                        },
                        {
                            "url": "?show=subscription&status=Processing",
                            "title": "Processing",
                            "paramKey": "Processing"
                        },
                        {
                            "url": "?show=subscription&status=Cancelled",
                            "title": "Cancelled",
                            "paramKey": "Cancelled"
                        },
                        {
                            "url": "?show=subscription&status=Delivered",
                            "title": "Shipped",
                            "paramKey": "Shipped"
                        }
                    ]} />
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="oid" onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        // this.addItem(resultData)
                    }} isResultLink={true} resultLink={'/orders/'} subNameTitleKey={'ba.phone'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="orders" subInfoKeys={[
                        {
                            "key": "c.cart",
                            "map": function (data) {
                                if (data.items) {
                                    return `${Object.keys(data.items).length} Items(s)`
                                }
                                return null
                            }
                        },
                        {
                            "key": "c",
                            "map": function (data) {
                                return <><br />{<span>{ecommerce_helper_function_get_formatted_price(data.allItemPrice)}</span>}</>
                            }
                        },
                        {
                            "key": "mt",
                            "map": function (data) {
                                return <><br /><span>Order Date: {DateTime.fromISO(data).toLocaleString(DateTime.DATETIME_MED)}</span></>
                            }
                        }

                    ]} smallMessages={["Showing oldest orders first. 10 per page."]} urlParams={{
                        of: this.orderFilter,
                        ch: "subscription"
                    }} />
                </div>
            </div>
        )
    }
}

class EcomOrders extends React.Component {
    constructor(props) {
        super()

        this.status = props.status

        var urlString = window.location

        var currURL = new URL(urlString)
        // var show = currURL.searchParams.get("show")
        this.orderFilter = currURL.searchParams.get("status")

    }

    componentWillMount() {

    }
    componentDidMount() {

    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <PickupRegistrationButton />
                </div>
                <div className="form-group">
                    View orders from
                </div>
                <div className="form-group">
                    <NavBarData paramKey="show" data={topBarData} />
                </div>
                <div>
                    <NavBarData paramKey="status" data={[
                        {
                            "url": "?show=ecom&status=all",
                            "title": "All",
                            "paramKey": "all"
                        },
                        {
                            "url": "?show=ecom&status=Processing",
                            "title": "Processing",
                            "paramKey": "Processing"
                        },
                        {
                            "url": "?show=ecom&status=Cancelled",
                            "title": "Cancelled",
                            "paramKey": "Cancelled"
                        },
                        {
                            "url": "?show=ecom&status=Shipped",
                            "title": "Shipped",
                            "paramKey": "Shipped"
                        }
                    ]} />
                </div>
                <div className="form-group" key="ecomorderOrderSearch" >
                    <ItemSearch nameTitleKey="oid" onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        // this.addItem(resultData)
                    }} isResultLink={true} resultLink={'/orders/'} subNameTitleKey={'ba.phone'} subHeadingKeys={["ba.firstname", "ba.lastname"]} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="orders" subInfoKeys={[
                        {
                            "key": "c.cart",
                            "map": function (data) {
                                return `${Object.keys(data.items).length} Items(s)`
                            }
                        },
                        // {
                        //     "key": "r",
                        //     "map": function (data) {
                        //         return <><br />{`Paid Online: ${data.currency} ${parseFloat(data.amount) / 100}`}</>
                        //     }
                        // },
                        {
                            "key": "c",
                            "map": function (data) {
                                if (data.paymentType === "online") {
                                    return <><br /><b>{ecommerce_helper_function_get_formatted_price(data.allItemPrice)}</b></>
                                }
                                if (data.paymentType === "cod") {
                                    return <><br /><b>COD</b></>
                                }
                                return null
                            }
                        }
                    ]} smallMessages={["Showing oldest orders first. 10 per page."]} urlParams={{
                        of: this.orderFilter,
                        ch: "ecom"
                    }} />
                </div>
            </div>
        )
    }
}

class ViewAllOrders extends React.Component {
    constructor(props) {
        super()




        this.state = {

        }
    }

    componentDidUpdate() {

    }

    render() {



        return (
            <div className="p-4">
                <div className="form-group">
                    <PickupRegistrationButton />
                </div>
                <div className="form-group">
                    View orders from
                </div>
                <div className="form-group">
                    <NavBarData paramKey="show" data={topBarData} />
                </div>
                <div className="form-group">
                    <ViewOrders show="all" />
                </div>
            </div>
        )
    }

}

class ChangeOrderState extends React.Component {
    constructor(props) {
        super()

        this.orderID = props.orderID
        this.onCancelCallback = props.onCancelCallback

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            orderState: ""
        }

    }

    componentDidMount() {
        this.getOrderState()
    }

    handleUpdateOrderState = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Order State",
                message: `Unable to update order state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Order State",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            // that.getOrderState()
            that.onCancelCallback()
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
    }

    updateOrderState = (state) => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {
            _id: this.orderID,
            s: state
        }, {
            params: {
                g: "ueo",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateOrderState(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateOrderState({})
            })
    }

    handleGetOrderState = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Order State",
                message: `Unable to get order state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }


        this.setState({ isSuccess: true, isError: false, orderState: response.payload })

    }

    getOrderState = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "gos",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleGetOrderState(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleGetOrderState({})
            })
    }

    render() {

        if (this.state.isError) {

            return (
                <div className="p-2">
                    <span>Some error has occurred. Please try again.</span>
                </div>
            )

        }

        return (
            <div className="p-2">
                <div className="form-group">
                    Current Status:
                </div>
                <div className="form-group">
                    <h4>{this.state.orderState}</h4>
                </div>
                <div className="form-group">
                    <hr />
                </div>
                <div className="form-group">
                    Change Status:
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Processing")
                    }}>
                        <FontAwesomeIcon icon={faWrench} />&nbsp;Processing
                    </button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Ready")
                    }}>
                        <FontAwesomeIcon icon={faCheck} />&nbsp;Ready
                    </button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Packed")
                    }}>
                        <FontAwesomeIcon icon={faBox} />&nbsp;Packed
                    </button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Partly Sent")
                    }}>
                        <FontAwesomeIcon icon={faPaperPlane} />&nbsp;Partly Sent
                    </button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Sent")
                    }}>
                        <FontAwesomeIcon icon={faPaperPlane} />&nbsp;Sent
                    </button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Returned")
                    }}>
                        <FontAwesomeIcon icon={faUndo} />&nbsp;Returned
                    </button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary order-status-change-button" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Delivered")
                    }}>
                        <FontAwesomeIcon icon={faDoorOpen} />&nbsp;Delivered
                    </button>
                </div>
            </div>
        )
    }
}

class EmailContactCustomer extends React.Component {
    constructor(props) {
        super()
        this.orderID = props.orderID
        this.onSuccessCallback = props.onSuccessCallback

        this.state = {
            subject: "",
            content: "",

            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }


    handleSendEmail = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to send customer email")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Send Customer Email",
                message: `Unable to send customer email. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Send Customer Email",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            // that.getOrderState()
            that.onSuccessCallback(response.payload)
        })

    }

    sendEmail = (state) => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {
            e: this.orderID,
            s: this.state.subject,
            m: this.state.content
        }, {
            params: {
                g: "sce",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSendEmail(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleSendEmail({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="emailContactSubject">Subject</label>
                    <input className="form-control" id="emailContactSubject" value={this.state.subject} onChange={(e) => {
                        this.setState({ subject: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <label htmlFor="emailContactContent">Message</label>
                    <ReactQuill id="emailContactContent" value={this.state.content} onChange={(value) => {
                        this.setState({ content: value })
                    }} />
                </div>

                <div className="form-group">
                    <button disabled={this.state.isLoading} className="btn btn-primary" onClick={this.sendEmail}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Send</button>
                </div>
            </div>
        )
    }
}

class ContactCustomer extends React.Component {
    constructor(props) {
        super()
        this.orderID = props.orderID
        this.onSuccessCallback = props.onSuccessCallback
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Email Customer",
                            message: <EmailContactCustomer orderID={this.orderID} onSuccessCallback={(response) => {

                                fullDialogDispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Email Customer",
                                    message: <span>{response}.</span>,
                                    isSuccess: true
                                })

                            }} />
                        })
                    }}>Email</button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary">SMS</button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary">App</button>
                </div>
            </div>
        )
    }
}

class OrderHistory extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            history: []
        }

        this.orderID = props.orderID
    }

    render() {
        return (
            <div className="p-2">
                <ItemSearch nameTitleKey="ty" onClickCallback={(resultData) => {
                    // const id = this.state.slider[iid]["_id"]
                    // this.addItem(resultData)
                }} isResultLink={false} subHeadingKeys={["f"]} resultClickable={false} subNameTitleKey={'l'} searchAllowed={false} gType="goh" timeKey="t" urlParams={{
                    id: this.orderID
                }} urlEndPoint="orders" subInfoKeys={[
                    {
                        "key": "sb",
                        "map": function (data) {
                            return <span>State was: {data}<br /></span>
                        }
                    },
                    {
                        "key": "sa",
                        "map": function (data) {
                            return <span>State is:&nbsp;<b>{data}</b></span>
                        }
                    }
                ]} smallMessages={["Showing recent history first. 10 per page."]} />
            </div>
        )
    }
}

class CancelOrder extends React.Component {
    constructor(props) {
        super()

        this.orderID = props.orderID
        this.onCancelCallback = props.onCancelCallback
        this.state = {
            isError: false,
            isLoading: false,
            isSuccess: false,
        }
    }

    handleUpdateOrderState = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Order State",
                message: `Unable to update order state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Order State",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            this.onCancelCallback()
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
    }

    updateOrderState = (state) => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {
            _id: this.orderID,
            s: state
        }, {
            params: {
                g: "ueo",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateOrderState(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateOrderState({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Are you sure you want to cancel the order?
                </div>
                <div className="form-group ">
                    <button className="m-1 btn btn-primary" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>
                        Close
                    </button>
                    <button disabled={this.state.isLoading} className="m-1 btn btn-danger" onClick={() => {
                        this.updateOrderState("Cancelled")
                    }}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span> Cancel Order
                    </button>

                </div>
            </div>
        )
    }
}

class ShipOrder extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.orderID = props.orderID
        this.onCancelCallback = props.onCancelCallback

        var pickupDateTime = DateTime.local().plus({ day: 1 }).toFormat("yyyy-LL-dd'T'HH:mm")

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            pickupDateTime: pickupDateTime,
            pieceCount: 1,
            actualWeight: 0.5,
            dimensionLength: 1,
            dimensionBreadth: 1,
            dimensionHeight: 1,
            dimensionCount: 1,

        }
    }

    handleUpdateOrderState = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Order State",
                message: `Unable to update order state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Order State",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            this.onCancelCallback()
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
    }

    updateOrderState = (state) => {

        /* prepare ship params data */

        var ecomParams = {
            p: this.state.pieceCount,
            w: this.state.actualWeight,
            l: this.state.dimensionLength,
            b: this.state.dimensionBreadth,
            h: this.state.dimensionHeight,
            c: this.state.dimensionCount,
            t: DateTime.fromFormat(this.state.pickupDateTime, "yyyy-LL-dd'T'HH:mm").toString()
        }


        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {
            _id: this.orderID,
            s: state,
            e: ecomParams
        }, {
            params: {
                g: "ueo",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateOrderState(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateOrderState({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Shipping Parameters
                </div>
                <div className="form-group">
                    <label>Pick up date and time</label>
                    <input type="datetime-local" className="form-control" value={this.state.pickupDateTime} onChange={(e) => {
                        this.setState({ pickupDateTime: e.target.value })
                    }} />
                    <small>Automatic date is current date plus 24 hours.</small>
                </div>
                <div className="form-group">
                    <label>Piece Count</label>
                    <input type="number" className="form-control" value={this.state.pieceCount} onChange={(e) => {
                        var tmpCount = 0
                        try {
                            tmpCount = parseInt(e.target.value)
                        }
                        catch (e) {
                            return
                        }

                        this.setState({ pieceCount: tmpCount })
                    }} />
                </div>
                <div className="form-group">
                    <label>Actual Weight (KG)</label>
                    <input type="number" className="form-control" value={this.state.actualWeight} onChange={(e) => {
                        var tmpActualWeight = 0
                        try {
                            tmpActualWeight = parseFloat(e.target.value)
                        }
                        catch (e) {
                            return
                        }

                        this.setState({ actualWeight: tmpActualWeight })
                    }} />
                </div>
                <div className="form-group">
                    <label>Dimension Length (cm)</label>
                    <input type="number" className="form-control" value={this.state.dimensionLength} onChange={(e) => {
                        var tmpDimensionLength = 0
                        try {
                            tmpDimensionLength = parseFloat(e.target.value)
                        }
                        catch (e) {
                            return
                        }

                        this.setState({ dimensionLength: tmpDimensionLength })
                    }} />
                </div>
                <div className="form-group">
                    <label>Dimension Breadth (cm)</label>
                    <input type="number" className="form-control" value={this.state.dimensionBreadth} onChange={(e) => {
                        var tmpDimensionBreadth = 0
                        try {
                            tmpDimensionBreadth = parseFloat(e.target.value)
                        }
                        catch (e) {
                            return
                        }

                        this.setState({ dimensionBreadth: tmpDimensionBreadth })
                    }} />
                </div>
                <div className="form-group">
                    <label>Dimension Height (cm)</label>
                    <input type="number" className="form-control" value={this.state.dimensionHeight} onChange={(e) => {
                        var tmpDimensionHeight = 0
                        try {
                            tmpDimensionHeight = parseFloat(e.target.value)
                        }
                        catch (e) {
                            return
                        }

                        this.setState({ dimensionHeight: tmpDimensionHeight })
                    }} />
                </div>
                <div className="form-group">
                    <label>Dimension Count</label>
                    <input type="number" className="form-control" value={this.state.dimensionCount} onChange={(e) => {
                        var tmpDimensionCount = 0
                        try {
                            tmpDimensionCount = parseInt(e.target.value)
                        }
                        catch (e) {
                            return
                        }

                        this.setState({ dimensionCount: tmpDimensionCount })
                    }} />
                </div>
                <div className="form-group">
                    Are you sure you want to SHIP the order?
                </div>
                <div className="form-group ">
                    <button className="m-1 btn btn-primary" disabled={this.state.isLoading} onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>
                        Close
                    </button>
                    <button className="m-1 btn btn-success" disabled={this.state.isLoading} onClick={() => {
                        this.updateOrderState("Shipped")
                    }}>
                        <span hidden={!this.state.isLoading}>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </span>
                        &nbsp;Ship Order
                    </button>

                </div>
            </div>
        )
    }
}

class PrintShippingLabel extends React.Component {
    constructor(props) {
        super()

        this.orderID = props.orderID
        this.orderData = props.orderData
        this.onCancelCallback = props.onCancelCallback
        this.state = {
            isError: false,
            isLoading: false,
            isSuccess: false,
        }
    }


    handlePrint = (e) => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "peo",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {

                that.setState({ isLoading: false })

                if (!("status" in response.data) || !("payload" in response.data)) {
                    alert("Unable to print shipping label")
                    that.setState({ isSuccess: false, isError: true })
                    return
                }
                if (response.data["status"] !== "success") {
                    toastsDispatcher.dispatch({
                        actionType: 'add-toast',
                        title: "Print Shipping Label",
                        message: `Print shipping label. ${response.data.payload}`,
                        date: new Date(),
                        isError: true
                    })
                    return
                }

                if (response["data"]["status"] === "success") {
                    var WinPrint = window.open('', 'proone', 'width=800px,height=500px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    setTimeout(() => {

                        WinPrint.focus();
                        // WinPrint.print();
                        // WinPrint.close();
                    }, 500)
                }
            })
            .catch(function (error) {
                that.setState({ isLoading: false })
                console.log(error)
                alert("failed to print. Contact website administrator.")
            })
    }

    handlePDFPrint = (e) => {


        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "peo",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                if (response["data"]["status"] === "success") {
                    const doc = new jsPDF(
                        {
                            orientation: 'portrait',
                            unit: 'px',
                            format: [1122.51, 794]
                        }
                    );

                    doc.html(response["data"]["payload"])
                        .then(() => {
                            var fileName = `ship-label-${that.orderData.oid}.pdf`

                            doc.save(fileName)
                            that.setState({ isPDFLoading: false })
                        });

                }
            })
            .catch(function (error) {
                console.log(error)
                alert("failed to get PDF. Contact website administrator.")
            })


    }



    render() {
        return (
            <div className="p-2">
                <div className="form-group ">
                    <button disabled={this.state.isLoading} className="m-1 btn btn-primary" onClick={() => {
                        this.handlePrint()
                    }}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span> Print
                    </button>
                    {/* <button className="m-1 btn btn-primary" onClick={() => {
                        this.handlePDFPrint()
                    }}>
                        PDF
                    </button> */}

                </div>
            </div>
        )
    }
}

class CancelWaybill extends React.Component {
    constructor(props) {
        super()

        this.orderID = props.orderID
        this.orderData = props.orderData
        this.onCancelCallback = props.onCancelCallback
        this.state = {
            isError: false,
            isLoading: false,
            isSuccess: false,
        }
    }


    handleUpdateOrderState = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Order State",
                message: `Unable to update order state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Order State",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })
        this.setState({ isSuccess: true, isError: false }, () => {
            this.onCancelCallback()
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
    }

    updateOrderState = (state) => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {
            _id: this.orderID,
            s: state
        }, {
            params: {
                g: "ueo",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleUpdateOrderState(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleUpdateOrderState({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group ">
                    <button disabled={this.state.isLoading} className="m-1 btn btn-primary" onClick={() => {
                        this.updateOrderState("CancelWaybill")
                    }}>
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span> Cancel Waybill
                    </button>

                </div>
            </div>
        )
    }
}

class PrintInvoice extends React.Component {
    constructor(props) {
        super()

        this.orderID = props.orderID
        this.orderData = props.orderData
        this.onCancelCallback = props.onCancelCallback
        this.state = {

        }
    }


    handlePrint = (e) => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "pei",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                if (response["data"]["status"] === "success") {
                    var WinPrint = window.open('', 'proone', 'width=800px,height=500px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    setTimeout(() => {

                        WinPrint.focus();
                        // WinPrint.print();
                        // WinPrint.close();
                    }, 500)
                }
            })
            .catch(function (error) {
                console.log(error)
                alert("failed to print. Contact website administrator.")
            })
    }

    handlePDFPrint = (e) => {

        ACCT = Cookies.get('ACCT')
        networkCallGetBlob(ADMINURL, "orders", {
            id: this.orderID
        }, {}, (response) => {
           
            let headerLine = response.headers['content-disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
    
        }, (response) => {
            
        }, (response) => {
            
        }, ACCT, "pip", "status")


    }



    render() {
        return (
            <div className="p-2">
                <div className="form-group ">
                    <button className="m-1 btn btn-primary" onClick={() => {
                        this.handlePrint()
                    }}>
                        Print
                    </button>
                    <button className="m-1 btn btn-primary" onClick={() => {
                        this.handlePDFPrint()
                    }}>
                        PDF
                    </button>

                </div>
            </div>
        )
    }
}

class ViewCouponInfo extends React.Component {
    constructor(props) {
        super()

        this.data = props.data
        console.log(this.data)
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Coupon values at the time of order.
                </div>
                <div className="form-group">
                    <label><b>Coupon</b></label>
                    <br />
                    <span>{this.data.cd.c}</span>
                </div>
                <div className="form-group">
                    <label><b>Amount</b></label>
                    <br />
                    <span>{this.data.cd.a}</span>
                </div>
                <div className="form-group">
                    <label><b>Up To Amount</b></label>
                    <br />
                    <span>{this.data.cd.ua}</span>
                </div>
                <div className="form-group">
                    <label><b>Discount Type</b></label>
                    <br />
                    <span hidden={!(this.data.cd.dt === "%")}>Percentage</span>
                    <span hidden={!(this.data.cd.dt === "d")}>Direct</span>
                </div>
                <div className="form-group">
                    <label><b>Coupon Type</b></label>
                    <br />
                    <span hidden={!(this.data.cd.ct === "d")}>Discount</span>
                    <span hidden={!(this.data.cd.ct === "l")}>Less</span>
                </div>
                <div className="form-group">
                    <label><b>Validity From</b></label>
                    <br />
                    <span>{DateTime.fromISO(this.data.cd.vf).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
                </div>
                <div className="form-group">
                    <label><b>Validity To</b></label>
                    <br />
                    <span>{DateTime.fromISO(this.data.cd.v).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
                </div>
                <div className="form-group">
                    <label><b>Is Lifetime Valid?</b></label>
                    <br />
                    <span hidden={!(this.data.cd.vl)}>Yes</span>
                    <span hidden={(this.data.cd.vl)}>No</span>
                </div>
                <div className="form-group">
                    <label><b>Is Voucher For Wallet?</b></label>
                    <br />
                    <span hidden={!(this.data.cd.w)}>Yes</span>
                    <span hidden={(this.data.cd.w)}>No</span>
                </div>
                <div className="form-group">
                    <label><b>Is just for shipping?</b></label>
                    <br />
                    <span hidden={!(this.data.cd.s)}>Yes</span>
                    <span hidden={(this.data.cd.s)}>No</span>
                </div>
                <div className="form-group">
                    <label><b>Entire shipping value off?</b></label>
                    <br />
                    <span hidden={!(this.data.cd.as)}>Yes</span>
                    <span hidden={(this.data.cd.as)}>No</span>
                </div>
                <div className="form-group">
                    <label><b>Valid Amount</b></label>
                    <br />
                    <span>{this.data.cd.va}</span>
                </div>
                <div className="form-group">
                    <label><b>Max Usage</b></label>
                    <br />
                    <span>{this.data.cd.us}</span>
                    <span hidden={!(this.data.cd.us) === 0}>&nbsp;(Unlimited)</span>
                </div>
                <div className="form-group">
                    <label><b>Max Overall Usage</b></label>
                    <br />
                    <span>{this.data.cd.ou}</span>
                    <span hidden={!(this.data.cd.ou) === 0}>&nbsp;(Unlimited)</span>
                </div>
                <div className="form-group">
                    <label><b>Description</b></label>
                    <br />
                    <span style={{
                        whiteSpace: 'pre-wrap'
                    }}>{this.data.cd.d}</span>
                </div>
                <div className="form-group">
                    <label><b>Coupon Enabled</b></label>
                    <br />
                    <span hidden={!(this.data.cd.e)}>Yes</span>
                    <span hidden={(this.data.cd.e)}>No</span>
                </div>
            </div>
        )
    }
}

class ViewPincodeInfo extends React.Component {
    constructor(props) {
        super()
        this.orderID = props.orderID
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            data: null
        }
    }

    handleFetchPincodeInfo = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get pincode service details")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Pincode Service Details",
                message: `Unable to get pincode service details. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ data: response.payload, isSuccess: true })
    }

    fetchPincodeInfo = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "opi",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchPincodeInfo(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchPincodeInfo({})
            })
    }

    componentDidMount() {
        this.fetchPincodeInfo()
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        if (this.state.isSuccess) {

            /* prepare holiday data */
            var holidaysTRs = []
            const holidaysData = this.state.data["BlueDartHolidays"]
            if ("Holiday" in holidaysData) {
                for (var i in holidaysData["Holiday"]) {
                    holidaysTRs.push(
                        <tr>
                            <td>
                                {holidaysData["Holiday"][i].Description}
                            </td>
                            <td>
                                {DateTime.fromISO(holidaysData["Holiday"][i].HolidayDate).toLocaleString(DateTime.DATE_MED)}
                            </td>
                        </tr>
                    )
                }
            }

            /* prepare data */

            var TRs = []

            for (var i in this.state.data) {
                if (i === "BlueDartHolidays") {
                    continue
                }
                if (i === "XMLName") {
                    continue
                }

                TRs.push(
                    <tr>
                        <td>{i}</td>
                        <td>{this.state.data[i]}</td>
                    </tr>
                )
            }


            return (
                <div className="p-2">
                    <div className="form-group">
                        Holidays
                    </div>
                    <div className="form-group">
                        <div className="table-responsive">
                            <table className="table">
                                {holidaysTRs}
                            </table>
                        </div>
                    </div>
                    <div className="form-group">
                        Other Info
                    </div>
                    <div className="form-group">
                        <div className="table-responsive">
                            <table className="table">
                                {TRs}
                            </table>
                        </div>
                    </div>
                </div>
            )
        }


        if (this.state.isError) {
            return (
                <div className="p-2 text-danger">
                    Failed to fetch pincode service data.
                </div>
            )
        }

        return null
    }
}

class ViewPaymentInfo extends React.Component {
    constructor(props) {
        super()
        this.orderID = props.orderID
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            data: null
        }
    }

    handleFetchPaymentInfo = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get payment details")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Payment Details",
                message: `Unable to get payment details. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ data: response.payload, isSuccess: true })
    }

    fetchPaymentInfo = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "opy",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchPaymentInfo(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchPaymentInfo({})
            })
    }

    componentDidMount() {
        this.fetchPaymentInfo()
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        if (this.state.isSuccess) {

            /* prepare data */

            var TRs = []

            for (var i in this.state.data) {
                if (i === "_id") {
                    continue
                }
                if (i === "uid") {
                    continue
                }

                if (i === "t") {
                    continue
                }

                if (i === "created_at") {
                    TRs.push(
                        <tr>
                            <td>{i}</td>
                            <td>{DateTime.fromSeconds(this.state.data[i]).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</td>
                        </tr>
                    )
                    continue
                }

                TRs.push(
                    <tr>
                        <td>{i}</td>
                        <td>{this.state.data[i]}</td>
                    </tr>
                )
            }


            return (
                <div className="p-2">
                    <div className="form-group">
                        <div className="table-responsive">
                            <table className="table">
                                {TRs}
                            </table>
                        </div>
                    </div>
                </div>
            )
        }


        if (this.state.isError) {
            return (
                <div className="p-2 text-danger">
                    Failed to fetch pincode service data.
                </div>
            )
        }

        return null
    }
}

class AWBInformation extends React.Component {
    constructor(props) {
        super()
        this.shipmentData = props.shipmentData
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <div className='form-group'>
                    AWB: <b>{this.shipmentData.awb}</b>
                </div>
                <div className='form-group'>
                    Channel: {this.shipmentData.c}
                </div>
                <hr />
                <div className='form-group'>
                    Courier: <b>{this.shipmentData.cd.name}</b>
                </div>
                <div className='form-group'>
                    Charges: {ecommerce_helper_function_get_formatted_price(this.shipmentData.cd.charge * 100)}
                </div>

            </div>
        )
    }
}

class ViewSingleOrder extends React.Component {
    constructor(props) {
        super()

        this.orderID = props.orderID
        this.history = props.history
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            order: {},
            shippingData: {}
        }
    }


    handleFetchOrder = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get order")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Order",
                message: `Unable to get order. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        this.setState({ order: response.payload.e, shippingData: response.payload.s, isSuccess: true })
    }

    fetchOrder = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "orders", {
            params: {
                g: "sgl",
                id: this.orderID,
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchOrder(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchOrder({})
            })
    }

    componentDidMount() {
        this.fetchOrder()
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin /> Loading
                </div>
            )
        }
        if (this.state.isSuccess) {

            /**
             * Process payment status
             */
            var paymentStatus = <span>&#8212;</span>

            if (String(this.state.order.c.paymentType).toUpperCase() === "ONLINE") {
                if (this.state.order.r.amount === this.state.order.r.amount_paid) {
                    paymentStatus = <span className="text-success">PAID</span>
                } else if (this.state.order.r.amount_paid > 0) {
                    paymentStatus = <span className="text-warning">Partially Paid | Paid: {this.state.order.r.amount_paid}</span>
                }
            }



            /**
             * prepare cart data
             */

            var cartData = []

            for (var i in this.state.order.c.cart.items) {
                console.log(this.state.order.c.cart.items[i])
                cartData.push(this.state.order.c.cart.items[i])
            }



            return (
                <div className="p-4">
                    <div className="form-group">
                        <span className="btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.history.goBack();
                        }}><FontAwesomeIcon icon={faArrowLeft} /> Back</span>
                        {/* <Link to="/orders"><FontAwesomeIcon icon={faArrowLeft} /> Orders</Link> */}
                    </div>
                    <div className="form-group">
                        <h4>Order ID: {this.state.order.oid}</h4>
                    </div>
                    <div className="form-group" hidden={this.state.order.os !== "Cancelled"}>
                        <h4 className="text-danger">Order has been cancelled.</h4>
                    </div>
                    <div className="form-group" hidden={this.state.order.os !== "Shipped"}>
                        <h4 className="text-primary"><u>Order State has been set to shipped.</u></h4>
                    </div>
                    <div className="form-group" hidden={this.state.order.os !== "Shipped"}>
                        <h4 className="text-primary"><u>AWB:&nbsp;
                            {/* {this.state.shippingData.c === "shiprocket" ? this.state.shippingData.r.awb_code : null} */}
                            {this.state.shippingData.awb}
                        </u></h4>
                        <small><span className='btn-link' onClick={() => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "AWB Information",
                                message: <AWBInformation shipmentData={this.state.shippingData} />
                            })
                        }}>View Details</span></small>

                    </div>

                    <div className="form-group" hidden={this.state.order.os !== "Shipped"}>
                        <h4 className="text-primary"><u>Channel: {this.state.shippingData.c}</u></h4>
                    </div>

                    <div className="form-group" hidden={this.state.order.os !== "Shipped"}>
                        <h4 className="text-primary">Ship Time: {DateTime.fromISO(this.state.shippingData.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</h4>
                    </div>
                    <div className="form-group">
                        <h4 className="text-primary">Current State:&nbsp;<u>{this.state.order.os}</u></h4>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-12 ">
                            <div className="form-group">
                                <button onClick={this.fetchOrder} className="btn btn-primary m-1">Refresh</button>
                                <button disabled={this.state.order.os !== "Shipped"} className="btn btn-primary m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Print Shipping Label",
                                        message: <PrintShippingLabel orderData={this.state.order} orderID={this.state.order._id} onCancelCallback={this.fetchOrder} />
                                    })
                                }}>Print Shipping Label</button>
                                <button disabled={this.state.order.os !== "Shipped"} className="btn btn-danger m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Cancel Shipping",
                                        message: <CancelWaybill orderData={this.state.order} orderID={this.state.order._id} onCancelCallback={this.fetchOrder} />
                                    })
                                }}>Cancel Shipping</button>
                                <button disabled={this.state.order.os === "Delivered" || this.state.order.os === "Shipped" || this.state.order.os === "Cancelled"} className="btn btn-primary m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Ship Order",
                                        message: <ShipOrder orderID={this.state.order._id} onCancelCallback={this.fetchOrder} data={this.state.order} />
                                    })
                                }}>Ship</button>
                                <button className="btn btn-primary m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Print Invoice",
                                        message: <PrintInvoice orderData={this.state.order} orderID={this.state.order._id} onCancelCallback={this.fetchOrder} />
                                    })
                                }}>Print Invoice</button>
                                <button className="btn btn-primary m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Contact Customer",
                                        message: <ContactCustomer orderID={this.state.order._id} />
                                    })
                                }}>Contact</button>
                                <button className="btn btn-primary m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Order History",
                                        message: <OrderHistory orderID={this.state.order._id} />
                                    })
                                }}>History</button>
                                <button disabled={this.state.order.os === "Delivered" || this.state.order.os === "Cancelled"} className="btn btn-success m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Change Order Status",
                                        message: <ChangeOrderState orderID={this.state.order._id} onCancelCallback={this.fetchOrder} />
                                    })
                                }}>Change Order Status</button>
                                <button disabled={this.state.order.os === "Delivered" || this.state.order.os === "Cancelled"} className="btn btn-danger m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: 'show-dialog',
                                        title: "Cancel Order?",
                                        message: <CancelOrder orderID={this.state.order._id} onCancelCallback={this.fetchOrder} />
                                    })
                                }}>Cancel Order</button>
                            </div>
                            <div className="form-group">
                                <span>
                                    <b>
                                        Order Time
                                    </b>
                                </span>
                                <br />
                                <span>
                                    {DateTime.fromISO(this.state.order.t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                                </span>
                            </div>
                            <div className="form-group" hidden={!(this.state.order.ch === "manual")}>
                                <span>
                                    <b>
                                        Manual Order Time
                                    </b>
                                </span>
                                <br />
                                <span>
                                    {DateTime.fromISO(this.state.order.mt).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                                </span>
                            </div>
                            <div className="form-group ml-0 pl-0 col-xl-8 col-lg-8 col-sm-12 col-8 d-flex flex-row">
                                <div className="p-2">
                                    <div className="form-group">
                                        <span><b>Billing Address</b></span>
                                    </div>
                                    <div className="form-group">
                                        <span>{this.state.order.ba.firstname.capitalize()}&nbsp;{this.state.order.ba.lastname.capitalize()}</span>
                                        <br />
                                        <span>{this.state.order.ba.address1}</span>
                                        <br />
                                        <span>{this.state.order.ba.address2}</span>
                                        <br />
                                        <span>{this.state.order.ba.city}</span>
                                        <br />
                                        <span>{this.state.order.ba.state}</span>
                                        <br />
                                        <span>{this.state.order.ba.country},&nbsp;{this.state.order.ba.pincode}</span>
                                        <br />
                                        <span>{this.state.order.ba.phone}</span>

                                    </div>
                                </div>
                                <div className=" p-2">
                                    <div className="form-group">
                                        <span><b>Shipping Address</b></span>
                                    </div>
                                    <div className="form-group">
                                        <span>{this.state.order.sa.firstname.capitalize()}&nbsp;{this.state.order.sa.lastname.capitalize()}</span>
                                        <br />
                                        <span>{this.state.order.sa.address1}</span>
                                        <br />
                                        <span>{this.state.order.sa.address2}</span>
                                        <br />
                                        <span>{this.state.order.sa.city}</span>
                                        <br />
                                        <span>{this.state.order.sa.state}</span>
                                        <br />
                                        <span>{this.state.order.sa.country},&nbsp;{this.state.order.sa.pincode}</span>
                                        <br />
                                        <span>{this.state.order.sa.phone}</span>

                                    </div>
                                </div>
                                {
                                    this.state.order.ia ?
                                        < div className=" p-2">
                                            <div className="form-group">
                                                <span><b>Issued By</b></span>
                                            </div>
                                            <div className="form-group">
                                                <span>{this.state.order.ia.firstname.capitalize()}&nbsp;{this.state.order.ia.lastname.capitalize()}</span>
                                                <br />
                                                <span>{this.state.order.ia.address1}</span>
                                                <br />
                                                <span>{this.state.order.ia.address2}</span>
                                                <br />
                                                <span>{this.state.order.ia.city}</span>
                                                <br />
                                                <span>{this.state.order.ia.state}</span>
                                                <br />
                                                <span>{this.state.order.ia.country},&nbsp;{this.state.order.ia.pincode}</span>
                                                <br />
                                                <span>{this.state.order.ia.phone}</span>

                                            </div>
                                        </div>
                                        : null
                                }

                            </div>
                            {/* <div className="form-group" hidden={this.state.order.ch === "manual"}>
                                <span><b>Payment Information</b></span>
                                <br />
                                <span>Payment Type: <b><i>{String(this.state.order.c.paymentType).toUpperCase()}</i></b></span>
                                <br />
                                <span>Order Total: INR&nbsp;<b>{String(this.state.order.c.allItemPrice).toUpperCase()}</b></span>
                                <br />
                                <span>Payment Status: {paymentStatus}</span>
                                <br />
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }}>
                                    Click to view Full Payment Info
                                </span>
                            </div> */}
                            <div className="form-group">
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Pincode Service Details",
                                        message: <ViewPincodeInfo orderID={this.orderID} />
                                    })
                                }}>
                                    Click to view shipping address pincode service details
                                </span>
                            </div>
                            <div className="form-group" hidden={!(this.state.order.ch === "ecom")}>
                                <span><b>Payment Information</b></span>
                                <br />
                                <span>Payment Type: <b><i>{String(this.state.order.c.paymentType).toUpperCase()}</i></b></span>
                                <br />
                                <span>Order Total: INR&nbsp;<b>{ecommerce_helper_function_get_formatted_price(this.state.order.c.allItemPrice)}</b></span>
                                <br />
                                <span>Payment Status: {paymentStatus}</span>
                                <br />
                                <span>Wallet Used: <span hidden={!this.state.order.w} className='text-success'>YES</span><span hidden={this.state.order.w} className=''>NO</span></span>
                                <br />
                                <span>Paid Through Wallet: INR&nbsp;<b>{ecommerce_helper_function_get_formatted_price(this.state.order.wp)}</b></span>
                                <br />
                                {/* <span hidden={!(String(this.state.order.c.paymentType).toUpperCase() === "ONLINE")} className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Payment Details",
                                        message: <ViewPaymentInfo orderID={this.orderID} />
                                    })
                                }} > */}
                                <span hidden={false} className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Payment Details",
                                        message: <ViewPaymentInfo orderID={this.orderID} />
                                    })
                                }} >
                                    Click to view full payment info
                                </span>
                            </div>
                            <div className="form-group">
                                <span><b>Customer Note</b></span>
                                <p style={{
                                    whiteSpace: 'pre-wrap',
                                    maxHeight: '200px',
                                    overflowY: 'scroll'
                                }}>
                                    {String(this.state.order.c.ci)}
                                </p>
                            </div>
                            <div className="form-group">
                                <span><b>Is this order gift?</b></span>
                                <p style={{
                                    whiteSpace: 'pre-wrap',
                                    maxHeight: '200px',
                                    overflowY: 'scroll'
                                }}>
                                    <span hidden={!(this.state.order.c.ig === true)} className="text-success">Yes</span>
                                    <span hidden={(this.state.order.c.ig === true)} className="">No</span>
                                </p>
                            </div>
                            <div className="form-group">
                                <span><b>Gift Note</b></span>
                                <p style={{
                                    whiteSpace: 'pre-wrap',
                                    maxHeight: '200px',
                                    overflowY: 'scroll'
                                }} hidden={!(this.state.order.c.ig === true)}>
                                    {String(this.state.order.c.gn)}
                                </p>
                            </div>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12 ">
                            <div className="form-group">
                                <span>
                                    <b>
                                        Total Cart Value:
                                    </b>
                                </span>

                            </div>
                            <div className="form-group ">
                                {ecommerce_helper_function_get_formatted_price(ecommerce_helper_function_get_cart_total(this.state.order.c.cart.items))}
                            </div>
                            <div className="form-group">
                                <span>
                                    <b>
                                        Total Order Value:
                                    </b>
                                </span>
                            </div>
                            <div className="form-group ">
                                {ecommerce_helper_function_get_formatted_price(this.state.order.c.allItemPrice)}
                            </div>
                            <div className="form-group">
                                <span>
                                    <b>
                                        Total Discount Value:
                                    </b>
                                </span>
                            </div>
                            <div className="form-group ">
                                {ecommerce_helper_function_get_formatted_price(this.state.order.c.da)}
                            </div>
                            <div className="form-group">
                                <span>
                                    <b>
                                        Shipping Value:
                                    </b>
                                </span>
                            </div>
                            <div className="form-group ">
                                {ecommerce_helper_function_get_formatted_price(this.state.order.s.a)}
                            </div>
                            <div className="form-group">
                                <span>
                                    <b>
                                        Coupon
                                    </b>
                                </span>
                            </div>
                            <div className="form-group">
                                <span style={{
                                    cursor: 'pointer'
                                }} className="btn-link" hidden={this.state.order.cid === "000000000000000000000000"} onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Coupon Info",
                                        message: <ViewCouponInfo data={this.state.order} />
                                    })
                                }}>
                                    View Coupon Info
                                </span>
                                <span hidden={!(this.state.order.cid === "000000000000000000000000")}>No coupon was applied</span>
                            </div>
                            <div className="form-group">
                                <span>
                                    <b>
                                        Cart Items
                                    </b>
                                </span>
                            </div>
                            <div className="form-group">
                                <ItemSearchTable searchLimit={20} data={cartData} gType="gsd" timeKey="t" urlEndPoint="main" columns={[
                                    {
                                        title: "SKU",
                                        key: "sku",
                                        map: (x) => {
                                            /* dialog to view product */
                                            return <a target="_blank" href={`${ADMINURLFRONT}product?g=sng&scr=sku&sku=${x.sku}`}>{x.sku}</a>
                                        }
                                    },
                                    {
                                        title: "Name",
                                        key: "name",
                                        map: (x) => {
                                            return x.name
                                        }
                                    },
                                    {
                                        title: "Price",
                                        key: "price",
                                        map: (x) => {
                                            return ecommerce_helper_function_get_formatted_price(x.price.sp)
                                        }
                                    },
                                    {
                                        title: "Quantity",
                                        key: "cartQuantity",
                                        map: (x) => {
                                            return x.cartQuantity
                                        }
                                    },
                                    {
                                        title: "Total",
                                        key: "total",
                                        map: (x) => {
                                            return ecommerce_helper_function_get_formatted_price(parseFloat(x.cartQuantity) * parseFloat(x.price.sp))
                                        }
                                    }
                                ]} />
                            </div>
                        </div>
                    </div>
                </div >

            )
        }
        if (this.state.isError) {
            return (
                <div className="p-2">
                    <span className="text-danger">Failed to get order. Please try again.</span>
                </div>
            )
        }
        return (
            null
        )
    }
}



function SingleOrder() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewSingleOrder orderID={id} history={history} />} title={"Order"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

class PickupRegistrationDialog extends React.Component {
    constructor(props) {
        super()

        this.onSuccessCallback = props.onSuccessCallback

        var pickupDateTime = DateTime.local(DateTime.now().year, DateTime.now().month, DateTime.now().day, 17).toFormat("yyyy-LL-dd")

        this.state = {
            weightOfShipment: 0,
            volumetricWeight: 0,
            pickupDateTime: pickupDateTime,

            isLoading: false,
            isSuccess: false,
            isError: false
        }
    }

    handlePickupRegistration = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }

        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Order State",
                message: `Unable to update order state. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }

        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Order State",
            message: `${response.payload.m} For Date: ${DateTime.fromISO(response.payload.t).toLocaleString()}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({ isSuccess: true, isError: false }, () => {
            this.onSuccessCallback()
        })
        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })
    }

    pickupRegistration = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {
            w: this.state.weightOfShipment,
            vw: this.state.volumetricWeight,
            p: DateTime.fromFormat(`${this.state.pickupDateTime}T17:00`, "yyyy-LL-dd'T'HH:mm").toString()
        }, {
            params: {
                g: "bpr",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handlePickupRegistration(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handlePickupRegistration({})
            })
    }



    componentDidMount() {
        // this.pickupRegistration()
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="pickupDatetime">Pick up date and time</label>
                    <input type="date" className="form-control" id="pickupDatetime" value={this.state.pickupDateTime} onChange={(e) => {
                        this.setState({ pickupDateTime: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="volumetricWeight">Volumetric Weight</label>
                    <input id="volumetricWeight" className="form-control" type="number" value={this.state.volumetricWeight} onChange={(e) => {
                        var tmpVolumetricWeight = 0
                        try {
                            tmpVolumetricWeight = parseInt(e.target.value)
                        } catch (err) {
                            tmpVolumetricWeight = 0
                        }
                        if (tmpVolumetricWeight < 0) {
                            return
                        }
                        this.setState({ volumetricWeight: tmpVolumetricWeight })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="actualWeight">Actual Weight</label>
                    <input id="actualWeight" className="form-control" type="number" value={this.state.weightOfShipment} onChange={(e) => {
                        var tmpWeightOfShipment = 0
                        try {
                            tmpWeightOfShipment = parseInt(e.target.value)
                        } catch (err) {
                            tmpWeightOfShipment = 0
                        }
                        if (tmpWeightOfShipment < 0) {
                            return
                        }
                        this.setState({ weightOfShipment: tmpWeightOfShipment })
                    }} />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" onClick={this.pickupRegistration} disabled={this.state.isLoading}>
                        <span className=""><FontAwesomeIcon spin icon={faCircleNotch} hidden={!this.state.isLoading} />&nbsp;</span>
                        Register Pickup
                    </button>
                </div>
            </div>
        )
    }
}

class PickupRegistrationButton extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            responseData: {}
        }
    }

    handleCheckPickupRegistration = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update order state")
            this.setState({ isSuccess: false, isError: true })
            return
        }

        if (response["status"] !== "success") {
            this.setState({ isSuccess: false, isError: true, })
            return
        }

        this.setState({ isSuccess: true, isError: false, responseData: response.payload }, () => {
            // this.onCancelCallback()
        })
    }

    checkPickupRegistration = () => {
        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "orders", {}, {
            params: {
                g: "cbp",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleCheckPickupRegistration(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleCheckPickupRegistration({})
            })
    }

    componentDidMount = () => {
        this.checkPickupRegistration()
    }

    render() {
        if (this.state.isSuccess) {
            if (this.state.responseData.e === true) {
                return (
                    <div className="p-2">
                        <div className="form-group">
                            {this.state.responseData.n} orders marked as Shipped.
                        </div>
                        <h4>Pickup already registered for date: {DateTime.fromISO(this.state.responseData.t).toLocaleString(DateTime.DATE_FULL)}</h4>
                        <hr />
                    </div>
                )
            } else {
                return (
                    <div className="p-2">
                        <div className="form-group">
                            {this.state.responseData.n} orders marked as Shipped.
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" onClick={() => {
                                fullDialogDispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Pickup Registration",
                                    message: <PickupRegistrationDialog onSuccessCallback={this.checkPickupRegistration} />
                                })
                            }}>
                                Register Pickup
                            </button>
                        </div>
                        <hr />
                    </div>
                )
            }
        }
        return null
    }
}


class Orders extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {
            show: show,
            status: status,
            showContent: <ViewAllOrders />
        }
    }

    componentDidUpdate() {

        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        if (show !== this.state.show || status !== this.state.status) {
            this.setState({ show: show, status: status })
        }
        // get show param

        // this.setState({contentToShow:<Window content={<ViewOrders />} title={"Orders"} show="" />})
    }

    render() {

        if (this.state.show === "all") {
            return [
                <div>
                    <NavBar />
                </div>,
                <Window content={<ViewAllOrders />} title={"Orders"} key={`all`} />,
            ]
        }

        if (this.state.show === "ecom") {
            return [
                <div>
                    <NavBar />
                </div>,
                <Window content={<EcomOrders status={this.state.status} key={`${this.state.show}${this.state.status}`} />} title={"Orders"} key={`${this.state.show}${this.state.status}`} />,
            ]
        }

        if (this.state.show === "manual") {
            return [
                <div>
                    <NavBar />
                </div>,
                <Window content={<ManualOrders status={this.state.status} key={`${this.state.show}${this.state.status}`} />} title={"Orders"} key={`${this.state.show}${this.state.status}`} />,
            ]
        }

        if (this.state.show === "subscription") {
            return [
                <div>
                    <NavBar />
                </div>,
                <Window content={<SubscriptionOrders status={this.state.status} key={`${this.state.show}${this.state.status}`} />} title={"Orders"} key={`${this.state.show}${this.state.status}`} />,
            ]
        }

        return [
            <div>
                <NavBar />
            </div>,
            // <Window content={this.state.showContent} title={"Orders"} />,
            // <Window content={<ViewOrders show={this.state.show}/>} title={"Orders"} />,
        ]
    }

}


export {
    Orders,
    ViewSingleOrder,
    SingleOrder
}