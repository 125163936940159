import { faArrowLeft, faCircleNotch, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { fullDialogDispatcher } from '../Components/FullDialog';
import { ItemSearch } from '../Components/ItemSearch';
import NavBar from '../Components/NavBar';
import { toastsDispatcher } from '../Components/Toasts';
import { Window } from '../Components/Window';
import axios from 'axios';
import Cookies from 'js-cookie';

import ReactQuill, { Quill } from 'react-quill';
import { Link } from 'react-router-dom';

import 'quill/themes/snow';
import 'quill/'
import { ADMINURL } from '../Components/MSURLS';

var Delta = Quill.import('delta');
var ACCT = Cookies.get('ACCT')
const { DateTime, } = require("luxon");

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['link', 'image', 'formula'],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
];

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}

class NewSMSCampaign extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">

            </div>
        )
    }
}

class NewEmailCampaign extends React.Component {
    constructor(props) {
        super()
        this.addSuccessCallback = props.addSuccessCallback
        this.state = {
            campaignName: "",
            campaignDescription: "",

            campaignNameError: "",

            isLoading: false,
            isError: false,
            isSuccess: false,

            stautus: ""
        }
    }

    resetErrors = () => {
        var campaignNameDOM = document.getElementById("emailCampaignName")


        campaignNameDOM.classList.remove("is-invalid")
    }

    handleAddCampaign = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add campaign")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Campaign",
                message: `Unable to add campaign. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            var campaignNameDOM = document.getElementById("emailCampaignName")
            campaignNameDOM.classList.add("is-invalid")
            this.setState({ campaignNameError: `${response.payload}.` })
            document.getElementsByClassName("is-invalid")[0].focus()

            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Campaign",
            message: <span>Campaign <b>{this.state.name}</b> successfully added.</span>,
            date: new Date(),
            isSuccess: true
        })

        fullDialogDispatcher.dispatch({
            actionType: "close-dialog"
        })

        if (this.addSuccessCallback !== undefined && this.addSuccessCallback !== null) {
            this.addSuccessCallback()
        }
    }

    addCampaign = () => {

        this.resetErrors()
        /** 
         * Check if campaign name is empty or not
         */
        var isError = false

        if (String(this.state.campaignName).trim() === "") {

            var campaignNameDOM = document.getElementById("emailCampaignName")
            campaignNameDOM.classList.add("is-invalid")
            this.setState({ campaignNameError: "Campaign Name is required." })
            isError = true
        }


        if (isError === true) {
            document.getElementsByClassName("is-invalid")[0].focus()
            return;
        }

        const that = this
        this.setState({ isLoading: true })
        axios.post(ADMINURL + "marketing", {
            n: this.state.campaignName,
            d: this.state.campaignDescription
        }, {
            params: {
                g: "ade",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleAddCampaign(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleAddCampaign({})
            })
    }
    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="emailCampaignName">Campaign Name</label>
                    <input id="emailCampaignName" type="text" className="form-control" value={this.state.campaignName} onChange={(e) => {
                        this.setState({ campaignName: e.target.value })
                    }} />
                    <div className="invalid-feedback">
                        {this.state.campaignNameError}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="emailCampaignDescription">Campaign Description</label>
                    <textarea id="emailCampaignDescription" rows="5" type="text" className="form-control" value={this.state.campaignDescription} onChange={(e) => {
                        this.setState({ campaignDescription: e.target.value })
                    }} />

                </div>
                <div className="form-group">
                    <button onClick={this.addCampaign} className="btn btn-primary"><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin /></span> Done</button>
                </div>
            </div>
        )
    }
}

class SMSMarketing extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-4">
            </div>
        )
    }
}

class EmailCampaignTargetSearch extends React.Component {
    constructor(props) {
        super()

        this.onDoneCallback = props.onDoneCallback
        this.data = props.data

        // this.data.tr = tmpSelectedData
        // console.log(this.data)

        this.state = {
            targetCriteria: "sel",
            selectedData: this.data.tr
        }
    }

    render() {

        var items = []
        for (var i in this.state.selectedData) {
            const idx = i
            items.push(
                <span className="badge bg-rust text-light rounded-pill p-2 m-1 d-flex justify-content-between" key={i}>
                    <span>
                        {this.state.selectedData[idx].f}
                    </span>
                    <span>
                        <FontAwesomeIcon className="ml-2" icon={faTimesCircle} title={`Remove`} style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            const tmpItems = this.state.selectedData
                            tmpItems.splice(idx, 1)
                            // delete tmpItems[idx]
                            this.setState({ items: tmpItems })
                        }} />
                    </span>
                </span>
            )
        }

        return (
            <div className="p-2">
                <div className="form-group p-2">
                    <label htmlFor="emailTargetSearchCriteria">Target Criteria</label>
                    <select id="emailTargetSearchCriteria" type="text" className="form-control" value={this.state.targetCriteria} onChange={(e) => {
                        this.setState({ targetCriteria: e.target.value })
                    }}>
                        <option value="sel">Selected</option>
                        <option value="exc">Excluding Selected</option>
                        <option value="all">All</option>
                    </select>
                </div>
                <div className="form-group">
                    <div className="p-2 d-flex flex-wrap">
                        {items}
                    </div>
                </div>
                <div className="form-group" hidden={this.state.targetCriteria === "all"}>

                    <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {
                        var tmpSelectedData = this.state.selectedData
                        // tmpSelectedData[resultData["_id"]] = {

                        // check existence
                        for (var i in tmpSelectedData) {
                            if (tmpSelectedData[i]["_id"] === resultData["_id"]) {
                                return
                            }
                        }
                        tmpSelectedData.push({
                            "_id": resultData["_id"],
                            "f": resultData["f"],
                            "l": resultData["l"],
                            "e": resultData["e"],
                            "p": resultData["ph"],
                        })
                        this.setState({ selectedData: tmpSelectedData })

                    }} isResultLink={false} subNameTitleKey={'l'} resultClickable={true} searchAllowed={true} gType="get" timeKey="" urlEndPoint="users" subInfoKeys={[]} />

                </div>
                <div className="form-group">
                    <button className="btn btn-primary m-1" onClick={() => {
                        this.onDoneCallback(this.state.selectedData, this.state.targetCriteria)
                    }}>Done</button>
                    <button className="btn btn-danger m-1" onClick={(e) => {
                        this.setState({ selectedData: [] })
                    }}>Clear</button>
                </div>
            </div>
        )
    }
}

class EmailCampaignRun extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            response: null
        }
        this.data = props.data
    }

    handleRunCampaign = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to run campaign.")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Run Campaign",
                message: `Unable to run campaign. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, response: <span className="text-danger">Some error has occurred. {response.payload}.</span> })

            return
        }

        this.setState({ isSuccess: true, isError: false, response: <span className="text-success">Campaign has been successfully started.</span> })

    }

    runCampaign = () => {

        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "marketing", {
            params: {
                g: "rec",
                id: this.data._id
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleRunCampaign(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleRunCampaign({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Make sure everything is in order.
                    <br />
                    Are you sure you want the run the campaign?
                </div>
                <div className="form-group">
                    <button className="btn btn-primary m-1" disabled={this.state.isLoading} onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "close-dialog"
                        })
                    }}>Close</button>
                    <button className="btn btn-success m-1" disabled={this.state.isLoading} onClick={this.runCampaign}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin /></span>&nbsp;Run Campaign</button>
                </div>
                <div className="form-group">
                    {this.state.response}
                </div>
            </div>
        )
    }
}

class SingleNotebook extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
        this.editor = null
        this.onChange = props.onChange
        this.value = props.value
    }

    imageUpload = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/jpeg');
        input.click();

        input.onchange = () => {
            this.handleImageUpload(input)
        }
    }

    handleImageUpload = (input) => {
        const that = this;



        for (var ii = 0; ii < input.files.length; ii++) {
            if (input.files[ii]["type"] !== "image/jpeg") {
                alert("Only JPEG files are allowed.")
                continue;
            }
            const ff = input.files[ii]

            this.setState({ isLoading: true })
            // increase currentIsLoadingCounter

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Image",
                message: <span>Uploading image <b>{ff.name}</b>.</span>,
                date: new Date(),
                isInfo: true
            })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)
                // const file = new Blob([e.target.result], {type: ff.type });
                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            it: 'ec'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })
                        //handle success

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Server side error while adding the image.",
                                date: new Date(),
                                isError: true
                            })
                            return
                        }

                        if (response["status"] !== "success") {
                            toastsDispatcher.dispatch({
                                actionType: 'add-toast',
                                title: "Add Image",
                                message: "Unable to add the image.",
                                date: new Date(),
                                isError: true
                            })


                            return
                        }

                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Image <b>{ff.name}</b> successfully uploaded and added.</span>,
                            date: new Date(),
                            isSuccess: true
                        })

                        const imgID = response["payload"]["id"]

                        // that.setState({ dataImage: imgID })
                        // alert(imgID)

                        var value = `${ADMINURL}/images?g=get&id=${imgID}`
                        // that.editor.focus()
                        var range = that.editor.getSelection()

                        if (value) {
                            // that.editor.getSelection()
                            that.editor.insertEmbed(range.index, 'image', value, "user");
                        }

                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        toastsDispatcher.dispatch({
                            actionType: 'add-toast',
                            title: "Add Image",
                            message: <span>Server side error while adding the image <b>{ff.name}</b>.</span>,
                            date: new Date(),
                            isError: true
                        })
                        console.log(response)

                    });

                // upload ends


            }


            reader.readAsDataURL(input.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    componentDidMount() {
        var DirectionAttribute = Quill.import('attributors/attribute/direction');
        Quill.register(DirectionAttribute, true);
        var AlignClass = Quill.import('attributors/class/align');
        Quill.register(AlignClass, true);
        var BackgroundClass = Quill.import('attributors/class/background');
        Quill.register(BackgroundClass, true);
        var ColorClass = Quill.import('attributors/class/color');
        Quill.register(ColorClass, true);
        var DirectionClass = Quill.import('attributors/class/direction');
        Quill.register(DirectionClass, true);
        var FontClass = Quill.import('attributors/class/font');
        Quill.register(FontClass, true);
        var SizeClass = Quill.import('attributors/class/size');
        Quill.register(SizeClass, true);
        var AlignStyle = Quill.import('attributors/style/align');
        Quill.register(AlignStyle, true);
        var BackgroundStyle = Quill.import('attributors/style/background');
        Quill.register(BackgroundStyle, true);
        var ColorStyle = Quill.import('attributors/style/color');
        Quill.register(ColorStyle, true);
        var DirectionStyle = Quill.import('attributors/style/direction');
        Quill.register(DirectionStyle, true);
        var FontStyle = Quill.import('attributors/style/font');
        Quill.register(FontStyle, true);
        var SizeStyle = Quill.import('attributors/style/size');
        Quill.register(SizeStyle, true);

        this.editor = new Quill("#notebookeditor", {
            modules: {
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        image: this.imageUpload
                    }
                }

                // 'image-tooltip': true,
            },
            theme: 'snow'
        });

        this.editor.root.innerHTML = this.value

        var change = new Delta();
        this.editor.on('text-change', (delta) => {
            this.onChange(this.editor.root.innerHTML)
            // var contents = this.editor.getContents();
            // change = change.compose(delta)
            // this.setState({ currentNotebookContent: contents })
            // console.log('contents', contents.ops[0]);
        })
    }

    render() {
        return (
            <div>
                <div id="toolbar">
                </div>
                <div id="notebookeditor">

                </div>
            </div>
        )
    }
}

class EmailCampaignReport extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-2">
                <ItemSearch getDataCaller={(d) => {
                    this.targetsDispatcher = d
                }} searchLimit={5} key="emailMarketingCampaignMessagesList" nameTitleKey="f" onClickCallback={(resultData) => {
                    // const id = this.state.slider[iid]["_id"]
                    // this.addItem(resultData)
                }} isResultLink={false} urlParams={{ id: this.data._id }} resultLink={''} subNameTitleKey={'l'} resultClickable={false} searchAllowed={true} gType="gcr" timeKey="t" urlEndPoint="marketing" subHeadingKeys={["e", "p"]} subInfoKeys={[
                    {
                        "key": "ie",
                        "map": function (data) {
                            if (data === true) {
                                return <span className="text-danger">Has Error<br /></span>
                            }
                            return <span className="text-success">No Reported Error<br /></span>


                        }
                    },
                    {
                        "key": "m",
                        "map": function (data) {
                            var msgs = []
                            for (var i in data) {
                                msgs.push(<>{data[i]}<br /></>)
                            }

                            return <span>{msgs}</span>
                        }
                    },

                ]} />
            </div>
        )
    }
}

class ViewSingleEmailCampaign extends React.Component {
    constructor(props) {
        super()

        this.history = props.history
        this.campaignID = props.campaignID

        this.editor = null

        this.targetsDispatcher = null

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            isSaveLoading: false,

            campaign: {},
            s: ""
        }
    }

    handleFetchCampaign = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get campaign.")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Get Campaign",
                message: `Unable to get campaign. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            return
        }

        this.setState({ campaign: response.payload, isSuccess: true })

    }

    fetchCampaign = () => {

        const that = this
        this.setState({ isLoading: true })
        axios.get(ADMINURL + "marketing", {
            params: {
                g: "gse",
                id: this.campaignID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchCampaign(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchCampaign({})
            })
    }

    handleSaveCampaign = (response) => {
        this.setState({ isSaveLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to save campaign.")
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Save Campaign",
                message: `Unable to save campaign. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Save Campaign",
            message: `Campaign successfuly saved.`,
            date: new Date(),
            isSuccess: true
        })
    }

    saveCampaign = () => {
        const that = this
        this.setState({ isSaveLoading: true })
        axios.post(ADMINURL + "marketing", this.state.campaign, {
            params: {
                g: "sec",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleSaveCampaign(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleSaveCampaign({})
            })
    }

    componentDidMount() {
        this.fetchCampaign()
    }





    render() {
        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;Loading
                </div>
            )
        }
        if (this.state.isSuccess) {
            var targetCriteria = null
            if (this.state.campaign.tc === "sel") {
                targetCriteria = "Selected"
            }
            if (this.state.campaign.tc === "all") {
                targetCriteria = "All"
            }
            if (this.state.campaign.tc === "exc") {
                targetCriteria = "Excluding"
            }

            var targetData = this.state.campaign.tr

            return (

                <div className="p-4">
                    <div className="col-xl-12 col-lg-12 col-sm-12 row">
                        <div className="col-xl-6 col-lg-6 col-sm-12">
                            <div className="form-group">
                                <Link to="/marketing"><FontAwesomeIcon icon={faArrowLeft} /> Marketing</Link>
                            </div>
                            <div className="form-group">
                                <h4>{this.state.campaign.n}</h4>
                            </div>
                            <div className="form-group">
                                <p style={{
                                    whiteSpace: "pre-wrap"
                                }}>{this.state.campaign.d}</p>
                            </div>
                            <div className="form-group">
                                <h5>Targets</h5>

                                <span className="btn-link" hidden={this.state.campaign.r} style={{
                                    cursor: 'pointer'
                                }} onClick={() => {

                                    fullDialogDispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Add Target",
                                        message: <EmailCampaignTargetSearch onDoneCallback={(data, targetCriteria) => {
                                            // set targets
                                            const tmpCampaign = this.state.campaign
                                            tmpCampaign.tr = data
                                            tmpCampaign.tc = targetCriteria

                                            this.setState({ campaign: tmpCampaign }, () => {
                                                console.log(this.state.campaign)
                                            })

                                            fullDialogDispatcher.dispatch({
                                                actionType: "close-dialog"
                                            })

                                            this.targetsDispatcher.dispatch({
                                                actionType: "get-data"
                                            })

                                        }} data={this.state.campaign} />
                                    })
                                }}><FontAwesomeIcon icon={faPlus} />&nbsp;Add/Remove Targets</span>
                                <br />
                                <span>Target Criteria: <b>{targetCriteria}</b></span>
                                <ItemSearch getDataCaller={(d) => {
                                    this.targetsDispatcher = d
                                }} searchLimit={5} key="emailMarketingTargetsList" nameTitleKey="f" onClickCallback={(resultData) => {
                                    // const id = this.state.slider[iid]["_id"]
                                    // this.addItem(resultData)
                                }} isResultLink={false} resultLink={'/orders/'} data={targetData} subNameTitleKey={'l'} resultClickable={false} searchAllowed={true} gType="get" timeKey="" urlEndPoint="orders" subHeadingKeys={["e", "p"]} />
                            </div>
                            <div className="form-group" hidden={!this.state.campaign.r}>
                                <h5>Run Information</h5>
                                <span><b>Run Time:</b></span>
                                <br />
                                <span>{DateTime.fromISO(this.state.campaign.rt).toLocaleString(DateTime.DATETIME_MED)} ({DateTime.fromISO(this.state.campaign.rt).toRelative().toString()})</span>
                                <br />
                                <span><b>Report:</b></span>
                                <br />
                                <span className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Report",
                                        message: <EmailCampaignReport data={this.state.campaign} />
                                    })
                                }}>
                                    View Report
                                </span>
                            </div>
                            <div className="form-group">
                                <button disabled={this.state.isSaveLoading} className="btn btn-primary m-1" onClick={this.saveCampaign}><span hidden={!this.state.isSaveLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Save</button>
                                <button className="btn btn-success m-1" disabled={this.state.campaign.r} onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Run Campaign?",
                                        message: <EmailCampaignRun data={this.state.campaign} />
                                    })
                                }}>Run Campaign</button>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12">
                            <input type="file" class="form-control-file" id={`emailCampaignImageUploadButton`} onChange={(input) => {
                                this.handleImageUpload(input)
                            }} hidden />
                            <div className="from-group">
                                <h5>Content</h5>

                                <div className="from-group">
                                    <label htmlFor="emailCampaignSubject">Subject</label>
                                    <input key="emailCampaignSubject" id="emailCampaignSubject" type="text" className="form-control" value={this.state.campaign.s} onChange={(e) => {
                                        const tmpCampaign = this.state.campaign
                                        tmpCampaign.s = e.target.value
                                        this.setState({ campaign: tmpCampaign })
                                    }} />
                                </div>
                                <div className="from-group mt-2">
                                    <label htmlFor="emailCampaignContent">Body</label>
                                    <SingleNotebook value={this.state.campaign.c} onChange={(html) => {
                                        const tmpCampaign = this.state.campaign
                                        tmpCampaign.c = html
                                        this.setState({ campaign: tmpCampaign })
                                    }} />
                                    {/* <ReactQuill key="emailCampaignContent" id="emailCampaignContent" key="emailCampaignEditor" ref={(el) => {
                                        this.editor = el;
                                    }} theme="snow" value={this.state.campaign.c}  onChange={(html, delta, source, editor) => {
                                        var tmpCampaign = this.state.campaign
                                        tmpCampaign.c = html
                                        // this.setState({ campaign: tmpCampaign })

                                    }} style={{
                                        height: ''
                                    }} modules={{
                                        toolbar: {
                                            container: toolbarOptions,
                                            handlers: {
                                                image: () => {
                                                    document.getElementById(`emailCampaignImageUploadButton`).click();
                                                }
                                            }
                                        }
                                    }} /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            )
        }
        return null
    }
}

function SingleEmailCampaign() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<ViewSingleEmailCampaign campaignID={id} history={history} />} title={"Email Marketing"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}


class EmailMarketing extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }

        this.reloadDataDispatcher = null
    }

    reloadData = () => {
        if (this.reloadDataDispatcher !== null) {
            this.reloadDataDispatcher.dispatch({
                actionType: "get-data"
            })
        }
    }

    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <span className="btn-link" style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "New Campaign",
                            message: <NewEmailCampaign addSuccessCallback={this.reloadData} />,

                        })
                    }}><FontAwesomeIcon icon={faPlus} />&nbsp;New Campaign</span>
                </div>
                <div>
                    <ItemSearch nameTitleKey="n" subNameTitleKey="d" subHeadingKeys={undefined} onClickCallback={(resultData) => {
                    }} resultClickable={true} isResultLink={true} resultLink={'/marketing/email/'} timeKey="time" sortCriteria="t" searchAllowed={true} gType="gec" urlEndPoint="marketing" getDataCaller={(dispatcher) => {
                        this.reloadDataDispatcher = dispatcher
                    }} />
                </div>
            </div>
        )
    }
}


class MarketingMain extends React.Component {
    constructor(props) {
        super()
        this.state = {
            columnedBool: false,
            columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between"
        }
    }

    render() {
        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Marketing &nbsp;&nbsp;<i class="fas fa-columns" style={{ cursor: 'pointer' }} title="Toggle Split" onClick={() => {
                        if (this.state.columnedBool) {
                            this.setState({ columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between", columnedBool: false })
                        } else {
                            this.setState({ columnClass: "col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between", columnedBool: true })
                        }
                    }}></i></h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div className="row">

                    <div className={this.state.columnClass}>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Email Marketing</h6>
                            </div>
                            <div class="m-0">
                                <EmailMarketing />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

function Marketing() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<EmailMarketing collectionID={id} />} title={"Email Marketing"} />,
        <Window content={<SMSMarketing collectionID={id} />} title={"SMS Marketing"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}



export {
    Marketing,
    SingleEmailCampaign
}
