import { faCheck, faCheckCircle, faCircleNotch, faCopy, faExclamationCircle, faThList, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import AuthenticatedImage, { UnAuthenticatedImage } from '../Components/AuthenticatedImage'
import { fullDialogDispatcher } from '../Components/FullDialog'
import { ItemSearch } from '../Components/ItemSearch'
import NavBar from '../Components/NavBar'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';

import { toastsDispatcher } from '../Components/Toasts'
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2'
import { ADMINURL } from '../Components/MSURLS'
import Pickr from '@simonwep/pickr'
import ReactQuill from 'react-quill'
import { ChromePicker, SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { ImageJPEGErrorDialog } from '../Components/CommonDialogs'
import { networkCallGet, networkCallPost, networkCallPostFile } from '../Components/network'
import { ecommerce_helper_function_get_formatted_price, helper_function_info_dialog, helper_function_yes_no_dialog } from '../Components/helper_functions'

var country_json_1 = require("country-state-city/lib/country.json");

const XLSX = require('xlsx');

var ACCT = Cookies.get('ACCT')

var { DateTime } = require('luxon');

var selectedItemsDialogIndex = null
var selectCollectionDialogIndex = null
var editVideoEntryDialogIndex = null
var deleteVideoEntryDialogIndex = null
var copyInventoryDataToAddInventoryDialogIndex = null
var bulkImageAddViewSingleImageDialogIndex = null
var bulkImageAddUploadImagesDialogIndex = null

var viewAllItemsVideoDispatcher = null
var viewItemVariationsDispatcher = null

var clearAddInventoryDataDialogIndex = null
var clearEditInventoryDataDialogIndex = null

var editInventoryDataDialogIndex = null
var selectInventoryItemDialogIndex = null

var viewItemVariationDialogIndex = null
var viewSingleItemVariationDialogIndex = null


var inventoryStockHistoryGetDataDispatcher = null
var fetchCurrentInventoryStockValue = null

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}


async function upload_images(data) {

}

class EditInventoryData extends React.Component {
    constructor(props) {
        super(props)
        this.resetAllFields = this.resetAllFields.bind(this)
        this.postItem = this.postItem.bind(this);
        this.itemID = props.itemID

        this.state = {
            name: "",
            description: "",
            ecomDescription: "",
            ecom: true,
            keywords: [],
            color: {
                color: "",
                colorn: ""
            },
            sku: "",
            hsn: "",
            type: "Goods",
            unit: "pcs",
            quantity: 0,
            dimensions: {
                height: 0,
                width: 0,
                depth: 0,
                unit: "mm"
            },
            weight: {
                value: 0,
                unit: 'gms'
            },
            info: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            price: {
                cp: 0,
                sp: 0,
                mrp: 0
                // unit: "inr"
            },
            tax: {
                p: 0,
                // c: "igst",
                t: "inc"
            },
            coupon: "",

            response: {},
            images: [],
            banners: [],

            article: "",
            material: "",
            gender: "",
            shape: "",
            occasion: "",
            purity: "",
            plating: "",
            countryoforigin: "",
            maxperecomorder: 0,
            configurationIdentifier: "",
            addOnIdentifier: {
                id: "",
                n: "",
                s: ""
            },
            files: [],
            checkstockonorder: true,
            leadtime: {
                v: 0,
                u: "d"
            },
            metakeyvalue: [],
            futurestocking: [],

            isLoading: false,
            isError: false,
            isSuccess: false,

            isFetchLoading: false,
            isFetchError: false,
            isFetchSuccess: false
        }
    }

    componentDidMount() {
        this.fetchInventoryItem()
    }

    handleFetchInventoryItem = (response) => {
        const that = this
        this.setState({ isFetchLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to fetch inventory item")
            this.setState({ isFetchSuccess: false, isFetchError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Inventory",
                message: `Failed to get inventory item data. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isFetchSuccess: false, isFetchError: true, })
            return
        }


        var tmpFutureStocking = []
        for (var i in response.payload.futurestocking) {
            tmpFutureStocking.push({
                ta: DateTime.fromISO(response.payload.futurestocking[i].ta).toFormat("yyyy-LL-dd'T'HH:mm"),
                n: response.payload.futurestocking[i].n,
                sa: parseInt(response.payload.futurestocking[i].sa)
            })
        }


        /* set fields */
        this.setState({
            name: response.payload.name,
            description: response.payload.description,
            ecomDescription: response.payload.ecomDescription,
            ecom: response.payload.ecom,
            keywords: response.payload.keywords,
            color: response.payload.color,
            sku: response.payload.sku,
            hsn: response.payload.hsn,
            type: response.payload.type,
            unit: response.payload.unit,
            quantity: response.payload.quantity,
            dimensions: response.payload.dimensions,
            weight: response.payload.weight,
            info: response.payload.info,
            price: response.payload.price,
            tax: response.payload.tax,
            coupon: response.payload.coupon,

            response: {},
            images: response.payload.images,

            article: response.payload.article,
            material: response.payload.material,
            gender: response.payload.gender,
            shape: response.payload.shape,
            occasion: response.payload.occasion,
            purity: response.payload.purity,
            plating: response.payload.plating,
            countryoforigin: response.payload.countryoforigin,
            maxperecomorder: response.payload.maxperecomorder,
            configurationIdentifier: response.payload.configurationIdentifier,
            addOnIdentifier: response.payload.addOnIdentifier,
            files: response.payload.files,
            checkstockonorder: response.payload.checkstockonorder,
            leadtime: response.payload.leadtime,
            metakeyvalue: response.payload.metakeyvalue,
            futurestocking: tmpFutureStocking,
            banners: response.payload.banners
        }, () => {
            that.setState({
                isFetchSuccess: true,
                isFetchError: false,
            })
        })

        /* */


    }

    fetchInventoryItem() {
        const that = this
        this.setState({
            isFetchLoading: true,
            isFetchSuccess: false,
            isFetchError: false
        })

        axios.get(ADMINURL + "inventory", {
            headers: { Authorization: ACCT },
            params: {
                g: "gsi",
                i: this.itemID
            }
        })
            .then(function (response) {
                that.handleFetchInventoryItem(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchInventoryItem({})
            })

    }

    resetAllFields() {
        this.setState({
            name: "",
            description: "",
            ecomDescription: "",
            ecom: true,
            keywords: [],
            color: {
                color: "",
                colorn: ""
            },
            sku: "",
            hsn: "",
            type: "Goods",
            unit: "pcs",
            quantity: 0,
            dimensions: {
                height: 0,
                width: 0,
                depth: 0,
                unit: "mm"
            },
            weight: {
                value: 0,
                unit: 'gms'
            },
            info: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            price: {
                cp: 0,
                sp: 0,
                mrp: 0
                // unit: "inr"
            },
            tax: {
                p: 0,
                // c: "igst",
                t: "inc"
            },
            coupon: "",

            response: {},
            images: [],
            banners: [],

            article: "",
            material: "",
            gender: "",
            shape: "",
            occasion: "",
            purity: "",
            plating: "",
            countryoforigin: "",
            maxperecomorder: 0,
            configurationIdentifier: "",
            addOnIdentifier: {
                id: "",
                n: "",
                s: ""
            },
            files: [],
            checkstockonorder: true,
            leadtime: {
                v: 0,
                u: "d"
            },
            metakeyvalue: [],
            futurestocking: [],

            isLoading: false,
            isError: false,
            isSuccess: true
        }, () => {
            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: clearEditInventoryDataDialogIndex
            })

            fullDialogV2Dispatcher.dispatch({
                actionType: "scroll-dialog",
                dialogIndex: editInventoryDataDialogIndex
            })

        })
    }

    FullScreenImageView = () => {
        return (
            <div id="fullscreenimageviewdialog1" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabIndex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog1") {
                        // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialog1image" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }

    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialog1image').style.transform = angle;
        })
    }

    ImageUploader = () => {
        var img_ = []

        for (var i in this.state.images) {
            const idx = i
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} src={this.state.images[i]["image"]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        // $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        // $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <div className="inquiry_selected_buttons">
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}></i>&nbsp;<span iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}>Delete</span>
                    </div>
                    <div className="">
                        QR Position
                        <select class="custom-select" name="type" id={`addIventoryImageQRPos` + i} defaultValue={this.state.images[i]["qp"]} iid={i} onChange={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images[iid]["qp"] = e.target.value;
                            this.setState({ images: tmp_images })
                        }}>
                            <option selected value="br">Bottom Right</option>
                            <option value="tl">Top Left</option>
                            <option value="tr">Top Right</option>
                            <option value="bl">Bottom Left</option>
                        </select>
                    </div>
                    <div className="">
                        QR Size
                        <select class="custom-select" name="type" id={`addIventoryImageQRSize` + i} defaultValue={this.state.images[i]["qs"]} iid={i} onChange={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images[iid]["qs"] = e.target.value;
                            this.setState({ images: tmp_images })
                        }}>
                            <option selected value="100">100 px</option>
                            <option value="200">200 px</option>
                            <option value="300">300 px</option>
                        </select>
                    </div>
                    <div className="">
                        <div class="form-check">
                            <input class="form-check-input" iid={idx} type="radio" name="primaryImageRadio" id={`primaryImageRadio` + i} checked={this.state.images[i]["ip"]} onChange={(e) => {

                                var iid_ = e.target.getAttribute("iid")
                                var tmpImages = this.state.images


                                // all false
                                for (var ii in tmpImages) {
                                    tmpImages[ii]["ip"] = false
                                }


                                tmpImages[iid_]["ip"] = e.target.checked
                                this.setState({ images: tmpImages })

                            }} />
                            <label class="form-check-label" for={`primaryImageRadio` + i}>
                                Is Primary
                            </label>
                        </div>
                    </div>
                </div>
            )
        }

        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    {/* <h5 class="card-title">Add Images</h5> */}
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>
                    {/* <this.Display_images /> */}
                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;

                                tmp_images.push(
                                    {
                                        "image": e.target.result,
                                        "qp": "br",
                                        "qs": "100",
                                        "ip": false
                                    }

                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }


    handlePostItem = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to edit inventory item")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Edit Inventory",
                message: `Failed to edit inventory item. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Edit Inventory",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Edit Inventory",
            message: <div>
                <span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span> Inventory item successfully edited.
            </div>
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "scroll-dialog",
            dialogIndex: editInventoryDataDialogIndex
        })

    }

    postItem() {

        this.setState({ isLoading: true })
        // $("#postItemButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Add")
        const that = this
        var tmp_images = []

        // if (this.state.images.length > 0) {
        //     for (var i in this.state.images) {
        //         tmp_images.push(
        //             {
        //                 "image": this.state.images[i]["image"].split(",")[1],
        //                 "qs": this.state.images[i]["qs"],
        //                 "qp": this.state.images[i]["qp"],
        //                 "ip": this.state.images[i]["ip"]
        //             }
        //         )
        //     }
        // }

        /* prepare future stocking */

        var tmpFutureStocking = []
        for (var i in this.state.futurestocking) {
            tmpFutureStocking.push({
                ta: DateTime.fromFormat(this.state.futurestocking[i].ta, "yyyy-LL-dd'T'HH:mm").toString(),
                n: this.state.futurestocking[i].n,
                sa: parseInt(this.state.futurestocking[i].sa)
            })
        }

        /* */

        axios.post(ADMINURL + "inventory", {
            _id: this.itemID,
            name: this.state.name,
            description: this.state.description,
            ecomDescription: this.state.ecomDescription,
            ecom: this.state.ecom,
            keywords: this.state.keywords,
            color: this.state.color,
            type: this.state.type,
            sku: this.state.sku,
            hsn: this.state.hsn,
            // quantity: parseInt(this.state.quantity),
            unit: this.state.unit,
            dimensions: this.state.dimensions,
            weight: this.state.weight,
            info: this.state.info,
            price: this.state.price,
            tax: this.state.tax,
            coupon: this.state.coupon,
            images: this.state.images,
            article: this.state.article,
            material: this.state.material,
            gender: this.state.gender,
            shape: this.state.shape,
            occasion: this.state.occasion,
            purity: this.state.purity,
            plating: this.state.plating,
            countryoforigin: this.state.countryoforigin,
            maxperecomorder: parseInt(this.state.maxperecomorder),
            configurationIdentifier: this.state.configurationIdentifier,
            addOnIdentifier: this.state.addOnIdentifier,
            files: this.state.files,
            checkstockonorder: this.state.checkstockonorder,
            leadtime: this.state.leadtime,
            metakeyvalue: this.state.metakeyvalue,
            futurestocking: tmpFutureStocking,
            banners: this.state.banners

        }, {
            headers: { Authorization: ACCT },
            params: { g: "udi" }
        })
            .then(function (response) {
                that.handlePostItem(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handlePostItem({})
            })
    }

    printStuff(key, value) {
        var ret = []
        if (value[0].constructor === {}.constructor) {
            for (var i in value[0]) {
                ret.push(
                    this.printStuff(i, value[0][i])
                )
            }
            return ret
        }

        ret.push(
            <span><span>{key}: {value}</span><br /></span>
        )

        return ret

    }

    Status = () => {

        useEffect(() => {
            // Update the document title using the browser API
            if ("_status" in this.state.response) {
                document.getElementById('addInventoryStatusDiv').scrollIntoView()
            }
        });

        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            // $("#add_inquiry_button").prop('disabled', true);
            // setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="addInventoryStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []

            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                // alert(i)
                ret.push(
                    this.printStuff(i, this.state.response.payload[0][i])
                    //     // <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="addInventoryStatusDiv" style={{
                    whiteSpace: 'pre-wrap'
                }} className="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return "";
    }



    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg" && input.target.files[ii]["type"] !== "image/png") {
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Image Error",
                    message: <ImageJPEGErrorDialog />
                })
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            // toastsDispatcher.dispatch({
            //     actionType: 'add-toast',
            //     title: "Add Image",
            //     message: <span>Uploading image <b>{ff.name}</b>.</span>,
            //     date: new Date(),
            //     isInfo: true
            // })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            g: 'upl'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {


                            return
                        }

                        if (response["status"] !== "success") {


                            return
                        }


                        const imgID = response["payload"]["id"]
                        const tmpImages = that.state.images
                        tmpImages.push({
                            image: imgID,
                            alt: "",
                            "eqr": false,
                            "qp": "bl",
                            "qs": 100,
                            ip: false
                        })
                        that.setState({ images: tmpImages })
                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }


    handleBannerUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg" && input.target.files[ii]["type"] !== "image/png") {
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Image Error",
                    message: <ImageJPEGErrorDialog />
                })
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            // toastsDispatcher.dispatch({
            //     actionType: 'add-toast',
            //     title: "Add Image",
            //     message: <span>Uploading image <b>{ff.name}</b>.</span>,
            //     date: new Date(),
            //     isInfo: true
            // })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            g: 'upl'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {


                            return
                        }

                        if (response["status"] !== "success") {


                            return
                        }


                        const imgID = response["payload"]["id"]
                        var tmpBanners = that.state.banners
                        if (!tmpBanners) {
                            tmpBanners = []
                        }

                        tmpBanners.push({
                            image: imgID,
                            alt: "",
                            "eqr": false,
                            "qp": "bl",
                            "qs": 100,
                            ip: false
                        })
                        that.setState({ banners: tmpBanners })
                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error
                        console.log(response)
                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }


    handleFileUpload = (response) => {

        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {

            alert("Unable to upload file")
            this.setState({ isSuccess: false, isError: true })
            return

        }

        if (response["status"] !== "success") {

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Upload File",
                message: `Failed to upload file. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })

            return
        }


        const fileID = response["payload"]["file"]
        const fileName = response["payload"]["name"]
        const tmpFiles = this.state.files
        tmpFiles.push({
            file: fileID,
            name: fileName,
            description: "",
            size: 0,
            meta: {},
            isUserSecured: false,
            allowedUsers: [],
            unallowedUsers: []
        })
        this.setState({ files: tmpFiles })

    }

    fileUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {

            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('file', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "files",
                    bodyFormData,
                    {
                        params: {
                            g: 'upl'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response) {
                        that.handleFileUpload(response.data)
                    })
                    .catch(function (response) {
                        that.handleFileUpload({})
                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {

        if (this.state.isFetchLoading) {
            return <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        }

        if (this.state.isFetchError) {
            return null
        }

        if (this.state.isFetchSuccess) {
            var images = []

            for (var i in this.state.images) {
                const idx = i
                images.push(
                    <div key={this.state.images[idx].image} className="m-1">
                        <AuthenticatedImage key={this.state.images[idx].image} className="p-2" imageID={this.state.images[idx].image} imageStyle={{
                            maxHeight: "150px",
                            cursor: 'pointer'
                            // borderRadius: '50%'
                        }} onClick={() => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: null,
                                closeOnOutsideClick: true,
                                message: <AuthenticatedImage imageID={this.state.images[idx].image} imageStyle={{
                                    maxWidth: "100%",
                                    width: "100%"
                                }}
                                />
                            })
                        }} />
                        <br />
                        <div>
                            <div className='form-group'>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name={`primaryRadioImage`} id={`primaryRadioImage${idx}`} checked={this.state.images[idx].ip} onChange={(e) => {
                                        var tmpImages = this.state.images
                                        for (var ii in tmpImages) {
                                            tmpImages[ii].ip = false
                                        }
                                        tmpImages[idx].ip = e.target.checked
                                        this.setState({ images: tmpImages })
                                    }} />
                                    <label class="form-check-label" for={`primaryRadioImage${idx}`}>
                                        Is Primary
                                    </label>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={this.state.images[idx].eqr} id={`checkBoxImage${idx}`} onChange={(e) => {
                                        var tmpImages = this.state.images
                                        tmpImages[idx].eqr = e.target.checked
                                        this.setState({ images: tmpImages })
                                    }} />
                                    <label class="form-check-label" for={`checkBoxImage${idx}`}>
                                        Enable QR
                                    </label>
                                </div>

                            </div>
                            <div className='form-group'>
                                <label>QR Size</label>
                                <select disabled={!this.state.images[idx].eqr} className='form-control' value={this.state.images[idx].qs} onChange={(e) => {
                                    var tmpImages = this.state.images
                                    tmpImages[idx].qs = parseInt(e.target.value)
                                    this.setState({ images: tmpImages })
                                }} >
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={300}>300</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>QR Position</label>
                                <select disabled={!this.state.images[idx].eqr} className='form-control' value={this.state.images[idx].qp} onChange={(e) => {
                                    var tmpImages = this.state.images
                                    tmpImages[idx].qp = e.target.value
                                    this.setState({ images: tmpImages })
                                }} >
                                    <option value={`bl`}>Bottom Left</option>
                                    <option value={`br`}>Bottom Right</option>
                                    <option value={`tr`}>Top Right</option>
                                    <option value={`tl`}>Top Left</option>
                                    <option value={`md`}>Middle</option>
                                    <option value={`ml`}>Middle Left</option>
                                    <option value={`mr`}>Middle Right</option>
                                    <option value={`mt`}>Middle Top</option>
                                    <option value={`mb`}>Middle Bottom</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <span className="p-2 btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    var tmpImages = this.state.images
                                    tmpImages.splice(idx, 1)
                                    this.setState({ images: tmpImages })
                                }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                            </div>
                        </div>
                    </div>
                )
            }

            var files = []

            for (var i in this.state.files) {
                const idx = i
                files.push(
                    <div key={this.state.files[idx].file} className="m-1 card p-2">
                        <div className='form-group'>
                            <span>{this.state.files[idx].name}</span>
                        </div>
                        <div className='form-group'>
                            <label htmlFor={`editFileDescription${idx}`}>Description</label>
                            <textarea id={`editFileDescription${idx}`} className='form-control' value={this.state.files[idx].description} onChange={(e) => {
                                var tmpFiles = this.state.files
                                tmpFiles[idx].description = e.target.value
                                this.setState({ files: tmpFiles })
                            }} />
                        </div>
                        <div className='form-group'>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={this.state.files[idx].isUserSecured} id={`editisusersecuredcheckbox${idx}`} onChange={(e) => {
                                    var tmpFiles = this.state.files
                                    tmpFiles[idx].isUserSecured = e.target.checked
                                    this.setState({ files: tmpFiles })
                                }} />
                                <label class="form-check-label" for={`editisusersecuredcheckbox${idx}`}>
                                    Is User Secured
                                </label>
                            </div>

                        </div>

                        <div className='form-group'>
                            <span className="p-2 btn-link" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                var tmpFiles = this.state.files
                                tmpFiles.splice(idx, 1)
                                this.setState({ files: tmpFiles })
                            }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                        </div>
                    </div>
                )
            }
            var banners = []

            for (var i in this.state.banners) {

                const idx = i
                banners.push(
                    <div key={this.state.banners[idx].image} className="m-1 card p-2">
                        <AuthenticatedImage key={this.state.banners[idx].image} className="p-2" imageID={this.state.banners[idx].image} imageStyle={{
                            maxHeight: "150px",
                            cursor: 'pointer'
                            // borderRadius: '50%'
                        }} onClick={() => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: null,
                                closeOnOutsideClick: true,
                                message: <AuthenticatedImage imageID={this.state.banners[idx].image} imageStyle={{
                                    maxWidth: "100%",
                                    width: "100%"
                                }}
                                />
                            })
                        }} />
                        <br />
                        <div>
                            <div className='form-group'>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name={`primaryRadioImage`} id={`primaryRadioImage${idx}`} checked={this.state.banners[idx].ip} onChange={(e) => {
                                        var tmpBanners = this.state.banners
                                        for (var ii in tmpBanners) {
                                            tmpBanners[ii].ip = false
                                        }
                                        tmpBanners[idx].ip = e.target.checked
                                        this.setState({ banners: tmpBanners })
                                    }} />
                                    <label class="form-check-label" for={`primaryRadioImage${idx}`}>
                                        Is Primary
                                    </label>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={this.state.banners[idx].eqr} id={`checkBoxImage${idx}`} onChange={(e) => {
                                        var tmpBanners = this.state.banners
                                        tmpBanners[idx].eqr = e.target.checked
                                        this.setState({ banners: tmpBanners })
                                    }} />
                                    <label class="form-check-label" for={`checkBoxImage${idx}`}>
                                        Enable QR
                                    </label>
                                </div>

                            </div>
                            <div className='form-group'>
                                <label>QR Size</label>
                                <select disabled={!this.state.banners[idx].eqr} className='form-control' value={this.state.banners[idx].qs} onChange={(e) => {
                                    var tmpBanners = this.state.banners
                                    tmpBanners[idx].qs = parseInt(e.target.value)
                                    this.setState({ banners: tmpBanners })
                                }} >
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={300}>300</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>QR Position</label>
                                <select disabled={!this.state.banners[idx].eqr} className='form-control' value={this.state.banners[idx].qp} onChange={(e) => {
                                    var tmpBanners = this.state.banners
                                    tmpBanners[idx].qp = e.target.value
                                    this.setState({ banners: tmpBanners })
                                }} >
                                    <option value={`bl`}>Bottom Left</option>
                                    <option value={`br`}>Bottom Right</option>
                                    <option value={`tr`}>Top Right</option>
                                    <option value={`tl`}>Top Left</option>
                                    <option value={`md`}>Middle</option>
                                    <option value={`ml`}>Middle Left</option>
                                    <option value={`mr`}>Middle Right</option>
                                    <option value={`mt`}>Middle Top</option>
                                    <option value={`mb`}>Middle Bottom</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <span className="p-2 btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    var tmpBanners = this.state.banners
                                    tmpBanners.splice(idx, 1)
                                    this.setState({ banners: tmpBanners })
                                }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                            </div>
                        </div>
                    </div>
                )
            }

            return [
                <div className="container p-4">


                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <label htmlFor="editInventoryName"><b>Name</b></label>
                                <input type="text" id="editInventoryName" className="form-control border-1 small" name="name" value={this.state.name} onChange={(e) => {
                                    this.setState({ name: e.target.value })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryType"><b>Type</b></label>
                                <select class="custom-select" name="type" id="editInventoryType" value={this.state.type} onChange={(e) => {
                                    this.setState({ type: e.target.value })
                                }}>
                                    <option value="Goods">Goods</option>
                                    <option value="Service">Service</option>
                                    <option value="AddOn">Add On</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className='form-group' hidden={!(this.state.type === "AddOn")}>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <label><b>Select item to add this add-on item to</b></label>
                                <br />
                                <span className='btn-link' onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Select Inventory Item",
                                        message: <SelectInventoryItemDialog onClickCallback={(resultData) => {

                                            this.setState({
                                                addOnIdentifier: {
                                                    n: resultData.name,
                                                    s: resultData.sku,
                                                    id: resultData._id
                                                }
                                            })
                                            /* close the dialog */

                                            fullDialogV2Dispatcher.dispatch({
                                                actionType: "close-dialog",
                                                dialogIndex: selectInventoryItemDialogIndex
                                            })

                                        }} />,
                                        dialogIndexCallback: (c) => {
                                            selectInventoryItemDialogIndex = c
                                        }
                                    })
                                }}>Select</span>
                                <br />
                                <span className='p-2 card' hidden={this.state.addOnIdentifier.n === "" || this.state.addOnIdentifier.id === "" || this.state.addOnIdentifier.s === ""}>
                                    <b>{this.state.addOnIdentifier.n}</b>
                                    <span>{this.state.addOnIdentifier.s}</span>
                                    <span className='btn-link' onClick={() => {
                                        this.setState({
                                            addOnIdentifier: {
                                                n: "",
                                                s: "",
                                                id: ""
                                            }
                                        })
                                    }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <label htmlFor="editInventoryDescription"><b>Description</b></label>
                                <input type="text" id="editInventoryDescription" className="form-control border-1 small" name="name" value={this.state.description} onChange={(e) => {
                                    this.setState({ description: e.target.value })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryColor"><b>Color</b></label>
                                <div className="d-flex d-flex-inline align-items-center">


                                    {/* <div style={styles.swatch} onClick={() => {
                                        this.setState({ displayColorPicker: !this.state.displayColorPicker })
                                    }}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => {
                                            this.setState({ displayColorPicker: false })
                                        }} />
                                        <SketchPicker
                                            color={this.state.color.color}
                                            onChangeComplete={(color) => {
                                                var tmp_color = this.state.color
                                                tmp_color['color'] = color.rgb
                                                this.setState({ color: tmp_color })
                                            }}
                                        />
                                    </div> : null} */}

                                    <input type="color" id="editInventoryColorHex" className="form-control border-1 small ml-2" name="color" value={this.state.color.color} onChange={(e) => {
                                        var tmp_color = this.state.color
                                        tmp_color['color'] = e.target.value
                                        this.setState({ color: tmp_color })
                                    }} />

                                    <input type="text" id="editInventoryColor" className="form-control border-1 small ml-2" name="colorn" value={this.state.color.colorn} onChange={(e) => {
                                        var tmp_color = this.state.color
                                        tmp_color[e.target.name] = e.target.value
                                        this.setState({ color: tmp_color })
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label htmlFor="editInventoryEcomDescription"><b>E-commmerce Description</b></label>
                                {/* <textarea rows="5" type="text" id="inventoryEcomDescription" className="form-control border-1 small" name="name" value={this.state.ecomDescription} onChange={(e) => {
                                this.setState({ ecomDescription: e.target.value })
                            }} /> */}
                                <ReactQuill id='editInventoryEcomDescription' theme="snow" value={this.state.ecomDescription} onChange={(data) => {
                                    this.setState({ ecomDescription: data })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label htmlFor="editInventoryEcom"><b>E-commerce Visibility</b></label>
                                <br />
                                <small>Should the item appear on E-commerce platform?</small>
                                <br />
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="editInventoryEcom" defaultChecked={this.state.ecom} onChange={(e) => {
                                        this.setState({ ecom: e.target.checked })
                                    }} checked={this.state.ecom} />
                                    <label className="form-check-label" htmlFor="editInventoryEcom">
                                        Show on e-commerce platform
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label htmlFor="editInventoryCheckStockOnOrder"><b>Check Stock On Order</b></label>
                                <br />
                                <small>Should the backend check for stock availability on order placement?</small>
                                <br />
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="editInventoryCheckStockOnOrder" defaultChecked={this.state.checkstockonorder} onChange={(e) => {
                                        this.setState({ checkstockonorder: e.target.checked })
                                    }} checked={this.state.checkstockonorder} />
                                    <label className="form-check-label" htmlFor="editInventoryCheckStockOnOrder">
                                        Check stock on order placement
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label htmlFor="editInventoryKeywords"><b>Keywords</b></label>
                                {
                                    this.state.keywords.map((item, index) => (
                                        <div className=''>
                                            <input type="text" id={`editInventoryKeywords${index}`} className="form-control border-1 small" name={`keywords${index}`} value={this.state.keywords[index]} onChange={(e) => {
                                                var tmpKeywords = this.state.keywords
                                                tmpKeywords[index] = e.target.value
                                                this.setState({ keywords: tmpKeywords })
                                            }} />

                                            <span title='Remove' className='btn-link' onClick={() => {

                                                var tmpKeywords = this.state.keywords
                                                tmpKeywords.splice(parseInt(index), 1)
                                                this.setState({ keywords: tmpKeywords })

                                            }} >
                                                <small>
                                                    <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove
                                                </small>
                                            </span>
                                        </div>
                                    ))
                                }
                                <br />
                                <span className='btn-link' onClick={() => {
                                    var tmpKeywords = this.state.keywords
                                    tmpKeywords.push("")
                                    this.setState({ keywords: tmpKeywords })
                                }}>
                                    Add Keyword
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <label htmlFor="editInventorySku"><b>SKU</b></label>
                                <input type="text" id="editInventorySku" className="form-control border-1 small" name="name" value={this.state.sku} onChange={(e) => {
                                    this.setState({ sku: e.target.value })
                                }} disabled={true} />
                                <small id="editInvoiceNumberHelp" class="form-text text-muted">Use Only When Custom SKU Is Needed.</small>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryHsn"><b>HSN</b></label>
                                <input type="text" id="editInventoryHsn" className="form-control border-1 small" name="name" value={this.state.hsn} onChange={(e) => {
                                    this.setState({ hsn: e.target.value })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <label htmlFor="editInventoryQuantity"><b>Quantity</b></label>
                                <input disabled={true} type="number" id="editInventoryQuantity" className="form-control border-1 small" name="name" value={this.state.quantity} onChange={(e) => {
                                    this.setState({ quantity: e.target.value })
                                }} /></div>

                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                <label htmlFor="editInventoryUnit"><b>Unit</b></label>
                                <select class="custom-select" name="type" id="editInventoryUnit" value={this.state.unit} onChange={(e) => {
                                    this.setState({ unit: e.target.value })
                                }}>
                                    <option value="box">Box</option>
                                    <option value="pcs">Pcs</option>
                                    <option value="tn">tn</option>
                                    <option value="kg">kg</option>
                                    <option value="lbs">lbs</option>
                                    <option value="gm">gm</option>
                                    <option value="mg">mg</option>
                                    <option value="km">km</option>
                                    <option value="m">m</option>
                                    <option value="ft">ft</option>
                                    <option value="in">in</option>
                                    <option value="cm">cm</option>
                                    <option value="mm">mm</option>
                                    <option value="hour">hour</option>
                                    <option value="day">day</option>
                                    <option value="month">month</option>
                                    <option value="year">year</option>

                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <b>Dimension</b>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <label htmlFor="editInventoryDimensionHeight">Height</label>
                                <input type="number" id="editInventoryDimensionHeight" className="form-control border-1 small" name="height" value={this.state.dimensions.height} onChange={(e) => {
                                    var tmp_dimension = this.state.dimensions
                                    tmp_dimension[e.target.name] = parseFloat(e.target.value)
                                    this.setState({ dimensions: tmp_dimension })

                                }} />
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                <label htmlFor="editInventoryDimensionWidth">Width</label>
                                <input type="number" id="editInventoryDimensionWidth" className="form-control border-1 small" name="width" value={this.state.dimensions.width} onChange={(e) => {
                                    var tmp_dimension = this.state.dimensions
                                    tmp_dimension[e.target.name] = parseFloat(e.target.value)
                                    this.setState({ dimensions: tmp_dimension })
                                }} />
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                <label htmlFor="editInventoryDimensionDepth">Depth</label>
                                <input type="number" id="editInventoryDimensionDepth" className="form-control border-1 small" name="depth" value={this.state.dimensions.depth} onChange={(e) => {
                                    var tmp_dimension = this.state.dimensions
                                    tmp_dimension[e.target.name] = parseFloat(e.target.value)
                                    this.setState({ dimensions: tmp_dimension })
                                }} />
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                <label htmlFor="editInventoryDimensionUnit">Unit</label>
                                <select class="custom-select" name="unit" id="editInventoryDimensionUnit" value={this.state.dimensions.unit} onChange={(e) => {
                                    var tmp_dimension = this.state.dimensions
                                    tmp_dimension[e.target.name] = e.target.value
                                    this.setState({ dimensions: tmp_dimension })
                                }}>
                                    <option value="km">km</option>
                                    <option value="m">m</option>
                                    <option value="ft">ft</option>
                                    <option value="in">in</option>
                                    <option value="cm">cm</option>
                                    <option value="mm">mm</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <b>Weight</b>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">

                                <label htmlFor="editInventoryWeightValue">Value</label>
                                <input type="number" id="editInventoryWeightValue" className="form-control border-1 small" name="value" value={this.state.weight.value} onChange={(e) => {
                                    var tmp_weight = this.state.weight
                                    tmp_weight[e.target.name] = parseFloat(e.target.value)
                                    this.setState({ weight: tmp_weight })

                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryWeightUnit">Unit</label>
                                <select class="custom-select" name="unit" id="editInventoryWeightUnit" value={this.state.weight.unit} onChange={(e) => {
                                    var tmp_weight = this.state.weight
                                    tmp_weight[e.target.name] = e.target.value
                                    this.setState({ weight: tmp_weight })
                                }}>
                                    <option value="tn">tn</option>
                                    <option value="kg">kg</option>
                                    <option value="lbs">lbs</option>
                                    <option value="gms">gms</option>
                                    <option value="mg">mg</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <b>Info</b>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label htmlFor="editInventoryInfoManufacturer">Manufacturer</label>
                                <input type="text" id="editInventoryInfoManufacturer" className="form-control border-1 small" name="manufacturer" value={this.state.info.manufacturer} onChange={(e) => {
                                    var tmp_info = this.state.info
                                    tmp_info[e.target.name] = e.target.value
                                    this.setState({ info: tmp_info })
                                }} />
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label htmlFor="editInventoryInfoBrand">Brand</label>
                                <input type="text" id="editInventoryInfoBrand" className="form-control border-1 small" name="brand" value={this.state.info.brand} onChange={(e) => {
                                    var tmp_info = this.state.info
                                    tmp_info[e.target.name] = e.target.value
                                    this.setState({ info: tmp_info })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label htmlFor="editInventoryInfoUpc">UPC</label>
                                <input type="text" id="editInventoryInfoUpc" className="form-control border-1 small" name="upc" value={this.state.info.upc} onChange={(e) => {
                                    var tmp_info = this.state.info
                                    tmp_info[e.target.name] = e.target.value
                                    this.setState({ info: tmp_info })
                                }} />

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label htmlFor="editInventoryInfoEan">EAN</label>
                                <input type="text" id="editInventoryInfoEan" className="form-control border-1 small" name="ean" value={this.state.info.ean} onChange={(e) => {
                                    var tmp_info = this.state.info
                                    tmp_info[e.target.name] = e.target.value
                                    this.setState({ info: tmp_info })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label htmlFor="editInventoryInfoMpn">MPN</label>
                                <input type="text" id="editInventoryInfoMpn" className="form-control border-1 small" name="mpn" value={this.state.info.mpn} onChange={(e) => {
                                    var tmp_info = this.state.info
                                    tmp_info[e.target.name] = e.target.value
                                    this.setState({ info: tmp_info })
                                }} />

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <label htmlFor="editInventoryInfoIsbn">ISBN</label>
                                <input type="text" id="editInventoryInfoIsbn" className="form-control border-1 small" name="isbn" value={this.state.info.isbn} onChange={(e) => {
                                    var tmp_info = this.state.info
                                    tmp_info[e.target.name] = e.target.value
                                    this.setState({ info: tmp_info })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <b>Price</b>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryPriceCp">Cost Price</label>
                                <input type="number" id="editInventoryPriceCp" className="form-control border-1 small" name="cp" value={this.state.price.cp} onChange={(e) => {
                                    var tmp_price = this.state.price
                                    tmp_price[e.target.name] = parseInt(e.target.value)
                                    this.setState({ price: tmp_price })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryPriceSp">Selling Price</label>
                                <input type="number" id="editInventoryPriceSp" className="form-control border-1 small" name="sp" value={this.state.price.sp} onChange={(e) => {
                                    var tmp_price = this.state.price
                                    tmp_price[e.target.name] = parseInt(e.target.value)
                                    this.setState({ price: tmp_price })
                                }} />

                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryPriceMrp">MRP</label>
                                <input type="number" id="editInventoryPriceMrp" className="form-control border-1 small" name="mrp" value={this.state.price.mrp} onChange={(e) => {
                                    var tmp_price = this.state.price
                                    tmp_price[e.target.name] = parseInt(e.target.value)
                                    this.setState({ price: tmp_price })
                                }} />

                            </div>
                            {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <label for="inventoryPriceUnit">Unit</label>
                            <select class="custom-select" name="unit" id="inventoryPriceUnit" defaultValue={this.state.price.unit} value={this.state.price.unit} onChange={(e) => {
                                var tmp_price = this.state.price
                                tmp_price[e.target.name] = e.target.value
                                this.setState({ price: tmp_price })
                            }}>
                                <option value="inr">INR</option>
                                <option value="usd">USD</option>
                            </select>
                        </div> */}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <b>Tax Info</b>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryTaxP">Tax Bracket (%)</label>
                                <input type="number" id="editInventoryTaxP" className="form-control border-1 small" name="p" value={this.state.tax.p} onChange={(e) => {
                                    var tmp_tax = this.state.tax
                                    var val = e.target.value
                                    if (parseInt(val) < 0) {
                                        val = 0
                                    }
                                    if (parseInt(val) > 10000) {
                                        val = 10000
                                    }
                                    tmp_tax[e.target.name] = parseInt(val)
                                    this.setState({ tax: tmp_tax })
                                }} />
                            </div>
                            {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <label for="inventoryTaxCategory">Tax Category</label>
                            <select class="custom-select" name="c" id="inventoryTaxCategory" defaultValue={this.state.tax.c} value={this.state.tax.c} onChange={(e) => {
                                var tmp_tax = this.state.tax
                                tmp_tax[e.target.name] = e.target.value
                                this.setState({ tax: tmp_tax })
                            }}>
                                <option value="cgst">CGST</option>
                                <option value="utgst">UTGST</option>
                                <option value="sgst">SGST</option>
                                <option value="igst">IGST</option>
                            </select>
                        </div> */}
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryTaxType">Tax Type</label>
                                <select class="custom-select" name="t" id="editInventoryTaxType" value={this.state.tax.t} onChange={(e) => {
                                    var tmp_tax = this.state.tax
                                    tmp_tax[e.target.name] = e.target.value
                                    this.setState({ tax: tmp_tax })
                                }}>
                                    <option value="inc">Inclusive</option>
                                    <option value="exc">Exclusive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <b>Lead Time</b>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryLeadTimeValue">Value</label>
                                <input type="number" id="editInventoryLeadTimeValue" className="form-control border-1 small" name="p" value={this.state.leadtime.v} onChange={(e) => {
                                    var tmp_leadtime = this.state.leadtime
                                    var val = e.target.value
                                    if (parseInt(val) < 0) {
                                        val = 0
                                    }
                                    if (parseInt(val) > 100) {
                                        val = 100
                                    }
                                    tmp_leadtime[e.target.name] = parseInt(val)
                                    this.setState({ leadtime: tmp_leadtime })
                                }} />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryLeadtimeUnit">Unit</label>
                                <select class="custom-select" name="u" id="editInventoryLeadtimeUnit" value={this.state.leadtime.u} onChange={(e) => {
                                    var tmp_leadtime = this.state.leadtime
                                    tmp_leadtime[e.target.name] = e.target.value
                                    this.setState({ leadtime: tmp_leadtime })
                                }}>
                                    <option value="s">Seconds</option>
                                    <option value="h">Hours</option>
                                    <option value="d">Days</option>
                                    <option value="w">Weeks</option>
                                    <option value="m">Months</option>
                                    <option value="y">Years</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryCoupon"><b>Coupon</b></label>
                                <input maxLength="12" type="text" id="editInventoryCoupon" className="form-control border-1 small" name="coupon" value={this.state.coupon} onChange={(e) => {
                                    this.setState({ coupon: e.target.value })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryArticle"><b>Article</b></label>
                                <input maxLength="100" type="text" id="editInventoryArticle" className="form-control border-1 small" name="article" value={this.state.article} onChange={(e) => {
                                    this.setState({ article: e.target.value })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryMaterial"><b>Material</b></label>
                                <input maxLength="100" type="text" id="editInventoryMaterial" className="form-control border-1 small" name="material" value={this.state.material} onChange={(e) => {
                                    this.setState({ material: e.target.value })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryGender"><b>Gender</b></label>
                                <input maxLength="100" type="text" id="editInventoryGender" className="form-control border-1 small" name="gender" value={this.state.gender} onChange={(e) => {
                                    this.setState({ gender: e.target.value })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryShape"><b>Shape</b></label>
                                <input maxLength="100" type="text" id="editInventoryShape" className="form-control border-1 small" name="shape" value={this.state.shape} onChange={(e) => {
                                    this.setState({ shape: e.target.value })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryOccasion"><b>Occasion</b></label>
                                <input maxLength="100" type="text" id="editInventoryOccasion" className="form-control border-1 small" name="occasion" value={this.state.occasion} onChange={(e) => {
                                    this.setState({ occasion: e.target.value })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryPurity"><b>Purity</b></label>
                                <input maxLength="100" type="text" id="editInventoryPurity" className="form-control border-1 small" name="purity" value={this.state.purity} onChange={(e) => {
                                    this.setState({ purity: e.target.value })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryPlating"><b>Plating</b></label>
                                <input maxLength="100" type="text" id="editInventoryPlating" className="form-control border-1 small" name="plating" value={this.state.plating} onChange={(e) => {
                                    this.setState({ plating: e.target.value })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryCountryOfOrigin"><b>Country Of Origin</b></label>


                                <select id='editInventoryCountryOfOrigin' className='form-control' value={this.state.countryoforigin} onChange={(e) => {
                                    this.setState({ countryoforigin: e.target.value })
                                }}>
                                    <option value="">Select Country of Origin</option>
                                    {
                                        country_json_1.map((item, index) => (
                                            <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryMaxPerEcomOrder"><b>Max Per Ecom Order</b></label>
                                <input type="number" min={0} id="editInventoryMaxPerEcomOrder" className="form-control border-1 small" name="maxperecomorder" value={this.state.maxperecomorder} onChange={(e) => {
                                    this.setState({ maxperecomorder: e.target.value })
                                }} />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label htmlFor="editInventoryConfigurationIdentifier"><b>Configuration Identifier</b></label>
                                <input type="text" min={0} id="editInventoryConfigurationIdentifier" className="form-control border-1 small" name="configurationIdentifier" value={this.state.configurationIdentifier} onChange={(e) => {
                                    this.setState({ configurationIdentifier: e.target.value })
                                }} />

                            </div>

                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">


                            <div class="card" >
                                <div class="card-body">
                                    {/* <h5 class="card-title">Add Images</h5> */}
                                    <label htmlFor=""><b>Images</b></label>
                                    <div class="d-flex flex-wrap align-items-center">
                                        {images}
                                    </div>
                                    {/* <this.Display_images /> */}
                                    <br />
                                    <input type="file" class="form-control-file" id="editAddReviewImageUpload" onChange={(input) => {
                                        this.handleImageUpload(input)
                                    }} hidden multiple />
                                    <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                                        document.getElementById(`editAddReviewImageUpload`).click();
                                    }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Images</button>

                                </div>
                            </div>



                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                            <div class="card" >
                                <div class="card-body">
                                    <label htmlFor=""><b>Banners</b></label>
                                    {/* <h5 class="card-title">Add Images</h5> */}
                                    <div class="d-flex flex-wrap align-items-center">
                                        {banners}
                                    </div>
                                    {/* <this.Display_images /> */}
                                    <br />
                                    <input type="file" class="form-control-file" id="editAddBannerUpload" onChange={(input) => {
                                        this.handleBannerUpload(input)
                                    }} hidden multiple />
                                    <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                                        document.getElementById(`editAddBannerUpload`).click();
                                    }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Banner</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                            <div class="card" >
                                <div class="card-body">
                                    <label htmlFor=""><b>Files</b></label>

                                    <div class="d-flex flex-wrap align-items-center">
                                        {files}
                                    </div>

                                    <br />
                                    <input type="file" class="form-control-file" id="editAddFilesUpload" onChange={(input) => {
                                        this.fileUpload(input)
                                    }} hidden multiple />
                                    <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                                        document.getElementById(`editAddFilesUpload`).click();
                                    }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Files</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label htmlFor="inventoryMetaKeyValue"><b>Meta Key Value</b></label>
                                {
                                    this.state.metakeyvalue.map((item, index) => (
                                        <div className=''>
                                            <label htmlFor={`editInventoryMetaKeyValueKey${index}`}>Key</label>
                                            <input maxLength={100} type="text" id={`editInventoryMetaKeyValueKey${index}`} className="form-control border-1 small" name={`editMetakeyvaluekey${index}`} value={this.state.metakeyvalue[index].k} onChange={(e) => {
                                                var tmpMetaKeyValue = this.state.metakeyvalue
                                                tmpMetaKeyValue[index].k = e.target.value
                                                this.setState({ metakeyvalue: tmpMetaKeyValue })
                                            }} />

                                            <label htmlFor={`editInventoryMetaKeyValueValue${index}`}>Value</label>
                                            <input maxLength={100} type="text" id={`editInventoryMetaKeyValueValue${index}`} className="form-control border-1 small" name={`editMetakeyvaluevalue${index}`} value={this.state.metakeyvalue[index].v} onChange={(e) => {
                                                var tmpMetaKeyValue = this.state.metakeyvalue
                                                tmpMetaKeyValue[index].v = e.target.value
                                                this.setState({ metakeyvalue: tmpMetaKeyValue })
                                            }} />

                                            <span title='Remove' className='btn-link' onClick={() => {

                                                var tmpMetaKeyValue = this.state.metakeyvalue
                                                tmpMetaKeyValue.splice(parseInt(index), 1)
                                                this.setState({ metakeyvalue: tmpMetaKeyValue })

                                            }} >
                                                <small>
                                                    <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove
                                                </small>
                                            </span>
                                        </div>
                                    ))
                                }
                                <br />
                                <span className='btn-link' onClick={() => {
                                    var tmpMetaKeyValue = this.state.metakeyvalue
                                    tmpMetaKeyValue.push({
                                        k: "",
                                        v: ""
                                    })
                                    this.setState({ metakeyvalue: tmpMetaKeyValue })
                                }}>
                                    Add Key Value
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label htmlFor="editInventoryFutureStocking"><b>Future Stocking</b></label>
                                {
                                    this.state.futurestocking.map((item, index) => (
                                        <div className=''>
                                            <label htmlFor={`editInventoryFutureStockingTime${index}`}>Time</label>
                                            <input maxLength={100} type="datetime-local" id={`editInventoryFutureStockingTime${index}`} className="form-control border-1 small" name={`editInventoryFutureStockingTime${index}`} value={this.state.futurestocking[index].ta} onChange={(e) => {
                                                var tmpFutureStocking = this.state.futurestocking
                                                tmpFutureStocking[index].ta = e.target.value
                                                this.setState({ futurestocking: tmpFutureStocking })
                                            }} />


                                            <label htmlFor={`editInventoryFutureStockingStockAmount${index}`}>Stock Amount</label>
                                            <input min={1} type="number" id={`editInventoryFutureStockingStockAmount${index}`} className="form-control border-1 small" name={`editInventoryFutureStockingStockAmount${index}`} value={this.state.futurestocking[index].sa} onChange={(e) => {
                                                var tmpFutureStocking = this.state.futurestocking
                                                tmpFutureStocking[index].sa = e.target.value
                                                this.setState({ futurestocking: tmpFutureStocking })
                                            }} />


                                            <label htmlFor={`editInventoryFutureStockingNote${index}`}>Note</label>
                                            <textarea maxLength={1000} type="text" id={`editInventoryFutureStockingNote${index}`} className="form-control border-1 small" name={`editInventoryFutureStockingNote${index}`} value={this.state.futurestocking[index].n} onChange={(e) => {
                                                var tmpFutureStocking = this.state.futurestocking
                                                tmpFutureStocking[index].n = e.target.value
                                                this.setState({ futurestocking: tmpFutureStocking })
                                            }} />

                                            <span title='Remove' className='btn-link' onClick={() => {

                                                var tmpFutureStocking = this.state.futurestocking
                                                tmpFutureStocking.splice(parseInt(index), 1)
                                                this.setState({ futurestocking: tmpFutureStocking })

                                            }} >
                                                <small>
                                                    <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove
                                                </small>
                                            </span>
                                        </div>
                                    ))
                                }
                                <br />
                                <span className='btn-link' onClick={() => {
                                    var tmpFutureStocking = this.state.futurestocking
                                    tmpFutureStocking.push({
                                        ta: DateTime.local().plus({ days: 1 }).toFormat("yyyy-LL-dd'T'HH:mm"),
                                        sa: 1,
                                        n: ""
                                    })
                                    this.setState({ futurestocking: tmpFutureStocking })
                                }}>
                                    Add Future Stock
                                </span>
                            </div>
                        </div>
                    </div>


                    <div className='form-group'>
                        <div className="row mt-2">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <button disabled={this.state.isLoading} className="btn btn-primary m-1" id="editPostItemButton" onClick={this.postItem}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Edit</button>

                                <button className="btn btn-primary m-1" onClick={() => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Clear Data",
                                        message: <div>
                                            <div className='form-group'>
                                                Do you want to clear all fields?
                                            </div>

                                            <div className='form-group'>
                                                <button className='btn btn-danger' onClick={this.resetAllFields}>Clear</button>
                                            </div>

                                        </div>,
                                        dialogIndexCallback: (c) => {
                                            clearEditInventoryDataDialogIndex = c
                                        }
                                    })
                                }}>Clear</button>
                                <this.Status />
                            </div>
                        </div>
                    </div>
                </div>


            ]

        }



        return null
    }

}

class ViewSingleInventoryData extends React.Component {
    constructor(props) {
        super()

        this.data = props.data

        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {

        }
    }

    render() {
        return (

            <div className="p-2">
                <div className='form-group'>
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Name</th>
                                    <td>{this.data.n}</td>
                                </tr>
                                <tr>
                                    <th scope="row">SKU</th>
                                    <td>{this.data.s}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Quantity</th>
                                    <td>{this.data.q}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Price</th>
                                    <td>
                                        <div>
                                            MRP: {ecommerce_helper_function_get_formatted_price(this.data.p.mrp)}
                                            <br />
                                            SP: {ecommerce_helper_function_get_formatted_price(this.data.p.sp)}
                                            <br />
                                            CP: {ecommerce_helper_function_get_formatted_price(this.data.p.cp)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Ecommerce Visibility</th>
                                    <td>{this.data.e ? <span className="text-success">Visible</span> : <span className="text-danger">NOT Visible</span>}</td>
                                </tr>

                                <tr>
                                    <th scope="row">Created</th>
                                    <td>{DateTime.fromISO(this.data.t).toLocaleString(DateTime.DATETIME_MED)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Updated</th>
                                    <td>{DateTime.fromISO(this.data.up).toLocaleString(DateTime.DATETIME_MED)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Description</th>
                                    <td><div style={{
                                        whiteSpace: 'pre-wrap'
                                    }}>{this.data.d}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='form-group'>
                    <button className='btn btn-primary' onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Edit Item",
                            message: <EditInventoryData data={this.data} itemID={this.data._id} />,
                            dialogWidth: 3,
                            dialogIndexCallback: (c) => {
                                editInventoryDataDialogIndex = c
                            }
                        })
                    }}>Edit</button>
                </div>
            </div>
        )
    }
}

class SelectCollection extends React.Component {
    constructor(props) {
        super()
        this.onDoneCallback = props.onDoneCallback
        this.state = {
        }
    }
    render() {


        return (
            <div className="p-2">

                <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                    if (this.onDoneCallback) {
                        this.onDoneCallback(resultData)
                    }
                    fullDialogV2Dispatcher.dispatch({
                        actionType: "close-dialog",
                        dialogIndex: selectCollectionDialogIndex
                    })
                }} resultClickable={true} isResultLink={false} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="collections" />
            </div>
        )
    }
}

class SelectedItems extends React.Component {
    constructor(props) {
        super()
        this.selectedItems = props.selectedItems
        this.onDoneCallback = props.onDoneCallback
        this.state = {
            selectedItems: this.selectedItems
        }
    }
    render() {

        /* prepare selected items */

        var selectedItems = []
        for (var i in this.state.selectedItems) {
            const idx = i
            selectedItems.push(
                <div className="form-group border rounded m-1 p-1">
                    <div className="d-flex justify-content-between mb-0 pb-0">
                        <span>
                            <b>{this.state.selectedItems[i].name}</b>
                            <br />
                            {this.state.selectedItems[i].sku}
                        </span>
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            const tmpSelectedItems = this.state.selectedItems
                            delete tmpSelectedItems[idx]
                            this.setState({ selectedItems: tmpSelectedItems })
                        }}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </div>
                </div>
            )
        }

        return (
            <div className="p-2">
                <div className="form-group">
                    Selected Items
                </div>
                <div className="form-group" style={{
                    maxHeight: '200px',
                    overflowY: 'scroll'
                }}>
                    {selectedItems}
                </div>
                <div className="form-group">
                    <label>Inventory Items</label>
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                        const tmpSelectedItems = this.state.selectedItems
                        tmpSelectedItems[resultData._id] = resultData
                        this.setState({ selectedItems: tmpSelectedItems })
                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="t" searchLimit={5} urlEndPoint="inventory" />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" onClick={() => {
                        if (this.onDoneCallback) {
                            this.onDoneCallback(this.state.selectedItems)
                        }
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "close-dialog",
                            dialogIndex: selectedItemsDialogIndex
                        })
                    }}>Done</button>
                </div>
            </div>
        )
    }
}

class DownloadCatalog extends React.Component {
    constructor(props) {
        super()

        this.stateData = props.stateData
        this.state = {
            displayPrice: true,
            displaySize: true,
            displaySKU: true,
            displayImage: true,
            displayStock: true,

            isLoading: false,
            isError: false,
            isSuccess: false,

            itemsPerRow: 3,

            toDate: this.stateData.toDate,
            fromDate: this.stateData.fromDate,
            ecom: this.stateData.ecom,
            all: this.stateData.all,
            searchTerm: this.stateData.searchTerm,
            onlySelectedItems: this.stateData.onlySelectedItems,
            selectedItems: this.stateData.selectedItems,
            outOfStock: this.stateData.outOfStock,
            collectionID: this.stateData.collectionID,

            onlyCollectionItems: this.stateData.onlyCollectionItems,
            onlyNonCollectionItems: this.stateData.onlyNonCollectionItems,

        }
    }

    downloadCatalogPDF = () => {
        this.setState({ isLoading: true })

        /* prepare selected items data */

        var selectedItems = []
        for (var i in this.state.selectedItems) {
            selectedItems.push(i)
        }

        const that = this
        axios.post(ADMINURL + "inventory", {
            f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
            t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
            s: this.state.searchTerm,
            e: this.state.ecom,
            a: this.state.all,
            i: selectedItems,
            o: this.state.onlySelectedItems,
            p: this.state.displayPrice,
            sz: this.state.displaySize,
            sk: this.state.displaySKU,
            im: this.state.displayImage,
            ds: this.state.displayStock,
            r: parseInt(this.state.itemsPerRow),
            c: this.state.collectionID,
            os: this.state.outOfStock,
            nc: this.state.onlyNonCollectionItems,
            oc: this.state.onlyCollectionItems
        }, {
            params: {
                g: "cat",
            },
            headers: { Authorization: ACCT },
            responseType: 'blob'

        })
            .then((response) => {
                try {
                    that.setState({ isLoading: false })
                    let headerLine = response.headers['content-disposition'];
                    let startFileNameIndex = headerLine.indexOf('"') + 1
                    let endFileNameIndex = headerLine.lastIndexOf('"');
                    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                } catch (error) {

                    fullDialogV2Dispatcher.dispatch({
                        actionType: "show-dialog",
                        title: "Catalog Download",
                        message: <div className="text-danger">Failed to download catalog. Please try again later.</div>
                    })

                }
            });
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label>Items Per Row</label>
                    <input type="number" min="1" className="form-control" value={this.state.itemsPerRow} onChange={(e) => {
                        this.setState({ itemsPerRow: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="catalogDownloadPrice" checked={this.state.displayPrice} onChange={(e) => {
                            this.setState({ displayPrice: e.target.checked })
                        }} />
                        <label class="form-check-label" for="catalogDownloadPrice">
                            Display Price
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="catalogDownloadSize" checked={this.state.displaySize} onChange={(e) => {
                            this.setState({ displaySize: e.target.checked })
                        }} />
                        <label class="form-check-label" for="catalogDownloadSize">
                            Display Size
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="catalogDownloadSKU" checked={this.state.displaySKU} onChange={(e) => {
                            this.setState({ displaySKU: e.target.checked })
                        }} />
                        <label class="form-check-label" for="catalogDownloadSKU">
                            Display SKU
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="catalogDownloadImage" checked={this.state.displayImage} onChange={(e) => {
                            this.setState({ displayImage: e.target.checked })
                        }} />
                        <label class="form-check-label" for="catalogDownloadImage">
                            Display Image
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="catalogDownloadStock" checked={this.state.displayStock} onChange={(e) => {
                            this.setState({ displayStock: e.target.checked })
                        }} />
                        <label class="form-check-label" for="catalogDownloadStock">
                            Display Stock
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    <button className="btn btn-primary m-1" disabled={this.state.isLoading} onClick={this.downloadCatalogPDF}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Download</button>
                </div>
            </div>
        )
    }
}

class DownloadInventoryData extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")

        /* prepare dates */
        var toDate = DateTime.local().toFormat("yyyy-LL-dd'T'HH:mm")
        var fromDate = DateTime.local().minus({ years: 1 }).toFormat("yyyy-LL-dd'T'HH:mm")

        /* search data caller */
        this.viewInventoryDispatcher = null

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            toDate: toDate,
            fromDate: fromDate,
            ecom: false,
            all: false,
            searchTerm: "",
            onlySelectedItems: false,
            outOfStock: false,

            onlyCollectionItems: false,
            onlyNonCollectionItems: false,

            selectedItems: {},
            collectionData: null,
            collectionID: undefined
        }
    }

    processExcel = (data) => {
        this.setState({ isLoading: true })

        const sheetData = []
        // // prepare data
        for (var i in data) {
            var ecomStatus = "Yes"
            if (!data[i].e) {
                ecomStatus = "No"
            }

            sheetData.push(
                {
                    "Name": data[i].n,
                    "SKU": data[i].s,
                    "Quantity": data[i].q,
                    "MRP": data[i].p.mrp,
                    "Ecom": ecomStatus,
                    "Collection Name": data[i].cn,
                    "Created": DateTime.fromISO(data[i].c).toLocaleString(DateTime.DATETIME_MED),
                    "Updated": DateTime.fromISO(data[i].u).toLocaleString(DateTime.DATETIME_MED),
                }
            )
        }

        var wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(sheetData)
        wb.SheetNames.push("Inventory Items")
        wb.Sheets["Inventory Items"] = sheet

        const d = new Date()
        const filename = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}-inventory-items.xlsx`

        XLSX.writeFile(wb, filename)


        this.setState({ isLoading: false })
    }

    handleDownloadData = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get inventory data")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "View Inventory Data",
                message: `Failed to view inventory data. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }

        this.processExcel(response.payload)

    }

    downloadData = () => {
        this.setState({ isLoading: true })

        var selectedItems = []
        for (var i in this.state.selectedItems) {
            selectedItems.push(i)
        }

        const that = this
        axios.post(ADMINURL + "inventory",
            {
                f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
                t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
                s: this.state.searchTerm,
                e: this.state.ecom,
                a: this.state.all,
                i: selectedItems,
                o: this.state.onlySelectedItems,
                c: this.state.collectionID,
                os: this.state.outOfStock,
                nc: this.state.onlyNonCollectionItems,
                oc: this.state.onlyCollectionItems
            }
            , {
                params: {
                    g: "dwn",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleDownloadData(response.data)
            })
            .catch(function (error) {
                that.handleDownloadData({})
            })

    }

    viewData = () => {

        /* prepare selected items data */

        var selectedItems = []
        for (var i in this.state.selectedItems) {
            selectedItems.push(i)
        }

        this.viewInventoryDispatcher.dispatch({
            actionType: "set-get-data",
            postData: {
                f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
                t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
                s: this.state.searchTerm,
                e: this.state.ecom,
                a: this.state.all,
                i: selectedItems,
                o: this.state.onlySelectedItems,
                c: this.state.collectionID,
                os: this.state.outOfStock,

                nc: this.state.onlyNonCollectionItems,
                oc: this.state.onlyCollectionItems

            }
        })
    }

    render() {

        /* prepare selection collection */

        var selectedCollection = null
        if (this.state.collectionData !== null) {
            selectedCollection = <div className="p-2 border rounded">
                <b>{this.state.collectionData.name}</b>
                <br />
                <p style={{
                    whiteSpace: 'pre-wrap'
                }}>{this.state.collectionData.description}</p>
                <br />
                <span className="btn-link" onClick={() => {
                    this.setState({ collectionData: null, collectionID: undefined })
                }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove</span>
            </div>
        }

        return (
            <div className="p-2">
                <div className="d-flex flex-wrap">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="inventoryDataDownloadName">Search</label>
                            <input type="text" className="form-control" id="inventoryDataDownloadName" value={this.state.searchTerm} onChange={(e) => {
                                this.setState({ searchTerm: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            Date Range
                            <div className="form-group">
                                <label htmlFor="inventoryDataDownloadFromDate">From</label>
                                <input className="form-control" type="datetime-local" id="inventoryDataDownloadFromDate" value={this.state.fromDate} onChange={(e) => {
                                    this.setState({ fromDate: e.target.value })
                                }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inventoryDataDownloadToDate">To</label>
                                <input className="form-control" type="datetime-local" id="inventoryDataDownloadToDate" value={this.state.toDate} onChange={(e) => {
                                    this.setState({ toDate: e.target.value })
                                }} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" disabled={this.state.all} value="" id="inventoryDataDownloadEcom" checked={this.state.ecom} onChange={(e) => {
                                    this.setState({ ecom: e.target.checked })
                                }} />
                                <label class="form-check-label" for="inventoryDataDownloadEcom">
                                    Visible on E-commerce
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="inventoryDataDownloadAll" checked={this.state.all} onChange={(e) => {
                                    this.setState({ all: e.target.checked })
                                }} />
                                <label class="form-check-label" for="inventoryDataDownloadAll">
                                    All Items
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="inventoryDataDownloadOutOfStock" checked={this.state.outOfStock} onChange={(e) => {
                                    this.setState({ outOfStock: e.target.checked })
                                }} />
                                <label class="form-check-label" for="inventoryDataDownloadOutOfStock">
                                    Out Of Stock Items
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="inventoryDataDownloadOnlyCollectionItems" checked={this.state.onlyCollectionItems} onChange={(e) => {
                                    this.setState({ onlyCollectionItems: e.target.checked })
                                }} />
                                <label class="form-check-label" for="inventoryDataDownloadOnlyCollectionItems">
                                    Get All Items Which Are In Some Collections
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="inventoryDataDownloadOnlyNonCollectionItems" checked={this.state.onlyNonCollectionItems} onChange={(e) => {
                                    this.setState({ onlyNonCollectionItems: e.target.checked })
                                }} />
                                <label class="form-check-label" for="inventoryDataDownloadOnlyNonCollectionItems">
                                    Get All Items Which Are Not In Any Collection
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <span className="btn-link" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Add/View Specific Items",
                                    message: <SelectedItems selectedItems={this.state.selectedItems} onDoneCallback={(data) => {
                                        this.setState({ selectedItems: data })
                                    }} />,
                                    dialogIndexCallback: (idx) => {
                                        selectedItemsDialogIndex = idx
                                    },
                                })
                            }}>Add/View Specific Items</span>
                        </div>
                        <div className="form-group">
                            Selected Items: {Object.size(this.state.selectedItems)} items selected
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" disabled={this.state.all} id="inventoryDataDownloadOnlySelectedItems" checked={this.state.onlySelectedItems} onChange={(e) => {
                                    this.setState({ onlySelectedItems: e.target.checked })
                                }} />
                                <label class="form-check-label" for="inventoryDataDownloadOnlySelectedItems">
                                    Only Selected Items
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <span className="btn-link" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Select Collection",
                                    message: <SelectCollection onDoneCallback={(data) => {
                                        this.setState({ collectionData: data, collectionID: data._id })
                                    }} />,
                                    dialogIndexCallback: (idx) => {
                                        selectCollectionDialogIndex = idx
                                    },
                                })
                            }}>Get Only Collection Items</span>
                            <br />
                            <small>Only collection items will be fetched</small>
                        </div>

                        <div className="form-group">
                            Selected Collection
                            <br />
                            {selectedCollection}
                        </div>

                        <div className="form-group">
                            <span className="text-danger" hidden={(this.state.collectionID === undefined)}><small>Only items in this collection will be fetched!</small></span>
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary m-1" disabled={this.state.isLoading} onClick={this.viewData}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>View</button>
                            <button className="btn btn-primary m-1" disabled={this.state.isLoading} onClick={this.downloadData}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Download</button>
                            <button className="btn btn-primary m-1" disabled={this.state.isLoading} onClick={() => {
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Download Catalog",
                                    message: <DownloadCatalog stateData={this.state} />
                                })
                            }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Download Catalog PDF</button>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <ItemSearch nameTitleKey="n" onClickCallback={(resultData) => {
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Inventory Item",
                                dialogWidth: 2,
                                message: <ViewSingleInventoryData data={resultData} />
                            })
                        }} isResultLink={false} resultClickable={true} searchAllowed={false} gType="dii" timeKey="t" urlEndPoint="inventory" subInfoKeys={[
                            {
                                "key": "s",
                                "map": function (data) {
                                    return <b>{data}</b>
                                }
                            },
                            {
                                "key": "p",
                                "map": function (data) {
                                    if (data !== undefined) {
                                        if (data.mrp !== undefined) {
                                            return [<br />, <span>MRP: {ecommerce_helper_function_get_formatted_price(data.mrp)}</span>]
                                        } else {
                                            return [<br />, <span>SP: {ecommerce_helper_function_get_formatted_price(data.mrp)}</span>]
                                        }
                                    }
                                }
                            },
                            {
                                "key": "e",
                                "map": function (data) {
                                    if (data === true) {
                                        return [<br />, <span className="text-success">Visible on Ecommerce</span>]
                                    } else {
                                        return [<br />, <span className="text-danger">NOT Visible on Ecommerce</span>]
                                    }

                                }
                            },
                            {
                                "key": "cn",
                                "map": function (data) {
                                    if (String(data).trim().length > 0) {
                                        return [<br />, <span className="">Collection: {data}</span>]
                                    }
                                    return null
                                }
                            },
                            {
                                "key": "i",
                                "map": function (data) {
                                    if (data !== undefined) {
                                        if (data.length === 0) {
                                            return null
                                        }
                                        /* prepare images */
                                        var images = []
                                        for (var i in data) {
                                            const idx = i
                                            images.push(
                                                <div style={{
                                                    maxWidth: '100px'
                                                }}>
                                                    <AuthenticatedImage key={data[idx].image} className="p-2" imageID={data[idx].image} imageStyle={{
                                                        width: "100%",
                                                        cursor: 'pointer'
                                                    }} onClick={() => {
                                                        fullDialogDispatcher.dispatch({
                                                            actionType: "show-dialog",
                                                            title: null,
                                                            closeOnOutsideClick: true,
                                                            message: <AuthenticatedImage imageID={data[idx].image} imageStyle={{
                                                                maxWidth: "100%",
                                                                width: "100%"
                                                            }}
                                                            />
                                                        })
                                                    }} />
                                                </div>
                                            )
                                        }
                                        return <div class="d-flex flex-wrap">
                                            {images}
                                        </div>
                                    }
                                }
                            },

                        ]} urlParams={{
                            uid: this.userID
                        }
                        } sortCriteria="t"
                            postData={{
                                f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
                                t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
                                s: this.state.searchTerm,
                                e: this.state.ecom,
                                a: this.state.all
                            }}
                            getDataCaller={(dispatcher) => {
                                this.viewInventoryDispatcher = dispatcher
                            }}
                        />

                    </div>
                </div>
                {/* <div className="row m-0">
                    <div className="col-xl-5 col-md-5 col-5 col-sm-12 ">
                       
                    </div>
                </div> */}
            </div>
        )
    }
}




class DeleteSingleVideoInventoryItem extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,
        }
    }

    handleDeleteVideo = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Delete Video",
                message: `Failed to delete video. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Delete Video",
                message: `Failed to delete video. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Delete Video",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({
            videoTitle: "",
            videoDescription: "",
            videoLink: ""
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Delete Video",
            message: <div className="p-2">
                <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></span>&nbsp;Video successully deleted.
            </div>
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-dialog",
            dialogIndex: deleteVideoEntryDialogIndex
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-dialog",
            dialogIndex: editVideoEntryDialogIndex
        })

        viewAllItemsVideoDispatcher.dispatch({
            actionType: "get-data"
        })

    }

    deleteVideo = () => {

        this.setState({ isLoading: true })

        const that = this
        axios.post(ADMINURL + "inventory",
            {
                d: this.data.d,
                ti: this.data.ti,
                l: this.data.l,
                _id: this.data._id
            }
            , {
                params: {
                    g: "dvi",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleDeleteVideo(response.data)
            })
            .catch(function (error) {
                that.handleDeleteVideo({})
            })

    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    Are you sure you want to delete this video entry?
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "close-dialog",
                            dialogIndex: deleteVideoEntryDialogIndex
                        })
                    }}>
                        Cancel
                    </button>


                    <button className="btn btn-danger m-1" disabled={this.state.isLoading} onClick={this.deleteVideo}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Delete</button>

                </div>
            </div>
        )
    }
}

class UpdateSingleVideoInventoryItem extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            videoTitle: this.data.ti,
            videoDescription: this.data.d,
            videoLink: this.data.l,

        }
    }

    handleEditVideo = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Edit Video",
                message: `Failed to edit video. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Edit Video",
                message: `Failed to edit video. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Edit Video",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({
            videoTitle: "",
            videoDescription: "",
            videoLink: ""
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Edit Video",
            message: <div className="p-2">
                <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></span>&nbsp;Video successully edited.
            </div>
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "close-dialog",
            dialogIndex: editVideoEntryDialogIndex
        })


        viewAllItemsVideoDispatcher.dispatch({
            actionType: "get-data"
        })

    }

    editVideo = () => {

        if (this.state.videoTitle.length === 0 || this.state.videoLink.length === 0) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Edit Video",
                message: `Please enter a video title and video link.`,
                date: new Date(),
                isError: true
            })
            return
        }


        this.setState({ isLoading: true })

        const that = this
        axios.post(ADMINURL + "inventory",
            {
                d: this.state.videoDescription,
                ti: this.state.videoTitle,
                l: this.state.videoLink,
                _id: this.data._id
            }
            , {
                params: {
                    g: "uvi",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleEditVideo(response.data)
            })
            .catch(function (error) {
                that.handleEditVideo({})
            })

    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label>Title</label>
                    <input maxLength="200" className="form-control" type="text" value={this.state.videoTitle} onChange={(e) => {
                        this.setState({ videoTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea maxLength="1000" rows="5" className="form-control" type="text" value={this.state.videoDescription} onChange={(e) => {
                        this.setState({ videoDescription: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Link</label>
                    <input maxLength="2048" className="form-control" type="text" value={this.state.videoLink} onChange={(e) => {
                        this.setState({ videoLink: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary m-1" disabled={this.state.isLoading} onClick={this.editVideo}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Edit</button>
                    <button className="btn btn-danger m-1" onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Delete Video Entry",
                            message: <DeleteSingleVideoInventoryItem data={this.data} />,
                            dialogIndexCallback: (idx) => {
                                deleteVideoEntryDialogIndex = idx
                            },
                        })
                    }}>Delete</button>
                </div>
            </div>
        )
    }
}

class ViewSingleVideoInventoryItem extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            videoTitle: "",
            videoDescription: "",
            videoLink: "",

            updateID: null
        }
    }


    handleAddVideo = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Video",
                message: `Failed to add video. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Video",
                message: `Failed to add video. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Video",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        this.setState({
            videoTitle: "",
            videoDescription: "",
            videoLink: ""
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Add Video",
            message: <div className="p-2">
                <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></span>&nbsp;Video successully added.
            </div>
        })

        viewAllItemsVideoDispatcher.dispatch({
            actionType: "get-data"
        })
    }

    addVideo = () => {

        if (this.state.videoTitle.length === 0 || this.state.videoLink.length === 0) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Video",
                message: `Please enter a video title and video link.`,
                date: new Date(),
                isError: true
            })
            return
        }


        this.setState({ isLoading: true })

        const that = this
        axios.post(ADMINURL + "inventory",
            {
                d: this.state.videoDescription,
                ti: this.state.videoTitle,
                l: this.state.videoLink,
                i: this.data._id
            }
            , {
                params: {
                    g: "avi",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleAddVideo(response.data)
            })
            .catch(function (error) {
                that.handleAddVideo({})
            })

    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <h3>{this.data.name}</h3>
                    <h6>{this.data.sku}</h6>
                </div>
                <div className="d-flex flex-wrap">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            Current Videos
                        </div>
                        <div className="form-group">
                            <ItemSearch nameTitleKey="ti" getDataCaller={(d) => {
                                viewAllItemsVideoDispatcher = d
                            }}
                                onClickCallback={(resultData) => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Edit Video Entry",
                                        message: <UpdateSingleVideoInventoryItem data={resultData} />,
                                        dialogWidth: 2,
                                        dialogIndexCallback: (idx) => {
                                            editVideoEntryDialogIndex = idx
                                        },
                                    })
                                }} resultClickable={true} searchAllowed={false} gType="gci" timeKey="t" searchLimit={10} urlEndPoint="inventory" urlParams={{
                                    id: this.data._id
                                }} subInfoKeys={[
                                    {
                                        "key": "l",
                                        "map": function (data) {
                                            return <span><small>{data}</small></span>
                                        }
                                    }]} />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            Add New Video
                        </div>
                        <div className="form-group">
                            <label>Title</label>
                            <input maxLength="200" className="form-control" type="text" value={this.state.videoTitle} onChange={(e) => {
                                this.setState({ videoTitle: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea maxLength="1000" rows="5" className="form-control" type="text" value={this.state.videoDescription} onChange={(e) => {
                                this.setState({ videoDescription: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <label>Link</label>
                            <input maxLength="2048" className="form-control" type="text" value={this.state.videoLink} onChange={(e) => {
                                this.setState({ videoLink: e.target.value })
                            }} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" disabled={this.state.isLoading} onClick={this.addVideo}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class AddVideoToItemPage extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label>Inventory Items</label>
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add Video",
                            message: <ViewSingleVideoInventoryItem data={resultData} />,
                            dialogWidth: 3
                        })
                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="t" searchLimit={10} urlEndPoint="inventory" />
                </div>
            </div>
        )
    }

}

class SelectInventoryItemDialog extends React.Component {
    constructor(props) {
        super()
        this.onClickCallback = props.onClickCallback
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label>Inventory Items</label>
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                        if (this.onClickCallback) {
                            this.onClickCallback(resultData)
                        }
                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="t" searchLimit={10} urlEndPoint="inventory" />
                </div>
            </div>
        )
    }

}

class AddInventory extends React.Component {
    constructor(props) {
        super(props)
        this.resetAllFields = this.resetAllFields.bind(this)
        this.postItem = this.postItem.bind(this);
        this.state = {
            name: "",
            description: "",
            ecomDescription: "",
            ecom: true,
            keywords: [],
            color: {
                color: "",
                colorn: ""
            },
            sku: "",
            hsn: "",
            type: "Goods",
            unit: "pcs",
            quantity: 0,
            dimensions: {
                height: 0,
                width: 0,
                depth: 0,
                unit: "mm"
            },
            weight: {
                value: 0,
                unit: 'gms'
            },
            info: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            price: {
                cp: 0,
                sp: 0,
                mrp: 0
                // unit: "inr"
            },
            tax: {
                p: 0,
                // c: "igst",
                t: "inc"
            },
            coupon: "",

            response: {},
            images: [],
            banners: [],

            article: "",
            material: "",
            gender: "",
            shape: "",
            occasion: "",
            purity: "",
            plating: "",
            countryoforigin: "",
            maxperecomorder: 0,
            configurationIdentifier: "",
            addOnIdentifier: {
                id: "",
                n: "",
                s: ""
            },
            files: [],
            checkstockonorder: true,
            leadtime: {
                v: 0,
                u: "d"
            },
            metakeyvalue: [],
            futurestocking: [],

            isLoading: false,
            isError: false,
            isSuccess: true
        }


        this.viewInventoryDispatcher = null
    }

    componentDidMount() {

    }

    resetAllFields() {
        this.setState({
            name: "",
            description: "",
            ecomDescription: "",
            ecom: true,
            keywords: [],
            color: {
                color: "",
                colorn: ""
            },
            sku: "",
            hsn: "",
            type: "Goods",
            unit: "pcs",
            quantity: 0,
            dimensions: {
                height: 0,
                width: 0,
                depth: 0,
                unit: "mm"
            },
            weight: {
                value: 0,
                unit: 'gms'
            },
            info: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            price: {
                cp: 0,
                sp: 0,
                mrp: 0
                // unit: "inr"
            },
            tax: {
                p: 0,
                // c: "igst",
                t: "inc"
            },
            coupon: "",

            response: {},
            images: [],
            banners: [],

            article: "",
            material: "",
            gender: "",
            shape: "",
            occasion: "",
            purity: "",
            plating: "",
            countryoforigin: "",
            maxperecomorder: 0,
            configurationIdentifier: "",
            addOnIdentifier: {
                id: "",
                n: "",
                s: ""
            },
            files: [],
            checkstockonorder: true,
            leadtime: {
                v: 0,
                u: "d"
            },
            metakeyvalue: [],
            futurestocking: [],

            isLoading: false,
            isError: false,
            isSuccess: true
        }, () => {
            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: clearAddInventoryDataDialogIndex
            })
            window.scroll(0, 0)
        })
    }

    FullScreenImageView = () => {
        return (
            <div id="fullscreenimageviewdialog1" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabIndex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog1") {
                        // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialog1image" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }

    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialog1image').style.transform = angle;
        })
    }

    ImageUploader = () => {
        var img_ = []

        for (var i in this.state.images) {
            const idx = i
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} src={this.state.images[i]["image"]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        // $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        // $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <div className="inquiry_selected_buttons">
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}></i>&nbsp;<span iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}>Delete</span>
                    </div>
                    <div className="">
                        QR Position
                        <select class="custom-select" name="type" id={`addIventoryImageQRPos` + i} defaultValue={this.state.images[i]["qp"]} iid={i} onChange={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images[iid]["qp"] = e.target.value;
                            this.setState({ images: tmp_images })
                        }}>
                            <option selected value="br">Bottom Right</option>
                            <option value="tl">Top Left</option>
                            <option value="tr">Top Right</option>
                            <option value="bl">Bottom Left</option>
                        </select>
                    </div>
                    <div className="">
                        QR Size
                        <select class="custom-select" name="type" id={`addIventoryImageQRSize` + i} defaultValue={this.state.images[i]["qs"]} iid={i} onChange={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images[iid]["qs"] = e.target.value;
                            this.setState({ images: tmp_images })
                        }}>
                            <option selected value="100">100 px</option>
                            <option value="200">200 px</option>
                            <option value="300">300 px</option>
                        </select>
                    </div>
                    <div className="">
                        <div class="form-check">
                            <input class="form-check-input" iid={idx} type="radio" name="primaryImageRadio" id={`primaryImageRadio` + i} checked={this.state.images[i]["ip"]} onChange={(e) => {

                                var iid_ = e.target.getAttribute("iid")
                                var tmpImages = this.state.images


                                // all false
                                for (var ii in tmpImages) {
                                    tmpImages[ii]["ip"] = false
                                }


                                tmpImages[iid_]["ip"] = e.target.checked
                                this.setState({ images: tmpImages })

                            }} />
                            <label class="form-check-label" for={`primaryImageRadio` + i}>
                                Is Primary
                            </label>
                        </div>
                    </div>
                </div>
            )
        }

        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    {/* <h5 class="card-title">Add Images</h5> */}
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>
                    {/* <this.Display_images /> */}
                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;

                                tmp_images.push(
                                    {
                                        "image": e.target.result,
                                        "qp": "br",
                                        "qs": "100",
                                        "ip": false
                                    }

                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    handlePostItem = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add inventory item")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Inventory",
                message: `Failed to add inventory item. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Inventory",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Add Inventory",
            message: <div>
                <span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span> Inventory item successfully added.
            </div>
        })

        window.scrollTo(0, 0);

        this.resetAllFields()

        this.viewInventoryDispatcher.dispatch({
            actionType: "get-data"
        })

    }

    postItem() {

        this.setState({ isLoading: true })
        // $("#postItemButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Add")
        const that = this
        var tmp_images = []

        // if (this.state.images.length > 0) {
        //     for (var i in this.state.images) {
        //         tmp_images.push(
        //             {
        //                 "image": this.state.images[i]["image"].split(",")[1],
        //                 "qs": this.state.images[i]["qs"],
        //                 "qp": this.state.images[i]["qp"],
        //                 "ip": this.state.images[i]["ip"]
        //             }
        //         )
        //     }
        // }

        var tmpFutureStocking = []
        for (var i in this.state.futurestocking) {
            tmpFutureStocking.push({
                ta: DateTime.fromFormat(this.state.futurestocking[i].ta, "yyyy-LL-dd'T'HH:mm").toString(),
                n: this.state.futurestocking[i].n,
                sa: parseInt(this.state.futurestocking[i].sa)
            })
        }


        axios.post(ADMINURL + "inventory", {
            name: this.state.name,
            description: this.state.description,
            ecomDescription: this.state.ecomDescription,
            ecom: this.state.ecom,
            keywords: this.state.keywords,
            color: this.state.color,
            type: this.state.type,
            sku: this.state.sku,
            hsn: this.state.hsn,
            // quantity: parseInt(this.state.quantity),
            unit: this.state.unit,
            dimensions: this.state.dimensions,
            weight: this.state.weight,
            info: this.state.info,
            price: this.state.price,
            tax: this.state.tax,
            coupon: this.state.coupon,
            images: this.state.images,
            article: this.state.article,
            material: this.state.material,
            gender: this.state.gender,
            shape: this.state.shape,
            occasion: this.state.occasion,
            purity: this.state.purity,
            plating: this.state.plating,
            countryoforigin: this.state.countryoforigin,
            maxperecomorder: parseInt(this.state.maxperecomorder),
            configurationIdentifier: this.state.configurationIdentifier,
            addOnIdentifier: this.state.addOnIdentifier,
            files: this.state.files,
            checkstockonorder: this.state.checkstockonorder,
            leadtime: this.state.leadtime,
            metakeyvalue: this.state.metakeyvalue,
            futurestocking: tmpFutureStocking,
            banners: this.state.banners
        }, {
            headers: { Authorization: ACCT },
            params: { g: "add" }
        })
            .then(function (response) {
                that.handlePostItem(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handlePostItem({})
            })
    }

    printStuff(key, value) {
        var ret = []
        if (value[0].constructor === {}.constructor) {
            for (var i in value[0]) {
                ret.push(
                    this.printStuff(i, value[0][i])
                )
            }
            return ret
        }

        ret.push(
            <span><span>{key}: {value}</span><br /></span>
        )

        return ret

    }

    Status = () => {

        useEffect(() => {
            // Update the document title using the browser API
            if ("_status" in this.state.response) {
                document.getElementById('addInventoryStatusDiv').scrollIntoView()
            }
        });

        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            // $("#add_inquiry_button").prop('disabled', true);
            // setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="addInventoryStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []

            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                // alert(i)
                ret.push(
                    this.printStuff(i, this.state.response.payload[0][i])
                    //     // <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="addInventoryStatusDiv" style={{
                    whiteSpace: 'pre-wrap'
                }} className="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return "";
    }



    handleImageUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg" && input.target.files[ii]["type"] !== "image/png") {
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Image Error",
                    message: <ImageJPEGErrorDialog />
                })
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            // toastsDispatcher.dispatch({
            //     actionType: 'add-toast',
            //     title: "Add Image",
            //     message: <span>Uploading image <b>{ff.name}</b>.</span>,
            //     date: new Date(),
            //     isInfo: true
            // })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            g: 'upl'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {


                            return
                        }

                        if (response["status"] !== "success") {


                            return
                        }


                        const imgID = response["payload"]["id"]
                        const tmpImages = that.state.images
                        tmpImages.push({
                            image: imgID,
                            alt: "",
                            "eqr": false,
                            "qp": "bl",
                            "qs": 100,
                            ip: false
                        })
                        that.setState({ images: tmpImages })
                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    handleBannerUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {
            if (input.target.files[ii]["type"] !== "image/jpeg" && input.target.files[ii]["type"] !== "image/png") {
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Image Error",
                    message: <ImageJPEGErrorDialog />
                })
                continue;
            }
            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            // toastsDispatcher.dispatch({
            //     actionType: 'add-toast',
            //     title: "Add Image",
            //     message: <span>Uploading image <b>{ff.name}</b>.</span>,
            //     date: new Date(),
            //     isInfo: true
            // })
            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('image', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "image",
                    bodyFormData,
                    {
                        params: {
                            g: 'upl'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response_) {

                        that.setState({ isLoading: false })

                        const response = response_.data

                        if (!("status" in response) || !("payload" in response)) {


                            return
                        }

                        if (response["status"] !== "success") {


                            return
                        }


                        const imgID = response["payload"]["id"]
                        const tmpBanners = that.state.banners
                        tmpBanners.push({
                            image: imgID,
                            alt: "",
                            "eqr": false,
                            "qp": "bl",
                            "qs": 100,
                            ip: false
                        })
                        that.setState({ banners: tmpBanners })
                    })
                    .catch(function (response) {

                        that.setState({ isLoading: false })
                        //handle error

                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    handleFileUpload = (response) => {

        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {

            alert("Unable to upload file")
            this.setState({ isSuccess: false, isError: true })
            return

        }

        if (response["status"] !== "success") {

            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Upload File",
                message: `Failed to upload file. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })

            return
        }


        const fileID = response["payload"]["file"]
        const fileName = response["payload"]["name"]
        const tmpFiles = this.state.files
        tmpFiles.push({
            file: fileID,
            name: fileName,
            description: "",
            size: 0,
            meta: {},
            isUserSecured: false,
            allowedUsers: [],
            unallowedUsers: []
        })
        this.setState({ files: tmpFiles }, () => {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Files",
                message: <span>File <b>{fileName}</b> successfully uploaded and added.</span>,
                date: new Date(),
                isSuccess: true
            })
        })

    }

    fileUpload = (input) => {
        const that = this;

        for (var ii = 0; ii < input.target.files.length; ii++) {

            const ff = input.target.files[ii]

            this.setState({ isLoading: true })

            var reader = new FileReader();
            reader.onload = function (e) {
                // upload image
                var bodyFormData = new FormData();
                const file = dataURItoBlob(e.target.result)

                bodyFormData.append('file', file, ff.name);

                // wg.Add()

                axios.post(
                    ADMINURL + "files",
                    bodyFormData,
                    {
                        params: {
                            g: 'upl'
                        },
                        headers: {
                            Authorization: ACCT
                        }
                    }

                )
                    .then(function (response) {
                        that.handleFileUpload(response.data)
                    })
                    .catch(function (response) {
                        that.handleFileUpload({})
                    });

                // upload ends


            }


            reader.readAsDataURL(input.target.files[ii])
            // reader.readAsArrayBuffer(input.target.files[ii])
        }
    }

    render() {

        var images = []

        for (var i in this.state.images) {
            const idx = i
            images.push(
                <div key={this.state.images[idx].image} className="m-1 card p-2">
                    <AuthenticatedImage key={this.state.images[idx].image} className="p-2" imageID={this.state.images[idx].image} imageStyle={{
                        maxHeight: "150px",
                        cursor: 'pointer'
                        // borderRadius: '50%'
                    }} onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: null,
                            closeOnOutsideClick: true,
                            message: <AuthenticatedImage imageID={this.state.images[idx].image} imageStyle={{
                                maxWidth: "100%",
                                width: "100%"
                            }}
                            />
                        })
                    }} />
                    <br />
                    <div>
                        <div className='form-group'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name={`primaryRadioImage`} id={`primaryRadioImage${idx}`} checked={this.state.images[idx].ip} onChange={(e) => {
                                    var tmpImages = this.state.images
                                    for (var ii in tmpImages) {
                                        tmpImages[ii].ip = false
                                    }
                                    tmpImages[idx].ip = e.target.checked
                                    this.setState({ images: tmpImages })
                                }} />
                                <label class="form-check-label" for={`primaryRadioImage${idx}`}>
                                    Is Primary
                                </label>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={this.state.images[idx].eqr} id={`checkBoxImage${idx}`} onChange={(e) => {
                                    var tmpImages = this.state.images
                                    tmpImages[idx].eqr = e.target.checked
                                    this.setState({ images: tmpImages })
                                }} />
                                <label class="form-check-label" for={`checkBoxImage${idx}`}>
                                    Enable QR
                                </label>
                            </div>

                        </div>
                        <div className='form-group'>
                            <label>QR Size</label>
                            <select disabled={!this.state.images[idx].eqr} className='form-control' value={this.state.images[idx].qs} onChange={(e) => {
                                var tmpImages = this.state.images
                                tmpImages[idx].qs = parseInt(e.target.value)
                                this.setState({ images: tmpImages })
                            }} >
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={300}>300</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label>QR Position</label>
                            <select disabled={!this.state.images[idx].eqr} className='form-control' value={this.state.images[idx].qp} onChange={(e) => {
                                var tmpImages = this.state.images
                                tmpImages[idx].qp = e.target.value
                                this.setState({ images: tmpImages })
                            }} >
                                <option value={`bl`}>Bottom Left</option>
                                <option value={`br`}>Bottom Right</option>
                                <option value={`tr`}>Top Right</option>
                                <option value={`tl`}>Top Left</option>
                                <option value={`md`}>Middle</option>
                                <option value={`ml`}>Middle Left</option>
                                <option value={`mr`}>Middle Right</option>
                                <option value={`mt`}>Middle Top</option>
                                <option value={`mb`}>Middle Bottom</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <span className="p-2 btn-link" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                var tmpImages = this.state.images
                                tmpImages.splice(idx, 1)
                                this.setState({ images: tmpImages })
                            }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                        </div>
                    </div>
                </div>
            )
        }

        var files = []

        for (var i in this.state.files) {
            const idx = i
            files.push(
                <div key={this.state.files[idx].file} className="m-1 card p-2">
                    <div className='form-group'>
                        <span>{this.state.files[idx].name}</span>
                    </div>
                    <div className='form-group'>
                        <label htmlFor={`fileDescription${idx}`}>Description</label>
                        <textarea id={`fileDescription${idx}`} className='form-control' value={this.state.files[idx].description} onChange={(e) => {
                            var tmpFiles = this.state.files
                            tmpFiles[idx].description = e.target.value
                            this.setState({ files: tmpFiles })
                        }} />
                    </div>
                    <div className='form-group'>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" checked={this.state.files[idx].isUserSecured} id={`isusersecuredcheckbox${idx}`} onChange={(e) => {
                                var tmpFiles = this.state.files
                                tmpFiles[idx].isUserSecured = e.target.checked

                                this.setState({ files: tmpFiles })
                            }} />
                            <label class="form-check-label" for={`isusersecuredcheckbox${idx}`}>
                                Is User Secured
                            </label>
                        </div>
                    </div>
                    <div className='form-group'>
                        <span className="p-2 btn-link" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            var tmpFiles = this.state.files
                            tmpFiles.splice(idx, 1)
                            this.setState({ files: tmpFiles })
                        }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                    </div>
                </div>
            )
        }


        var banners = []

        for (var i in this.state.banners) {
            const idx = i
            banners.push(
                <div key={this.state.banners[idx].image} className="m-1 card p-2">
                    <AuthenticatedImage key={this.state.banners[idx].image} className="p-2" imageID={this.state.banners[idx].image} imageStyle={{
                        maxHeight: "150px",
                        cursor: 'pointer'
                        // borderRadius: '50%'
                    }} onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: null,
                            closeOnOutsideClick: true,
                            message: <AuthenticatedImage imageID={this.state.banners[idx].image} imageStyle={{
                                maxWidth: "100%",
                                width: "100%"
                            }}
                            />
                        })
                    }} />
                    <br />
                    <div>
                        <div className='form-group'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name={`primaryRadioImage`} id={`primaryRadioImage${idx}`} checked={this.state.banners[idx].ip} onChange={(e) => {
                                    var tmpBanners = this.state.banners
                                    for (var ii in tmpBanners) {
                                        tmpBanners[ii].ip = false
                                    }
                                    tmpBanners[idx].ip = e.target.checked
                                    this.setState({ banners: tmpBanners })
                                }} />
                                <label class="form-check-label" for={`primaryRadioImage${idx}`}>
                                    Is Primary
                                </label>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={this.state.banners[idx].eqr} id={`checkBoxImage${idx}`} onChange={(e) => {
                                    var tmpBanners = this.state.banners
                                    tmpBanners[idx].eqr = e.target.checked
                                    this.setState({ banners: tmpBanners })
                                }} />
                                <label class="form-check-label" for={`checkBoxImage${idx}`}>
                                    Enable QR
                                </label>
                            </div>

                        </div>
                        <div className='form-group'>
                            <label>QR Size</label>
                            <select disabled={!this.state.banners[idx].eqr} className='form-control' value={this.state.banners[idx].qs} onChange={(e) => {
                                var tmpBanners = this.state.banners
                                tmpBanners[idx].qs = parseInt(e.target.value)
                                this.setState({ banners: tmpBanners })
                            }} >
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={300}>300</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label>QR Position</label>
                            <select disabled={!this.state.banners[idx].eqr} className='form-control' value={this.state.banners[idx].qp} onChange={(e) => {
                                var tmpBanners = this.state.banners
                                tmpBanners[idx].qp = e.target.value
                                this.setState({ banners: tmpBanners })
                            }} >
                                <option value={`bl`}>Bottom Left</option>
                                <option value={`br`}>Bottom Right</option>
                                <option value={`tr`}>Top Right</option>
                                <option value={`tl`}>Top Left</option>
                                <option value={`md`}>Middle</option>
                                <option value={`ml`}>Middle Left</option>
                                <option value={`mr`}>Middle Right</option>
                                <option value={`mt`}>Middle Top</option>
                                <option value={`mb`}>Middle Bottom</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <span className="p-2 btn-link" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                var tmpBanners = this.state.banners
                                tmpBanners.splice(idx, 1)
                                this.setState({ banners: tmpBanners })
                            }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Delete</span>
                        </div>
                    </div>
                </div>
            )
        }

        return [
            <div className="p-4">

                <div className="d-flex flex-wrap">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <label htmlFor="inventoryName"><b>Name</b></label>
                                    <input type="text" id="inventoryName" className="form-control border-1 small" name="name" value={this.state.name} onChange={(e) => {
                                        this.setState({ name: e.target.value })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryType"><b>Type</b></label>
                                    <select class="custom-select" name="type" id="inventoryType" value={this.state.type} onChange={(e) => {
                                        this.setState({ type: e.target.value })
                                    }}>
                                        <option value="Goods">Goods</option>
                                        <option value="Service">Service</option>
                                        <option value="AddOn">Add On</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className='form-group' hidden={!(this.state.type === "AddOn")}>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <label><b>Select item to add this add-on item to</b></label>
                                    <br />
                                    <span className='btn-link' onClick={() => {
                                        fullDialogV2Dispatcher.dispatch({
                                            actionType: "show-dialog",
                                            title: "Select Inventory Item",
                                            message: <SelectInventoryItemDialog onClickCallback={(resultData) => {

                                                this.setState({
                                                    addOnIdentifier: {
                                                        n: resultData.name,
                                                        s: resultData.sku,
                                                        id: resultData._id
                                                    }
                                                })
                                                /* close the dialog */

                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: selectInventoryItemDialogIndex
                                                })

                                            }} />,
                                            dialogIndexCallback: (c) => {
                                                selectInventoryItemDialogIndex = c
                                            }
                                        })
                                    }}>Select</span>
                                    <br />
                                    <span className='p-2 card' hidden={this.state.addOnIdentifier.n === "" || this.state.addOnIdentifier.id === "" || this.state.addOnIdentifier.s === ""}>
                                        <b>{this.state.addOnIdentifier.n}</b>
                                        <span>{this.state.addOnIdentifier.s}</span>
                                        <span className='btn-link' onClick={() => {
                                            this.setState({
                                                addOnIdentifier: {
                                                    n: "",
                                                    s: "",
                                                    id: ""
                                                }
                                            })
                                        }}><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <label htmlFor="inventoryDescription"><b>Description</b></label>
                                    <input type="text" id="inventoryDescription" className="form-control border-1 small" name="name" value={this.state.description} onChange={(e) => {
                                        this.setState({ description: e.target.value })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryColor"><b>Color</b></label>
                                    <div className="d-flex d-flex-inline align-items-center">


                                        {/* <div style={styles.swatch} onClick={() => {
                                    this.setState({ displayColorPicker: !this.state.displayColorPicker })
                                }}>
                                    <div style={styles.color} />
                                </div>
                                {this.state.displayColorPicker ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={() => {
                                        this.setState({ displayColorPicker: false })
                                    }} />
                                    <SketchPicker
                                        color={this.state.color.color}
                                        onChangeComplete={(color) => {
                                            var tmp_color = this.state.color
                                            tmp_color['color'] = color.rgb
                                            this.setState({ color: tmp_color })
                                        }}
                                    />
                                </div> : null} */}

                                        <input type="color" id="inventoryColorHex" className="form-control border-1 small ml-2" name="color" value={this.state.color.color} onChange={(e) => {
                                            var tmp_color = this.state.color
                                            tmp_color['color'] = e.target.value
                                            this.setState({ color: tmp_color })
                                        }} />

                                        <input type="text" id="inventoryColor" className="form-control border-1 small ml-2" name="colorn" value={this.state.color.colorn} onChange={(e) => {
                                            var tmp_color = this.state.color
                                            tmp_color[e.target.name] = e.target.value
                                            this.setState({ color: tmp_color })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inventoryEcomDescription"><b>E-commmerce Description</b></label>
                                    {/* <textarea rows="5" type="text" id="inventoryEcomDescription" className="form-control border-1 small" name="name" value={this.state.ecomDescription} onChange={(e) => {
                            this.setState({ ecomDescription: e.target.value })
                        }} /> */}
                                    <ReactQuill theme="snow" value={this.state.ecomDescription} onChange={(data) => {
                                        this.setState({ ecomDescription: data })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inventoryEcom"><b>E-commerce Visibility</b></label>
                                    <br />
                                    <small>Should the item appear on E-commerce platform?</small>
                                    <br />
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="inventoryEcom" defaultChecked={this.state.ecom} onChange={(e) => {
                                            this.setState({ ecom: e.target.checked })
                                        }} checked={this.state.ecom} />
                                        <label className="form-check-label" htmlFor="inventoryEcom">
                                            Show on e-commerce platform
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inventoryCheckStockOnOrder"><b>Check Stock On Order</b></label>
                                    <br />
                                    <small>Should the backend check for stock availability on order placement?</small>
                                    <br />
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="inventoryCheckStockOnOrder" defaultChecked={this.state.checkstockonorder} onChange={(e) => {
                                            this.setState({ checkstockonorder: e.target.checked })
                                        }} checked={this.state.checkstockonorder} />
                                        <label className="form-check-label" htmlFor="inventoryCheckStockOnOrder">
                                            Check stock on order placement
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inventoryKeywords"><b>Keywords</b></label>
                                    {
                                        this.state.keywords.map((item, index) => (
                                            <div className=''>
                                                <input type="text" id={`inventoryKeywords${index}`} className="form-control border-1 small" name={`keywords${index}`} value={this.state.keywords[index]} onChange={(e) => {
                                                    var tmpKeywords = this.state.keywords
                                                    tmpKeywords[index] = e.target.value
                                                    this.setState({ keywords: tmpKeywords })
                                                }} />

                                                <span title='Remove' className='btn-link' onClick={() => {

                                                    var tmpKeywords = this.state.keywords
                                                    tmpKeywords.splice(parseInt(index), 1)
                                                    this.setState({ keywords: tmpKeywords })

                                                }} >
                                                    <small>
                                                        <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove
                                                    </small>
                                                </span>
                                            </div>
                                        ))
                                    }
                                    <br />
                                    <span className='btn-link' onClick={() => {
                                        var tmpKeywords = this.state.keywords
                                        tmpKeywords.push("")
                                        this.setState({ keywords: tmpKeywords })
                                    }}>
                                        Add Keyword
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <label htmlFor="inventorySku"><b>SKU</b></label>
                                    <input type="text" id="inventorySku" className="form-control border-1 small" name="name" value={this.state.sku} onChange={(e) => {
                                        this.setState({ sku: e.target.value })
                                    }} />
                                    <small id="invoiceNumberHelp" class="form-text text-muted">Use Only When Custom SKU Is Needed.</small>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryHsn"><b>HSN</b></label>
                                    <input type="text" id="inventoryHsn" className="form-control border-1 small" name="name" value={this.state.hsn} onChange={(e) => {
                                        this.setState({ hsn: e.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                    <label htmlFor="inventoryQuantity"><b>Quantity</b></label>
                                    <input disabled={true} type="number" id="inventoryQuantity" className="form-control border-1 small" name="name" value={this.state.quantity} onChange={(e) => {
                                        this.setState({ quantity: e.target.value })
                                    }} /></div>

                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                    <label htmlFor="inventoryUnit"><b>Unit</b></label>
                                    <select class="custom-select" name="type" id="inventoryUnit" value={this.state.unit} onChange={(e) => {
                                        this.setState({ unit: e.target.value })
                                    }}>
                                        <option value="box">Box</option>
                                        <option value="pcs">Pcs</option>
                                        <option value="tn">tn</option>
                                        <option value="kg">kg</option>
                                        <option value="lbs">lbs</option>
                                        <option value="gm">gm</option>
                                        <option value="mg">mg</option>
                                        <option value="km">km</option>
                                        <option value="m">m</option>
                                        <option value="ft">ft</option>
                                        <option value="in">in</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                        <option value="hour">hour</option>
                                    <option value="day">day</option>
                                    <option value="month">month</option>
                                    <option value="year">year</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <b>Dimension</b>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                    <label htmlFor="inventoryDimensionHeight">Height</label>
                                    <input type="number" id="inventoryDimensionHeight" className="form-control border-1 small" name="height" value={this.state.dimensions.height} onChange={(e) => {
                                        var tmp_dimension = this.state.dimensions
                                        tmp_dimension[e.target.name] = parseFloat(e.target.value)
                                        this.setState({ dimensions: tmp_dimension })

                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                    <label htmlFor="inventoryDimensionWidth">Width</label>
                                    <input type="number" id="inventoryDimensionWidth" className="form-control border-1 small" name="width" value={this.state.dimensions.width} onChange={(e) => {
                                        var tmp_dimension = this.state.dimensions
                                        tmp_dimension[e.target.name] = parseFloat(e.target.value)
                                        this.setState({ dimensions: tmp_dimension })
                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                    <label htmlFor="inventoryDimensionDepth">Depth</label>
                                    <input type="number" id="inventoryDimensionDepth" className="form-control border-1 small" name="depth" value={this.state.dimensions.depth} onChange={(e) => {
                                        var tmp_dimension = this.state.dimensions
                                        tmp_dimension[e.target.name] = parseFloat(e.target.value)
                                        this.setState({ dimensions: tmp_dimension })
                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                    <label htmlFor="inventoryDimensionUnit">Unit</label>
                                    <select class="custom-select" name="unit" id="inventoryDimensionUnit" value={this.state.dimensions.unit} onChange={(e) => {
                                        var tmp_dimension = this.state.dimensions
                                        tmp_dimension[e.target.name] = e.target.value
                                        this.setState({ dimensions: tmp_dimension })
                                    }}>
                                        <option value="km">km</option>
                                        <option value="m">m</option>
                                        <option value="ft">ft</option>
                                        <option value="in">in</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <b>Weight</b>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">

                                    <label htmlFor="inventoryWeightValue">Value</label>
                                    <input type="number" id="inventoryWeightValue" className="form-control border-1 small" name="value" value={this.state.weight.value} onChange={(e) => {
                                        var tmp_weight = this.state.weight
                                        tmp_weight[e.target.name] = parseFloat(e.target.value)
                                        this.setState({ weight: tmp_weight })

                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryWeightUnit">Unit</label>
                                    <select class="custom-select" name="unit" id="inventoryWeightUnit" value={this.state.weight.unit} onChange={(e) => {
                                        var tmp_weight = this.state.weight
                                        tmp_weight[e.target.name] = e.target.value
                                        this.setState({ weight: tmp_weight })
                                    }}>
                                        <option value="tn">tn</option>
                                        <option value="kg">kg</option>
                                        <option value="lbs">lbs</option>
                                        <option value="gms">gms</option>
                                        <option value="mg">mg</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <b>Info</b>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label htmlFor="inventoryInfoManufacturer">Manufacturer</label>
                                    <input type="text" id="inventoryInfoManufacturer" className="form-control border-1 small" name="manufacturer" value={this.state.info.manufacturer} onChange={(e) => {
                                        var tmp_info = this.state.info
                                        tmp_info[e.target.name] = e.target.value
                                        this.setState({ info: tmp_info })
                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label htmlFor="inventoryInfoBrand">Brand</label>
                                    <input type="text" id="inventoryInfoBrand" className="form-control border-1 small" name="brand" value={this.state.info.brand} onChange={(e) => {
                                        var tmp_info = this.state.info
                                        tmp_info[e.target.name] = e.target.value
                                        this.setState({ info: tmp_info })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label htmlFor="inventoryInfoUpc">UPC</label>
                                    <input type="text" id="inventoryInfoUpc" className="form-control border-1 small" name="upc" value={this.state.info.upc} onChange={(e) => {
                                        var tmp_info = this.state.info
                                        tmp_info[e.target.name] = e.target.value
                                        this.setState({ info: tmp_info })
                                    }} />

                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label htmlFor="inventoryInfoEan">EAN</label>
                                    <input type="text" id="inventoryInfoEan" className="form-control border-1 small" name="ean" value={this.state.info.ean} onChange={(e) => {
                                        var tmp_info = this.state.info
                                        tmp_info[e.target.name] = e.target.value
                                        this.setState({ info: tmp_info })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label htmlFor="inventoryInfoMpn">MPN</label>
                                    <input type="text" id="inventoryInfoMpn" className="form-control border-1 small" name="mpn" value={this.state.info.mpn} onChange={(e) => {
                                        var tmp_info = this.state.info
                                        tmp_info[e.target.name] = e.target.value
                                        this.setState({ info: tmp_info })
                                    }} />

                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label htmlFor="inventoryInfoIsbn">ISBN</label>
                                    <input type="text" id="inventoryInfoIsbn" className="form-control border-1 small" name="isbn" value={this.state.info.isbn} onChange={(e) => {
                                        var tmp_info = this.state.info
                                        tmp_info[e.target.name] = e.target.value
                                        this.setState({ info: tmp_info })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <b>Price</b>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryPriceCp">Cost Price</label>
                                    <input type="number" id="inventoryPriceCp" className="form-control border-1 small" name="cp" value={this.state.price.cp} onChange={(e) => {
                                        var tmp_price = this.state.price
                                        tmp_price[e.target.name] = parseInt(e.target.value)
                                        this.setState({ price: tmp_price })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryPriceSp">Selling Price</label>
                                    <input type="number" id="inventoryPriceSp" className="form-control border-1 small" name="sp" value={this.state.price.sp} onChange={(e) => {
                                        var tmp_price = this.state.price
                                        tmp_price[e.target.name] = parseInt(e.target.value)
                                        this.setState({ price: tmp_price })
                                    }} />

                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryPriceMrp">MRP</label>
                                    <input type="number" id="inventoryPriceMrp" className="form-control border-1 small" name="mrp" value={this.state.price.mrp} onChange={(e) => {
                                        var tmp_price = this.state.price
                                        tmp_price[e.target.name] = parseInt(e.target.value)
                                        this.setState({ price: tmp_price })
                                    }} />

                                </div>
                                {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryPriceUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryPriceUnit" defaultValue={this.state.price.unit} value={this.state.price.unit} onChange={(e) => {
                            var tmp_price = this.state.price
                            tmp_price[e.target.name] = e.target.value
                            this.setState({ price: tmp_price })
                        }}>
                            <option value="inr">INR</option>
                            <option value="usd">USD</option>
                        </select>
                    </div> */}
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <b>Tax Info</b>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryTaxP">Tax Bracket (%)</label>
                                    <input type="number" id="inventoryTaxP" className="form-control border-1 small" name="p" value={this.state.tax.p} onChange={(e) => {
                                        var tmp_tax = this.state.tax
                                        var val = e.target.value
                                        if (parseInt(val) < 0) {
                                            val = 0
                                        }
                                        if (parseInt(val) > 10000) {
                                            val = 10000
                                        }
                                        tmp_tax[e.target.name] = parseInt(val)
                                        this.setState({ tax: tmp_tax })
                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryTaxType">Tax Type</label>
                                    <select class="custom-select" name="t" id="inventoryTaxType" value={this.state.tax.t} onChange={(e) => {
                                        var tmp_tax = this.state.tax
                                        tmp_tax[e.target.name] = e.target.value
                                        this.setState({ tax: tmp_tax })
                                    }}>
                                        <option value="inc">Inclusive</option>
                                        <option value="exc">Exclusive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <b>Lead Time</b>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryLeadTimeValue">Value</label>
                                    <input type="number" id="inventoryLeadTimeValue" className="form-control border-1 small" name="p" value={this.state.leadtime.v} onChange={(e) => {
                                        var tmp_leadtime = this.state.leadtime
                                        var val = e.target.value
                                        if (parseInt(val) < 0) {
                                            val = 0
                                        }
                                        if (parseInt(val) > 100) {
                                            val = 100
                                        }
                                        tmp_leadtime[e.target.name] = parseInt(val)
                                        this.setState({ leadtime: tmp_leadtime })
                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryLeadtimeUnit">Unit</label>
                                    <select class="custom-select" name="u" id="inventoryLeadtimeUnit" value={this.state.leadtime.u} onChange={(e) => {
                                        var tmp_leadtime = this.state.leadtime
                                        tmp_leadtime[e.target.name] = e.target.value
                                        this.setState({ leadtime: tmp_leadtime })
                                    }}>
                                        <option value="s">Seconds</option>
                                        <option value="h">Hours</option>
                                        <option value="d">Days</option>
                                        <option value="w">Weeks</option>
                                        <option value="m">Months</option>
                                        <option value="y">Years</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryCoupon"><b>Coupon</b></label>
                                    <input maxLength="12" type="text" id="inventoryCoupon" className="form-control border-1 small" name="coupon" value={this.state.coupon} onChange={(e) => {
                                        this.setState({ coupon: e.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryArticle"><b>Article</b></label>
                                    <input maxLength="100" type="text" id="inventoryArticle" className="form-control border-1 small" name="article" value={this.state.article} onChange={(e) => {
                                        this.setState({ article: e.target.value })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryMaterial"><b>Material</b></label>
                                    <input maxLength="100" type="text" id="inventoryMaterial" className="form-control border-1 small" name="material" value={this.state.material} onChange={(e) => {
                                        this.setState({ material: e.target.value })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryGender"><b>Gender</b></label>
                                    <input maxLength="100" type="text" id="inventoryGender" className="form-control border-1 small" name="gender" value={this.state.gender} onChange={(e) => {
                                        this.setState({ gender: e.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>

                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryShape"><b>Shape</b></label>
                                    <input maxLength="100" type="text" id="inventoryShape" className="form-control border-1 small" name="shape" value={this.state.shape} onChange={(e) => {
                                        this.setState({ shape: e.target.value })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryOccasion"><b>Occasion</b></label>
                                    <input maxLength="100" type="text" id="inventoryOccasion" className="form-control border-1 small" name="occasion" value={this.state.occasion} onChange={(e) => {
                                        this.setState({ occasion: e.target.value })
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryPurity"><b>Purity</b></label>
                                    <input maxLength="100" type="text" id="inventoryPurity" className="form-control border-1 small" name="purity" value={this.state.purity} onChange={(e) => {
                                        this.setState({ purity: e.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryPlating"><b>Plating</b></label>
                                    <input maxLength="100" type="text" id="inventoryPlating" className="form-control border-1 small" name="plating" value={this.state.plating} onChange={(e) => {
                                        this.setState({ plating: e.target.value })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryCountryOfOrigin"><b>Country Of Origin</b></label>


                                    <select className='form-control' value={this.state.countryoforigin} onChange={(e) => {
                                        this.setState({ countryoforigin: e.target.value })
                                    }}>
                                        <option value="">Select Country of Origin</option>
                                        {
                                            country_json_1.map((item, index) => (
                                                <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryMaxPerEcomOrder"><b>Max Per Ecom Order</b></label>
                                    <input type="number" min={0} id="inventoryMaxPerEcomOrder" className="form-control border-1 small" name="maxperecomorder" value={this.state.maxperecomorder} onChange={(e) => {
                                        this.setState({ maxperecomorder: e.target.value })
                                    }} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="inventoryConfigurationIdentifier"><b>Configuration Identifier</b></label>
                                    <input type="text" min={0} id="inventoryConfigurationIdentifier" className="form-control border-1 small" name="configurationIdentifier" value={this.state.configurationIdentifier} onChange={(e) => {
                                        this.setState({ configurationIdentifier: e.target.value })
                                    }} />

                                </div>

                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                                <div class="card" >
                                    <div class="card-body">
                                        <label htmlFor=""><b>Images</b></label>
                                        {/* <h5 class="card-title">Add Images</h5> */}
                                        <div class="d-flex flex-wrap align-items-center">
                                            {images}
                                        </div>
                                        {/* <this.Display_images /> */}
                                        <br />
                                        <input type="file" class="form-control-file" id="addReviewImageUpload" onChange={(input) => {
                                            this.handleImageUpload(input)
                                        }} hidden multiple />
                                        <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                                            document.getElementById(`addReviewImageUpload`).click();
                                        }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Images</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                                <div class="card" >
                                    <div class="card-body">
                                        <label htmlFor=""><b>Banners</b></label>
                                        {/* <h5 class="card-title">Add Images</h5> */}
                                        <div class="d-flex flex-wrap align-items-center">
                                            {banners}
                                        </div>
                                        {/* <this.Display_images /> */}
                                        <br />
                                        <input type="file" class="form-control-file" id="addBannerUpload" onChange={(input) => {
                                            this.handleBannerUpload(input)
                                        }} hidden multiple />
                                        <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                                            document.getElementById(`addBannerUpload`).click();
                                        }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Banner</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                                <div class="card" >
                                    <div class="card-body">
                                        <label htmlFor=""><b>Files</b></label>

                                        <div class="d-flex flex-wrap align-items-center">
                                            {files}
                                        </div>

                                        <br />
                                        <input type="file" class="form-control-file" id="addFilesUpload" onChange={(input) => {
                                            this.fileUpload(input)
                                        }} hidden multiple />
                                        <button disabled={this.state.isLoading} className="btn btn-primary" type="button" onClick={(e) => {
                                            document.getElementById(`addFilesUpload`).click();
                                        }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Files</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inventoryMetaKeyValue"><b>Meta Key Value</b></label>
                                    {
                                        this.state.metakeyvalue.map((item, index) => (
                                            <div className=''>
                                                <label htmlFor={`inventoryMetaKeyValueKey${index}`}>Key</label>
                                                <input maxLength={100} type="text" id={`inventoryMetaKeyValueKey${index}`} className="form-control border-1 small" name={`metakeyvaluekey${index}`} value={this.state.metakeyvalue[index].k} onChange={(e) => {
                                                    var tmpMetaKeyValue = this.state.metakeyvalue
                                                    tmpMetaKeyValue[index].k = e.target.value
                                                    this.setState({ metakeyvalue: tmpMetaKeyValue })
                                                }} />

                                                <label htmlFor={`inventoryMetaKeyValueValue${index}`}>Value</label>
                                                <input maxLength={100} type="text" id={`inventoryMetaKeyValueValue${index}`} className="form-control border-1 small" name={`metakeyvaluevalue${index}`} value={this.state.metakeyvalue[index].v} onChange={(e) => {
                                                    var tmpMetaKeyValue = this.state.metakeyvalue
                                                    tmpMetaKeyValue[index].v = e.target.value
                                                    this.setState({ metakeyvalue: tmpMetaKeyValue })
                                                }} />

                                                <span title='Remove' className='btn-link' onClick={() => {

                                                    var tmpMetaKeyValue = this.state.metakeyvalue
                                                    tmpMetaKeyValue.splice(parseInt(index), 1)
                                                    this.setState({ metakeyvalue: tmpMetaKeyValue })

                                                }} >
                                                    <small>
                                                        <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove
                                                    </small>
                                                </span>
                                            </div>
                                        ))
                                    }
                                    <br />
                                    <span className='btn-link' onClick={() => {
                                        var tmpMetaKeyValue = this.state.metakeyvalue
                                        tmpMetaKeyValue.push({
                                            k: "",
                                            v: ""
                                        })
                                        this.setState({ metakeyvalue: tmpMetaKeyValue })
                                    }}>
                                        Add Key Value
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='form-group'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inventoryFutureStocking"><b>Future Stocking</b></label>
                                    {
                                        this.state.futurestocking.map((item, index) => (
                                            <div className=''>
                                                <label htmlFor={`inventoryFutureStockingTime${index}`}>Time</label>
                                                <input maxLength={100} type="datetime-local" id={`inventoryFutureStockingTime${index}`} className="form-control border-1 small" name={`inventoryFutureStockingTime${index}`} value={this.state.futurestocking[index].ta} onChange={(e) => {
                                                    var tmpFutureStocking = this.state.futurestocking
                                                    tmpFutureStocking[index].ta = e.target.value
                                                    this.setState({ futurestocking: tmpFutureStocking })
                                                }} />


                                                <label htmlFor={`inventoryFutureStockingStockAmount${index}`}>Stock Amount</label>
                                                <input min={1} type="number" id={`inventoryFutureStockingStockAmount${index}`} className="form-control border-1 small" name={`inventoryFutureStockingStockAmount${index}`} value={this.state.futurestocking[index].sa} onChange={(e) => {
                                                    var tmpFutureStocking = this.state.futurestocking
                                                    tmpFutureStocking[index].sa = e.target.value
                                                    this.setState({ futurestocking: tmpFutureStocking })
                                                }} />


                                                <label htmlFor={`inventoryFutureStockingNote${index}`}>Note</label>
                                                <textarea maxLength={1000} type="text" id={`inventoryFutureStockingNote${index}`} className="form-control border-1 small" name={`inventoryFutureStockingNote${index}`} value={this.state.futurestocking[index].n} onChange={(e) => {
                                                    var tmpFutureStocking = this.state.futurestocking
                                                    tmpFutureStocking[index].n = e.target.value
                                                    this.setState({ futurestocking: tmpFutureStocking })
                                                }} />

                                                <span title='Remove' className='btn-link' onClick={() => {

                                                    var tmpFutureStocking = this.state.futurestocking
                                                    tmpFutureStocking.splice(parseInt(index), 1)
                                                    this.setState({ futurestocking: tmpFutureStocking })

                                                }} >
                                                    <small>
                                                        <FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove
                                                    </small>
                                                </span>
                                            </div>
                                        ))
                                    }
                                    <br />
                                    <span className='btn-link' onClick={() => {
                                        var tmpFutureStocking = this.state.futurestocking
                                        tmpFutureStocking.push({
                                            ta: DateTime.local().plus({ days: 1 }).toFormat("yyyy-LL-dd'T'HH:mm"),
                                            sa: 1,
                                            n: ""
                                        })
                                        this.setState({ futurestocking: tmpFutureStocking })
                                    }}>
                                        Add Future Stock
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className='form-group'>
                            <div className="row mt-2">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <button disabled={this.state.isLoading} className="btn btn-primary m-1" id="postItemButton" onClick={this.postItem}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add</button>

                                    <button disabled={this.state.isLoading} className="btn btn-primary m-1" id="postItemButton" onClick={() => {
                                        fullDialogV2Dispatcher.dispatch({
                                            actionType: "show-dialog",
                                            title: "Bulk Add",
                                            message: <BulkInventoryAdd />,
                                            dialogWidth: 3
                                        })
                                    }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Bulk Add</button>

                                    <button className="btn btn-primary m-1" onClick={() => {
                                        fullDialogV2Dispatcher.dispatch({
                                            actionType: "show-dialog",
                                            title: "Clear Data",
                                            message: <div>
                                                <div className='form-group'>
                                                    Do you want to clear all fields?
                                                </div>

                                                <div className='form-group'>
                                                    <button className='btn btn-danger' onClick={this.resetAllFields}>Clear</button>
                                                </div>

                                            </div>,
                                            dialogIndexCallback: (c) => {
                                                clearAddInventoryDataDialogIndex = c
                                            }
                                        })
                                    }}>Clear</button>
                                    <this.Status />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <ItemSearch nameTitleKey="name" buttons={[
                            {
                                icon: <FontAwesomeIcon icon={faCopy} />,
                                title: "Copy to Add Inventory",
                                action: (data, index, pageNumber) => {
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Copy Data",
                                        dialogIndexCallback: (d) => {
                                            copyInventoryDataToAddInventoryDialogIndex = d
                                        },
                                        message: <div className="p-2">
                                            <div className="form-group">
                                                Do you want to copy data of "<b>{data.name}</b> to Add Inventory?"
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary m-1" onClick={() => {
                                                    var tmpFutureStocking = []
                                                    for (var i in data.futurestocking) {
                                                        tmpFutureStocking.push({
                                                            ta: DateTime.fromISO(data.futurestocking[i].ta).toFormat("yyyy-LL-dd'T'HH:mm"),
                                                            n: data.futurestocking[i].n,
                                                            sa: parseInt(data.futurestocking[i].sa)
                                                        })
                                                    }
                                                    this.setState({
                                                        name: data.name,
                                                        description: data.description,
                                                        ecomDescription: data.ecomDescription,
                                                        ecom: data.ecom,
                                                        keywords: data.keywords,
                                                        color: data.color,

                                                        hsn: data.hsn,
                                                        type: data.type,
                                                        unit: data.unit,
                                                        quantity: data.quantity,
                                                        dimensions: data.dimensions,
                                                        weight: data.weight,
                                                        info: data.info,
                                                        price: data.price,
                                                        tax: data.tax,
                                                        coupon: data.coupon,

                                                        // response: {},
                                                        images: data.images,

                                                        article: data.article,
                                                        material: data.material,
                                                        gender: data.gender,
                                                        shape: data.shape,
                                                        occasion: data.occasion,
                                                        purity: data.purity,
                                                        plating: data.plating,
                                                        countryoforigin: data.countryoforigin,
                                                        maxperecomorder: data.maxperecomorder,
                                                        configurationIdentifier: data.configurationIdentifier,
                                                        addOnIdentifier: data.addOnIdentifier,
                                                        files: data.files,
                                                        checkstockonorder: data.checkstockonorder,
                                                        leadtime: data.leadtime,
                                                        metakeyvalue: data.metakeyvalue,
                                                        futurestocking: tmpFutureStocking,
                                                        banners: data.banners

                                                    }, () => {
                                                        fullDialogV2Dispatcher.dispatch({
                                                            actionType: "close-dialog",
                                                            dialogIndex: copyInventoryDataToAddInventoryDialogIndex
                                                        })
                                                        fullDialogV2Dispatcher.dispatch({
                                                            actionType: "show-dialog",
                                                            title: "Copy Data",
                                                            message: <div><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span> Data successfully copied!</div>
                                                        })

                                                    })
                                                }} >Copy</button>

                                                <button className="btn btn-danger" onClick={() => {
                                                    fullDialogV2Dispatcher.dispatch({
                                                        actionType: "close-dialog",
                                                        dialogIndex: copyInventoryDataToAddInventoryDialogIndex
                                                    })
                                                }}>
                                                    Cancel
                                                </button>



                                            </div>
                                        </div>
                                    })



                                }
                            }
                        ]} onClickCallback={(resultData) => {
                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Edit Item",
                                message: <EditInventoryData data={this.data} itemID={resultData._id} />,
                                dialogWidth: 3,
                                dialogIndexCallback: (c) => {
                                    editInventoryDataDialogIndex = c
                                }
                            })
                        }} isResultLink={false} resultClickable={true} searchAllowed={false} gType="inv" timeKey="t" urlEndPoint="inventory" subInfoKeys={[
                            {
                                "key": "sku",
                                "map": function (data) {
                                    return <b>{data}</b>
                                }
                            },
                            {
                                "key": "price",
                                "map": function (data) {
                                    if (data !== undefined) {
                                        if (data.mrp !== undefined) {
                                            return [<br />, <span>MRP: {ecommerce_helper_function_get_formatted_price(data.mrp)}</span>]
                                        } else {
                                            return [<br />, <span>SP: {ecommerce_helper_function_get_formatted_price(data.mrp)}</span>]
                                        }
                                    }
                                }
                            },
                            {
                                "key": "ecom",
                                "map": function (data) {
                                    if (data === true) {
                                        return [<br />, <span className="text-success">Visible on Ecommerce</span>]
                                    } else {
                                        return [<br />, <span className="text-danger">NOT Visible on Ecommerce</span>]
                                    }

                                }
                            },
                            {
                                "key": "cn",
                                "map": function (data) {
                                    if (String(data).trim().length > 0) {
                                        return [<br />, <span className="">Collection: {data}</span>]
                                    }
                                    return null
                                }
                            },
                            {
                                "key": "images",
                                "map": function (data) {
                                    if (data !== undefined) {
                                        if (data.length === 0) {
                                            return null
                                        }
                                        /* prepare images */
                                        var images = []
                                        for (var i in data) {
                                            const idx = i
                                            images.push(
                                                <div style={{
                                                    maxWidth: '100px'
                                                }}>
                                                    <AuthenticatedImage key={data[idx].image} className="p-2" imageID={data[idx].image} imageStyle={{
                                                        width: "100%",
                                                        cursor: 'pointer'
                                                    }} onClick={() => {
                                                        fullDialogDispatcher.dispatch({
                                                            actionType: "show-dialog",
                                                            title: null,
                                                            closeOnOutsideClick: true,
                                                            message: <AuthenticatedImage imageID={data[idx].image} imageStyle={{
                                                                maxWidth: "100%",
                                                                width: "100%"
                                                            }}
                                                            />
                                                        })
                                                    }} />
                                                </div>
                                            )
                                        }
                                        return <div class="d-flex flex-wrap">
                                            {images}
                                        </div>
                                    }
                                }
                            },

                        ]} urlParams={{
                            uid: this.userID
                        }
                        } sortCriteria="t"
                            // postData={{
                            //     f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
                            //     t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
                            //     s: this.state.searchTerm,
                            //     e: this.state.ecom,
                            //     a: this.state.all
                            // }}
                            getDataCaller={(dispatcher) => {
                                this.viewInventoryDispatcher = dispatcher
                            }}
                        />

                    </div>
                </div>
            </div>

        ]
    }

}


class CurrentInventoryStockValue extends React.Component {
    constructor(props) {
        super()
        this.itemID = props.itemID

        fetchCurrentInventoryStockValue = this.fetchCurrentInventoryStockValue

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            currentValue: 0,
        }
    }

    componentDidMount() {
        this.fetchCurrentInventoryStockValue()
    }

    handleFetchCurrentInventoryStockValue = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get inventory stock entry")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            this.setState({ isSuccess: false, isError: true, })
            return
        }

        this.setState({ isSuccess: true, isError: false, currentValue: response.payload.v })
    }

    fetchCurrentInventoryStockValue = () => {


        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "inventory", {
            params: {
                g: "gsv",
                id: this.itemID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchCurrentInventoryStockValue(response.data)
            })
            .catch(function (error) {
                that.handleFetchCurrentInventoryStockValue({})
            })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <div className="form-group">
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                </div>
            )
        }
        if (this.state.isError) {
            return (
                <div>
                    <div className="form-group text-danger">
                        Failed to get current inventory stock value.
                    </div>
                </div>
            )
        }
        if (this.state.isSuccess) {
            return (
                <div>
                    <div className="form-group">
                        Current Inventory Stock Value
                    </div>
                    <div className="form-group">
                        <h4>
                            {parseFloat(this.state.currentValue).toLocaleString('en-IN')}
                        </h4>
                    </div>
                </div>
            )
        }
        return null
    }
}

class AddInventoryStockEntry extends React.Component {
    constructor(props) {
        super()
        this.itemID = props.itemID

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            amount: 0,

            inventoryStockEntryType: "inventoryStockManualEntry",
            note: ""
        }
    }


    handleInventoryStockEntry = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add inventory stock entry")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Inventory Stock Entry",
                message: `Failed to add inventory stock entry. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this
        that.setState({ amount: 0, note: "" })
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Inventory Stock Entry",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        inventoryStockHistoryGetDataDispatcher.dispatch({
            actionType: "get-data"
        })
        fetchCurrentInventoryStockValue()


    }

    addInventoryStockEntry = () => {

        /* prepare post data */
        var m = false

        var e = false

        if (this.state.inventoryStockEntryType === "inventoryStockManualEntry") {
            m = true
        }

        if (this.state.inventoryStockEntryType === "inventoryStockEcomEntry") {
            e = true
        }

        var postData = {
            a: parseFloat(this.state.amount),
            m: m,
            e: e,
            n: this.state.note,
            id: this.itemID
        }


        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "inventory",
            postData
            , {
                params: {
                    g: "man",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleInventoryStockEntry(response.data)
            })
            .catch(function (error) {
                that.handleInventoryStockEntry({})
            })
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    Add Inventory Stock Entry
                </div>
                <div className="form-group">
                    <label htmlFor="inventoryStockEntryStockAmount">Stock Amount</label>
                    <input type="number" id="inventoryStockEntryStockAmount" value={this.state.amount} className="form-control" onChange={(e) => {
                        this.setState({ amount: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Entry Type</label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="inventoryStockManualEntry" id="inventoryStockManualEntry" checked={this.state.inventoryStockEntryType === "inventoryStockManualEntry"} onChange={(e) => {
                            this.setState({
                                inventoryStockEntryType: e.target.name
                            })
                        }} />
                        <label class="form-check-label" for="inventoryStockManualEntry">
                            Manual
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="inventoryStockEcomEntry" id="inventoryStockEcomEntry" checked={this.state.inventoryStockEntryType === "inventoryStockEcomEntry"} onChange={(e) => {
                            this.setState({
                                inventoryStockEntryType: e.target.name
                            })
                        }} />
                        <label class="form-check-label" for="inventoryStockEcomEntry">
                            Ecommerce
                        </label>
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor="inventoryStockEntryNote">Note</label>
                    <textarea value={this.state.note} id="inventoryStockEntryNote" className='form-control' onChange={(e) => {
                        this.setState({ note: e.target.value })
                    }} maxLength="1000" />
                </div>
                <div className="form-group">
                    <button disabled={this.state.isLoading} onClick={this.addInventoryStockEntry} className="btn btn-primary">
                        <span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCheckCircle} spin />&nbsp;</span>Add
                    </button>
                </div>
            </div>
        )
    }
}

class ViewInventoryStockHistory extends React.Component {
    constructor(props) {
        super()
        this.itemID = props.itemID
        this.state = {

        }
    }

    render() {
        return (
            <div className="">
                <div className="form-group">
                    Inventory Stock History
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKeyPrefix={<span></span>} nameTitleKey="a" onClickCallback={(resultData) => { }} isResultLink={false} resultClickable={false} searchAllowed={false} gType="gsh" timeKey="t" urlEndPoint="inventory" subInfoKeys={[
                        {
                            "key": "a",
                            "map": function (data) {
                                if (parseInt(data) < 0) {
                                    return <span className='text-danger'>Outgoing</span>
                                }
                                return <span className='text-success'>Incoming</span>
                            }
                        },
                        {
                            "key": "n",
                            "map": function (data) {
                                return <div style={{
                                    whiteSpace: 'pre-wrap'
                                }}>
                                    {data}
                                </div>
                            }
                        },
                        {
                            "key": "m",
                            "map": function (data) {
                                if (data === true) {
                                    return <small>Manual</small>
                                }
                                return null
                            }
                        },
                        {
                            "key": "b",
                            "map": function (data) {
                                if (data === true) {
                                    return <small>Bulk</small>
                                }
                                return null
                            }
                        },
                        {
                            "key": "e",
                            "map": function (data) {
                                if (data === true) {
                                    return <small>E-commerce</small>
                                }
                                return null
                            }
                        },
                    ]} urlParams={{
                        id: this.itemID
                    }
                    } getDataCaller={(dispatcher) => {
                        inventoryStockHistoryGetDataDispatcher = dispatcher
                    }} />
                </div>
            </div>
        )
    }
}
class ManageInventoryStock extends React.Component {
    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")


        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                    fullDialogDispatcher.dispatch({
                        actionType: "show-dialog",
                        title: "Inventory Stock",
                        dialogWidth: 5,
                        message: <div>
                            <div className="m-0">
                                <div className='form-group'>
                                    <h4>{resultData.name}</h4>
                                </div>
                                <div className='form-group'>
                                    <h5>{resultData.sku}</h5>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                                    <CurrentInventoryStockValue itemID={resultData._id} />
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                                    <AddInventoryStockEntry itemID={resultData._id} />
                                </div>

                                <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                                    <ViewInventoryStockHistory itemID={resultData._id} />
                                </div>
                            </div>
                        </div>
                    })
                }} isResultLink={false} resultClickable={true} searchAllowed={false} gType="inv" timeKey="t" urlEndPoint="inventory" subInfoKeys={[
                    {
                        "key": "sku",
                        "map": function (data) {
                            return <b>{data}</b>
                        }
                    },
                    {
                        "key": "price",
                        "map": function (data) {
                            if (data !== undefined) {
                                if (data.mrp !== undefined) {
                                    return [<br />, <span>MRP: {ecommerce_helper_function_get_formatted_price(data.mrp)}</span>]
                                } else {
                                    return [<br />, <span>SP: {ecommerce_helper_function_get_formatted_price(data.mrp)}</span>]
                                }
                            }
                        }
                    },
                    {
                        "key": "ecom",
                        "map": function (data) {
                            if (data === true) {
                                return [<br />, <span className="text-success">Visible on Ecommerce</span>]
                            } else {
                                return [<br />, <span className="text-danger">NOT Visible on Ecommerce</span>]
                            }

                        }
                    },

                ]} urlParams={{
                    uid: this.userID
                }
                } sortCriteria="t"
                    // postData={{
                    //     f: DateTime.fromFormat(this.state.fromDate, "yyyy-LL-dd'T'HH:mm").toString(),
                    //     t: DateTime.fromFormat(this.state.toDate, "yyyy-LL-dd'T'HH:mm").toString(),
                    //     s: this.state.searchTerm,
                    //     e: this.state.ecom,
                    //     a: this.state.all
                    // }}
                    getDataCaller={(dispatcher) => {
                        this.viewInventoryDispatcher = dispatcher
                    }}
                />
            </div>
        )
    }
}

class ViewSingleItemVariationPage extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            isDeleteItemVariationLoading: false,
            isDeleteItemVariationError: false,
            isDeleteItemVariationSuccess: false,
        }
    }

    deleteItemVariation = () => {

        networkCallPost(ADMINURL, "inventoryvariations", {
            vid: this.data.var_id
        }, {}, {

        }, (data) => {
            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: viewSingleItemVariationDialogIndex
            })
            viewItemVariationsDispatcher.dispatch({
                actionType: "get-data"
            })
        }, (data) => {

        }, (error) => {

        }, ACCT, "del", "status")
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                </div>
                <div className="form-group">
                    <button className='btn btn-danger' onClick={() => {
                        this.deleteItemVariation()
                    }}>
                        Delete Variation
                    </button>
                </div>

            </div>
        )
    }
}
class AddItemVariationPage extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: false,

            isAddItemVariationLoading: false,
            isAddItemVariationError: false,
            isAddItemVariationSuccess: false,

            itemVariationItems: []
        }
    }


    addItemVariation = (data) => {
        networkCallPost(ADMINURL, "inventoryvariations", {

        }, {}, {
            a: this.data._id,
            b: data._id
        }, (data) => {
            viewItemVariationsDispatcher.dispatch({
                actionType: "get-data"
            })
        }, (data) => {

        }, (error) => {

        }, ACCT, "add", "status")
    }

    getItemVariations = () => {

    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <h3>{this.data.name}</h3>
                    <h6>{this.data.sku}</h6>
                </div>
                <div className="form-group">
                    <button onClick={() => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Select Item',
                            message: <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                                // const id = this.state.slider[iid]["_id"]
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "close-dialog",
                                    dialogIndex: viewItemVariationDialogIndex
                                })
                                this.addItemVariation(resultData)
                            }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="_created" urlEndPoint="inventory"
                            />,
                            dialogIndexCallback: (d) => {
                                viewItemVariationDialogIndex = d
                            }
                        })
                    }} className='btn btn-primary'>
                        Add
                    </button>
                </div>
                <div className="form-group">
                    Item Variations
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="name" getDataCaller={(d) => {
                        viewItemVariationsDispatcher = d
                    }} onClickCallback={(resultData) => {

                        fullDialogV2Dispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'View Variation',
                            message: <ViewSingleItemVariationPage data={resultData} />,
                            dialogIndexCallback: (d) => {
                                viewSingleItemVariationDialogIndex = d
                            }
                        })

                    }} resultClickable={true} searchAllowed={false} gType="get" timeKey="t" searchLimit={10} urlEndPoint="inventoryvariations" urlParams={{
                        iid: this.data._id
                    }} subInfoKeys={[
                        {
                            "key": "sku",
                            "map": function (data) {
                                return <span><small>{data}</small></span>
                            }
                        }]} />
                </div>

            </div>
        )
    }
}

class ItemVariationPage extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label>Inventory Items Variations</label>
                    <ItemSearch nameTitleKey="name" onClickCallback={(resultData) => {
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Add Item Variation",
                            message: <AddItemVariationPage data={resultData} />,
                            dialogWidth: 3
                        })
                    }} resultClickable={true} searchAllowed={true} gType="inv" timeKey="t" searchLimit={10} urlEndPoint="inventory" />
                </div>
            </div>
        )
    }

}

class BulkImagesUpload extends React.Component {
    constructor(props) {
        super()
        this.data = props.data
        this.onSuccess = props.onSuccess
        this.onError = props.onError

        this.imageIIndex = 0
        this.imageKIndex = 0

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false,

            imagesData: [...this.data],
            errorData: [],
            successData: [],

            currentFilename: ""
        }
    }

    componentDidMount() {
        this.uploadImage()
    }

    uploadImageNext = () => {

        if (this.data.length - 1 === this.imageIIndex && this.imageKIndex == this.data[this.imageIIndex].images.length - 1) {
            this.setState({
                isLoading: false,
                isError: false,
                isSuccess: true,
            })

            fullDialogV2Dispatcher.dispatch({
                actionType: "close-dialog",
                dialogIndex: bulkImageAddUploadImagesDialogIndex
            })


            if (this.onSuccess) {
                this.onSuccess(this.state.imagesData, this.state.successData, this.state.errorData)
            }

            return
        }

        if (this.data[this.imageIIndex].images.length - 1 == this.imageKIndex) {
            this.imageIIndex += 1
            this.imageKIndex = 0
        } else {
            this.imageKIndex += 1
        }

        this.uploadImage()
    }

    uploadImage = () => {

        const image_data = dataURItoBlob(this.data[this.imageIIndex].images[this.imageKIndex].imageData)
        const image_name = this.data[this.imageIIndex].images[this.imageKIndex].filename

        this.setState({
            isLoading: true,
            isError: false,
            isSuccess: false,
            currentFilename: image_name
        })

        networkCallPostFile(ADMINURL, "image", {}, {}, image_data, image_name, (data) => {


            const tmpSuccessData = this.state.successData
            tmpSuccessData.push({
                filename: image_name,
                resp: data.payload
            })


            const tmpImagesData = this.state.imagesData
            tmpImagesData[this.imageIIndex].images[this.imageKIndex].image = data.payload.id

            this.setState({
                successData: tmpSuccessData,
                imagesData: tmpImagesData
            })


            this.uploadImageNext()


        }, (data) => {


            const tmpErrorData = this.state.errorData
            tmpErrorData.push({
                filename: image_name,
                resp: data.payload
            })
            this.setState({
                errorData: tmpErrorData
            })

            this.uploadImageNext()

        }, (data) => {

            const tmpErrorData = this.state.errorData
            tmpErrorData.push({
                filename: image_name,
                resp: data
            })
            this.setState({
                errorData: tmpErrorData
            })

            this.uploadImageNext()

        }, ACCT, null, "status")

    }


    render() {
        if (this.state.isLoading) {
            return <div className='' >
                <div className='d-flex justify-content-center form-group'>

                    <h3>
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                    </h3>
                </div>
                <div className='form-group'>
                    Uploading: <b>{this.state.currentFilename}</b>
                </div>
            </div>
        }
        return (
            <div>

            </div>
        )
    }
}
class BulkInventoryAdd extends React.Component {
    constructor(props) {
        super()
        this.state = {

            isLoading: false,
            isError: false,
            isSuccess: true,

            isFileLoading: false,
            isFileSuccess: false,
            isFileError: false,

            isStockFileLoading: false,
            isStockFileSuccess: false,
            isStockFileError: false,

            isImageFileLoading: false,
            isImageFileSuccess: false,
            isImageFileError: false,

            isAddLoading: false,
            isAddSuccess: false,
            isAddError: false,

            isImageAddLoading: false,
            isImageAddSuccess: false,
            isImageAddError: false,


            inventoryData: [],
            inventoryStockData: [],

            inventoryFileName: "",

            inventoryImages: [],
            inventoryImagesProcessed: [],

            inventoryBulkImagesErrors: [],
            inventoryBulkImagesSuccess: [],

        }
    }

    uploadFile = (file, action) => {

        if (action === "photos") {
            if (file["type"] !== "image/jpeg" && file["type"] !== "image/x-png" && file["type"] !== "image/png") {
                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Invalid file format. JPEG and PNG file expected.</span>, false, "", () => { })
                return
            }
        } else {
            if (file["type"] !== "application/vnd.ms-excel" && file["type"] !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && file["type"] !== "application/vnd.openxmlformats-officedocument.spreadsheetml.template") {
                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Invalid file format. Excel file expected.</span>, false, "", () => { })
                return
            }
        }

        this.setState({

            isFileLoading: true,
            isFileSuccess: false,
            isFileError: false,

            isStockFileLoading: true,
            isStockFileSuccess: false,
            isStockFileError: false,

            isImageFileLoading: true,
            isImageFileSuccess: false,
            isImageFileError: false,

            isImageAddLoading: false,
            isImageAddSuccess: false,
            isImageAddError: false,

        })
        const that = this
        var reader = new FileReader();
        reader.onload = function (e) {

            that.setState({

                isFileLoading: false,
                isFileSuccess: false,
                isFileError: false,

                isStockFileLoading: false,
                isStockFileSuccess: false,
                isStockFileError: false,

                isImageFileLoading: false,
                isImageFileSuccess: false,
                isImageFileError: false,

                inventoryFileName: file.name

            })

            const data = new Uint8Array(e.target.result);

            if (action === "bulkadd") {
                that.processFileData(e.target.result)
            }

            if (action === "stock") {
                that.processStockFileData(e.target.result)
            }

            if (action === "photos") {
                that.processImageView(e.target.result, file.name)
            }

        }
        if (action === "photos") {
            reader.readAsDataURL(file)
            return
        }
        reader.readAsArrayBuffer(file)

    }

    processImageView = (data, filename) => {

        var image_sku = "";
        var image_original_filename = "";
        var image_index = 0;
        var image_qr_size = 0;
        var image_qr_position = 0;

        /* get image data */

        const filename_split = String(filename).split(";")

        if (filename_split.length != 5) {
            helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to add image. Invalid file name format.</span>, false, "", () => { })
            return
        }

        /* */
        image_sku = filename_split[0]
        image_qr_position = filename_split[1]
        image_qr_size = parseInt(filename_split[2])
        image_index = parseInt(filename_split[3])
        image_original_filename = filename_split[4]
        /* */

        var tmpInventoryImages = this.state.inventoryImages

        /* check if such sku already exist */
        var sku_exist = false;
        for (var i in tmpInventoryImages) {
            if (tmpInventoryImages[i].sku === image_sku) {
                tmpInventoryImages[i].images.push({
                    imageData: data,
                    image: "",
                    filename: filename,
                    originalFilename: image_original_filename,
                    index: image_index,
                    sku: image_sku,
                    qr_position: image_qr_position,
                    qr_size: image_qr_size,
                })
                sku_exist = true
                break
            }
        }
        /* */

        if (!sku_exist) {
            tmpInventoryImages.push({
                sku: image_sku,
                images: [{
                    imageData: data,
                    image: "",
                    filename: filename,
                    originalFilename: image_original_filename,
                    index: image_index,
                    sku: image_sku,
                    qr_position: image_qr_position,
                    qr_size: image_qr_size,
                }]
            })
        }




        this.setState({
            inventoryImages: tmpInventoryImages,
            isImageFileLoading: false,
            isImageFileSuccess: true,
            isImageFileError: false,

        })
    }

    processStockFileData = (data) => {

        try {
            var workbook = XLSX.read(data);
        } catch (error) {
            this.setState({
                isStockFileLoading: false,
                isStockFileSuccess: false,
                isStockFileError: true,
            })
            helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to read the excel file.</span>, false, "", () => { })
            return
        }

        if (Object.keys(workbook.Sheets).length !== 1) {
            this.setState({
                isStockFileLoading: false,
                isStockFileSuccess: false,
                isStockFileError: true,
            })
            helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>No valid sheet found.</span>, false, "", () => { })
            return
        }

        /* process data from each and create a array to upload */
        const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]]

        const sheet_json = XLSX.utils.sheet_to_json(sheet)



        /* */

        this.setState({
            isStockFileLoading: false,
            isStockFileSuccess: true,
            isStockFileError: false,
            inventoryStockData: sheet_json
        })

        /* prepare data from every row */



        /* */

    }

    processFileData = (data) => {

        try {
            var workbook = XLSX.read(data);
        } catch (error) {
            this.setState({
                isFileLoading: false,
                isFileSuccess: false,
                isFileError: true,
            })
            helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to read the excel file.</span>, false, "", () => { })
            return
        }

        if (Object.keys(workbook.Sheets).length !== 1) {
            this.setState({
                isFileLoading: false,
                isFileSuccess: false,
                isFileError: true,
            })
            helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>No valid sheet found.</span>, false, "", () => { })
            return
        }

        /* process data from each and create a array to upload */
        const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]]

        const sheet_json = XLSX.utils.sheet_to_json(sheet)
        /* */

        /* convert visible on ecommerce to bool */

        for (var i in sheet_json) {
            if (String(sheet_json[i]["Visible on Ecommerce"]).toLowerCase() === "yes") {
                sheet_json[i]["Visible on Ecommerce"] = true
            } else {
                sheet_json[i]["Visible on Ecommerce"] = false
            }
        }

        /* */


        this.setState({
            isFileLoading: false,
            isFileSuccess: true,
            isFileError: false,
            inventoryData: sheet_json
        })


    }

    addData = () => {
        this.setState({
            isAddLoading: true,
            isAddSuccess: false,
            isAddError: false,
        })

        networkCallPost(ADMINURL, "inventorybulk", {}, {}, {
            i: this.state.inventoryData
        }, (data) => {
            this.setState({
                isAddLoading: false,
                isAddSuccess: true,
                isAddError: false,
            })

            helper_function_info_dialog("Bulk Add", <span>
                {
                    data.payload.c.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Added:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.c.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
                {
                    data.payload.u.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Updated:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.u.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
                {
                    data.payload.e.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Errors:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.e.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
            </span>, false, "", () => { })


            // helper_function_info_dialog("Bulk Add", <span><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>Data added.</span>, false, "", () => { })


        },
            (data) => {
                this.setState({
                    isAddLoading: false,
                    isAddSuccess: false,
                    isAddError: true,
                })

                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to add data.</span>, false, "", () => { })

            },
            (data) => {
                this.setState({
                    isAddLoading: false,
                    isAddSuccess: false,
                    isAddError: true,
                })

                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Unable to add data. Please try again.</span>, false, "", () => { })

            }, ACCT, "abi", "status")
    }

    addStockData = () => {
        this.setState({
            isAddLoading: true,
            isAddSuccess: false,
            isAddError: false,
        })

        networkCallPost(ADMINURL, "inventorybulk", {}, {}, {
            i: this.state.inventoryStockData,
            fn: this.state.inventoryFileName
        }, (data) => {
            this.setState({
                isAddLoading: false,
                isAddSuccess: true,
                isAddError: false,
            })

            helper_function_info_dialog("Bulk Add", <span>
                {
                    data.payload.u.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Updated:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.u.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
                {
                    data.payload.e.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Errors:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.e.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
            </span>, false, "", () => { })


            // helper_function_info_dialog("Bulk Add", <span><span className='text-success'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>Data added.</span>, false, "", () => { })


        },
            (data) => {
                this.setState({
                    isAddLoading: false,
                    isAddSuccess: false,
                    isAddError: true,
                })

                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to add stock data: {data.payload}.</span>, false, "", () => { })

            },
            (data) => {
                this.setState({
                    isAddLoading: false,
                    isAddSuccess: false,
                    isAddError: true,
                })

                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Unable to add stock data. Please try again.</span>, false, "", () => { })

            }, ACCT, "asd", "status")
    }

    uploadImages = () => {


        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Upload Images",
            message: <BulkImagesUpload data={this.state.inventoryImages} onSuccess={(imagesData, successData, errorData) => {

                this.setState({
                    inventoryImagesProcessed: imagesData,
                    inventoryBulkImagesSuccess: successData,
                    inventoryBulkImagesErrors: errorData,
                }, () => {
                    this.addImagesData()
                })


            }} />,
            dialogIndexCallback: (i) => {
                bulkImageAddUploadImagesDialogIndex = i
            },
            closeDisable: true
        })
    }

    addImagesData = () => {

        this.setState({
            isImageAddLoading: true,
            isImageAddSuccess: false,
            isImageAddError: false,
        })

        const final_data = []
        for (var i in this.state.inventoryImagesProcessed) {

            const tmp_item = {
                sku: this.state.inventoryImagesProcessed[i].sku,
                images: []
            }

            for (var k in this.state.inventoryImagesProcessed[i].images) {
                tmp_item.images.push({
                    image: this.state.inventoryImagesProcessed[i].images[k].image,
                    filename: this.state.inventoryImagesProcessed[i].images[k].filename,
                    originalFilename: this.state.inventoryImagesProcessed[i].images[k].originalFilename,
                    index: this.state.inventoryImagesProcessed[i].images[k].index,
                    sku: this.state.inventoryImagesProcessed[i].images[k].sku,
                    qr_position: this.state.inventoryImagesProcessed[i].images[k].qr_position,
                    qr_size: this.state.inventoryImagesProcessed[i].images[k].qr_size,

                })
            }
            final_data.push({ ...tmp_item })
        }


        networkCallPost(ADMINURL, "inventorybulk", {}, {}, {
            i: final_data,
        }, (data) => {
            this.setState({
                isImageAddLoading: false,
                isImageAddSuccess: true,
                isImageAddError: false,
            })

            helper_function_info_dialog("Bulk Add", <span>
                {
                    data.payload.u.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Updated:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.u.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
                {
                    data.payload.e.length > 0 ?
                        <div className='form-group'>
                            <b>
                                Errors:
                            </b>
                            <br />
                            <ul>
                                {
                                    data.payload.e.map((val, idx) => {
                                        return <li>
                                            {val}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
            </span>, false, "", () => { })

        },
            (data) => {
                this.setState({
                    isImageAddLoading: false,
                    isImageAddSuccess: false,
                    isImageAddError: true,
                })

                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Failed to add stock data: {data.payload}.</span>, false, "", () => { })

            },
            (data) => {
                this.setState({
                    isImageAddLoading: false,
                    isImageAddSuccess: false,
                    isImageAddError: true,
                })

                helper_function_info_dialog("Bulk Add", <span><span className='text-danger'><FontAwesomeIcon icon={faTimesCircle} />&nbsp;</span>Unable to add stock data. Please try again.</span>, false, "", () => { })

            }, ACCT, "aim", "status")
    }

    render() {
        const that = this

        return (
            <div className="">
                <div className='form-group d-flex' style={{
                    gap: '1em'
                }}>
                    <input type="file" class="form-control-file" id="uploadBulkDataItemFile" onChange={(e) => {
                        if (e.target.files.length === 1) {
                            this.uploadFile(e.target.files[0], "bulkadd")
                        }
                    }} hidden />
                    <button className='btn btn-primary' onClick={() => {
                        document.getElementById('uploadBulkDataItemFile').value = ""
                        document.getElementById('uploadBulkDataItemFile').click();
                    }}>
                        Upload Bulk Item File
                    </button>
                    {/* <input type="file" class="form-control-file" id="uploadBulkDataItemVariationsFile" onChange={(e) => {
                        if (e.target.files.length === 1) {
                            this.uploadFile(e.target.files[0], "variations")
                        }
                    }} hidden />
                    <button className='btn btn-primary' onClick={() => {
                        document.getElementById('uploadBulkDataItemVariationsFile').value = ""
                        document.getElementById('uploadBulkDataItemVariationsFile').click();
                    }}>
                        Upload Bulk Item Variations File
                    </button> */}

                    <input type="file" class="form-control-file" id="uploadBulkPhotosFile" onChange={(e) => {
                        for (var i = 0; i < e.target.files.length; i++) {
                            this.uploadFile(e.target.files[i], "photos")
                        }
                    }} hidden multiple />
                    <button className='btn btn-primary' onClick={() => {
                        document.getElementById('uploadBulkPhotosFile').value = ""
                        document.getElementById('uploadBulkPhotosFile').click();
                    }}>
                        Upload Bulk Photos File
                    </button>


                    <input type="file" class="form-control-file" id="uploadBulkStockFile" onChange={(e) => {
                        if (e.target.files.length === 1) {
                            this.uploadFile(e.target.files[0], "stock")
                        }
                    }} hidden />
                    <button className='btn btn-primary' onClick={() => {
                        document.getElementById('uploadBulkStockFile').value = ""
                        document.getElementById('uploadBulkStockFile').click();
                    }}>
                        Upload Bulk Stock File
                    </button>


                </div>
                <div className='form-group'>
                    {
                        this.state.isFileLoading || this.state.isStockFileLoading || this.state.isImageFileLoading ? <span>
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        </span> : this.state.isFileSuccess ?
                            <ItemSearch data={this.state.inventoryData} nameTitleKey="Name" subNameTitleKey="SKU" onClickCallback={(resultData) => {
                                if (this.onDoneCallback) {
                                    this.onDoneCallback(resultData)
                                }
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "close-dialog",
                                    dialogIndex: selectCollectionDialogIndex
                                })
                            }} resultClickable={true} isResultLink={false} searchAllowed={false} gType="get" timeKey="t" urlEndPoint="collections" subInfoKeys={[
                                {
                                    "key": "Description",
                                    "map": function (data) {
                                        return <b>{data}</b>
                                    }
                                },
                            ]} />
                            : this.state.isStockFileSuccess ?
                                <ItemSearch data={this.state.inventoryStockData} subNameTitleKey="Amount" nameTitleKey="SKU" onClickCallback={(resultData) => {
                                    if (this.onDoneCallback) {
                                        this.onDoneCallback(resultData)
                                    }
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "close-dialog",
                                        dialogIndex: selectCollectionDialogIndex
                                    })
                                }} resultClickable={true} isResultLink={false} searchAllowed={false} gType="get" timeKey="t" urlEndPoint="collections" />
                                : this.state.isImageFileSuccess ?
                                    <ItemSearch data={this.state.inventoryImages} nameTitleKey="sku" onClickCallback={(resultData) => {

                                    }} resultClickable={false} isResultLink={false} searchAllowed={false} gType="get" timeKey="t" urlEndPoint="collections" subInfoKeys={[
                                        {
                                            "key": "images",
                                            "map": function (data) {
                                                if (data !== undefined) {
                                                    if (data.length === 0) {
                                                        return null
                                                    }

                                                    /* prepare images */
                                                    var images = []
                                                    for (var i in data) {
                                                        const idx = i

                                                        images.push(
                                                            <div style={{
                                                                maxWidth: '100px',
                                                            }}>

                                                                <UnAuthenticatedImage className="p-2" key={data[idx].filename} imageData={data[idx].imageData} imageStyle={{
                                                                    width: "100%",
                                                                    cursor: 'pointer'
                                                                }} onClick={() => {

                                                                    fullDialogV2Dispatcher.dispatch({
                                                                        actionType: "show-dialog",
                                                                        title: null,
                                                                        closeOnOutsideClick: true,
                                                                        dialogIndexCallback: (d) => {
                                                                            bulkImageAddViewSingleImageDialogIndex = d
                                                                        },
                                                                        message: <div>
                                                                            <div className='form-group'>
                                                                                <UnAuthenticatedImage imageData={data[idx].imageData} key={data[idx].filename} imageStyle={{
                                                                                    maxWidth: "100%",
                                                                                    width: "100%"
                                                                                }}
                                                                                />
                                                                            </div>
                                                                            <div className='form-group'>
                                                                                <button className='btn btn-danger' onClick={() => {
                                                                                    const tmpInventoryImages = that.state.inventoryImages
                                                                                    for (var ii in tmpInventoryImages) {
                                                                                        if (tmpInventoryImages[ii].sku === data[idx].sku) {
                                                                                            tmpInventoryImages[ii].images.splice(idx, 1);
                                                                                            that.setState({
                                                                                                inventoryImages: tmpInventoryImages
                                                                                            })

                                                                                            fullDialogV2Dispatcher.dispatch({
                                                                                                actionType: "close-dialog",
                                                                                                dialogIndex: bulkImageAddViewSingleImageDialogIndex
                                                                                            })

                                                                                        }
                                                                                    }
                                                                                }}>
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }} />
                                                            </div>
                                                        )
                                                    }
                                                    return <div class="d-flex flex-wrap">
                                                        {images}
                                                    </div>
                                                }
                                            }
                                        },
                                    ]} />
                                    : null
                    }
                </div>
                {
                    this.state.isFileSuccess ?
                        <div className='form-group'>
                            <button disabled={this.state.isAddLoading || this.state.isAddSuccess} className='btn btn-primary' onClick={() => {
                                this.addData()
                            }}>
                                <span hidden={!this.state.isAddLoading} ><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Data
                            </button>

                        </div>
                        : null
                }

                {
                    this.state.isStockFileSuccess ?
                        <div className='form-group'>
                            <button disabled={this.state.isAddLoading || this.state.isAddSuccess} className='btn btn-primary' onClick={() => {
                                this.addStockData()
                            }}>
                                <span hidden={!this.state.isAddLoading} ><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Stock Data
                            </button>

                        </div>
                        : null
                }


                {
                    this.state.isImageFileSuccess ?
                        <div className='form-group d-flex' style={{
                            gap: '1em'
                        }}>
                            <button disabled={this.state.isImageAddLoading || this.state.isImageAddSuccess} className='btn btn-primary' onClick={() => {
                                this.uploadImages()
                            }}>
                                <span hidden={!this.state.isImageAddLoading} ><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Images
                            </button>

                            <button disabled={this.state.isImageAddLoading} className='btn btn-danger' onClick={() => {
                                helper_function_yes_no_dialog("Reset Images", <span>Are you sure you want to reset all images?</span>, "Reset", "Cancel", () => {
                                    that.setState({
                                        inventoryImages: [],
                                        isImageFileLoading: false,
                                        isImageFileSuccess: false,
                                        isImageFileError: false,
                                    })
                                }, () => { }, true, true)
                            }}>
                                <span hidden={!this.state.isImageAddLoading} ><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Reset
                            </button>

                        </div>
                        : null
                }


            </div>
        )
    }


}

function Inventory() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<AddInventory urlID={id} />} title={"Add Inventory"} alreadyMinimised={true} />,
        <Window content={<ManageInventoryStock urlID={id} />} title={"Inventory Stock"} alreadyMinimised={true} />,
        <Window content={<ItemVariationPage urlID={id} />} title={"Items Variations"} alreadyMinimised={true} />,
        <Window content={<DownloadInventoryData urlID={id} />} title={"Download Inventory Data"} alreadyMinimised={true} />,
        <Window content={<AddVideoToItemPage urlID={id} />} title={"Add Video To Inventory Item"} alreadyMinimised={true} />,
        // <Window content={<ViewInventory urlID={id} />} title={"View Inventory"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}

export {
    Inventory,
    ViewSingleInventoryData
}