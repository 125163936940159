import { faMinusCircle,  faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'



class Window extends React.Component {
    constructor(props) {
        super()
        this.alreadyMinimised = props.alreadyMinimised
        if (this.alreadyMinimised === undefined || this.alreadyMinimised === null) {
            this.alreadyMinimised = false
        } else {
            this.alreadyMinimised = true
        }
        this.state = {
            minimize: this.alreadyMinimised,
            close: false
        }
        this.content = props.content
        this.title = props.title
    }

    handleMinimize = () => {
        this.setState({ minimize: !this.state.minimize })
    }

    render() {

        var content = this.content

        if (this.state.minimize) {
            content = null
        }

        return (
            <div className="p-2">
                <div className="window">
                    <div className="window-title p-2 d-flex justify-content-between">
                        <span>
                            <FontAwesomeIcon icon={faTimesCircle} className="" />
                            <span hidden={this.state.minimize}>
                                <FontAwesomeIcon icon={faMinusCircle} title="Minimize" className="ml-1" style={{
                                    cursor: 'pointer'
                                }} onClick={this.handleMinimize} />
                            </span>
                            <span hidden={!this.state.minimize}>
                                <FontAwesomeIcon icon={faPlusCircle}  title="Minimize" className="ml-1" style={{
                                    cursor: 'pointer'
                                }} onClick={this.handleMinimize} />
                            </span>
                            <span className="ml-1">
                                {this.title}
                            </span>
                        </span>
                    </div>
                    {content}
                </div>
            </div>
        )
    }

}

export {
    Window
}