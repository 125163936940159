import React from 'react'
import { useHistory, useParams } from 'react-router'
import { ItemSearch } from '../Components/ItemSearch'
import NavBar from '../Components/NavBar'
import { toastsDispatcher } from '../Components/Toasts'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { fullDialogDispatcher } from '../Components/FullDialog'
import { Link } from 'react-router-dom'
import { ADMINURL } from '../Components/MSURLS'

var { DateTime } = require('luxon');
var ACCT = Cookies.get('ACCT')
var viewCouponsGetDataDispatcher = null
var _ = require('lodash');

Object.size = function (obj) {
    var size = 0,
        key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};


class SingleCouponPage extends React.Component {
    constructor(props) {
        super()

        this.couponID = props.couponID

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            coupon: {},
            couponEdited: {},
            inclusionList: {},
            inclusionListEdited: {},
            exclusionList: {},
            exclusionListEdited: {},

        }
    }

    handleFetchCoupon = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to get coupon")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            this.setState({ isSuccess: false, isError: true, })
            return
        }

        const couponData = response.payload.c

        couponData.v = DateTime.fromISO(couponData.v).toFormat("yyyy-LL-dd'T'HH:mm")
        couponData.vf = DateTime.fromISO(couponData.vf).toFormat("yyyy-LL-dd'T'HH:mm")


        this.setState({
            isSuccess: true, isError: false,
            coupon: _.cloneDeep(couponData), couponEdited: _.cloneDeep(couponData),
            exclusionList: _.cloneDeep(response.payload.e), exclusionListEdited: _.cloneDeep(response.payload.e),
            inclusionList: _.cloneDeep(response.payload.i), inclusionListEdited: _.cloneDeep(response.payload.i),
        })

    }

    fetchCoupon = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "coupons", {
            params: {
                g: "sgl",
                id: this.couponID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleFetchCoupon(response.data)
            })
            .catch(function (error) {
                that.handleFetchCoupon({})
            })
    }

    componentDidMount() {
        this.fetchCoupon()
    }


    handleUpdateCoupon = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to update coupon")
            this.setState({ isSuccess: false, isError: true })
            return
        }

        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Update Coupon",
                message: `Failed to update coupon. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }

        const that = this

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Update Coupon",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        // viewCouponsGetDataDispatcher.dispatch({
        //     actionType: "get-data"
        // })
        that.fetchCoupon()

        window.scrollTo(0, 0);

    }

    updateCoupon = () => {
        var couponData = {
            a: parseFloat(this.state.couponEdited.a),
            ua: parseFloat(this.state.couponEdited.ua),
            dt: this.state.couponEdited.dt,
            ct: this.state.couponEdited.ct,
            v: DateTime.fromFormat(this.state.couponEdited.v, "yyyy-LL-dd'T'HH:mm").toString(),
            vf: DateTime.fromFormat(this.state.couponEdited.vf, "yyyy-LL-dd'T'HH:mm").toString(),
            va: parseFloat(this.state.couponEdited.va),
            vl: this.state.couponEdited.vl,
            us: parseInt(this.state.couponEdited.us),
            ou: parseInt(this.state.couponEdited.ou),
            w: this.state.couponEdited.w,
            d: this.state.couponEdited.d,
            e: this.state.couponEdited.e,
            s: this.state.couponEdited.s,
            as: this.state.couponEdited.as
        }

        /* prepare exclusion and inclusion list */
        var tmpInclusionList = {}
        var tmpExclusionList = {}

        for (var i in this.state.inclusionListEdited) {
            tmpInclusionList[i] = {}
        }
        for (var i in this.state.exclusionListEdited) {
            tmpExclusionList[i] = {}
        }

        var postData = {
            c: couponData,
            i: tmpInclusionList,
            e: tmpExclusionList
        }

        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "coupons",
            postData
            , {
                params: {
                    g: "upd",
                    id: this.couponID
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleUpdateCoupon(response.data)
            })
            .catch(function (error) {
                that.handleUpdateCoupon({})
            })
    }

    handleDeleteCoupon = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to delete coupon")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            this.setState({ isSuccess: false, isError: true, })
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Delete Coupon",
                message: `Failed to delete coupon. ${response.payload}.`,
                date: new Date(),
                isError: true
            })
            return
        }

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Delete Coupon",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        window.location = "/coupons"

        this.setState({ isSuccess: true, isError: false, })

    }

    deleteCoupon = () => {
        this.setState({ isLoading: true })
        const that = this
        axios.get(ADMINURL + "coupons", {
            params: {
                g: "del",
                id: this.couponID
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleDeleteCoupon(response.data)
            })
            .catch(function (error) {
                that.handleDeleteCoupon({})
            })
    }

    resetCouponData = () => {

        this.setState({
            couponEdited: _.cloneDeep(this.state.coupon),
            exclusionListEdited: _.cloneDeep(this.state.exclusionList),
            inclusionListEdited: _.cloneDeep(this.state.inclusionList),
        })

        window.scrollTo(0, 0);
    }


    render() {
        if (this.state.isLoading) {
            return (
                <div className="p-2">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }
        if (this.state.isError) {
            return (
                <div className="p-2 text-danger">
                    <span>Some error has occurred.</span>
                    {/* <span>{this.state.coupon}</span> */}
                </div>
            )
        }
        if (this.state.isSuccess) {
            /* prepare inclusion list */

            var inclusionList = []

            for (var i in this.state.inclusionListEdited) {
                const idx = i
                inclusionList.push(
                    <div className="p-2 border mb-1 d-flex justify-content-between" key={i}>
                        <span>
                            {this.state.inclusionListEdited[i].user[0].f}&nbsp;{this.state.inclusionListEdited[i].user[0].l}&nbsp;|&nbsp;{this.state.inclusionListEdited[i].user[0].e}&nbsp;|&nbsp;{this.state.inclusionListEdited[i].user[0].ph}
                        </span>
                        <span>
                            <span style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                const tmpInclusionListEdited = this.state.inclusionListEdited
                                delete tmpInclusionListEdited[idx]
                                this.setState({ inclusionListEdited: tmpInclusionListEdited })
                            }}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </span>
                    </div>
                )
            }


            /* prepare exclusion list */

            var exclusionnList = []

            for (var i in this.state.exclusionListEdited) {
                const idx = i
                exclusionnList.push(
                    <div className="p-2 border mb-1 d-flex justify-content-between" key={i}>
                        <span>
                            {this.state.exclusionListEdited[i].user[0].f}&nbsp;{this.state.exclusionListEdited[i].user[0].l}&nbsp;|&nbsp;{this.state.exclusionListEdited[i].user[0].e}&nbsp;|&nbsp;{this.state.exclusionListEdited[i].user[0].ph}
                        </span>
                        <span>
                            <span style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                const tmpExclusionListEdited = this.state.exclusionListEdited
                                delete tmpExclusionListEdited[idx]
                                this.setState({ exclusionListEdited: tmpExclusionListEdited })
                            }}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </span>
                    </div>
                )
            }
            return (
                <div className="p-2">
                    <div className="form-group p-2">
                        <Link className="btn-link" to={`/coupons`}><FontAwesomeIcon icon={faArrowLeft} /> Coupons</Link>
                        {/* <Link to="/orders"><FontAwesomeIcon icon={faArrowLeft} /> Orders</Link> */}
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                Update Coupon
                            </div>
                            <div className="form-group">
                                <h3>
                                    {this.state.coupon.c}
                                </h3>
                            </div>
                            <div className="form-group">
                                Created On:&nbsp;{DateTime.fromISO(this.state.coupon.t).toLocaleString(DateTime.DATETIME_MED)}&nbsp;({DateTime.fromISO(this.state.coupon.t).toRelative().toString()})
                            </div>
                            <div className="form-group">
                                Updated On:&nbsp;{DateTime.fromISO(this.state.coupon.u).toLocaleString(DateTime.DATETIME_MED)}&nbsp;({DateTime.fromISO(this.state.coupon.u).toRelative().toString()})
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponAmount">Amount</label>
                                <input type="number" className="form-control" id="couponAmount" value={this.state.couponEdited.a} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.a = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                                <small>Amount in number or percentage with which to calculate coupon worth.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponUpToAmount">Up To Amount</label>
                                <input type="number" className="form-control" id="couponUpToAmount" value={this.state.couponEdited.ua} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.ua = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                                <small>Amount in number which limits the maximum discount that will be applied. Leave 0 for no limit.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="couponDiscountType">Discount Type</label>
                                <select className="form-control" value={this.state.couponEdited.dt} id="couponDiscountType" onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.dt = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }}>
                                    <option value="%">Percentage</option>
                                    <option value="d">Direct</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponCouponType">Coupon Type</label>
                                <select className="form-control" value={this.state.couponEdited.ct} id="couponCouponType" onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.ct = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })

                                }}>
                                    <option value="d">Discount</option>
                                    <option value="l">Less</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponValidityTo">Validity To</label>
                                <input type="datetime-local" className="form-control" id="couponValidityTo" value={this.state.couponEdited.v} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.v = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponValidityFrom">Validity From</label>
                                <input type="datetime-local" className="form-control" id="couponValidityFrom" value={this.state.couponEdited.vf} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.vf = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                                <small>Leave this field unchanged to activate coupon from this point in time.</small>
                            </div>
                            <div className="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="couponValidityLifetime" checked={this.state.couponEdited.vl} onChange={(e) => {
                                        const tmpCoupon = this.state.couponEdited
                                        tmpCoupon.vl = e.target.checked
                                        this.setState({ couponEdited: tmpCoupon })
                                    }} />
                                    <label class="form-check-label" for="couponValidityLifetime">
                                        Lifetime Valid?
                                    </label>
                                </div>

                            </div>
                            <div className="form-group">

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="couponWallet" checked={this.state.couponEdited.w} onChange={(e) => {
                                        const tmpCoupon = this.state.couponEdited
                                        tmpCoupon.w = e.target.checked
                                        this.setState({ couponEdited: tmpCoupon })
                                    }} />
                                    <label htmlFor="couponWallet">Is a voucher for wallet?</label>
                                </div>
                                <small>Coupon can only be used for redeeming rupees in wallet.</small>
                            </div>
                            <div className="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="couponShipping" checked={this.state.couponEdited.s} onChange={(e) => {
                                        const tmpCoupon = this.state.couponEdited
                                        tmpCoupon.s = e.target.checked
                                        this.setState({ couponEdited: tmpCoupon })
                                    }} />
                                    <label htmlFor="couponShipping">Is just for shipping?</label>
                                </div>
                                <small>Coupon is applicable on just shipping amount.</small>
                            </div>
                            <div className="form-group">

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="couponAllShipping" checked={this.state.couponEdited.as} onChange={(e) => {
                                        const tmpCoupon = this.state.couponEdited
                                        tmpCoupon.as = e.target.checked
                                        this.setState({ couponEdited: tmpCoupon })
                                    }} />
                                    <label htmlFor="couponAllShipping">Entire shipping value off?</label>
                                </div>
                                <small>If checked then entire shipping charges will be waived.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponValidityAmount">Valid Amount</label>
                                <input type="number" className="form-control" id="couponValidityAmount" value={this.state.couponEdited.va} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.va = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                                <small>Minimum amount required for the coupon to be applicable.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponMaxUsage">Max Usage</label>
                                <input type="number" className="form-control" id="couponMaxUsage" value={this.state.couponEdited.us} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.us = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                                <small>Maximum times a single person can redeem the coupon.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponMaxUsage">Overall Max Usage</label>
                                <input type="number" className="form-control" id="couponMaxUsage" value={this.state.couponEdited.ou} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.ou = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                                <small>Maximum times all the users combined can redeem the coupon.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="couponDescription">Description</label>
                                <textarea type="text" className="form-control" id="couponDescription" value={this.state.couponEdited.d} onChange={(e) => {
                                    const tmpCoupon = this.state.couponEdited
                                    tmpCoupon.d = e.target.value
                                    this.setState({ couponEdited: tmpCoupon })
                                }} />
                            </div>
                            <div className="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="couponEnabled" checked={this.state.couponEdited.e} onChange={(e) => {
                                        const tmpCoupon = this.state.couponEdited
                                        tmpCoupon.e = e.target.checked
                                        this.setState({ couponEdited: tmpCoupon })
                                    }} />
                                    <label class="form-check-label" for="couponEnabled">
                                        Coupon Enabled
                                    </label>
                                </div>

                            </div>
                            <div className="form-group">
                                Exclusion List
                                <div>
                                    {exclusionnList}
                                </div>
                                <button disabled={Object.size(this.state.inclusionListEdited)} className="btn btn-primary btn-sm" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Exclude User",
                                        message: <CouponUserSelecter onClick={(resultData) => {
                                            const tmpExclusionList = this.state.exclusionListEdited
                                            tmpExclusionList[resultData._id] = {
                                                user:
                                                    [{
                                                        f: resultData.f,
                                                        l: resultData.l,
                                                        e: resultData.e,
                                                        ph: resultData.ph,
                                                    }]
                                            }
                                            this.setState({ exclusionListEdited: tmpExclusionList })
                                            fullDialogDispatcher.dispatch({
                                                actionType: "close-dialog",
                                            })
                                        }} />
                                    })
                                }}>Add</button>
                            </div>
                            <div className="form-group">
                                Inclusion List
                                <div>
                                    {inclusionList}
                                </div>
                                <button className="btn btn-primary btn-sm" disabled={Object.size(this.state.exclusionListEdited)} onClick={() => {

                                    fullDialogDispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Include User",
                                        message: <CouponUserSelecter onClick={(resultData) => {
                                            const tmpInclusionList = this.state.inclusionListEdited
                                            tmpInclusionList[resultData._id] = {
                                                user:
                                                    [{
                                                        f: resultData.f,
                                                        l: resultData.l,
                                                        e: resultData.e,
                                                        ph: resultData.ph,
                                                    }]
                                            }

                                            this.setState({ inclusionListEdited: tmpInclusionList })
                                            fullDialogDispatcher.dispatch({
                                                actionType: "close-dialog",
                                            })
                                        }} />
                                    })
                                }}>Add</button>
                            </div>
                            <hr />
                            <div className="form-group">
                                <button disabled={this.state.isLoading} className="btn btn-primary m-1" onClick={this.updateCoupon}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Update Coupon</button>

                                <button disabled={this.state.isLoading} className="btn btn-primary m-1" onClick={this.resetCouponData}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Reset Data</button>

                                <button disabled={this.state.isLoading} className="btn btn-danger m-1" onClick={() => {
                                    fullDialogDispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: `Delete Coupon: ${this.state.coupon.c} `,
                                        message: <div className="">
                                            <div className="form-group">
                                                <button className="btn btn-primary m-1" onClick={() => {
                                                    fullDialogDispatcher.dispatch({
                                                        actionType: "close-dialog"
                                                    })
                                                }}>Cancel</button>
                                                <button className="btn btn-danger m-1" onClick={() => {
                                                    fullDialogDispatcher.dispatch({
                                                        actionType: "close-dialog"
                                                    })
                                                    this.deleteCoupon()
                                                }}>Delete</button>
                                            </div>
                                        </div>
                                    })
                                }}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Delete Coupon</button>
                            </div>
                        </div>

                        <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                            <div className="form-group">
                                View Coupon Usage
                            </div>
                            <div className="form-group">
                                <ItemSearch nameTitleKey="a" onClickCallback={(resultData) => { }} isResultLink={false} resultClickable={false} searchAllowed={false} gType="gcu" timeKey="t" urlEndPoint="coupons" subInfoKeys={[
                                    {
                                        "key": "m",
                                        "map": function (data) {
                                            if (data === true) {
                                                return "Manual"
                                            }
                                            return null
                                        }
                                    },


                                ]} urlParams={{
                                    id: this.couponID
                                }} />
                            </div>
                        </div>
                    </div>


                </div>
            )
        }
        return (
            null
        )
    }
}

function SingleCoupon() {
    let { id } = useParams();
    const history = useHistory();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<SingleCouponPage couponID={id} history={history} />} title={"Coupon"} />,
    ]

}

class CouponEdit extends React.Component {

    constructor(props) {
        super()
        this.onClickCallback = props.onClick

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false
        }
    }

    render() {
        return (
            <div className="p-2">

            </div>
        )
    }
}

class CouponUserSelecter extends React.Component {

    constructor(props) {
        super()
        this.onClickCallback = props.onClick

        this.state = {
            isLoading: false,
            isError: false,
            isSuccess: false
        }
    }

    render() {
        return (
            <div className="p-2">
                <ItemSearch nameTitleKey="f" onClickCallback={(resultData) => {
                    this.onClickCallback(resultData)
                }} isResultLink={false} subNameTitleKey={''} resultClickable={true} searchAllowed={true} gType="ecm" timeKey="" urlEndPoint="users" subInfoKeys={[
                    {
                        "key": "l",
                        "map": function (data) {
                            return data
                        }
                    },
                    {
                        "key": "ph",
                        "map": function (data) {
                            return data
                        }
                    },
                    {
                        "key": "e",
                        "map": function (data) {
                            return data
                        }
                    },
                ]} />
            </div>
        )
    }
}

class AddCoupon extends React.Component {

    constructor(props) {
        super()

        var toDate = DateTime.local().plus({ months: 1 }).toFormat("yyyy-LL-dd'T'HH:mm")
        var fromDate = DateTime.local().toFormat("yyyy-LL-dd'T'HH:mm")

        this.state = {
            coupon: "",
            amount: 0,
            upToAmount: 0,
            discountType: "%",
            couponType: "d",
            validityToDate: toDate,
            validityFromDate: fromDate,
            validAmount: 0,
            validityLifetime: false,
            maxUsage: 0,
            overallMaxUsage: 0,
            isForWallet: false,
            couponEnabled: true,
            exclusionList: {},
            inclusionList: {},
            description: "",
            isForShipping: false,
            allShippingOff: false,

            isLoading: false,
            isError: false,
            isSuccess: false
        }
    }

    handleAddCoupon = (response) => {
        this.setState({ isLoading: false })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to add coupon")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Add Coupon",
                message: `Failed to add coupon. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true, })
            return
        }
        const that = this

        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Add Coupon",
            message: `${response.payload}.`,
            date: new Date(),
            isSuccess: true
        })

        viewCouponsGetDataDispatcher.dispatch({
            actionType: "get-data"
        })

        window.scrollTo(0, 0);


    }

    addCoupon = () => {
        var couponData = {
            c: this.state.coupon,
            a: parseFloat(this.state.amount),
            ua: parseFloat(this.state.upToAmount),
            dt: this.state.discountType,
            ct: this.state.couponType,
            v: DateTime.fromFormat(this.state.validityToDate, "yyyy-LL-dd'T'HH:mm").toString(),
            vf: DateTime.fromFormat(this.state.validityFromDate, "yyyy-LL-dd'T'HH:mm").toString(),
            va: parseFloat(this.state.validAmount),
            vl: this.state.validityLifetime,
            us: parseInt(this.state.maxUsage),
            ou: parseInt(this.state.overallMaxUsage),
            w: this.state.isForWallet,
            d: this.state.description,
            e: this.state.couponEnabled,
            s: this.state.isForShipping,
            as: this.state.allShippingOff
        }

        /* prepare exclusion and inclusion list */
        var tmpInclusionList = {}
        var tmpExclusionList = {}

        for (var i in this.state.inclusionList) {
            tmpInclusionList[i] = {}
        }
        for (var i in this.state.exclusionList) {
            tmpExclusionList[i] = {}
        }
        var postData = {
            c: couponData,
            i: tmpInclusionList,
            e: tmpExclusionList
        }

        this.setState({ isLoading: true })
        const that = this
        axios.post(ADMINURL + "coupons",
            postData
            , {
                params: {
                    g: "add",
                },
                headers: { Authorization: ACCT },

            })
            .then(function (response) {
                that.handleAddCoupon(response.data)
            })
            .catch(function (error) {
                that.handleAddCoupon({})
            })
    }


    render() {

        /* prepare inclusion list */

        var inclusionList = []

        for (var i in this.state.inclusionList) {
            const idx = i
            inclusionList.push(
                <div className="p-2 border mb-1 d-flex justify-content-between" key={i}>
                    <span>
                        {this.state.inclusionList[i].f}&nbsp;{this.state.inclusionList[i].l}&nbsp;|&nbsp;{this.state.inclusionList[i].e}&nbsp;|&nbsp;{this.state.inclusionList[i].ph}
                    </span>
                    <span>
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            const tmpInclusionList = this.state.inclusionList
                            delete tmpInclusionList[idx]
                            this.setState({ inclusionList: tmpInclusionList })
                        }}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </span>
                </div>
            )
        }


        /* prepare exclusion list */

        var exclusionnList = []

        for (var i in this.state.exclusionList) {
            const idx = i
            exclusionnList.push(
                <div className="p-2 border mb-1 d-flex justify-content-between" key={i}>
                    <span>
                        {this.state.exclusionList[i].f}&nbsp;{this.state.exclusionList[i].l}&nbsp;|&nbsp;{this.state.exclusionList[i].e}&nbsp;|&nbsp;{this.state.exclusionList[i].ph}
                    </span>
                    <span>
                        <span style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            const tmpExclusionList = this.state.exclusionList
                            delete tmpExclusionList[idx]
                            this.setState({ exclusionList: tmpExclusionList })
                        }}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </span>
                </div>
            )
        }
        return (
            <div className="p-2">
                <div className="form-group">
                    Add New Coupon
                </div>
                <div className="form-group">
                    <label htmlFor="couponCoupon">Coupon</label>
                    <input type="text" className="form-control" id="couponCoupon" value={this.state.coupon} onChange={(e) => {
                        this.setState({ coupon: e.target.value })
                    }} />
                    <small>Leave the field blank for random coupon string.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponAmount">Amount</label>
                    <input type="number" className="form-control" id="couponAmount" value={this.state.amount} onChange={(e) => {
                        this.setState({ amount: e.target.value })
                    }} />
                    <small>Amount in number or percentage with which to calculate coupon worth.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponUpToAmount">Up To Amount</label>
                    <input type="number" className="form-control" id="couponUpToAmount" value={this.state.upToAmount} onChange={(e) => {
                        this.setState({ upToAmount: e.target.value })
                    }} />
                    <small>Amount in number which limits the maximum discount that will be applied. Leave 0 for no limit.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponDiscountType">Discount Type</label>
                    <select className="form-control" value={this.state.discountType} id="couponDiscountType" onChange={(e) => {
                        this.setState({ discountType: e.target.value })
                    }}>
                        <option value="%">Percentage</option>
                        <option value="d">Direct</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="couponCouponType">Coupon Type</label>
                    <select className="form-control" value={this.state.couponType} id="couponCouponType" onChange={(e) => {
                        this.setState({ couponType: e.target.value })
                    }}>
                        <option value="d">Discount</option>
                        <option value="l">Less</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="couponValidityTo">Validity To</label>
                    <input type="datetime-local" className="form-control" id="couponValidityTo" value={this.state.validityToDate} onChange={(e) => {
                        this.setState({ validityToDate: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label htmlFor="couponValidityFrom">Validity From</label>
                    <input type="datetime-local" className="form-control" id="couponValidityFrom" value={this.state.validityFromDate} onChange={(e) => {
                        this.setState({ validityFromDate: e.target.value })
                    }} />
                    <small>Leave this field unchanged to activate coupon from this point in time.</small>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="couponValidityLifetime" checked={this.state.validityLifetime} onChange={(e) => {
                            this.setState({ validityLifetime: e.target.checked })
                        }} />
                        <label class="form-check-label" for="couponValidityLifetime">
                            Lifetime Valid?
                        </label>
                    </div>

                </div>
                <div className="form-group">

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="couponWallet" checked={this.state.isForWallet} onChange={(e) => {
                            this.setState({ isForWallet: e.target.checked })
                        }} />
                        <label htmlFor="couponWallet">Is a voucher for wallet?</label>
                    </div>
                    <small>Coupon can only be used for redeeming rupees in wallet.</small>
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="couponShipping" checked={this.state.isForShipping} onChange={(e) => {
                            this.setState({ isForShipping: e.target.checked })
                        }} />
                        <label htmlFor="couponShipping">Is just for shipping?</label>
                    </div>
                    <small>Coupon is applicable on just shipping amount.</small>
                </div>
                <div className="form-group">

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="couponAllShipping" checked={this.state.allShippingOff} onChange={(e) => {
                            this.setState({ allShippingOff: e.target.checked })
                        }} />
                        <label htmlFor="couponAllShipping">Entire shipping value off?</label>
                    </div>
                    <small>If checked then entire shipping charges will be waived.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponValidityAmount">Valid Amount</label>
                    <input type="number" className="form-control" id="couponValidityAmount" value={this.state.validAmount} onChange={(e) => {
                        this.setState({ validAmount: e.target.value })
                    }} />
                    <small>Minimum amount required for the coupon to be applicable.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponMaxUsage">Max Usage</label>
                    <input type="number" className="form-control" id="couponMaxUsage" value={this.state.maxUsage} onChange={(e) => {
                        this.setState({ maxUsage: e.target.value })
                    }} />
                    <small>Maximum times a single person can redeem the coupon.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponMaxUsage">Overall Max Usage</label>
                    <input type="number" className="form-control" id="couponMaxUsage" value={this.state.overallMaxUsage} onChange={(e) => {
                        this.setState({ overallMaxUsage: e.target.value })
                    }} />
                    <small>Maximum times all the users combined can redeem the coupon.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="couponDescription">Description</label>
                    <textarea type="text" className="form-control" id="couponDescription" value={this.state.description} onChange={(e) => {
                        this.setState({ description: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="couponEnabled" checked={this.state.couponEnabled} onChange={(e) => {
                            this.setState({ couponEnabled: e.target.checked })
                        }} />
                        <label class="form-check-label" for="couponEnabled">
                            Coupon Enabled
                        </label>
                    </div>

                </div>
                <div className="form-group">
                    Exclusion List
                    <div>
                        {exclusionnList}
                    </div>
                    <button disabled={Object.size(this.state.inclusionList)} className="btn btn-primary btn-sm" onClick={() => {
                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Exclude User",
                            message: <CouponUserSelecter onClick={(resultData) => {
                                const tmpExclusionList = this.state.exclusionList
                                tmpExclusionList[resultData._id] = {
                                    f: resultData.f,
                                    l: resultData.l,
                                    e: resultData.e,
                                    ph: resultData.ph,
                                }
                                this.setState({ exclusionList: tmpExclusionList })
                                fullDialogDispatcher.dispatch({
                                    actionType: "close-dialog",
                                })
                            }} />
                        })
                    }}>Add</button>
                </div>
                <div className="form-group">
                    Inclusion List
                    <div>
                        {inclusionList}
                    </div>
                    <button className="btn btn-primary btn-sm" disabled={Object.size(this.state.exclusionList)} onClick={() => {

                        fullDialogDispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Include User",
                            message: <CouponUserSelecter onClick={(resultData) => {
                                const tmpInclusionList = this.state.inclusionList
                                tmpInclusionList[resultData._id] = {
                                    f: resultData.f,
                                    l: resultData.l,
                                    e: resultData.e,
                                    ph: resultData.ph,
                                }
                                this.setState({ inclusionList: tmpInclusionList })
                                fullDialogDispatcher.dispatch({
                                    actionType: "close-dialog",
                                })
                            }} />
                        })
                    }}>Add</button>
                </div>

                <hr />
                <div className="form-group">
                    <button disabled={this.state.isLoading} className="btn btn-primary" onClick={this.addCoupon}><span hidden={!this.state.isLoading}><FontAwesomeIcon icon={faCircleNotch} spin />&nbsp;</span>Add Coupon</button>
                </div>
            </div>
        )
    }
}

class ViewAllCoupons extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {

        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    View All Coupons
                </div>
                <div className="form-group">
                    <small>Click on coupon to edit</small>
                </div>
                <div className="form-group">
                    <ItemSearch nameTitleKey="c" onClickCallback={(resultData) => {
                        // fullDialogDispatcher.dispatch({
                        //     actionType: "show-dialog",
                        //     title: "Edit Coupon",
                        //     dialogWidth: 2,
                        //     message: <CouponEdit data={resultData} />
                        // })
                    }} isResultLink={true} resultLink={`/coupons/`} resultClickable={true} searchAllowed={true} gType="get" timeKey="t" urlEndPoint="coupons" subInfoKeys={[
                        {
                            "key": "d",
                            "map": function (data) {
                                if (data.length > 0) {
                                    return <span style={{
                                        whiteSpace: 'pre-wrap'
                                    }}>{data}</span>
                                }
                                return null
                            }
                        },
                    ]} urlParams={{}
                    } getDataCaller={(dispatcher) => {
                        viewCouponsGetDataDispatcher = dispatcher
                    }} />
                </div>
            </div>
        )
    }
}

class CouponsPage extends React.Component {

    constructor(props) {
        super()
        var urlString = window.location

        var currURL = new URL(urlString)
        var show = currURL.searchParams.get("show")
        var status = currURL.searchParams.get("status")
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <div className="row m-0">
                    <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                        <AddCoupon />
                    </div>

                    <div className="col-xl-6 col-md-6 col-6 col-sm-12 ">
                        <ViewAllCoupons />
                    </div>
                </div>
            </div>
        )
    }
}

function Coupons() {
    let { id } = useParams();

    return [
        <div>
            <NavBar />
        </div>,
        <Window content={<CouponsPage urlID={id} />} title={"Coupons"} />,
        // <Window content={<ContactPage />} title={"Contact Page"} />,

        // <AboutPage />
    ]

}


export {
    Coupons,
    SingleCoupon
}