import React from 'react'
import NavBar from '../Components/NavBar'
import { Window } from '../Components/Window'
import axios from 'axios';
import Cookies from 'js-cookie';

import { toastsDispatcher } from '../Components/Toasts';
import { fullDialogV2Dispatcher } from '../Components/FullDialogV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faShare } from '@fortawesome/free-solid-svg-icons';
import { ADMINURL } from '../Components/MSURLS';
var ACCT = Cookies.get('ACCT')


class HomePageGeneral extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            disablingStyle: {

            }
        }
    }

    handleReloadStaticContent = (response) => {
        this.setState({ isLoading: false, disablingStyle: {} })

        if (!("status" in response) || !("payload" in response)) {
            alert("Unable to reload static content")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Reload Static Content",
                message: `Unable to reload static content. ${response.payload}.`,
                date: new Date(),
                isError: true
            })

            this.setState({ isSuccess: false, isError: true })
            return
        }
        toastsDispatcher.dispatch({
            actionType: 'add-toast',
            title: "Reload Static Content",
            message: `Successfully reloaded static content.`,
            date: new Date(),
            isSuccess: true
        })
    }

    reloadStaticContent = () => {
        const that = this
        this.setState({
            isLoading: true, disablingStyle: {
                pointerEvents: "none",
                opacity: "0.5"
            }
        })
        axios.get(ADMINURL + "admin", {
            params: {
                g: "rsc",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.handleReloadStaticContent(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleReloadStaticContent({})
            })
    }

    render() {
        return (
            <div className="p-2" style={this.state.disablingStyle}>
                <div className="form-group">
                    <span style={{
                        fontWeight: 'bolder'
                    }}>Static Content</span>
                    <br />
                    <span className="btn btn-primary mr-2 " onClick={() => {
                        this.reloadStaticContent()
                    }}>
                        Reload Static Content
                    </span>
                </div>
                <div className="form-group">
                    <span style={{
                        fontWeight: 'bolder'
                    }}>Analytics</span>
                    <br />
                    <a target="_blank" className="btn btn-primary mr-2 " href="https://analytics.google.com/">
                       <FontAwesomeIcon icon={faExternalLinkAlt}/>&nbsp;Google Analytics
                    </a>
                </div>
            </div>
        )
    }
}

class Home extends React.Component {

    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        return [<div>
            <NavBar />
        </div>,
        <Window content={<HomePageGeneral />} title={"General Settings"} />,
           
        
        ]
    }
}

export {
    Home
}